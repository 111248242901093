import { useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Text from '../../components/common/Text';
import { Input } from '../../components/Input/Input';
import { useAction } from '../../hooks';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { saveWalletAction, setMnemonicAction } from '../../modules/wallet/actions';
import AccountService from '../../services/symbol/AccountService';
import { RootState } from '../../types';
import { Toast } from '../../utils/toastHelper';
import { ReactComponent as HideIcon } from './../../icons/hide.svg';
import { ReactComponent as ShowIcon } from './../../icons/show.svg';
import { COPY_SEED_PHRASE_STEP } from './constants';
import './WalletCreation.scss';

const WalletCreation: FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [isBloored, setIsBloored] = useState(true);
  const [step, setStep] = useState(COPY_SEED_PHRASE_STEP);
  const { mnemonic } = useSelector((state: RootState) => state.wallet);
  const setMnemonic = useAction(setMnemonicAction);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const { mnemonic } = await AccountService.createRandomMnemonic();
        await setMnemonic({ mnemonic });
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  const copyToClipboard = useCallback(() => {
    navigator.clipboard.writeText(mnemonic as string).then(() => {
      Toast.info(t('_CopiedToClipboard_'));
    });
  }, [mnemonic]);

  const onSubmit = async () => {
    if (step.stepNumber === 1) {
      setStep(step.nextStep);
      return;
    } else if (step.stepNumber === 2) {
      setIsLoading(true);
      dispatch(saveWalletAction({ mnemonic }));
      setIsLoading(false);
    }
    history.push('/products');
  };

  const { values, setFieldValue } = useFormik({
    initialValues: { mnemonic: '' },
    onSubmit,
    validateOnChange: true,
  });
  const handleBack = useCallback(() => {
    setStep(COPY_SEED_PHRASE_STEP);
  }, []);

  const disabled = mnemonic !== values.mnemonic;

  return (
    <div className="wallet-creation">
      <div className="page-header">
        {step.stepNumber === 2 && (
          <div className={'signup__back-button'} onClick={handleBack}>
            <ArrowIcon />
            <span>{t('_Back_')}</span>
          </div>
        )}
        <h2 className="auth__title">{t(step.title)}</h2>
        <p className="auth__subtitle">{t(step.subtitle)}</p>
        <p className="auth__description">{t(step.description)}</p>
      </div>

      <div className="wallet-creation__form-wrapper">
        {step.stepNumber === 1 ? (
          <Input
            type="text"
            label={t('_YourSeedPhrase_')}
            placeholder={t('_EnterYourSeedPhrase_')}
            value={(mnemonic as string) || ''}
            isTextArea
            isReadOnly
            className={isBloored && mnemonic ? 'blooredText' : ''}
          />
        ) : (
          <Input
            type="text"
            value={values.mnemonic}
            label={t('_YourSeedPhrase_')}
            placeholder={t('_EnterYourSeedPhrase_')}
            name="mnemonic"
            onChangeTextArea={(e: React.ChangeEvent<HTMLTextAreaElement>) => setFieldValue('mnemonic', e.target.value)}
            isTextArea
          />
        )}
        {step.stepNumber === 1 && (
          <Text
            color="primary-blue"
            align="right"
            type="subtitle_s"
            onClick={copyToClipboard}
            className="pointer bloored-button"
          >
            {t('_Copy_')}
          </Text>
        )}
        {step.stepNumber === 1 &&
          (isBloored ? (
            <HideIcon onClick={() => setIsBloored(false)} />
          ) : (
            <ShowIcon onClick={() => setIsBloored(true)} />
          ))}
        <p className="wallet-creation__warning">{t(step.warningText)}</p>

        <Button
          text={t(step.buttonValue)}
          isDisabled={step.stepNumber !== 1 && disabled}
          onClick={onSubmit}
          isPrimary
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default WalletCreation;
