import { Auth } from 'aws-amplify';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import Text from '../../components/common/Text';
import Loader from '../../components/Loader';
import { ReactComponent as SuccessIcon } from '../../icons/accepted.svg';
import { ReactComponent as FailIcon } from '../../icons/denied.svg';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { UserApi } from '../../services/api/UserApi';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { Toast } from '../../utils/toastHelper';
import styles from './styles.module.scss';

const KYC_Response: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [isVerified, setIsVerified] = useState(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [KYCReason, setKYCReason] = useState<string>('');

  useEffect(() => {
    setLoading(true);
    Auth.currentAuthenticatedUser()
      .then((user) => {
        UserApi.getUser(user.signInUserSession.idToken.payload['cognito:username']).then((res) => {
          if (res.data.kyc_status === 'accepted') {
            setIsVerified(true);
          } else if (res.data.kyc_status === 'cancelled') {
            setKYCReason(t('_KYC_is_cancelled_'));
            setIsVerified(false);
          } else {
            setKYCReason(res.data.kyc_reason);
            setIsVerified(false);
          }
        });
        setLoading(false);
      })
      .catch(() => {
        setIsVerified(false);
        setLoading(false);
        history.push('/kyc-finish');
      });
  }, []);

  useLayoutEffect(() => {
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.parentNode.removeChild(iframe);
    }
  }, []);

  const handleStart = async () => {
    try {
      if (localStorage.getItem('authFlow') === 'registration') {
        history.push('/wallet-creation');
        return;
      }

      if (!localStorage.getItem('MNEMONIC_MODEL')) {
        history.push('/wallet-connect');
        return;
      }

      const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
      const mnemonic = mnemonicModel.mnemonic;
      dispatch(
        saveWalletAction({
          mnemonic,
          onSuccess: () => {
            dispatch(networkInitStateAction());
            history.push('/products');
          },
          onError: () => {
            history.push('/wallet-connect');
          },
        }),
      );
    } catch (e) {
      return Toast.error(e);
    }
  };

  return (
    <div className={styles.container}>
      {loading || typeof isVerified !== 'boolean' ? (
        <div className={styles['loader_wrapper']}>
          <Loader />
        </div>
      ) : (
        <div className={styles.container__content}>
          <Text type="h1" color="secondary-dark-blue" className="Visby_Bold">
            {isVerified ? t('_Congratulations_') : t('_Verification_failed_')}
          </Text>
          <div className={styles['verification-text-container']}>
            <Text type="subtitle_m" color="ultra-dark-gray">
              {isVerified ? t('_Congratulation_text_') : t('_Failure_text_')}
            </Text>
            {KYCReason && (
              <Text type="subtitle_m" color="tertiary-orange">
                {KYCReason}
              </Text>
            )}
          </div>

          <div className={styles.container__content__img}>{isVerified ? <SuccessIcon /> : <FailIcon />}</div>
          {isVerified ? (
            <div className={styles.container__content__btns}>
              <Button appearence="primary" text={t('_Get_started_')} onClick={handleStart} />
            </div>
          ) : (
            <div className={styles.container__content__btns}>
              <Button appearence="primary" text={t('_Try_again_')} onClick={() => history.push('/kyc')} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KYC_Response;
