/*eslint-disable*/
import store from '../redux/store';
import { BaseSecureStorage } from '../storage/persistence/BaseSecureStorage';
import { db } from './db';
import { ProductDB } from './productDB.interface';

class ProductsDBService {
  constructor() {
    db.products.hook('creating', (primKey, obj, transaction): void => {
      this._modifyPrivateKeys(obj, 'encrytKey');
    });

    db.products.hook('updating', (modifications, primKey, obj, transaction): void => {
      this._modifyPrivateKeys(obj, 'encrytKey');
    });

    db.products.hook('reading', (obj): ProductDB.Product | ProductDB.Product[] => {
      if (obj) {
        return this._modifyPrivateKeys(obj, 'decrypKey');
      }
      return obj;
    });
  }

  private _modifyPrivateKeys(obj: ProductDB.Product, method: 'encrytKey' | 'decrypKey'): ProductDB.Product {
    if (obj.privateKey && typeof obj.privateKey == 'string') {
      obj.privateKey = <string>BaseSecureStorage[method](obj.privateKey);
    }
    if (obj.account.privateKey && typeof obj.account.privateKey == 'string') {
      obj.account.privateKey = <string>BaseSecureStorage[method](obj.account.privateKey);
    }
    return obj;
  }

  public add(item: ProductDB.Product): Promise<number> {
    return db.products.add(item).catch((e) => {
      console.info(e.message);
    });
  }

  public getAll(): Promise<ProductDB.Product[]> {
    const { auth } = store.getState();
    if (auth && auth.username) {
      return db.products.where({ userId: auth?.username }).toArray();
    } else {
      return Promise.resolve([]);
    }
  }

  public getByBulkId(bulkId: string): Promise<ProductDB.Product[]> {
    return db.products.where({ bulkId }).toArray();
  }

  public get(publicKey: string): Promise<ProductDB.Product | undefined> {
    return db.products.get({ publicKey });
  }

  public update(id: number, newItemData: Partial<ProductDB.Product>): Promise<number> {
    return db.products.update(id, newItemData);
  }

  public delete(id: number): Promise<void> {
    return db.products.delete(id);
  }
}

export const productsDBService = new ProductsDBService();
