import dayjs from 'dayjs';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../../components/common/Text';
import { useRedirect } from '../../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg';
import { editUserProfile } from '../../../modules/user/actions';
import { CommonHelpers } from '../../../utils/commonHelpers';
import { COUNTRY_LIST } from '../../../utils/constants';
import { Toast } from '../../../utils/toastHelper';
import ProfileInfo from '../../SignUp/ProfileInfo';
import './styles.scss';

const getCountryName = (code: any) => {
  const selected = COUNTRY_LIST.filter((item) => Object.values(item)?.[0] === code);
  return selected?.[0] && Object.keys(selected?.[0])?.[0];
};

const EditProfile = () => {
  const { t } = useTranslation();
  const { userData, loading } = useSelector((state: any) => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const handleBack = useRedirect('/settings');
  const [values, setValues] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    address: '',
    address2: '',
    birthdate: '',
    phone: '',
    country: { label: 'France', value: 'FR' },
    citizenship: { label: 'France', value: 'FR' },
  });
  useEffect(() => {
    if (userData) {
      const _values = {
        firstName: userData.info.firstName,
        middleName: userData.info.middleName,
        lastName: userData.info.lastName,
        address: userData.info.address,
        address2: userData.info.address2,
        birthdate: userData.info.birthdate,
        phone: userData.info.phone,
        country: { label: getCountryName(userData.info.country), value: userData.info.country },
        citizenship: userData.info.citizenship
          ? { label: getCountryName(userData.info.citizenship), value: userData.info.citizenship }
          : {
              label: 'France',
              value: 'FR',
            },
      };
      setValues(_values);
    }
  }, [loading]);
  const handleSubmit = () => {
    setIsLoading(true);
    const info = CommonHelpers.removeObjectEmptyFillds({
      firstName: values.firstName,
      middleName: values.middleName,
      lastName: values.lastName,
      country: values.country.value.toLocaleUpperCase(),
      citizenship: values.citizenship.value.toLocaleUpperCase(),
      address: values.address,
      address2: values.address2,
      birthdate: values.birthdate ? dayjs(values.birthdate).format() : '',
      phone: values.phone.startsWith('+') ? values.phone : '+' + values.phone,
      // "region": "",
    });

    if (!info.firstName) {
      setErrors({ firstName: t('_First_name_is_mandatory_') });
      setIsLoading(false);
      return;
    } else if (!info.lastName) {
      setErrors({ lastName: t('_Last_name_is_mandatory_') });
      setIsLoading(false);
      return;
    } else if (!info.phone || info.phone === '+') {
      setErrors({ phone: t('_Phone_is_mandatory_') });
      setIsLoading(false);
      return;
    } else if (!info.birthdate) {
      setErrors({ birthdate: t('_Date_of_birth_is_mandatory_') });
      setIsLoading(false);
      return;
    } else if (moment().diff(moment(values.birthdate), 'years') < 18) {
      setErrors({ birthdate: t('_Choose_valid_date_of_birth_') });
      setIsLoading(false);
      return;
    } else if (!info.address) {
      setErrors({ address: t('_Address_is_mandatory_') });
      setIsLoading(false);
      return;
    }

    dispatch(
      editUserProfile.req({
        params: { info },
        onSuccess: () => {
          Toast.success(t('_UserSuccessfullyUpdated_'));
          localStorage.setItem('country', info.country);
          setIsLoading(false);
          handleBack();
        },
        onFail: (message) => {
          if (message === '"info.birthdate" must be in ISO 8601 date format') {
            Toast.error(t('_Invalid_birthdate_format_'));
          }
          if (message === '"info.phone" length must be at least 10 characters long') {
            setErrors({ phone: '_PleaseEnterValidPhone_' });
          }
          if (!message.includes('info.phone"')) {
            Toast.error(message);
          }
          setIsLoading(false);
        },
      }),
    );
  };
  const handleChange = (name, value) => {
    setValues((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title authenticated-page-title">
          {t('_PersonalInfo_')}
        </Text>
      </div>
      <div className="inputGroups">
        <ProfileInfo
          values={values}
          buttonValue={t('_Save_')}
          handleSubmit={handleSubmit}
          handleSetFieldValue={handleChange}
          errors={errors}
          isButtonLoading={isLoading}
        />
      </div>
    </>
  );
};

export default EditProfile;
