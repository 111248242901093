import cn from 'classnames';
import { FC, InputHTMLAttributes } from 'react';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import styles from './Search.module.scss';

interface SearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  searchIcon?: boolean;
  wrappedClass?: string;
}

export const SearchInput: FC<SearchInputProps> = ({ searchIcon, wrappedClass, ...props }) => {
  return (
    <div className={cn(styles.search, wrappedClass)}>
      <input {...props} />
      {searchIcon && <SearchIcon />}
    </div>
  );
};
