import { createReducer } from 'deox';
import produce from 'immer';
import { setError } from '../errors/action';
import {
  logoutAction,
  setIdAction,
  setNotificationTokenAction,
  setPrevUsernameAction,
  setUserType,
  userLogin,
} from './actions';
import { AuthState } from './types';

const initialState: AuthState = {
  loading: false,
  error: null,
  username: '',
  id: null,
  notificationToken: null,
  attributes: {},
  prevUsername: null,
  userType: '',
};

export const authReducer = createReducer(initialState, (handle) => [
  handle(userLogin.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = null;
    }),
  ),
  handle(userLogin.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      Object.assign(draft, payload);
    }),
  ),
  handle(userLogin.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload;
    }),
  ),
  handle(setIdAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.id = payload;
    }),
  ),
  handle(setUserType, (state, { payload }) =>
    produce(state, (draft) => {
      draft.userType = payload;
    }),
  ),
  handle(setPrevUsernameAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.prevUsername = payload;
    }),
  ),
  handle(setNotificationTokenAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.notificationToken = payload;
    }),
  ),

  // handle(refreshTokens, (state, { payload }) =>
  //   produce(state, draft => {
  //     draft.token = payload.access;
  //     draft.refresh = payload.refresh;
  //   }),
  // ),
  handle(logoutAction, ({ prevUsername }) => ({
    ...initialState,
    prevUsername,
  })),

  handle(setError, (state, { payload }) =>
    produce(state, (draft) => {
      draft.error = payload;
    }),
  ),
]);
