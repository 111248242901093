import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Upload from 'react-images-uploading';
import { ReactComponent as PlusIcon } from '../../icons/addOrgLogo.svg';
import { ReactComponent as CloseIcon } from '../../icons/close-filled.svg';
import { ATTACHMENTS_IMAGES_SIZE_LIMIT } from '../../utils/constants';
import Text from '../common/Text';
import { ReactComponent as DefaultImage } from './../../icons/orgLogo.svg';
import styles from './costumUploader.styles.module.scss';
const acceptType = ['jpeg', 'jpg', 'png'];

interface IProps {
  label: string;
  isRequered: boolean;
  onChange: any;
  classname?: string;
  isMakeRemove?: any;
  images?: any;
  costumTitle?: string;
  costumText?: string;
}
const CostumUploader: FC<IProps> = ({
  label,
  isRequered,
  onChange,
  classname = '',
  isMakeRemove,
  images,
  costumTitle = '_AttachNecessaryFiles_',
  costumText = '_SupportedFiles_',
}) => {
  const { t } = useTranslation();
  const ref = useRef<null | HTMLDivElement>(null);

  const [imagesList, setImagesList] = useState<{ src: string }[]>([]);
  const [_errors, setErrors] = useState(null);
  useEffect(() => {
    setImagesList(images);
  }, [images]);
  const handleChange = (newImages: any) => {
    setImagesList(newImages);
    onChange(newImages.map((image: any) => ({ src: image.src })));
  };

  const onImageRemove = () => {
    setImagesList([]);
    onChange([]);
    isMakeRemove && isMakeRemove();
  };

  return (
    <div className={cn(styles.costumUploader, classname)}>
      {!!label && (
        <p className={styles['upload-wraper__label']}>
          {label} {isRequered && <span className="isRequired">*</span>}
        </p>
      )}
      {imagesList.length ? (
        <div className={cn(styles['image-upload__item'], 'image-item')}>
          <CloseIcon className={styles['image-upload__item-close']} onClick={onImageRemove} />
          <img
            src={
              imagesList[0].src.startsWith('https') || imagesList[0].src.startsWith('data')
                ? imagesList[0].src
                : process.env.REACT_APP_ATTACHMENTS_DOMAIN + imagesList[0].src
            }
            alt="foo"
          />
        </div>
      ) : (
        <>
          <div
            className={
              _errors
                ? cn(styles.costumUploader__infoWrapper, styles.costumUploader__infoWrapper__withErrors)
                : styles.costumUploader__infoWrapper
            }
          >
            <div className={styles.costumUploader__infoWrapper__imageRow}>
              <DefaultImage />
            </div>
            <div className={styles.costumUploader__infoWrapper__infoRow}>
              <Text type="caption" color="gray-darkness">
                {t(costumTitle)}
              </Text>
              <Text type="caption" color="ultra-dark-gray" fontWeight="bold">
                {t(costumText)}
              </Text>
            </div>
            {_errors && (
              <div className={styles.errorBlock}>
                {_errors.maxNumber && (
                  <span className={styles.errors}>
                    {t('_MaxNumberMessage_')} {1}
                  </span>
                )}
                {_errors.acceptType && (
                  <span className={styles.errors}>
                    {t('_AllowedFileTypes_')}:{' '}
                    {acceptType.reduce(
                      (acc, cur, currentIndex) => acc + `.${cur}${currentIndex !== acceptType.length - 1 ? ', ' : ''}`,
                      '',
                    )}
                  </span>
                )}
                {_errors.maxFileSize && <span className={styles.errors}>{t('_MaxFileSize_')}</span>}
              </div>
            )}
          </div>
          <Upload
            acceptType={acceptType}
            dataURLKey="src"
            multiple={false}
            onChange={handleChange}
            value={imagesList}
            maxFileSize={ATTACHMENTS_IMAGES_SIZE_LIMIT}
          >
            {({ onImageUpload, errors }) => {
              setErrors(errors);
              return (
                <>
                  <div className={cn(styles['image-upload'])} ref={ref} onClick={onImageUpload}>
                    <PlusIcon />
                  </div>
                </>
              );
            }}
          </Upload>
        </>
      )}
    </div>
  );
};

export default CostumUploader;
