import { useFormik } from 'formik';
import _ from 'lodash';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  Account,
  Address,
  AggregateTransaction,
  Deadline,
  Mosaic,
  MosaicId,
  NetworkType,
  PlainMessage,
  PublicAccount,
  Transaction,
  TransactionHttp,
  TransferTransaction,
  UInt64,
} from 'symbol-sdk';
import BusyLoader from '../../../../components/BusyLoader';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { ImageUpload } from '../../../../components/ImageUpload/ImageUpload';
import { Input } from '../../../../components/Input/Input';
import Loader from '../../../../components/Loader';
import { Modal } from '../../../../components/Modal/Modal';
import ProductField from '../../../../components/ProductField';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import WarningModal from '../../../../components/WarningModal';
import { useRedirect } from '../../../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../../../icons/closeX.svg';
import { ProductDB } from '../../../../indexedDB';
import { getOrganizations } from '../../../../modules/organization/actions';
import { createProductForCompany, editProductForCompany, getCategories } from '../../../../modules/product/actions';
import { loadAccountAction, loadAccountsBalancesAction } from '../../../../modules/wallet/actions';
import store, { GlobalListener } from '../../../../redux/store';
import { CommonApi } from '../../../../services/api';
import { ProductApi } from '../../../../services/api/ProductApi';
import { OrganizationApi } from '../../../../services/api/_OrganizationApi';
import AccountService from '../../../../services/symbol/AccountService';
import ListenerService from '../../../../services/symbol/ListenerService';
import NetworkService from '../../../../services/symbol/NetworkService';
import TransactionService from '../../../../services/symbol/TransactionService';
import { MosaicModel } from '../../../../storage/models/MosaicModel';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { CommonHelpers } from '../../../../utils/commonHelpers';
import { ATTACHMENTS_IMAGES_AMOUNT_LIMIT, BULK_CREATION_PRICE } from '../../../../utils/constants';
import { Toast } from '../../../../utils/toastHelper';
import { AddProductValidation } from '../../../../utils/validations';
import { CategoryForm } from '../../../FieldsConfiguration/components/CategoryForm/CategoryForm';
import {
  createEmptyProductState,
  createRequestPayloadForProduct,
  mapProductDetailsToPrdouctState,
} from '../../helpers';
import { WarrantyModal } from '../WarrantyModal';
import CreateTemplateModal from './CreateTemplateModal';
import './styles.scss';

interface Option {
  label: string;
  value: string;
}

const CreateProduct: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const params: any = useParams();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState(createEmptyProductState());
  const [fields, setFileds] = useState([]);
  const [warrantyOptions, setWarrantyOptions] = useState<any>([]);
  const [fieldsLoading, setFiledsLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [requestBack, setRequestBack] = useState(null);
  const [product, setProduct] = useState(null);
  const { userWarranties, categories } = useSelector((state: any) => state.products);
  const { organizations } = useSelector((state: any) => state.organization);
  const redirectToProducts = useRedirect('/products');
  const [progressBarValue, setProgressBarValue] = useState(0);
  const { _id: userId } = useSelector((state: any) => state.user.userData);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const isKycAccepted = useSelector((state: any) => state.user?.userData?.kyc_status === 'accepted');
  const { userData } = useSelector((state: any) => state.user);
  const [isCreateTemplateModalOpen, setIsCreateTemplateModalOpen] = useState(false);
  const [submitPayload, setSubmitPayload] = useState(null);

  useEffect(() => {
    dispatch(loadAccountAction({}));
  }, []);

  useEffect(() => {
    if (params?.alias) {
      setIsEditMode(true);
    }
    return () => {
      setIsEditMode(false);
    };
  }, []);

  useEffect(() => {
    if (isEditMode) {
      setIsBusy(true);
      ProductApi.getOne(params.alias)
        .then((res) => {
          setProduct(res.data);
          return res.data;
        })
        .then(mapProductDetailsToPrdouctState)
        .then((v) => setFormValues(v))
        .catch(console.error)
        .finally(() => setIsBusy(false));
    }
  }, [isEditMode]);

  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [isCategoiresCalled, setIsCategoriesCalled] = useState<any>(false);
  const [quantity, setQuantity] = useState<number>(1);
  const [productQuantityError, setProductQuantityError] = useState<string>('');
  const { username } = useSelector((state: any) => state.auth);
  const { balance } = useSelector((state: any) => state.account);
  const [isCreateCategoryModalOpen, setIsCreateCategoryModalOpen] = useState(false);
  const [isCreateCategoryLoading, setIsCreateCategoryLoading] = useState(false);

  useEffect(() => {
    if (!isCategoiresCalled) {
      dispatch(getOrganizations.req());
    }
  }, []);

  useEffect(() => {
    dispatch(loadAccountsBalancesAction());
    // AccountSecureStorage.removeAccount('CBEEBE6EFB2EBF9DF6F33F7E0FF6CBD2D1AF5C53F45238FCF5639F4327A8CFC5', 'testnet')
  }, [username]);
  useEffect(() => {
    if (organizations && !isCategoiresCalled) {
      if (organizations.data[0]) {
        setIsCategoriesCalled(true);
        dispatch(getCategories.req(organizations.data[0]._id));
      }
    }
  }, [organizations]);

  const sendEditRequest = async (payload: any) => {
    setIsBusy(true);
    try {
      !payload.warrantyId && delete payload.warrantyId;

      //@ts-ignore
      if (payload.info.images.length) {
        const images = [];
        const urlImages = [];
        for (let i = 0; i < payload.info.images.length; i++) {
          if (payload.info.images[i].src.startsWith('data')) {
            images.push(payload.info.images[i]);
          } else {
            if (payload.info.images[i].src.includes('http')) {
              urlImages.push(payload.info.images[i]);
            } else {
              const _ = {
                src: process.env.REACT_APP_ATTACHMENTS_DOMAIN + payload.info.images[i].src,
              };
              urlImages.push(_);
            }
          }
        }
        Promise.all(urlImages.map((img) => fetch(img.src)))
          .then((response) => {
            response.map((resObj) => {
              resObj.blob().then((blob) => {
                const reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onloadend = async function () {
                  const base64data: any = reader.result;
                  images.push({ src: base64data });
                  payload.info.images = images;
                  await TransactionService.productWalletTransfer({
                    payload: {
                      product: payload,
                      productId: product._id,
                      privateId: product?.privateQrCode?.link,
                      transactionPrice: '0',
                      onError: () => {
                        setIsBusy(false);
                      },
                      onSuccess: (checksum, wallet) => {
                        dispatch(
                          editProductForCompany.req(
                            {
                              id: product._id,
                              payloadData: {
                                ...payload,
                                checksum,
                                eventTransactionId: wallet.transactionHash,
                                transactionId: wallet.transactionHash,
                              },
                            },
                            () => {
                              setIsBusy(false);
                              redirectToProducts();
                            },
                            () => {
                              setIsBusy(false);
                            },
                          ),
                        );
                      },
                    },
                  });
                };
              });
            });
          })
          .catch(() => Toast.error(t('_SomethingWrongWithImages_')));
      } else {
        payload.info.images = [];
        await TransactionService.productWalletTransfer({
          payload: {
            product: payload,
            productId: product._id,
            privateId: product?.privateQrCode?.link,
            transactionPrice: '0',
            onError: () => {
              setIsBusy(false);
            },
            onSuccess: (checksum, wallet) => {
              dispatch(
                editProductForCompany.req(
                  {
                    id: product._id,
                    payloadData: {
                      ...payload,
                      checksum,
                      eventTransactionId: wallet.transactionHash,
                      transactionId: wallet.transactionHash,
                    },
                  },
                  () => {
                    setIsBusy(false);
                    redirectToProducts();
                  },
                  () => {
                    setIsBusy(false);
                  },
                ),
              );
            },
          },
        });
      }
    } catch (e) {
      console.error(e);
      setIsBusy(false);
      Toast.error(`${t('_ErrorWhileUpdatingProduct_')} ${JSON.stringify(e)}`);
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = createRequestPayloadForProduct(data);
      if (isEditMode) {
        sendEditRequest(payload);
      } else {
        setIsBusy(true);

        if (userData.isVerificationNeeded && !isKycAccepted) {
          Toast.error(t('_Verify_account_first_'));
          redirectToProducts();
          return;
        }
        const approvalStatus = organizations?.data?.[0]?.approvalStatus || organizations?.data?.approvalStatus;
        if (userData.isVerificationNeeded && approvalStatus !== 'accepted') {
          Toast.error(t('_Verify_organization_first_'));
          redirectToProducts();
          return;
        }
        if (+quantity < 1) {
          setIsBusy(false);
          return setProductQuantityError(t('_Quantity_cant_be_0_'));
        }
        if (+quantity > 1000) {
          setIsBusy(false);
          return setProductQuantityError(t('_Quantity_cant_exceed_1000_'));
        }
        if (+quantity * BULK_CREATION_PRICE > balance) {
          setIsBusy(false);
          return setProductQuantityError(t('_Insufficient_balance_to_create_X_products_', { quantity }));
        }

        const productLimits = await ProductApi.getProductLimits();
        if (productLimits.data.available === 0) {
          setIsBusy(false);
          return setProductQuantityError(t('_You_can_not_create_more_products_'));
        }

        if (productLimits.data.available < +quantity) {
          setIsBusy(false);
          return setProductQuantityError(t('_Can_create_only_X_products_', { quantity: productLimits.data.available }));
        }

        setSubmitPayload(payload);
        setIsCreateTemplateModalOpen(true);
        setIsBusy(false);
      }
    } catch (e: any) {
      setIsBusy(false);
      Toast.error(e.message);
    }
  };

  const handleCreateProductAndTemplate = async (saveAsTemplate: boolean) => {
    try {
      setIsBusy(true);
      setIsCreateTemplateModalOpen(false);

      if (+quantity < 1) {
        setIsBusy(false);
        return setProductQuantityError(t('_Quantity_cant_be_0_'));
      }
      if (+quantity > 1000) {
        setIsBusy(false);
        return setProductQuantityError(t('_Quantity_cant_exceed_1000_'));
      }
      if (+quantity * BULK_CREATION_PRICE > balance) {
        setIsBusy(false);
        return setProductQuantityError(t('_Insufficient_balance_to_create_X_products_', { quantity }));
      }

      const productLimits = await ProductApi.getProductLimits();
      if (productLimits.data.available === 0) {
        setIsBusy(false);
        return setProductQuantityError(t('_You_can_not_create_more_products_'));
      }

      if (productLimits.data.available < +quantity) {
        setIsBusy(false);
        return setProductQuantityError(t('_Can_create_only_X_products_', { quantity: productLimits.data.available }));
      }

      if (+quantity === 1) {
        await TransactionService.productWalletTransfer({
          payload: {
            product: submitPayload,
            transactionPrice: '0.0001',
            onError: () => {
              setIsBusy(false);
            },
            onSuccess: (checksum, wallet) => {
              dispatch(
                createProductForCompany.req({
                  params: {
                    ...submitPayload,
                    checksum,
                    _id: wallet.publicKey,
                    eventTransactionId: wallet.transactionHash,
                    transactionId: wallet.transactionHash,
                  },
                  wallet,
                  onSuccess: async () => {
                    try {
                      if (saveAsTemplate) {
                        await ProductApi.createProductTemplate({ ...submitPayload, quantity });
                      }
                      setIsBusy(false);
                      redirectToProducts();
                    } catch (error) {
                      setIsBusy(false);
                    }
                  },
                  onFail: () => {
                    setIsBusy(false);
                  },
                }),
              );
            },
          },
        });
      } else {
        const {
          account: { ownedMosaics, selectedAccountAddress },
          network: { selectedNetwork, network, selectedNode },
          wallet: { selectedAccount },
        } = store.getState();

        const mosaicName = selectedNetwork.currencyMosaicId;
        const mosaic: MosaicModel = _.cloneDeep(
          ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
        );
        mosaic.amount = parseFloat('0.0001') * Math.pow(10, mosaic?.divisibility);

        const networkType = selectedNetwork.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;

        const transactions: {
          transaction: Transaction;
          hashSum: string;
          publicKey: string;
          privateKey;
          account: ProductDB.Account;
        }[] = [];
        for (let i = 0; i < quantity; i++) {
          const mnemonicModel = AccountService.createRandomMnemonic();
          const productWalletAccount = AccountService.createFromMnemonicAndIndex(
            mnemonicModel.mnemonic,
            0,
            'wallet',
            network === 'testnet' ? 'testnet' : 'mainnet',
          );
          const hashSum = TransactionService._generateHash(
            { ...submitPayload, publicKey: productWalletAccount.id },
            'product',
          );
          const {
            // @ts-ignore
            address: { address: recipientAddress },
          } = PublicAccount.createFromPublicKey(
            productWalletAccount.id,
            network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET,
          );

          transactions.push({
            transaction: TransferTransaction.create(
              Deadline.create(selectedNetwork.epochAdjustment),
              Address.createFromRawAddress(recipientAddress),
              [new Mosaic(new MosaicId(mosaic.mosaicId), UInt64.fromUint(mosaic.amount))],
              PlainMessage.create(hashSum),
              networkType,
              UInt64.fromUint(0),
            ),
            hashSum,
            publicKey: productWalletAccount.id,
            privateKey: productWalletAccount.privateKey,
            account: {
              id: productWalletAccount.id,
              name: productWalletAccount.name,
              type: productWalletAccount.type,
              privateKey: productWalletAccount.privateKey,
              path: productWalletAccount.path,
              network: productWalletAccount.network,
            },
          });

          if (i % 50 === 0) {
            setProgressBarValue((i / quantity) * 100);

            // this breaks the loop and allows the UI to update
            await new Promise((resolve) => setTimeout(resolve, 0));
          }
        }

        const signerAccount = Account.createFromPrivateKey(selectedAccount.privateKey, networkType);

        const aggregateTransaction = AggregateTransaction.createComplete(
          Deadline.create(selectedNetwork.epochAdjustment),
          transactions.map((transaction) => transaction.transaction.toAggregate(signerAccount.publicAccount)),
          networkType,
          [],
          transactions[0].transaction.maxFee,
        );

        const signedTransaction = signerAccount.sign(aggregateTransaction, selectedNetwork.generationHash);

        GlobalListener.close();
        const blockChain: ListenerService = new ListenerService(
          async () => {
            try {
              blockChain.close();
              GlobalListener.listen(selectedAccountAddress);
              const res = await ProductApi.createProductBulk({
                ...submitPayload,
                transactions: transactions.map((transaction) => ({
                  checksum: transaction.hashSum,
                  walletId: transaction.publicKey,
                  eventTransactionId: signedTransaction.hash,
                  transactionId: signedTransaction.hash,
                })),
              });
              if (saveAsTemplate) {
                await ProductApi.createProductTemplate({ ...submitPayload, quantity });
              }
              transactions.forEach((transaction) => {
                ProductsSecureStorage.createNewProduct({
                  publicKey: transaction.publicKey,
                  privateKey: transaction.privateKey,
                  transactionHash: signedTransaction.hash,
                  userWallet: selectedAccount.id,
                  userId,
                  account: transaction.account,
                  bulkId: res.data._id,
                });
              });
              setIsBusy(false);
              redirectToProducts();
            } catch (error) {
              setIsBusy(false);
            }
          },
          (e) => {
            blockChain.close();
            GlobalListener.listen(selectedAccountAddress);
            console.error('blockchain error', e);
            setIsBusy(false);
          },
        );
        const networkS = await NetworkService.getNetworkModelFromNode(selectedNode);
        blockChain.setNetwork(networkS);
        await blockChain.listen(selectedAccountAddress);

        const transactionHttp = new TransactionHttp(selectedNetwork.node);
        transactionHttp.announce(signedTransaction).subscribe(
          // eslint-disable-next-line no-console
          (x) => console.log(x),
          (err) => console.error(err),
        );

        return;
      }
    } catch (error) {
      setIsBusy(false);
    }
  };

  useEffect(() => {
    const options =
      userWarranties?.warranties.map((item: any) => {
        return {
          value: item?._id,
          label: item?.title,
        };
      }) || [];
    options.push({ value: 'add', label: t('_AddNewWarranty_') });
    setWarrantyOptions(options);
  }, [userWarranties]);
  useEffect(() => {
    if (organizations) {
      const options = categories.map((item) => ({ value: item._id, label: item.name }));
      options.push({ value: 'add', label: t('_AddNewCategory_') });
      setCategoriesOptions(options);
    }
  }, [categories]);

  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: formValues,
    validateOnChange: false,
    validationSchema: AddProductValidation,
    onSubmit,
    enableReinitialize: true,
  });
  useEffect(() => {
    const { productName } = values;
    if (productName) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [values]);

  useEffect(() => {
    async function getCategoryFields() {
      setFiledsLoading(true);
      try {
        const _fieldsRes = await CommonApi.getFiledsForCategory(formValues.productCategory.value);
        if (CommonHelpers.isOkStatus(_fieldsRes.status)) {
          setFileds(_fieldsRes.data.fields);
        }
        setFiledsLoading(false);
      } catch {
        setFiledsLoading(false);
      }
    }
    if (isEditMode && formValues?.productCategory?.value) {
      getCategoryFields();
    }
  }, [isEditMode, formValues]);

  const onAddWarranty = (selectedOption: Option) => {
    setWarrantyOpened(true);
    setFieldValue('warranty', selectedOption);
  };

  const historyGoBack = () => {
    history.goBack();
  };
  const historyFieldManagment = () => {
    history.push({
      pathname: '/fields-management',
      state: { from: 'fromCagtegoryCreate' },
    });
  };

  const onAddCategory = () => {
    setIsCreateCategoryModalOpen(true);
  };

  const [warrantyOpened, setWarrantyOpened] = useState(false);

  const handleWarrantyCreate = () => {
    setWarrantyOpened(false);
  };

  const handleSetCategory = async (key, category) => {
    setFieldValue(key, category);
    setHasChange(true);
    setFiledsLoading(true);
    try {
      const _fieldsRes = await CommonApi.getFiledsForCategory(category.value);
      if (CommonHelpers.isOkStatus(_fieldsRes.status)) {
        setFileds(_fieldsRes.data.fields);
      }
      setFiledsLoading(false);
    } catch {
      setFiledsLoading(false);
    }
  };

  const handleSetProductFiled = (name, value, fieldInfo) => {
    setHasChange(true);
    const fields = {
      ...values.fields,
      [name]: { ...fieldInfo, value },
    };
    setFieldValue('fields', fields);
  };

  const onCancel = () => {
    if (hasChange) {
      setIsOpenWarning(true);
      setRequestBack(true);
    } else {
      historyGoBack();
    }
  };

  const createCategory = async (dto: any) => {
    try {
      setIsCreateCategoryLoading(true);

      const { data: addedCategory } = await OrganizationApi.addCategory(
        dto,
        isEmployee ? userData.inviteCode : userData.organizationId,
      );
      setIsCreateCategoryModalOpen(false);

      dispatch(getCategories.success([...categories, addedCategory]));

      handleSetCategory('productCategory', { value: addedCategory._id, label: addedCategory.name });

      Toast.success(`${t('_Category_')}: ${addedCategory.name} ${t('_created_')} !`);
    } catch (e) {
      Toast.error(`${JSON.stringify(e)}`);
    } finally {
      setIsCreateCategoryLoading(false);
    }
  };

  return (
    <div className="page-header CvCreation">
      <WarrantyModal
        onSubmit={handleWarrantyCreate}
        open={warrantyOpened}
        onClose={(warranty: any) => {
          if (warranty) {
            setFieldValue('warranty', {
              label: warranty.title,
              value: warranty._id,
            });
          }
          setWarrantyOpened(false);
        }}
      />

      <Modal
        open={isCreateCategoryModalOpen}
        onClose={() => {
          setIsCreateCategoryModalOpen(false);
        }}
      >
        <CategoryForm onSubmit={createCategory} loading={isCreateCategoryLoading} />
      </Modal>

      <div className="signup__back-button" onClick={historyGoBack}>
        <ArrowIcon />
        <span>{t('_Back_')}</span>
      </div>
      <Text type="h1" className="page__title">
        {t(isEditMode ? '_EditProduct_' : '_CreateProduct_')}
      </Text>
      <BusyLoader className="creation-container product-creation-container" isBusy={isBusy} progress={progressBarValue}>
        <div className="creation-submit-content flexible jBetween aStart create_container">
          <h2 className="modal__subtitle main_info-title">{t('_Details_')}</h2>
          <div className="create-product__row">
            <ImageUpload
              max={ATTACHMENTS_IMAGES_AMOUNT_LIMIT}
              label={t('_Photos_')}
              className="image-upload create-product-upload"
              images={values.images}
              onChange={(files) => {
                setFieldValue('images', files);
                setHasChange(true);
              }}
            />
            {errors && <p className="errors image-errors">{errors.images}</p>}
          </div>
          <div className="create-product__row">
            <div className="create-product__item">
              <Input
                type="text"
                labelClassName="product-create-label"
                name="productName"
                className="product-create-input"
                placeholder={t('_Name_')}
                isRequired
                label={t('_Name_')}
                value={values.productName}
                onChange={(e: any) => {
                  setFieldValue('productName', e.target.value);
                  setHasChange(true);
                }}
              />
              {errors && <p className="errors">{errors.productName}</p>}
            </div>
            <div className="create-product__item">
              <p className="label product-create-label">{t('_Warranty_')}</p>
              <Select
                value={
                  values.warranty?.value === 'add'
                    ? ''
                    : warrantyOptions.filter((option: any) => option.label === values.warranty.label)
                }
                onChange={(selectedOption: Option | any) => {
                  selectedOption.value === 'add'
                    ? onAddWarranty(selectedOption)
                    : setFieldValue('warranty', selectedOption);
                  setHasChange(true);
                }}
                options={warrantyOptions ? warrantyOptions : [{}]}
                name="warranty"
                className="product-create-input"
                classNamePrefix="input-select"
                placeholder={t('_startTyping_')}
                maxMenuHeight={240}
              />
              {values.warranty.label && values.warranty.value !== 'add' && (
                <div
                  className="removeWarrantyIcon"
                  onClick={() => {
                    setFieldValue('warranty', {});
                    setHasChange(true);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
              {errors && <p className="errors">{errors.warranty}</p>}
            </div>
          </div>
          {!isEditMode && (
            <div className="create-product__row">
              <div className="create-product__item">
                <Input
                  type="text"
                  labelClassName="product-create-label"
                  name="productName"
                  className="product-create-input"
                  placeholder={t('_Quantity_')}
                  label={t('_Quantity_')}
                  value={quantity}
                  onChange={(e: any) => {
                    if (!isNaN(e.target.value)) {
                      setQuantity(e.target.value.replace(/[^0-9]/g, ''));
                      setHasChange(true);
                    }
                  }}
                />
                <p className="errors">{productQuantityError}</p>
              </div>
            </div>
          )}
          <div className="create-product__row">
            <div className="create-product__item create-product__item_full">
              <p className="label product-create-label">{t('_Category_')}</p>
              <Select
                value={categoriesOptions.filter((option: any) => {
                  return option.label === values.productCategory.label || option.value === values.productCategory.value;
                })}
                onChange={(selectedOption) => {
                  selectedOption.value === 'add'
                    ? onAddCategory()
                    : handleSetCategory('productCategory', selectedOption);
                }}
                options={categoriesOptions ? categoriesOptions : [{}]}
                name="productCategory"
                className="product-create-input"
                classNamePrefix="input-select"
                placeholder={t('_startTyping_')}
                maxMenuHeight={240}
              />
              {/* @ts-ignore */}
              {errors && <p className="errors">{errors.productCategory?.value}</p>}
            </div>
          </div>
          <div className="create-product__row">
            <div className="create-product__item_full">
              <TextEditor
                placeholder={t('_Description_')}
                onChange={(text) => {
                  setFieldValue('description', text);
                  setHasChange(true);
                }}
                initialState={values.description}
              />

              {errors && <p className="errors">{errors.description}</p>}
            </div>
            {!!fields.length && (
              <>
                <h2 className="modal__subtitle custom_fields-title">{t('Custom fields')}</h2>
                <div className="create-product__row">
                  {fieldsLoading ? (
                    fields.map((field) => {
                      return (
                        <ProductField
                          key={field._id}
                          value={values.fields ? values.fields[field.name]?.value || '' : ''}
                          setFieldValue={handleSetProductFiled}
                          fieldInfo={field}
                        />
                      );
                    })
                  ) : (
                    <div className="loader_wrapper">
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </BusyLoader>
      <div className="flexible buttons-block">
        <Button isPrimary isDisabled={!isValid || isBusy} onClick={handleSubmit}>
          {isEditMode ? t('_Save_') : t('_Create_')}
        </Button>
        <Button isPrimaryOutline onClick={onCancel}>
          {t('_Cancel_')}
        </Button>
      </div>
      {isOpenWarning && (
        <WarningModal
          onClose={() => {
            setIsOpenWarning(false);
            setRequestBack(null);
          }}
          onSubmit={() => {
            requestBack ? historyGoBack() : historyFieldManagment();
          }}
        />
      )}
      <CreateTemplateModal
        isOpen={isCreateTemplateModalOpen}
        onClose={() => setIsCreateTemplateModalOpen(false)}
        onSubmit={handleCreateProductAndTemplate}
      />
    </div>
  );
};

export default CreateProduct;
