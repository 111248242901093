import { createAction } from 'deox';
import { UInt64 } from 'symbol-sdk';
import { HarvestedBlock, HarvestingModel, HarvestingStatus } from '../../storage/models/HarvestingModel';

export const harvestingInitAction = createAction('harvesting/HARVESTING_INIT');
export const loadStateAction = createAction('harvesting/LOAD_STATEN');
export const loadHarvestedBlocksAction = createAction('harvesting/LOAD_HARVESTED_BLOCKS');
export const loadHarvestedBlocksStatsAction = createAction('harvesting/LOAD_HARVESTED_BLOCKS_STATS');
export const loadHarvestingModelAction = createAction('harvesting/LOAD_HARVESTING_MODEL');
export const loadHarvestingNodesAction = createAction('harvesting/LOAD_HARVESTING_NODES');
export const resetStateAction = createAction('harvesting/RESET_STATE');
/*-----*/
export const setInitializedAction = createAction(
  'harvesting/SET_INITIALIZED',
  (res) => (payload: boolean) => res(payload),
);
/*-----*/
export const setHarvestedBlocksAction = createAction(
  'harvesting/SET_HARVESTED_BLOCKS',
  (res) => (blocks: HarvestedBlock[]) => res(blocks),
);
/*-----*/
export const setIsFetchingHarvestedBlocksAction = createAction('harvesting/SET_HARVESTED_BLOCKS');
/*-----*/

/*-----*/
export const setHarvestedBlocksPageInfoAction = createAction('harvesting/SET_HARVESTED_BLOCKS_PAGE_INFO');
/*-----*/

export const setStatusAction = createAction(
  'harvesting/SET_STATUS',
  (res) => (status: HarvestingStatus) => res(status),
);
export const setHarvestedBlockStatsAction = createAction(
  'harvesting/SET_HARVESTED_BLOCK_STATS',
  (res) => (blocks: { totalBlockCount: number; totalFeesEarned: UInt64 }) => res(blocks),
);
export const setIsFetchingHarvestedBlockStatsAction = createAction('harvesting/SET_IS_FETCHING_HARVESTED_BLOCK_STATS');
export const setHarvestingModelAction = createAction(
  'harvesting/SET_HARVESTING_MODEL',
  (res) => (model: HarvestingModel | null) => res(model),
);
export const setNodesAction = createAction(
  'harvesting/SET_NODES',
  (res) => (nodes: { publicKey: string; url: string }[]) => res(nodes),
);
