import { Auth } from 'aws-amplify';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { Network } from 'symbol-hd-wallets';
import { getWhitelistedPublicKeys } from '../../config/environment';
import { loadAllDataAction } from '../../modules/account/actions';
import { GlobalListener } from '../../redux/store/index';
import { CommonApi } from '../../services/api';
import AccountService from '../../services/symbol/AccountService';
import { AccountModel } from '../../storage/models/AccountModel';
import { AccountSecureStorage } from '../../storage/persistence/AccountSecureStorage';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import {
  loadAccountAction,
  loadAccountsBalancesAction,
  saveWalletAction,
  setAccountBalancesAction,
  setAccountsAction,
  setLoader,
  setLoading,
  setMnemonicAction,
  setSelectedAccountAction,
  walletInitStateAction,
} from './actions';

function* loadAccountsBalancesSaga(): Generator<any> {
  try {
    const {
      wallet: { accounts },
      network,
    }: any = yield select();
    const balancePairs: any = yield all(
      accounts.map(
        (account: AccountModel) =>
          new Promise((resolve) => {
            try {
              const rawAddress = AccountService.getAddressByAccountModelAndNetwork(account, network.network);
              AccountService.getBalanceAndOwnedMosaicsFromAddress(rawAddress, network.selectedNetwork).then((data) => {
                resolve({
                  id: account.id,
                  balance: data.balance,
                });
              });
            } catch (e) {
              resolve({
                id: account.id,
                balance: 0,
              });
            }
          }),
      ),
    );
    const accountBalances = balancePairs.reduce((acc, pair) => {
      acc[pair.id] = pair.balance;
      return acc;
    }, {});

    yield put(setAccountBalancesAction({ accountBalances }));
  } catch (e) {
    console.error(e);
    yield put(
      setAccountBalancesAction({
        accountBalances: {},
      }),
    );
  }
}

function* reloadAccountsSaga() {
  const {
    network: { network },
  } = yield select();
  // @ts-ignore
  const accounts = yield AccountSecureStorage.getAllAccountsByNetwork(network);
  yield put(setAccountsAction({ accounts }));
  yield put(loadAccountsBalancesAction());
}

function* loadAccountSaga({ payload }: ReturnType<typeof loadAccountAction>) {
  try {
    const { id } = payload;
    const { network } = yield select();

    let accountModel;
    if (id) {
      accountModel = yield AccountSecureStorage.getAccountById(id, network.network);
    } else {
      const m = yield AccountSecureStorage.getAllAccountsByNetwork(network.network);
      const index = Number(localStorage.getItem('accountIndex'));
      accountModel = m[index];
    }
    if (!accountModel) return;
    yield put(setSelectedAccountAction({ accountModel }));
    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(accountModel, network.network);
    try {
      GlobalListener.listen(rawAddress);
      // .then(console.log);
    } catch (e) {
      console.error(e);
    }
    yield put(loadAllDataAction(true));
    const {
      auth: { id: userId, notificationToken },
    } = yield select();

    try {
      // const {
      //   username,
      //   attributes: { email, name, phone_number, birthdate, address },
      // } = yield Auth.currentUserInfo();
      // yield UserApi.registerUser({
      //   _id,
      //   email,
      //   cognitoUserId: username,
      //   info: {
      //     fullName: name,
      //     address,
      //     birthdate,
      //     phone: phone_number,
      //   },
      // });
    } catch (e) {
      console.error(e);
    } finally {
      yield put(setLoading(100));
      yield put(setLoader(false));
    }
    try {
      if (userId) {
        // @ts-ignore
        const user = yield Auth.currentAuthenticatedUser();
        yield Auth.updateUserAttributes(user, {
          'custom:notificationToken': notificationToken,
        });
      }
    } catch (e) {
      console.error(e);
    }
  } catch (e) {
    console.error(e);
  }
}
function* walletInitStateSaga() {
  const {
    wallet: { accounts },
  } = yield select();
  try {
    // @ts-ignore
    const mnemonicModel = yield MnemonicSecureStorage.retrieveMnemonic();
    if (mnemonicModel) {
      yield put(setLoading(15));
      yield put(setMnemonicAction({ mnemonic: mnemonicModel.mnemonic }));
    }

    yield reloadAccountsSaga();
    if (accounts.length > 0) {
      yield put(loadAccountAction({}));
    }
    yield put(loadAccountsBalancesAction());
  } catch (error) {
    console.error(error);
  }
}

function* saveWalletSaga({ payload }) {
  const { mnemonic, onError, onSuccess } = payload;
  try {
    const { network } = yield select();
    yield put(setLoader(true));
    yield MnemonicSecureStorage.saveMnemonic(mnemonic);
    // let mnemonicModel = yield MnemonicSecureStorage.retrieveMnemonic();
    const mainnetAccountModel = AccountService.createFromMnemonicAndIndex(mnemonic, 0, 'wallet', 'mainnet');

    yield AccountSecureStorage.createNewAccount(mainnetAccountModel);

    const testnetAccountModel = AccountService.createFromMnemonicAndIndex(mnemonic, 0, 'wallet', 'testnet');

    yield AccountSecureStorage.createNewAccount(testnetAccountModel);
    const walletsResp = yield CommonApi.getWallets();

    const publicKey = network.network === 'testnet' ? testnetAccountModel?.id : mainnetAccountModel?.id;
    const isExiistingWallet = walletsResp?.data?.data.find((w) => w.publicKey === publicKey);
    if (isExiistingWallet) {
      yield loadAccountSaga({ type: 'wallet/LOAD_ACCOUNT_ACTION', payload: { id: publicKey } });
      const { network } = yield select();
      const m = yield AccountSecureStorage.getAllAccountsByNetwork(network.network);
      const index = m.findIndex((model) => model.id === publicKey);
      localStorage.setItem('accountIndex', JSON.stringify(index));
    } else {
      const { wallet } = yield select();
      const index = wallet.accounts.length;
      localStorage.setItem('accountIndex', JSON.stringify(index));
      yield CommonApi.addWallet({ publicKey });
    }
    const optinAccounts = [];
    for (let i = 0; i < 3; i++) {
      const optinMainnetAccount = AccountService.createFromMnemonicAndIndex(
        mnemonic,
        i,
        `Opt In Account ${i + 1}`,
        'mainnet',
        Network.BITCOIN,
      );
      if (getWhitelistedPublicKeys('mainnet').indexOf(String(optinMainnetAccount.id)) >= 0) {
        optinAccounts.push(optinMainnetAccount);
      }
      const optinTestnetAccount = AccountService.createFromMnemonicAndIndex(
        mnemonic,
        i,
        `Opt In Account ${i + 1}`,
        'testnet',
        Network.BITCOIN,
      );
      if (getWhitelistedPublicKeys('testnet').indexOf(String(optinTestnetAccount.id)) >= 0) {
        optinAccounts.push(optinTestnetAccount);
      }
    }
    for (const account of optinAccounts) {
      yield AccountSecureStorage.createNewAccount(account);
    }
    yield reloadAccountsSaga();
    yield put(
      loadAccountAction({
        id: network.network === 'testnet' ? testnetAccountModel.id : mainnetAccountModel.id,
      }),
    );
    if (onSuccess) {
      onSuccess();
    }
  } catch (e) {
    if (onError) {
      onError(e);
    }
  }
}

export function* watchWallet() {
  yield takeLatest(walletInitStateAction, walletInitStateSaga);
  yield takeLatest(saveWalletAction, saveWalletSaga);
  yield takeLatest(loadAccountsBalancesAction, loadAccountsBalancesSaga);
  yield takeLatest(loadAccountAction, loadAccountSaga);
}
