import { FC } from 'react';
import { useSelector } from 'react-redux';
import DetailsStep from './DetailsStep';
import InfoStep from './InfoStep';
import OwnerStep from './OwnerStep';

const ProducrtOwnership: FC<any> = ({ product }) => {
  const userId = useSelector((state: any) => state.auth.username);

  const renderContent = () => {
    const isOwner = product.owner._id === userId;
    if (product.status !== 'registered') {
      return <InfoStep />;
    } else {
      if (isOwner) {
        if (!product.warranty?._id) {
          return <OwnerStep isOwner productId={product._id} withoutWarranty />;
        }
        if (product.warranty?.status === 'Not active') {
          return <OwnerStep isOwner productId={product._id} warrantyId={product?.warranty?._id} />;
        }
        return <DetailsStep warranty={product.warranty} />;
      }
      return <OwnerStep isOwner={false} productId={product._id} />;
    }
  };
  return renderContent();
};

export default ProducrtOwnership;
