import { put, takeLatest } from 'redux-saga/effects';
import { processRequestError } from '../../modules/errors';
import { commonAction, menuAction } from './actions';

function* commonSaga({ payload }: ReturnType<typeof commonAction.request>) {
  const { onSuccessCb, onFailCb } = payload;
  try {
    if (onSuccessCb) {
      onSuccessCb();
    }
  } catch (error) {
    if (onFailCb) {
      // @ts-ignore
      onFailCb(error);
    }
    // @ts-ignore
    yield put(processRequestError({ error, failAction: commonAction.fail }));
  }
}

function* menuSaga(params: any) {
  yield put(menuAction.success(params.payload));
}

export function* watchCommon() {
  yield takeLatest(commonAction.request, commonSaga);
  yield takeLatest(menuAction.request, menuSaga);
  yield takeLatest(menuAction.request, menuSaga);
}
