import { createReducer } from 'deox';
import produce from 'immer';
import { getUserData, setDataLoading, setKYCVerified, setUserGroup } from './actions';
import { UserData } from './types';

const initialState: UserData = {
  loading: false,
  userData: null,
  kyc_verified: false,
};

export const userReducer = createReducer(initialState, (handle) => [
  handle(setDataLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(setKYCVerified.req, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.kyc_verified = payload;
    }),
  ),
  handle(getUserData.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userData = payload;
    }),
  ),
  handle(setUserGroup, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userData = { ...draft.userData, group: payload };
    }),
  ),
]);
