import { Divider, IconButton, Menu, MenuItem } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/Button/Button';
import { Modal } from '../../../../components/Modal/Modal';
import NoItem from '../../../../components/NoItem';
import { SearchInput } from '../../../../components/Search/Search';
import { ReactComponent as MoreIcon } from '../../../../icons/more.svg';
import { OrganizationApi } from '../../../../services/api/_OrganizationApi';
import { DeleteEntityModal } from '../../../../shared/components';
import modalStyles from '../../../../shared/components/MenuActions/MenuActions.module.scss';
import { Toast } from '../../../../utils/toastHelper';
import { Category } from '../../types';
import { CategoryCustomFields } from '../CategoryCustomFields/CategoryCustomFields';
import { CategoryForm } from '../CategoryForm/CategoryForm';
import styles from './Categories.module.scss';

interface CategoriesProps {
  categories: Category[];
  onCategoryDeleted: (categoryId: string) => void;
  onCategoryCreated: (category: Category) => void;
  handleCategoryFetch: () => void;
  organizationId: any;
}

const generateData = (data) => {
  const newData = { ...data };

  for (const key in newData) {
    if (!newData[key]) {
      delete newData[key];
    }
  }

  return newData;
};

export const Categories: FC<CategoriesProps> = ({
  categories,
  onCategoryCreated,
  onCategoryDeleted,
  handleCategoryFetch,
  organizationId,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [createCategoryModal, setCreateCategoryModal] = useState({
    open: false,
    loading: false,
  });
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [deleteCategoryModal, setDeleteCategoryModal] = useState({
    categoryId: '',
    open: false,
  });

  const [categoryCustomFieldsModal, setCategoryCustomFieldsModal] = useState({
    categoryId: '',
    categoryName: '',
    open: false,
  });
  const { userData } = useSelector((state: any) => state.user);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const handleDelete = async (categoryId: string) => {
    try {
      await OrganizationApi.removeCategory(categoryId);
      setDeleteCategoryModal({ open: false, categoryId: '' });
      onCategoryDeleted(categoryId);
      Toast.success(t('_CategorySuccessfullyRemoved_'));
    } catch (e) {
      Toast.error(t('_DELETECATEGORYERROR_') + JSON.stringify(e));
    }
  };

  const createCategory = async (dto: any, isEditMode: boolean) => {
    setLoading(true);
    if (isEditMode) {
      try {
        await OrganizationApi.editCategory(generateData(dto), selectedCategory.id);
        setLoading(false);
        setCreateCategoryModal({ open: false, loading: false });
        handleCategoryFetch();
      } catch (e) {
        setLoading(false);
        Toast.error(`${JSON.stringify(e)}`);
      }
    } else {
      try {
        setCreateCategoryModal({ open: createCategoryModal.open, loading: true });

        const { data: addedCategory } = await OrganizationApi.addCategory(
          dto,
          isEmployee ? userData.inviteCode : organizationId,
        );
        setCreateCategoryModal({ open: false, loading: false });

        onCategoryCreated({ id: addedCategory._id, name: addedCategory.name });
        setLoading(false);

        Toast.success(`${t('_Category_')}: ${addedCategory.name} ${t('_created_')} !`);
      } catch (e) {
        if (e?.response?.data?.message?.includes('name must be unique')) {
          Toast.error(t('_CategoryNameMustBeUnique_'));
        } else {
          Toast.error(`${JSON.stringify(e)}`);
        }
        setLoading(false);
      }
    }
  };

  return (
    <div className={styles.categories}>
      <div className={styles.categories__common}>
        {categories?.length > 0 ? (
          <SearchInput
            placeholder={t('_Search_')}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            searchIcon
            wrappedClass={styles['categories__common-search']}
          />
        ) : (
          <div />
        )}
        <Modal
          open={createCategoryModal.open}
          onClose={() => {
            setCreateCategoryModal({ open: false, loading: false });
            setSelectedCategory(null);
          }}
        >
          <CategoryForm onSubmit={createCategory} loading={loading} selected={selectedCategory} />
        </Modal>

        <Button
          isSmall
          isPrimary
          text={t('_AddCategory_')}
          onClick={() => setCreateCategoryModal({ open: true, loading: false })}
          className={styles['custom-button']}
        />
      </div>
      <div className={styles.categories__list}>
        {categories?.length > 0 ? (
          categories
            .filter((category) => category.name.toLowerCase().includes(searchTerm))
            .map((category) => (
              <div className={styles.category} key={category.id} id={category.id}>
                <span className={styles.categoryName}>{category.name}</span>
                <span className={styles.categoryDescription}>{category.description}</span>
                <div className={styles.categoryActions}>
                  {category.isCanBeDeleted && (
                    <CategoryActions
                      categoryId={category.id}
                      categoryName={category.name}
                      onClose={() => {
                        setCreateCategoryModal({ open: false, loading: false });
                        setSelectedCategory(null);
                      }}
                      onEditCategory={(data) => {
                        setCreateCategoryModal({ open: true, loading: false });
                        setSelectedCategory(data);
                      }}
                      onDelete={(categoryId) => setDeleteCategoryModal({ categoryId, open: true })}
                      onEdit={(categoryId) => {
                        setCategoryCustomFieldsModal({
                          categoryId,
                          open: true,
                          categoryName: category.name,
                        });
                      }}
                    />
                  )}
                </div>
              </div>
            ))
        ) : (
          <NoItem />
        )}

        <CategoryCustomFields
          {...categoryCustomFieldsModal}
          organizationId={isEmployee ? userData.inviteCode : organizationId}
          onClose={() => setCategoryCustomFieldsModal({ open: false, categoryId: '', categoryName: '' })}
        />
        <DeleteEntityModal
          entityId={deleteCategoryModal.categoryId}
          title={`${t('_DeleteCategory_')} ?`}
          open={deleteCategoryModal.open}
          onClose={() => setDeleteCategoryModal({ categoryId: '', open: false })}
          onDelete={handleDelete}
        />
      </div>
    </div>
  );
};

interface CategoryActionsProps {
  onDelete: (categoryId: string) => void;
  onEdit: (categoryId: string) => void;
  categoryId: string;
  categoryName: string;
  onEditCategory: (data: any) => void;
  onClose: () => void;
}

const CategoryActions: FC<CategoryActionsProps> = ({
  onDelete,
  onEdit,
  categoryId,
  onEditCategory,
  categoryName,
  onClose,
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenActions = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <IconButton onClick={handleOpenActions}>
        <MoreIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => {
          setAnchorEl(null);
          onClose();
        }}
        PaperProps={{
          className: modalStyles.menu,
        }}
      >
        <MenuItem
          onClick={() => {
            onEditCategory({ id: categoryId, name: categoryName });
            setAnchorEl(null);
          }}
          className={modalStyles.menu__item}
        >
          {t('_EditCategory_')}
        </MenuItem>
        <Divider className={modalStyles.menu__divider} />
        <MenuItem
          onClick={() => {
            onEdit(categoryId);
            setAnchorEl(null);
          }}
          className={modalStyles.menu__item}
        >
          {t('_EditCustomField_')}
        </MenuItem>
        <Divider className={modalStyles.menu__divider} />
        <MenuItem
          onClick={() => {
            onDelete(categoryId);
            setAnchorEl(null);
          }}
          className={modalStyles.menu__item}
        >
          {t('_Delete_')}
        </MenuItem>
      </Menu>
    </>
  );
};
