import MIUIModal from '@mui/material/Modal';
import cn from 'classnames';
import { FC } from 'react';
import { ReactComponent as CloseIcon } from '../../icons/close.svg';
import styles from './Modal.module.scss';

interface ModalProps {
  open: boolean;
  onClose: () => void;
  isCustom?: boolean;
  className?: any;
}

export const Modal: FC<ModalProps> = ({ children, open, onClose, isCustom, className }) => {
  const style = isCustom ? cn(styles['modal-content'], styles['modal-costum-content']) : styles['modal-content'];
  return (
    <MIUIModal open={open} className={cn(styles['modal'], className)} onBackdropClick={onClose}>
      <div className={style}>
        <CloseIcon className={styles.close} onClick={onClose} />
        {children}
      </div>
    </MIUIModal>
  );
};
