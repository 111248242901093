import cn from 'classnames';
import { FC, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import ImageSlderModal from '../../../../components/ImageSliderModal';
import styles from './ProductImagesSlider.module.scss';

interface ProductImagesProps {
  images: {
    src: string;
    alt?: string;
  }[];
  imgFit?: string;
}

export const ProductImagesSlider: FC<ProductImagesProps> = ({ images, imgFit }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selected, setSelected] = useState(0);
  const toggleModal = (status) => {
    setModalVisible(status);
  };
  return (
    <div className={cn(styles.wrapper, 'ProductImagesSlider')}>
      <Swiper
        modules={[Pagination, Navigation]}
        pagination={{
          clickable: true,
          bulletActiveClass: styles['active-dot'],
        }}
        navigation={true}
        onSlideChange={({ activeIndex }) => setSelected(activeIndex)}
      >
        {images.map((image, i) => (
          <SwiperSlide className={styles.slide} key={image.src + i}>
            <div className={styles.image} onClick={() => toggleModal(true)}>
              <img src={image.src} alt={image.alt} className={imgFit || 'cover'} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <ImageSlderModal initialSlide={selected} images={images} open={modalVisible} onClose={() => toggleModal(false)} />
    </div>
  );
};
