/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Auth } from 'aws-amplify';
import cn from 'classnames';
import { useFormik } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { Toast } from '../../utils/toastHelper';
import { ForgotValidation as validationSchema } from '../../utils/validations';
import { Timer } from './components/Timer';
import { ENTER_CODE_STEP, FORGOT_PASSWORD_STEP } from './constants';
import './ForgotPassword.scss';

const ForgotPassword: FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pass, setPass] = useState('');
  const [loading, setLoading] = useState(false);
  const [confirmPass, setConfirmPass] = useState('');
  const [step, setStep] = useState(FORGOT_PASSWORD_STEP);
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const [isDisabledResendButton, setIsDisabledResendButton] = useState<boolean>(true);
  const [confirmationCode, setConfirmationCode] = useState('');
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);

  const onSubmit = async (data: { email: string }, { setErrors }: any) => {
    const _email = data.email.trim();
    try {
      if (step.stepNumber === 1) {
        setLoading(true);
        try {
          await Auth.forgotPassword(_email);
          setStep(ENTER_CODE_STEP);
        } catch (e) {
          if (e.message === 'Username/client id combination not found.') {
            setErrors({
              email: t('_Cant_find_veritise_account'),
            });
          } else {
            if (e.code === 'InvalidParameterException') {
              try {
                await Auth.resendSignUp(values.email);
                setStep(ENTER_CODE_STEP);
              } catch (e) {
                //@ts-ignore
                Toast.error(`${t('_SomethingWrong_')}: ${e.message}`);
              }
            } else if (e.code === 'LimitExceededException') {
              setErrors({
                email: t('_Too_many_requests_'),
              });
            } else {
              setErrors({
                email: t('_Registration_not_finished_'),
              });
            }
          }
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(true);
        try {
          await Auth.forgotPasswordSubmit(_email, confirmationCode, pass);
          Toast.success(t('_PasswordHasBeenChanged_'));
          history.push('/');
        } catch (e) {
          //@ts-ignore
          Toast.error(`${t('_SomethingWrong_')}: ${e.message}`);
        } finally {
          setLoading(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const goBack = () => {
    history.push('/');
  };

  const resendCode = async () => {
    try {
      await Auth.resendSignUp(values.email);
      Toast.success(t('_ConfirmationCodeHasBeenResent_'));
      setIsRestart(true);
    } catch (e) {
      if (e.code === 'InvalidParameterException') {
        try {
          await Auth.forgotPassword(values.email);
        } catch (e) {
          //@ts-ignore
          Toast.error(`${t('_SomethingWrong_')}: ${e.message}`);
        }
      } else {
        //@ts-ignore
        Toast.error(`${t('_SomethingWrong_')}: ${e.message}`);
      }
    }
  };

  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      //@ts-ignore
      confirmationCode: confirmationCode,
      newPassword: pass,
      confirmNewPassword: confirmPass,
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login">
      <div className="login__wrapper forgot-password__wrapper">
        <h2 className="auth__title">{t(step.title)}</h2>
        <h4 className="auth__description forgot-password__description">{t(step.description)}</h4>
        <form className="login__form forgot-password__form" onSubmit={handleSubmit}>
          <div className="signup__back-button back-to-login" onClick={goBack}>
            <ArrowIcon />
            <span>{t('_Back_')}</span>
          </div>

          {step.stepNumber === 1 && (
            <>
              <Input
                type="email"
                placeholder={t('_EnterYourEmail_')}
                label={t('_Email_')}
                name="email"
                size="lg"
                value={values.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('email', e.target.value)}
              />
              {errors && <p className="errors">{errors.email}</p>}
            </>
          )}
          {step.stepNumber === 2 && (
            <>
              <ReactPinField
                length={6}
                validate="0123456789"
                name="confirmationCode"
                className="enter-code-input"
                onChange={(value) => setConfirmationCode(value)}
                onComplete={(value) => setConfirmationCode(value)}
              />
              <Input
                type="password"
                placeholder={t('_EnterNewPassword_')}
                label={t('_Password_')}
                name="newPassword"
                size="lg"
                isPassword
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPass(e.target.value)}
              />
              <Input
                type="password"
                placeholder={t('_ConfirmNewPassword_')}
                label={t('_Password_')}
                size="lg"
                isPassword
                name="confirmNewPassword"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPass(e.target.value)}
              />
            </>
          )}
          {step.stepNumber === 3 && (
            <>
              <Input
                type="password"
                placeholder={t('_EnterNewPassword_')}
                label={t('_Password_')}
                name="newPassword"
                size="lg"
                isPassword
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPass(e.target.value)}
              />
              <Input
                type="password"
                placeholder={t('_ConfirmNewPassword_')}
                label={t('_Password_')}
                size="lg"
                isPassword
                name="confirmNewPassword"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPass(e.target.value)}
              />
            </>
          )}
          <div className="login__button-wrapper">
            <Button
              isPrimary
              className="login__button forgot__button"
              type="submit"
              text={t(step.buttonValue)}
              isDisabled={
                values.email === '' ||
                (step.stepNumber === 2 && confirmationCode.length !== 6 && (!pass || pass !== confirmPass))
              }
              onClick={handleSubmit}
              isLoading={loading}
            />
          </div>
        </form>
        {step.additional && (
          <div className="login__link-signup forgot-password-link">
            <span>{t('_DidNotReceiveTheLetter_')}</span>
            <p
              className={cn({ disabled: isDisabledResendButton })}
              onClick={() => !isDisabledResendButton && resendCode()}
            >
              {t('_Resend_')}
            </p>
            <div className="forgot-password__timer">
              <Timer
                expiryTimestamp={time}
                isRestarted={isRestart}
                showResendButton={() => setIsDisabledResendButton(false)}
                onChangeReset={() => {
                  setIsRestart(false);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
