import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useAction } from '../../hooks';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { LogoutIcon } from '../../icons/sidebar';
import { ReactComponent as VeritiseLogo } from '../../icons/veritise-icon.svg';
import { logoutActionRequest } from '../../modules/auth/actions';
import { NavigationOptionsForCompany, NavigationOptionsForIndividualUser } from './navigation';
import styles from './Sidebar.module.scss';

export const Sidebar = ({ className, ...props }) => {
  const location = useLocation();
  const history = useHistory();
  const logOut = useAction(logoutActionRequest);
  const { activeProductMenu } = useSelector((state: any) => state.common);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  // const menuRef = useRef(null);
  // const [isOpen, setIsOpen] = useState<boolean>(false);

  const userType = useDetectUserType();
  // useOnClickOutside(menuRef, () => setIsOpen(false));

  const isBusinessUser = userType === 'company';

  const handleLogOut = () => {
    logOut({ onSuccess: () => history.push('/login') });
  };

  return (
    <div className={cn(className, styles.sidebar)} {...props}>
      <div className={styles.sidebar__logo}>
        <VeritiseLogo />
      </div>

      {/* uncomment for CV */}
      {/* <div className={styles.sidebar__menu} ref={menuRef}>
        {isBusinessUser && <MenuIcon onClick={() => setIsOpen(true)} />}
        {isOpen && isBusinessUser && <MenuModal onClose={() => setIsOpen(false)} />}
      </div> */}

      <nav className={styles.sidebar__navigation}>
        {isBusinessUser
          ? NavigationOptionsForCompany.filter(({ path }) => !(isEmployee && path === '/billing')).map(
              ({ Icon, path, text, needStrokeStyle, separatePath, isCv, required }) => {
                return (
                  (isCv !== activeProductMenu || required) && (
                    <Link
                      to={path}
                      className={cn(styles.sidebar__navigation_item, {
                        [styles.active]:
                          `/${location.pathname.split('/')[1]}` === path ||
                          separatePath?.includes(`/${location.pathname.split('/')[1]}`),
                        [styles['needStrokeStyle']]: needStrokeStyle,
                        [styles['needStrokeStyleActive']]:
                          (needStrokeStyle && `/${location.pathname.split('/')[1]}` === path) ||
                          separatePath?.includes(`/${location.pathname.split('/')[1]}`),
                      })}
                      key={path + text}
                    >
                      <div
                        className={cn(styles['sidebar__navigation_item-left-border'], {
                          [styles['active-border']]:
                            `/${location.pathname.split('/')[1]}` === path ||
                            separatePath?.includes(`/${location.pathname.split('/')[1]}`),
                        })}
                      />
                      <Icon />
                    </Link>
                  )
                );
              },
            )
          : NavigationOptionsForIndividualUser.map(({ Icon, path, text, needStrokeStyle }) => {
              return (
                <Link
                  to={path}
                  className={cn(styles.sidebar__navigation_item, {
                    [styles.active]: `/${location.pathname.split('/')[1]}` === path,
                    [styles['needStrokeStyle']]: needStrokeStyle,
                    [styles['needStrokeStyleActive']]:
                      needStrokeStyle && `/${location.pathname.split('/')[1]}` === path,
                  })}
                  key={path + text}
                >
                  <div
                    className={cn(styles['sidebar__navigation_item-left-border'], {
                      [styles['active-border']]: `/${location.pathname.split('/')[1]}` === path,
                    })}
                  />
                  <Icon />
                </Link>
              );
            })}
      </nav>
      <div className={styles.sidebar__logout}>
        <LogoutIcon onClick={handleLogOut} />
      </div>
    </div>
  );
};
