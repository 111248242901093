import { FC, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Timer } from '../../../ForgotPassword/components/Timer';
import styles from './styles.module.scss';

interface IProps {
  next: (code: string) => void;
  resend: () => void;
  waitingState?: boolean;
}

const ConfirmCodeStep: FC<IProps> = ({ next, resend, waitingState = false }) => {
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);

  const { t } = useTranslation();
  const ref = useRef();
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const [code, setCode] = useState<string>();
  const [isDisabledResendButton, setIsDisabledResendButton] = useState<boolean>(true);

  const resendCode = () => {
    resend();
    setIsRestart(true);
  };

  const handleFocus = useCallback(() => {
    const elements: { inputs: HTMLInputElement[] } = ref.current;
    if (elements && elements.inputs) {
      elements.inputs.forEach((input) => {
        if (input.value) return;
        input.placeholder = '';
      });
    }
  }, []);

  const handleBlur = useCallback(() => {
    const elements: { inputs: HTMLInputElement[] } = ref.current;
    if (elements && elements.inputs) {
      elements.inputs.forEach((input) => {
        if (input.value) return;
        input.placeholder = '0';
      });
    }
  }, []);

  return (
    <div className={styles.confirmCodeContainer}>
      <ReactPinField
        ref={ref}
        length={6}
        validate="0123456789"
        name="confirmationCode"
        className={styles.codeInput}
        placeholder={'0'}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onComplete={(code) => setCode(code)}
      />
      <div className={styles.timer}>
        <Timer
          expiryTimestamp={time}
          isRestarted={isRestart}
          showResendButton={() => setIsDisabledResendButton(false)}
          onChangeReset={() => setIsRestart(false)}
        />
      </div>
      <Button
        isLoading={waitingState}
        isPrimary
        type="submit"
        text={t('_Confirm_')}
        isDisabled={!code || code.length !== 6}
        onClick={() => next(code)}
      />
      <div className={styles.resendBlock}>
        <span>{t('_DidNotReceiveTheLetter_')}</span>
        <Text
          color={isDisabledResendButton ? 'ultra-dark-gray' : 'primary-blue'}
          className={isDisabledResendButton ? styles.disabled : styles.enabled}
          onClick={() => !isDisabledResendButton && resendCode()}
        >
          {t('_Resend_')}
        </Text>
      </div>
    </div>
  );
};

export default ConfirmCodeStep;
