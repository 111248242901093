import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Text from '../../components/common/Text';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import './Payment.scss';
import StripeComponent from './StripeComponent';

const Payment = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className="page-header">
      <div className="signup__back-button" onClick={() => history.goBack()}>
        <ArrowIcon />
        <span>{t('_Back_')}</span>
      </div>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_Payment_')}
      </Text>
      <div className="flexible jCenter">
        <div className="payments-container">
          <Text type="h4">
            <span>{t('_AddPaymentMethod_')}</span>
          </Text>
          <div className="payments-block">
            <div className="stripe-container">
              <StripeComponent />
              <div className="share-link-info">
                <span>{t('_NeedSomeoneElseToPay_')} </span>
                <a href="">{t('_GetPaymentLinkToShare_')}</a>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
