import moment from 'moment';
import { Product, Status } from '../../modules/product/types';

type Image = {
  src: string;
};
type Field = {
  name: string;
  type: string;
  sort: number;
  value: any;
};
export interface ProductState {
  warranty: Option;
  productName: string;
  productCategory: Option;
  description: string;
  images: Image[];
  fields: Field[];
}

export interface Option {
  label: string;
  value: string;
}

export const periodOptions = [
  { value: 1, label: '1 day' },
  { value: 3, label: '3 days' },
  { value: 7, label: '1 week' },
  { value: 30, label: '1 month' },
  { value: 90, label: '3 months' },
  { value: 180, label: '6 months' },
  { value: 365, label: '1 year' },
  { value: 730, label: '2 years' },
];

export const statusOptions = [
  { value: Status.Authentic, label: 'Authentic' },
  { value: Status.Registered, label: 'Registered' },
  { value: Status.Transferred, label: 'Transferred' },
];

export const generateCategoryOptions = (products: ProductState | any) => {
  const result = products
    .map((item: Product) => {
      if (item.category) {
        return {
          value: item.category._id,
          label: item.category.name,
        };
      }
    })
    .filter((v: Option, i: Option, a: any) => a.findIndex((t: Option) => t?.value && t?.value === v?.value) === i); // get unique categories

  return result;
};

export const generateDateRanges = (products: ProductState | any) => {
  const result = products.map((item: Product) => {
    return moment(item.createdAt, 'YYYY-MM-DD').toDate();
  });
  return { startDate: Math.min(...result), endDate: Math.max(...result) };
};

export const getPageSize = (isBusiness) => (isBusiness ? 7 : 3);
