import { IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';
import { Modal } from '../../../../components/Modal/Modal';
import NoItem from '../../../../components/NoItem';
import { SearchInput } from '../../../../components/Search/Search';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/trash.svg';
import { OrganizationApi } from '../../../../services/api/_OrganizationApi';
import { DeleteEntityModal as DeleteCustomFieldModal } from '../../../../shared/components';
import { Toast } from '../../../../utils/toastHelper';
import { CustomField } from '../../types';
import { CustomFieldForm } from '../CustomFieldForm/CustomFieldForm';
import styles from './CustomFields.module.scss';

interface CustomFieldsProps {
  customFields: CustomField[];
  onCustomFieldDeleted: (customFieldId: string) => void;
  onCustomFieldUpdated: (customFieldId: string, dto: any) => void;
  onCustomFieldCreated: (dto: any) => void;
  organizationId: any;
}

interface EditCustomField extends CustomField {
  open: boolean;
}

export const CustomFields: FC<CustomFieldsProps> = ({
  customFields,
  onCustomFieldDeleted,
  organizationId,
  onCustomFieldCreated,
  onCustomFieldUpdated,
}) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [createCustomFieldOpen, setCreateCustomFieldOpen] = useState(false);

  const [editCustomFieldModal, setEditCustomFieldModal] = useState<EditCustomField>({
    id: '',
    name: '',
    type: 'text',
    value: '',
    open: false,
  });

  const [deleteCustomFieldModal, setDeleteCustomFieldModal] = useState({
    open: false,
    customFieldId: '',
  });

  const deleteCustomField = async (customFieldId: string) => {
    try {
      await OrganizationApi.deleteField(organizationId, customFieldId);
      Toast.success(t('_FieldSuccessfullyRemovedFromSystem_'));

      onCustomFieldDeleted(customFieldId);
      setDeleteCustomFieldModal({ open: false, customFieldId: '' });
    } catch (e) {
      Toast.error(t('_ErrorDeleteCustomField_') + JSON.stringify(e));
    }
  };
  const onSuccessCallBack = (field, isEdit) => {
    if (isEdit) {
      onCustomFieldUpdated(editCustomFieldModal.id, field);
      setEditCustomFieldModal({ open: false, id: '', name: '', type: 'text', value: '' });
    } else {
      onCustomFieldCreated({ id: field._id, name: field.name, type: field.type });
      setCreateCustomFieldOpen(false);
    }
  };

  return (
    <div className={styles['custom-fields']}>
      <div className={styles['custom-fields__common']}>
        {customFields.length > 0 ? (
          <SearchInput
            placeholder={t('_Search_')}
            onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}
            searchIcon
            wrappedClass={styles['custom-fields__common-search']}
          />
        ) : (
          <div />
        )}

        <Modal open={createCustomFieldOpen} onClose={() => setCreateCustomFieldOpen(false)}>
          <CustomFieldForm
            name=""
            value=""
            type="text"
            organizationId={organizationId}
            action="create"
            onSuccessCallBack={onSuccessCallBack}
          />
        </Modal>

        <Button
          appearence="primary"
          text={t('_CreateField_')}
          style={{ width: 186 }}
          onClick={() => setCreateCustomFieldOpen(true)}
          className={styles['custom-button']}
        />
      </div>
      <DeleteCustomFieldModal
        title={`${t('_DeleteCustomField_')} ?`}
        entityId={deleteCustomFieldModal.customFieldId}
        open={deleteCustomFieldModal.open}
        onClose={() => setDeleteCustomFieldModal({ open: false, customFieldId: '' })}
        onDelete={deleteCustomField}
      />
      <div className={styles['custom-fields__table']}>
        {customFields.length > 0 ? (
          <Table>
            <TableHead className={styles.head}>
              <TableRow>
                <TableCell component="th">{t('_Name_')}</TableCell>
                <TableCell>{t('_Type_')}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Modal
                open={editCustomFieldModal.open}
                onClose={() => setEditCustomFieldModal({ ...editCustomFieldModal, open: false })}
              >
                <CustomFieldForm
                  action="edit"
                  name={editCustomFieldModal.name}
                  type={editCustomFieldModal.type}
                  value={editCustomFieldModal.value}
                  customFieldId={editCustomFieldModal.id}
                  organizationId={organizationId}
                  onSuccessCallBack={onSuccessCallBack}
                />
              </Modal>
              {customFields
                .filter((cf) => cf.name.toLowerCase().includes(searchTerm))
                .map((cf) => (
                  <TableRow className={styles.row} key={cf.id}>
                    <TableCell>{cf.name}</TableCell>
                    <TableCell>{cf.type}</TableCell>
                    <TableCell align="right">
                      <div className={styles.actions}>
                        <IconButton
                          onClick={() =>
                            setEditCustomFieldModal({
                              id: cf.id,
                              open: true,
                              name: cf.name,
                              type: cf.type,
                              value: cf.value,
                            })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton onClick={() => setDeleteCustomFieldModal({ open: true, customFieldId: cf.id })}>
                          <TrashIcon />
                        </IconButton>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        ) : (
          <NoItem />
        )}
      </div>
    </div>
  );
};
