const DEFAULT_PHONE_NUMBER = '+123456789';
const VERITISE_URL = 'https://veritise.com/';
const APP_URL = 'https://app.veritise.com';
const JURISDICTION_CODES = [
  { 'Abu Dhabi (UAE)': 'ae_az' },
  { 'Alabama (US)': 'us_al' },
  { 'Alaska (US)': 'us_ak' },
  { Albania: 'al' },
  { 'Arizona (US)': 'us_az' },
  { 'Arkansas (US)': 'us_ar' },
  { Aruba: 'aw' },
  { Australia: 'au' },
  { Bahamas: 'bs' },
  { Bahrain: 'bh' },
  { Bangladesh: 'bd' },
  { Barbados: 'bb' },
  { Belarus: 'by' },
  { Belgium: 'be' },
  { Belize: 'bz' },
  { Bermuda: 'bm' },
  { Bolivia: 'bo' },
  { Brazil: 'br' },
  { Bulgaria: 'bg' },
  { 'California (US)': 'us_ca' },
  { Cambodia: 'kh' },
  { Canada: 'ca' },
  { 'Colorado (US)': 'us_co' },
  { 'Connecticut (US)': 'us_ct' },
  { Croatia: 'hr' },
  { Curaçao: 'cw' },
  { Cyprus: 'cy' },
  { 'Delaware (US)': 'us_de' },
  { Denmark: 'dk' },
  { 'District of Columbia (US)': 'us_dc' },
  { 'Dominican Republic': 'do' },
  { 'Dubai (UAE)': 'ae_du' },
  { Finland: 'fi' },
  { 'Florida (US)': 'us_fl' },
  { France: 'fr' },
  { 'French Guiana': 'gf' },
  { 'Georgia (US)': 'us_ga' },
  { Germany: 'de' },
  { Gibraltar: 'gi' },
  { Greece: 'gr' },
  { Greenland: 'gl' },
  { Guadeloupe: 'gp' },
  { Guernsey: 'gg' },
  { 'Hawaii (US)': 'us_hi' },
  { 'Hong Kong': 'hk' },
  { Iceland: 'is' },
  { 'Idaho (US)': 'us_id' },
  { India: 'in' },
  { 'Indiana (US)': 'us_in' },
  { 'Iowa (US)': 'us_ia' },
  { Iran: 'ir' },
  { Ireland: 'ie' },
  { 'Isle of Man': 'im' },
  { Israel: 'il' },
  { Jamaica: 'jm' },
  { Japan: 'jp' },
  { Jersey: 'je' },
  { 'Kansas (US)': 'us_ks' },
  { 'Kentucky (US)': 'us_ky' },
  { Latvia: 'lv' },
  { Liechtenstein: 'li' },
  { 'Louisiana (US)': 'us_la' },
  { Luxembourg: 'lu' },
  { 'Maine (US)': 'us_me' },
  { Malaysia: 'my' },
  { Malta: 'mt' },
  { Martinique: 'mq' },
  { 'Maryland (US)': 'us_md' },
  { 'Massachusetts (US)': 'us_ma' },
  { Mauritius: 'mu' },
  { Mayotte: 'yt' },
  { Mexico: 'mx' },
  { 'Michigan (US)': 'us_mi' },
  { 'Minnesota (US)': 'us_mn' },
  { 'Mississippi (US)': 'us_ms' },
  { 'Missouri (US)': 'us_mo' },
  { Moldova: 'md' },
  { 'Montana (US)': 'us_mt' },
  { Montenegro: 'me' },
  { Myanmar: 'mm' },
  { 'Nebraska (US)': 'us_ne' },
  { Netherlands: 'nl' },
  { 'Nevada (US)': 'us_nv' },
  { 'New Brunswick (Canada)': 'ca_nb' },
  { 'New Hampshire (US)': 'us_nh' },
  { 'New Jersey (US)': 'us_nj' },
  { 'New Mexico (US)': 'us_nm' },
  { 'New York (US)': 'us_ny' },
  { 'New Zealand': 'nz' },
  { 'Newfoundland and Labrador (Canada)': 'ca_nl' },
  { 'North Carolina (US)': 'us_nc' },
  { 'North Dakota': 'us_nd' },
  { Norway: 'no' },
  { 'Nova Scotia (Canada)': 'ca_ns' },
  { 'Ohio (US)': 'us_oh' },
  { 'Oklahoma (US)': 'us_ok' },
  { 'Oregon (US)': 'us_or' },
  { Pakistan: 'pk' },
  { Panama: 'pa' },
  { 'Pennsylvania (US)': 'us_pa' },
  { Poland: 'pl' },
  { 'Prince Edward Island (Canada)': 'ca_pe' },
  { 'Puerto Rico': 'pr' },
  { 'Quebec (Canada)': 'ca_qc' },
  { 'Rhode Island (US)': 'us_ri' },
  { Romania: 'ro' },
  { Rwanda: 'rw' },
  { Réunion: 're' },
  { 'Saint Barthélemy': 'bl' },
  { 'Saint Martin (French part)': 'mf' },
  { 'Saint Pierre and Miquelon': 'pm' },
  { Singapore: 'sg' },
  { Slovakia: 'sk' },
  { Slovenia: 'si' },
  { 'South Africa': 'za' },
  { 'South Carolina (US)': 'us_sc' },
  { 'South Dakota (US)': 'us_sd' },
  { Spain: 'es' },
  { Sweden: 'se' },
  { Switzerland: 'ch' },
  { Tajikistan: 'tj' },
  { Tanzania: 'tz' },
  { 'Tennessee (US)': 'us_tn' },
  { Texas: 'us_tx' },
  { Thailand: 'th' },
  { Tonga: 'to' },
  { Tunisia: 'tn' },
  { Uganda: 'ug' },
  { Ukraine: 'ua' },
  { 'United Kingdom': 'gb' },
  { 'Utah (US)': 'us_ut' },
  { Vanuatu: 'vu' },
  { 'Vermont (US)': 'us_vt' },
  { 'Viet Nam': 'vn' },
  { 'Virginia (US)': 'us_va' },
  { 'Washington (US)': 'us_wa' },
  { 'West Virginia (US)': 'us_wv' },
  { 'Wisconsin (US)': 'us_wi' },
  { 'Wyoming (US)': 'us_wy' },
];
const COUNTRY_LIST = [
  {
    Afghanistan: 'AF',
  },
  {
    Aland: 'AX',
  },
  {
    Albania: 'AL',
  },
  {
    Algeria: 'DZ',
  },
  {
    'American Samoa': 'AS',
  },
  {
    Andorra: 'AD',
  },
  {
    Angola: 'AO',
  },
  {
    Anguilla: 'AI',
  },
  {
    Antarctica: 'AQ',
  },
  {
    'Antigua and Barbuda': 'AG',
  },
  {
    Argentina: 'AR',
  },
  {
    Armenia: 'AM',
  },
  {
    Aruba: 'AW',
  },
  {
    Australia: 'AU',
  },
  {
    Austria: 'AT',
  },
  {
    Azerbaijan: 'AZ',
  },
  {
    Bahamas: 'BS',
  },
  {
    Bahrain: 'BH',
  },
  {
    Bangladesh: 'BD',
  },
  {
    Barbados: 'BB',
  },
  {
    Belarus: 'BY',
  },
  {
    Belgium: 'BE',
  },
  {
    Belize: 'BZ',
  },
  {
    Benin: 'BJ',
  },
  {
    Bermuda: 'BM',
  },
  {
    Bhutan: 'BT',
  },
  {
    Bolivia: 'BO',
  },
  {
    'Bosnia and Herzegovina': 'BA',
  },
  {
    Botswana: 'BW',
  },
  {
    'Bouvet Island': 'BV',
  },
  {
    Brazil: 'BR',
  },
  {
    'British Indian Ocean Territory': 'IO',
  },
  {
    Brunei: 'BN',
  },
  {
    Bulgaria: 'BG',
  },
  {
    'Burkina Faso': 'BF',
  },
  {
    'Burma (Myanmar)': 'MM',
  },
  {
    Burundi: 'BI',
  },
  {
    Cambodia: 'KH',
  },
  {
    Cameroon: 'CM',
  },
  {
    Canada: 'CA',
  },
  {
    'Cape Verde': 'CV',
  },
  {
    'Cayman Islands': 'KY',
  },
  {
    'Central African Republic': 'CF',
  },
  {
    Chad: 'TD',
  },
  {
    Chile: 'CL',
  },
  {
    China: 'CN',
  },
  {
    'Christmas Island': 'CX',
  },
  {
    'Cocos (Keeling) Islands': 'CC',
  },
  {
    Colombia: 'CO',
  },
  {
    Comoros: 'KM',
  },
  {
    'Congo, Dem. Republic': 'CD',
  },
  {
    'Congo, Republic': 'CG',
  },
  {
    'Cook Islands': 'CK',
  },
  {
    'Costa Rica': 'CR',
  },
  {
    Croatia: 'HR',
  },
  {
    Cuba: 'CU',
  },
  {
    Cyprus: 'CY',
  },
  {
    'Czech Republic': 'CZ',
  },
  {
    Denmark: 'DK',
  },
  {
    Djibouti: 'DJ',
  },
  {
    Dominica: 'DM',
  },
  {
    'Dominican Republic': 'DO',
  },
  {
    'East Timor': 'TL',
  },
  {
    Ecuador: 'EC',
  },
  {
    Egypt: 'EG',
  },
  {
    'El Salvador': 'SV',
  },
  {
    'Equatorial Guinea': 'GQ',
  },
  {
    Eritrea: 'ER',
  },
  {
    Estonia: 'EE',
  },
  {
    Ethiopia: 'ET',
  },
  {
    'Falkland Islands': 'FK',
  },
  {
    'Faroe Islands': 'FO',
  },
  {
    Fiji: 'FJ',
  },
  {
    Finland: 'FI',
  },
  {
    France: 'FR',
  },
  {
    'French Guiana': 'GF',
  },
  {
    'French Polynesia': 'PF',
  },
  {
    'French Southern Territories': 'TF',
  },
  {
    Gabon: 'GA',
  },
  {
    Gambia: 'GM',
  },
  {
    Georgia: 'GE',
  },
  {
    Germany: 'DE',
  },
  {
    Ghana: 'GH',
  },
  {
    Gibraltar: 'GI',
  },
  {
    Greece: 'GR',
  },
  {
    Greenland: 'GL',
  },
  {
    Grenada: 'GD',
  },
  {
    Guadeloupe: 'GP',
  },
  {
    Guam: 'GU',
  },
  {
    Guatemala: 'GT',
  },
  {
    Guernsey: 'GG',
  },
  {
    Guinea: 'GN',
  },
  {
    'Guinea-Bissau': 'GW',
  },
  {
    Guyana: 'GY',
  },
  {
    Haiti: 'HT',
  },
  {
    'Heard Island and McDonald Islands': 'HM',
  },
  {
    Honduras: 'HN',
  },
  {
    HongKong: 'HK',
  },
  {
    Hungary: 'HU',
  },
  {
    Iceland: 'IS',
  },
  {
    India: 'IN',
  },
  {
    Indonesia: 'ID',
  },
  {
    Iran: 'IR',
  },
  {
    Iraq: 'IQ',
  },
  {
    Ireland: 'IE',
  },
  {
    Israel: 'IL',
  },
  {
    Italy: 'IT',
  },
  {
    'Ivory Coast': 'CI',
  },
  {
    Jamaica: 'JM',
  },
  {
    Japan: 'JP',
  },
  {
    Jersey: 'JE',
  },
  {
    Jordan: 'JO',
  },
  {
    Kazakhstan: 'KZ',
  },
  {
    Kenya: 'KE',
  },
  {
    Kiribati: 'KI',
  },
  {
    'Korea, Dem. Republic of': 'KP',
  },
  {
    Kuwait: 'KW',
  },
  {
    Kyrgyzstan: 'KG',
  },
  {
    Laos: 'LA',
  },
  {
    Latvia: 'LV',
  },
  {
    Lebanon: 'LB',
  },
  {
    Lesotho: 'LS',
  },
  {
    Liberia: 'LR',
  },
  {
    Libya: 'LY',
  },
  {
    Liechtenstein: 'LI',
  },
  {
    Lithuania: 'LT',
  },
  {
    Luxemburg: 'LU',
  },
  {
    Macau: 'MO',
  },
  {
    Macedonia: 'MK',
  },
  {
    Madagascar: 'MG',
  },
  {
    Malawi: 'MW',
  },
  {
    Malaysia: 'MY',
  },
  {
    Maldives: 'MV',
  },
  {
    Mali: 'ML',
  },
  {
    Malta: 'MT',
  },
  {
    'Man Island': 'IM',
  },
  {
    'Marshall Islands': 'MH',
  },
  {
    Martinique: 'MQ',
  },
  {
    Mauritania: 'MR',
  },
  {
    Mauritius: 'MU',
  },
  {
    Mayotte: 'YT',
  },
  {
    Mexico: 'MX',
  },
  {
    Micronesia: 'FM',
  },
  {
    Moldova: 'MD',
  },
  {
    Monaco: 'MC',
  },
  {
    Mongolia: 'MN',
  },
  {
    Montenegro: 'ME',
  },
  {
    Montserrat: 'MS',
  },
  {
    Morocco: 'MA',
  },
  {
    Mozambique: 'MZ',
  },
  {
    Namibia: 'NA',
  },
  {
    Nauru: 'NR',
  },
  {
    Nepal: 'NP',
  },
  {
    Netherlands: 'NL',
  },
  {
    'Netherlands Antilles': 'AN',
  },
  {
    'New Caledonia': 'NC',
  },
  {
    'New Zealand': 'NZ',
  },
  {
    Nicaragua: 'NI',
  },
  {
    Niger: 'NE',
  },
  {
    Nigeria: 'NG',
  },
  {
    Niue: 'NU',
  },
  {
    'Norfolk Island': 'NF',
  },
  {
    'Northern Mariana Islands': 'MP',
  },
  {
    Norway: 'NO',
  },
  {
    Oman: 'OM',
  },
  {
    Pakistan: 'PK',
  },
  {
    Palau: 'PW',
  },
  {
    'Palestinian Territories': 'PS',
  },
  {
    Panama: 'PA',
  },
  {
    'Papua New Guinea': 'PG',
  },
  {
    Paraguay: 'PY',
  },
  {
    Peru: 'PE',
  },
  {
    Philippines: 'PH',
  },
  {
    Pitcairn: 'PN',
  },
  {
    Poland: 'PL',
  },
  {
    Portugal: 'PT',
  },
  {
    'Puerto Rico': 'PR',
  },
  {
    Qatar: 'QA',
  },
  {
    'Reunion Island': 'RE',
  },
  {
    Romania: 'RO',
  },
  {
    'Russian Federation': 'RU',
  },
  {
    Rwanda: 'RW',
  },
  {
    'Republic of Kosovo': 'XK',
  },
  {
    'Saint Barthelemy': 'BL',
  },
  {
    'Saint Kitts and Nevis': 'KN',
  },
  {
    'Saint Lucia': 'LC',
  },
  {
    'Saint Martin': 'MF',
  },
  {
    'Saint Pierre and Miquelon': 'PM',
  },
  {
    'Saint Vincent and the Grenadines': 'VC',
  },
  {
    Samoa: 'WS',
  },
  {
    'San Marino': 'SM',
  },
  {
    'Saudi Arabia': 'SA',
  },
  {
    Senegal: 'SN',
  },
  {
    Serbia: 'RS',
  },
  {
    Seychelles: 'SC',
  },
  {
    'Sierra Leone': 'SL',
  },
  {
    Singapore: 'SG',
  },
  {
    Slovakia: 'SK',
  },
  {
    Slovenia: 'SI',
  },
  {
    'Solomon Islands': 'SB',
  },
  {
    Somalia: 'SO',
  },
  {
    'South Africa': 'ZA',
  },
  {
    'South Georgia and the South Sandwich Islands': 'GS',
  },
  {
    'South Korea': 'KR',
  },
  {
    Spain: 'ES',
  },
  {
    'Sri Lanka': 'LK',
  },
  {
    Sudan: 'SD',
  },
  {
    Suriname: 'SR',
  },
  {
    'Svalbard and Jan Mayen': 'SJ',
  },
  {
    Swaziland: 'SZ',
  },
  {
    Sweden: 'SE',
  },
  {
    Switzerland: 'CH',
  },
  {
    Syria: 'SY',
  },
  {
    'São Tomé and Príncipe': 'ST',
  },
  {
    Taiwan: 'TW',
  },
  {
    Tajikistan: 'TJ',
  },
  {
    Tanzania: 'TZ',
  },
  {
    Thailand: 'TH',
  },
  {
    Togo: 'TG',
  },
  {
    Tokelau: 'TK',
  },
  {
    Tonga: 'TO',
  },
  {
    'Trinidad and Tobago': 'TT',
  },
  {
    Tunisia: 'TN',
  },
  {
    Turkey: 'TR',
  },
  {
    Turkmenistan: 'TM',
  },
  {
    'Turks and Caicos Islands': 'TC',
  },
  {
    Tuvalu: 'TV',
  },
  {
    Uganda: 'UG',
  },
  {
    Ukraine: 'UA',
  },
  {
    'United Arab Emirates': 'AE',
  },
  {
    'United Kingdom': 'GB',
  },
  {
    'United States': 'US',
  },
  {
    'United States Minor Outlying Islands': 'UM',
  },
  {
    Uruguay: 'UY',
  },
  {
    Uzbekistan: 'UZ',
  },
  {
    Vanuatu: 'VU',
  },
  {
    'Vatican City State': 'VA',
  },
  {
    Venezuela: 'VE',
  },
  {
    Vietnam: 'VN',
  },
  {
    'Virgin Islands (British)': 'VG',
  },
  {
    'Virgin Islands (U.S.)': 'VI',
  },
  {
    'Wallis and Futuna': 'WF',
  },
  {
    'Western Sahara': 'EH',
  },
  {
    Yemen: 'YE',
  },
  {
    Zambia: 'ZM',
  },
  {
    Zimbabwe: 'ZW',
  },
];
const BIRTDATE_FORMAT = 'dd-mm-yyyy';
const BULK_CREATION_PRICE = 0.0001;
const API_PAYLOAD_LIMIT = 6291456;
const ATTACHMENTS_IMAGES_AMOUNT_LIMIT = 5;
const ATTACHMENTS_IMAGES_SIZE_LIMIT = 5242880;

export {
  DEFAULT_PHONE_NUMBER,
  VERITISE_URL,
  JURISDICTION_CODES,
  BIRTDATE_FORMAT,
  COUNTRY_LIST,
  APP_URL,
  BULK_CREATION_PRICE,
  API_PAYLOAD_LIMIT,
  ATTACHMENTS_IMAGES_AMOUNT_LIMIT,
  ATTACHMENTS_IMAGES_SIZE_LIMIT,
};
