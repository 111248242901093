import {
  add,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarWeeks,
  differenceInHours,
  differenceInMinutes,
  format,
} from 'date-fns';
import moment from 'moment';

export const shortenFormatDate = (date: string | Date) => {
  const now = new Date();
  const tt = new Date(date);

  const mins = differenceInMinutes(now, tt);
  if (mins >= 60) {
    const hours = differenceInHours(now, tt);
    if (hours >= 60) {
      const days = differenceInCalendarDays(now, tt);
      if (days >= 7) {
        const weeks = differenceInCalendarWeeks(now, tt);
        if (weeks >= 4) {
          const months = differenceInCalendarMonths(now, tt);
          return `${months}m`;
        } else {
          return `${weeks}w`;
        }
      } else {
        return `${days}d`;
      }
    } else {
      return `${hours}h`;
    }
  } else {
    return `${mins}min`;
  }
};

export const formatTime = (date: Date) => format(new Date(date), 'h:mmaaa').toLocaleLowerCase();

export const formatDate = (date: Date) => {
  const now = new Date();
  const otherDate = new Date(date);
  const days = differenceInCalendarDays(now, otherDate);
  if (days === 0) {
    return 'Today';
  }

  if (days === 1) {
    return 'Yesterday';
  }

  if (now.getFullYear() > otherDate.getFullYear()) {
    return format(date, 'LLLL do, yyyy');
  }

  return format(date, 'LLLL do');
};

// asDate keeps your date as a date.
// Sometimes due to implementation details
// the JavaScriptCore (Apple iOS) might change underlying type
// of a date object to string type, so then the other code might throw exceptions.
export const asDate = (v: number | string | Date) => {
  if (!v) {
    return new Date();
  }
  return new Date(v);
};

const msecPerDay = 1000 * 60 * 60 * 24;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dateDiffDays = (fromDate: any, toDate: any) => {
  const _from = asDate(fromDate);
  const _to = asDate(toDate);
  const diff = (_to.getTime() - _from.getTime()) / msecPerDay;
  return diff;
};

export const yesterDay = (): Date => {
  let y = new Date();
  y = add(y, { days: -1 });
  return y;
};

export const dateCheckerAndConverter = (date, format = 'DD/MM/YYYY'): Date | string => {
  return date ? moment(date).format(format) : '-';
};

export const leftDates = (endDate): string => {
  if (!endDate) {
    return '-';
  }
  const now = new Date();
  const tt = new Date(endDate);
  const days = differenceInCalendarDays(tt, now);
  return `${days} days`;
};

export const formatUserBirtdate = (dateStr, separator, back_separator = '-') => {
  return dateStr.split(separator).reverse().join(back_separator);
};
