import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../components/Button';
import Text from '../../../components/common/Text';
import { ReactComponent as AlertIcon } from '../../../icons/alert.svg';
import { removeUserAccount } from '../../../modules/user/actions';
import styles from './styles.module.scss';

const RemoveAccountModal: FC<{ onClose: () => void }> = ({ onClose }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const handleRemove = useCallback(() => {
    dispatch(removeUserAccount.req(() => history.push('/')));
  }, []);
  return (
    <div className={styles.modalContainer}>
      <div className={styles.modalContainer__title}>
        <Text type="h2">{t('_DeleteAccount_')}?</Text>
      </div>
      <div className={styles.modalContainer__icon}>
        <AlertIcon />
      </div>
      <div className={styles.modalContainer__desc}>
        <Text color="ultra-dark-gray" type="body_m" fontWeight="regular">
          {t('_When_you_delete_account_')}
        </Text>
      </div>
      <div className={styles.modalContainer__actionBtns}>
        <Button text={t('_Delete_')} appearence="alert" onClick={handleRemove} />
        <Button text={t('_Cancel_')} appearence="white" onClick={onClose} />
      </div>
    </div>
  );
};

export default RemoveAccountModal;
