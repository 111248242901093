//@ts-ignore
import moment from 'moment';
import qs from 'query-string';
import { Api } from './Api';

export class ProductApi {
  static createProducts() {
    return Api.post('/service/store/product');
  }
  static getOwnedProducts(type = 'business', accountID: string, filters: any, range: number[]) {
    if (type === 'company') {
      if (filters !== undefined) {
        const queryString = qs.stringify(
          {
            'filter[name]': filters.searchFilter,
            'filter[status]': !filters.statusFilter ? '' : filters.statusFilter.value,
            'filter[categoryId]': !filters.categoryFilter ? '' : filters.categoryFilter.value,
            'filter[manufacturerId]': accountID,
            'filter[createdAt]':
              filters.dateRangeFilter?.start && filters.dateRangeFilter?.end
                ? `${moment(filters.dateRangeFilter.start).format('YYYY-MM-DD')}:${moment(
                    filters.dateRangeFilter.end,
                  ).format('YYYY-MM-DD')}`
                : '',
            range: JSON.stringify(range),
          },
          {
            skipEmptyString: true,
          },
        );
        return Api.get(`/product?${queryString}`);
      }
      return Api.get(`/product?filter[manufacturerId]=${accountID}`);
    }
  }

  static getOne(alias: string) {
    return Api.get(`/p/${alias}`);
  }

  static removeProduct(id: string) {
    return Api.put(`/product/${id}`, { status: 'removed' });
  }

  static putScanHistory(productID: string) {
    return Api.post('/user/scan', { productId: productID });
  }

  static getScanHistory() {
    return Api.get('/user/scan');
  }

  static getScanItem(id: string) {
    return Api.get(`/user/scan/${id}`);
  }

  static claimOnwership(productPublicKey: string) {
    return Api.put(`/product/${productPublicKey}/claim`);
  }

  static sendOwnership(productID: string, fromAccountPrivateKey: string, toAccountID: string) {
    const payload = {
      userId: toAccountID, // public key
      userPrivateKey: fromAccountPrivateKey, // private key of owner
    };
    return Api.put(`/updateClaimOwnership/${productID}`, payload);
  }

  static getTrace(productID: string) {
    return Api.get(`/product/${productID}/history`);
  }

  static getWarranties(filters?: any, isApi_one?: boolean, range?: number[]) {
    let query = '';
    Object.keys(filters).forEach((key, index) => {
      query += `${index === 0 ? '?' : '&'}filter[${key}]=${filters[key]}`;
    });
    return isApi_one
      ? Api.get(`/warranty${query}&range=${JSON.stringify(range)}`)
      : Api.get(`/2.0/warranty${query}&range=${JSON.stringify(range)}`);
  }

  static getCategories(accountID?: string) {
    // if (type === 'company') {
    return Api.get(`/category${accountID === undefined ? '' : `?filter[organizationId]=${accountID}`}`);
    // }
    // return Api.get(`/user/category?filter[organizationId]=${accountID}`);
  }

  static registerWarranty(productID: string, warrantyID: string) {
    const payload = {
      productId: productID,
    };
    return Api.put(`/warranty/${warrantyID}/register`, payload);
  }

  static updateWarranty(warrantyID: string, payload) {
    return Api.put(`/warranty/${warrantyID}`, payload);
  }

  static addProduct(payload: any) {
    return Api.post(`/product`, payload);
  }

  static updateProduct(id: string, payload: any) {
    return Api.put(`/product/${id}`, payload);
  }

  static getProductHistory(id: string) {
    return Api.get(`/product/${id}/history`);
  }

  static getProductCategory(categoryId = '') {
    return Api.get(`/category/${categoryId}`);
  }

  static addWarranty(type = 'company', payload: any) {
    if (type === 'company') {
      return Api.post(`/warranty`, payload);
    }
    return Api.post(`/user/warranty`, payload);
  }
  static getUserProducts(range) {
    return Api.get(`/user/product/?range=${range}`);
  }
  static sendNewProductAccept(productID: string, toAccountID: string, walletPublickKey: string) {
    return Api.put(`/product/${productID}/accept`, {
      accountId: toAccountID,
      productId: walletPublickKey,
    });
  }
  static getProductLimits() {
    return Api.get(`/product/limits`);
  }
  static createProductBulk(payload: {
    name: string;
    description: string;
    info: { images: { src: string }[] };
    transactions: {
      checksum: string;
      walletId: string;
      eventTransactionId: string;
      transactionId: string;
    }[];
  }) {
    return Api.post(`/product-bulk`, payload);
  }

  static getProductBulks(accountID: string, filters: any, range: number[]) {
    if (filters === undefined) {
      return Api.get(`/product-bulk?filter[manufacturerId]=${accountID}`);
    }
    const queryString = qs.stringify(
      {
        'filter[name]': filters.searchFilter,
        'filter[categoryId]': !filters.categoryFilter ? '' : filters.categoryFilter.value,
        'filter[manufacturerId]': accountID,
        'filter[createdAt]':
          filters.dateRangeFilter?.start && filters.dateRangeFilter?.end
            ? `${moment(filters.dateRangeFilter.start).format('YYYY-MM-DD')}:${moment(
                filters.dateRangeFilter.end,
              ).format('YYYY-MM-DD')}`
            : '',
        range: JSON.stringify(range),
      },
      {
        skipEmptyString: true,
      },
    );
    return Api.get(`/product-bulk?${queryString}`);
  }
  static getBulkById(id: string) {
    return Api.get(`/product-bulk/${id}`);
  }
  static deleteProductBulk(id: string) {
    return Api.delete(`/product-bulk/${id}`);
  }
  static editProductBulk(id: string, payload: any) {
    return Api.put(`/product-bulk/${id}`, payload);
  }
  static getProductByBulkId(bulkID: string) {
    return Api.get(`/product-bulk/${bulkID}/ids`);
  }

  static createProductTemplate(payload: { name: string; description: string; info: { images: { src: string }[] } }) {
    return Api.post(`/product-template`, payload);
  }

  static getProductTemplates(accountID: string, filters: any, range: number[]) {
    if (filters === undefined) {
      return Api.get(`/product-template?filter[manufacturerId]=${accountID}`);
    }
    const queryString = qs.stringify(
      {
        'filter[name]': filters.searchFilter,
        'filter[categoryId]': !filters.categoryFilter ? '' : filters.categoryFilter.value,
        'filter[manufacturerId]': accountID,
        'filter[createdAt]':
          filters.dateRangeFilter?.start && filters.dateRangeFilter?.end
            ? `${moment(filters.dateRangeFilter.start).format('YYYY-MM-DD')}:${moment(
                filters.dateRangeFilter.end,
              ).format('YYYY-MM-DD')}`
            : '',
        range: JSON.stringify(range),
      },
      {
        skipEmptyString: true,
      },
    );
    return Api.get(`/product-template?${queryString}`);
  }

  static getProductTemplateById(id: string) {
    return Api.get(`/product-template/${id}`);
  }

  static deleteProductTemplate(id: string) {
    return Api.delete(`/product-template/${id}`);
  }

  static editProductTemplate(id: string, payload: any) {
    return Api.put(`/product-template/${id}`, payload);
  }
}
