import { IconButton } from '@mui/material';
import moment from 'moment';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/common/Label';
import Text from '../../../components/common/Text';
import { Modal } from '../../../components/Modal/Modal';
import { ReactComponent as DeclineBtnIcon } from '../../../icons/closeBtn.svg';
import { ReactComponent as SuccessBtnIcon } from '../../../icons/successBtn.svg';
import { ReactComponent as ViewIcon } from '../../../icons/view.svg';
import { ProductApi } from '../../../services/api/ProductApi';
import { Toast } from '../../../utils/toastHelper';
import WarrantyModal from '../WarrantyModal';
import styles from './styles.module.scss';

const WarrantyRow: FC<any> = ({ item, fetchData, manufacturerId }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const toggleModal = useCallback((status: boolean) => {
    setVisible(status);
  }, []);

  const handleChangeStatus = async (status) => {
    const isSuccess = status !== 'Declined';
    const content = isSuccess ? t('_Warranty_was_activated_') : t('_Warranty_was_declined_');
    Toast[isSuccess ? 'success' : 'error'](content);
    await ProductApi.updateWarranty(item.warranty._id, { status });
    fetchData(manufacturerId);
    setVisible(false);
  };
  const handleClick = (e) => {
    if (e.target.id === 'warranty-row') {
      toggleModal(true);
    }
  };

  return (
    <div className={styles.warrantyRow} onClick={handleClick} id="warranty-row">
      <Text
        id="warranty-row"
        type="body_m"
        color="dark-primary"
        fontWeight="regular"
        className={styles.warrantyRow__md_col}
      >
        {item.name}
      </Text>
      <Text
        id="warranty-row"
        type="body_m"
        color="dark-primary"
        fontWeight="regular"
        className={styles.warrantyRow__md_col}
      >
        {item?.warranty?.info?.email}
      </Text>
      <Text
        id="warranty-row"
        type="body_m"
        color="dark-primary"
        fontWeight="regular"
        className={styles.warrantyRow__md_col}
      >
        {item?.warranty?.period ? `${item.warranty.period} ${t('_days_')}` : ''}
      </Text>
      <Text
        id="warranty-row"
        type="body_m"
        color="dark-primary"
        fontWeight="regular"
        className={styles.warrantyRow__md_col}
      >
        {item?.warranty?.endDate ? moment(item?.warranty?.endDate).format('DD/MM/YYYY') : ''}
      </Text>
      <Label
        status={item?.warranty?.status === 'Not active' ? 'not-active' : item.warranty.status}
        text={item?.warranty?.status === 'Not active' ? 'Not active' : null}
        isWarranty
        classname={styles.warrantyLabel}
        id="warranty-row"
      />

      <div className={styles.warrantyRow__actionsContainer} id="view-action">
        {item?.warranty?.status === 'Pending' && (
          <IconButton className="icon-button" onClick={() => handleChangeStatus('Active')}>
            <SuccessBtnIcon />
          </IconButton>
        )}
        {item?.warranty?.status === 'Pending' && (
          <IconButton className="icon-button" onClick={() => handleChangeStatus('Declined')}>
            <DeclineBtnIcon />
          </IconButton>
        )}
        <IconButton className="icon-button" onClick={() => toggleModal(true)} id="icon">
          <ViewIcon id="icon" />
        </IconButton>
      </div>
      <Modal open={visible} onClose={() => toggleModal(false)}>
        <WarrantyModal product={item} handleChangeStatus={handleChangeStatus} />
      </Modal>
    </div>
  );
};

export default WarrantyRow;
