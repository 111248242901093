import dayjs from 'dayjs';
import sortby from 'lodash.sortby';
import './styles.scss';

const ChangeLogItem = ({ date, version, items }) => {
  const sortedItems = sortby(items, (item) => item.type);
  return (
    <div className="ChangeLogItem">
      <div className="title">
        {version} &nbsp;&nbsp;{dayjs(date).format('MMM DD, YYYY')}
      </div>
      {sortedItems?.map((item) => (
        <div className="log-item" key={item._id}>
          <div className="log-item-title">{item.type}</div>
          <div className="log-item-desc">{item.description}</div>
        </div>
      ))}
    </div>
  );
};

export default ChangeLogItem;
