import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import Text from '../../components/common/Text';
import Loader from '../../components/Loader';
import { ReactComponent as SuccessIcon } from '../../icons/accepted.svg';
import { ReactComponent as FailIcon } from '../../icons/denied.svg';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import styles from './styles.module.scss';

const KYB_Response: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [KYBReason, setKYBReason] = useState<string>('');
  const fromOrgPage = window.location.search.includes('fromOrgPage');
  const { userData } = useSelector((state: any) => state.user);

  useEffect(() => {
    OrganizationApi.getOrganizations()
      .then((res) => {
        if (
          res.data.data[0].kybStatus === 'verification.accepted' ||
          res.data.data[0].kybStatus === 'accepted' ||
          res.data.data[0].kybStatus === 'pending'
        ) {
          setIsVerified(true);
        } else {
          setKYBReason(res.data.data[0].kybReason);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        Toast.error(CommonHelpers.erorHandlerMessage(e));
      });
  }, []);

  const handleStart = async () => {
    if (fromOrgPage) {
      history.push('/products');
      return;
    }

    if (!userData.info.firstName) {
      history.push('/profile-details');
      return;
    }

    if (userData.kyc_status === 'not verified') {
      sessionStorage.setItem('userData', JSON.stringify(userData));
      history.push('/intro-kyc');
      return;
    }

    if (localStorage.getItem('authFlow') === 'registration') {
      history.push('/wallet-creation');
      return;
    }

    if (!localStorage.getItem('MNEMONIC_MODEL')) {
      history.push('/wallet-connect');
      return;
    }

    const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
    const mnemonic = mnemonicModel.mnemonic;
    dispatch(
      saveWalletAction({
        mnemonic,
        onSuccess: () => {
          dispatch(networkInitStateAction());
          history.push('/products');
        },
        onError: () => {
          history.push('/wallet-connect');
        },
      }),
    );
  };

  return (
    <div className={styles.container}>
      {loading ? (
        <div className={styles['loader_wrapper']}>
          <Loader />
        </div>
      ) : (
        <div className={styles.container__content}>
          <Text type="h1" color="secondary-dark-blue" className="Visby_Bold">
            {isVerified ? t('_Congratulations_') : t('_Verification_failed_')}
          </Text>
          <div className={styles['verification-text-container']}>
            <Text type="subtitle_m" color="ultra-dark-gray">
              {isVerified ? t('_Your_data_sent_for_verification_') : t('_Failure_text_')}
            </Text>
            {KYBReason && (
              <Text type="subtitle_m" color="tertiary-orange">
                {KYBReason}
              </Text>
            )}
          </div>

          <div className={styles.container__content__img}>{isVerified ? <SuccessIcon /> : <FailIcon />}</div>
          {isVerified ? (
            <div className={styles.container__content__btns}>
              <Button
                appearence="primary"
                text={t(fromOrgPage ? '_Back_to_the_dashboard_' : '_Get_started_')}
                onClick={handleStart}
              />
            </div>
          ) : (
            <div className={styles.container__content__btns}>
              <Button
                appearence="primary"
                text={t('_Try_again_')}
                onClick={() => history.push(fromOrgPage ? '/company' : '/organisation-details')}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default KYB_Response;
