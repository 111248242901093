import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoCardIcon } from '../../icons/card.svg';
import BusyLoader from '../BusyLoader';
import { Button } from '../Button/Button';
import Text from '../common/Text';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';
import styles from './HiddenBankCardInfo.module.scss';

const AddModal = ({ onAddCard, isBusyRequest = true, onClose, isEditMode, data }) => {
  const { t } = useTranslation();
  const [cardNumber, setCardNumber] = useState('');
  const [exMonth, setExMonth] = useState('');
  const [exYear, setExYear] = useState('');
  const [secureCode, setSecureCode] = useState('');

  const isValid = useMemo(
    () =>
      (cardNumber?.length === 19 || isEditMode) &&
      exMonth?.length === 2 &&
      exYear?.length === 2 &&
      (secureCode?.length === 3 || isEditMode),
    [cardNumber, exMonth, exYear, secureCode],
  );

  useEffect(() => {
    if (isEditMode) {
      setExMonth(data?.card.month.toString());
      setExYear(data?.card.year.toString().slice(2));
    }
  }, [isEditMode, data]);

  return (
    <Modal open onClose={onClose}>
      <BusyLoader
        isBusy={isBusyRequest}
        className={cn(styles.addCardModal, { [styles.addCardModal_busy]: isBusyRequest })}
      >
        <Text type="h1" className={styles.deleteCardModalTitle}>
          {t(isEditMode ? '_EditPaymentMethod_' : '_AddPaymentMethod_')}
        </Text>
        <Input
          type="text"
          label={t('_CardNumber_')}
          value={cardNumber}
          isDisabled={isEditMode}
          placeholder={isEditMode ? `**** **** **** ${data?.card?.last4}` : '**** **** **** ****'}
          onChange={({ target }) => {
            if ((target.value.length < 20 && Number(target.value.split(' ').join(''))) || !target.value) {
              setCardNumber(
                target.value
                  ? target.value
                      .split(' ')
                      .join('')
                      .match(/.{1,4}/g)
                      .join(' ')
                  : target.value,
              );
            }
          }}
        />
        <div className="flexible aEnd">
          <Input
            type="number"
            label={t('_ExpirationDate_')}
            value={exMonth}
            placeholder="MM"
            onChange={({ target }) => {
              if (target.value.length < 3) {
                setExMonth(target.value);
              }
            }}
          />
          <span className={styles.inputDivider}>/</span>
          <Input
            type="number"
            value={exYear}
            placeholder="YY"
            onChange={({ target }) => {
              if (target.value.length < 3) {
                setExYear(target.value);
              }
            }}
          />
        </div>
        {!isEditMode && (
          <div className={styles.securityCardBlock}>
            <Input
              type="number"
              label={t('_SecurityCode_')}
              value={secureCode}
              className={styles.securityCardBlock_input}
              placeholder="***"
              onChange={({ target }) => {
                if (target.value.length < 4) {
                  setSecureCode(target.value);
                }
              }}
            />
            <NoCardIcon className={styles.securityCardIcon} />
          </div>
        )}
        <Button
          className={styles.addModalButton}
          onClick={() =>
            onAddCard(
              {
                number: Number(cardNumber.split(' ').join('')),
                month: exMonth,
                year: `20${exYear}`,
                cvc: secureCode,
              },
              isEditMode,
            )
          }
          isDisabled={!isValid}
          isPrimary
        >
          {t('_Save_')}
        </Button>
      </BusyLoader>
    </Modal>
  );
};

export default AddModal;
