export enum LoginActions {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const FORGOT_PASSWORD_STEP = {
  stepNumber: 1,
  title: '_Forgot_password_step_1_title_',
  description: '_Forgot_password_step_1_description_',
  inputType: 'email',
  buttonValue: '_Continue_',
  additional: false,
};

export const ENTER_CODE_STEP = {
  stepNumber: 2,
  title: '_Forgot_password_step_2_title_',
  description: '_Forgot_password_step_2_description_',
  inputType: 'code',
  buttonValue: '_ResetPassword_',
  additional: true,
};

export const ENTER_NEW_PASSWORD_STEP = {
  stepNumber: 3,
  title: '_Forgot_password_step_3_title_',
  description: '_Forgot_password_step_3_description_',
  inputType: 'code',
  buttonValue: '_Confirm_',
  additional: false,
};
