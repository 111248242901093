import { Auth } from 'aws-amplify';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch } from 'react-router';
import { useHistory, useLocation } from 'react-router-dom';
import AppDownloader from './components/AppDownloader';
import { LayoutAnonymous } from './components/Layout/LayoutAnonymous';
import { LayoutAuthenticated } from './components/Layout/LayoutAuthenticated';
import { useDetectUserType } from './hooks/useDetectUserType';
import { useQuery } from './hooks/useQuery';
import { logoutActionRequest, userLogin } from './modules/auth/actions';
import { networkInitStateAction } from './modules/network/actions';
import { getOrganizations } from './modules/organization/actions';
import { getUserData } from './modules/user/actions';
import About from './pages/About';
import ApiPage from './pages/ApiPage';
import Billing from './pages/Billing';
import ConfirmSecQuestions from './pages/ConfirmSecQuestions';
import ConnectWallet from './pages/Connect-wallet';
// import CVComponent from './pages/CV';
// import CvCreation from './pages/CV/CvCreation';
// import CVItem from './pages/CV/CvItem';
// import CVList from './pages/CVList';
// import CvListProfile from './pages/CVList/CvListProfile';
// import CVRequests from './pages/CVRequests';
// import CvRequestsItem from './pages/CVRequests/CvRequestsItem';
// import CvRequestsWorkItem from './pages/CVRequests/CvRequestsWorkItem';
import { FieldsConfiguration } from './pages/FieldsConfiguration/FieldsConfiguration';
import ForgotPassword from './pages/ForgotPassword/component';
import ImportWallet from './pages/Import-wallet';
import IntroKYC from './pages/intro-kyc/component';
import KYB from './pages/KYB';
import KYB_Response from './pages/KYB-response';
import KYC_Response from './pages/KYC-response';
import KYC from './pages/KYC/component';
import Login from './pages/Login/component';
import { NotFound } from './pages/NotFound/NotFound';
import OrganizationInfo from './pages/OrganizationInfo/component';
import Payment from './pages/Payment';
import { BulkDetails } from './pages/ProductDetails/BulkDetails';
import { ProductDetails } from './pages/ProductDetails/ProductDetails';
import { TemplateDetails } from './pages/ProductDetails/TemplateDetails';
import Products from './pages/Products/component';
import CreateProduct from './pages/Products/components/CreateProduct';
import CreateProductFromTemplate from './pages/Products/components/CreateProduct/CreateProductFromTemplate';
import EditBulk from './pages/Products/components/EditBulk';
import EditTemplate from './pages/Products/components/EditTemplate';
import PublicProduct from './pages/PublicProduct';
import Scan from './pages/Scan';
import ScanHistory from './pages/ScanHistory';
import Settings from './pages/Settings';
import ChangeEmail from './pages/Settings/ChangeEmail';
import ChangePassword from './pages/Settings/ChangePassword';
import EditProfile from './pages/Settings/EditProfile';
import EmailVerification from './pages/SignUp/EmailVerification';
import OrganisationDetails from './pages/SignUp/OrganisationDetails';
import ProfileDetails from './pages/SignUp/ProfileDetails';
import RoleSelection from './pages/SignUp/RoleSelection';
import SetSecAnswers from './pages/SignUp/SetSecAnswers';
import SignUp from './pages/SignUp/SignUp';
import Subscription from './pages/Subscription';
import Tutorial from './pages/Tutorial';
import UserManagement from './pages/UserManagement';
import UserManagementProfile from './pages/UserManagement/UserManagementProfile';
import WalletDetails from './pages/Wallet-details';
import WalletCreation from './pages/WalletCreation/component';
import Warranties from './pages/Warranties';
import { OrganizationApi } from './services/api/OrganizationApi';
import { PrivateRoute } from './services/PrivateRoute';
import { Toast } from './utils/toastHelper';

const authorizedRoutes = [
  '/start',
  '/products',
  '/products/create',
  '/products/edit/:productId',
  '/products/:alias',
  '/bulk/:id',
  '/bulk/edit/:id',
  '/products/create-from-template/:id',
  '/template/:id',
  '/template/edit/:id',
  '/fields-management',
  '/company',
  '/scan',
  '/history-scan',
  '/settings/profile',
  '/settings',
  '/warranties',
  '/wallet-details',
  '/import-wallet',
  '/billing',
  '/settings/change-password',
  '/settings/change-email',
  '/payment',
  '/cv/:tabId?',
  '/cv/:alias/:tabId?',
  '/cv-creation/:cvUniqId?',
  '/about-app',
  '/cv-list',
  '/cv-requests',
  '/cv-requests-item/:tabId?/:cvId?/:requestId?',
  '/cv-requests-work-item/:workId?/:cvUniqId?',
  '/cv-item/:workId?/:cvUniqId?',
  '/tutorial',
  '/api',
  '/kyc-intro',
  '/cv-list-profile/:cvId?',
  '/user-management',
  '/user-management-profile/:userId?',
  '/.well-known/apple-app-site-association',
  '/.well-known/assetlinks.json',
];

const unauthorizedRoutes = [
  '/',
  '/login',
  '/confirm-security-questions',
  '/forgot-password',
  '/signup',
  '/email-verification',
  '/set-security-answers',
  '/role-selection',
  '/subscription-plan',
  '/organisation-details',
  '/kyb',
  '/kyb-finish',
  '/profile-details',
  '/intro-kyc',
  '/kyc',
  '/kyc-finish',
  '/wallet-creation',
  '/wallet-connect',

  // on MVP prod, all routes above were commented
  '/p/:alias',
  '/product',
];

export const Routes = () => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const { username } = useSelector((state: any) => state.auth);
  const { userData, loading } = useSelector((state: any) => state.user);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const location = useLocation();

  useEffect(() => {
    (async () => {
      try {
        if (
          !sessionStorage.getItem('active_session') &&
          +localStorage.getItem('closed_session_timestamp') < Date.now()
        ) {
          dispatch(
            logoutActionRequest({
              onSuccess: () => {
                if (authorizedRoutes.includes(location.pathname)) {
                  history.push('/');
                }
              },
            }),
          );
          return;
        }

        const session = await Auth.currentSession();
        session.getAccessToken().getJwtToken();

        const user = await Auth.currentAuthenticatedUser();
        dispatch(userLogin.request(user));
      } catch (e) {
        //
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(networkInitStateAction());
    const selectedLang = localStorage.getItem('selectedLang') || 'en';
    selectedLang && i18n.changeLanguage(selectedLang);
  }, []);

  useEffect(() => {
    if (username) {
      dispatch(getUserData.req(username));
      dispatch(getOrganizations.req());
    }
  }, [username, location.pathname]);

  const userType = useDetectUserType();
  const isBusinessUser = userType === 'company';

  const removeInvitationParams = useCallback(() => {
    localStorage.removeItem('invited-organizationId');

    query.delete('invite');
    query.delete('organizationId');

    history.replace({
      search: query.toString(),
    });
  }, []);

  const handleInvitedUser = useCallback(async () => {
    const invitedIdLS = localStorage.getItem('invited-organizationId');
    const invitedIdQuery = query.get('organizationId');
    const inviteOrganizationId = invitedIdQuery || invitedIdLS;

    if (query.has('invite') && invitedIdQuery) {
      if (isBusinessUser) {
        removeInvitationParams();

        return Toast.error(t('_Invite_can_only_be_accepted_by_individual_'));
      } else {
        localStorage.setItem('invited-organizationId', invitedIdQuery);
      }
    }

    if (!isBusinessUser && userData?.verified && inviteOrganizationId) {
      try {
        await OrganizationApi.assignUser(inviteOrganizationId);
      } catch (e) {
        //
      } finally {
        removeInvitationParams();

        dispatch(getUserData.req(username));
      }
    }
  }, [isBusinessUser, userData, username]);

  useEffect(() => {
    handleInvitedUser();
  }, [userData]);

  // if (loading) {
  //   return (
  //     <div className="whole-page-loading_wrapper">
  //       <Loader />
  //     </div>
  //   );
  // }

  return (
    <Switch>
      {userData ? (
        <Route exact path={authorizedRoutes}>
          <LayoutAuthenticated>
            <Switch>
              <PrivateRoute exact path={['/products']} component={Products} />
              {isBusinessUser && <PrivateRoute exact path={['/products/create']} component={CreateProduct} />}
              <PrivateRoute exact path={['/products/:alias']} component={ProductDetails} />
              <PrivateRoute exact path={['/fields-management']} component={FieldsConfiguration} />
              <PrivateRoute exact path={['/company']} component={OrganizationInfo} />
              <PrivateRoute exact path={['/settings']} component={Settings} />
              <PrivateRoute exact path={['/import-wallet']} component={ImportWallet} />
              <PrivateRoute exact path={['/wallet-details']} component={WalletDetails} />
              <PrivateRoute exact path={['/settings/profile']} component={EditProfile} />
              <PrivateRoute exact path={['/settings/change-password']} component={ChangePassword} />
              <PrivateRoute exact path={['/settings/change-email']} component={ChangeEmail} />
              <PrivateRoute exact path={['/about-app']} component={About} />
              <PrivateRoute exact path={['/tutorial']} component={Tutorial} />
              <PrivateRoute exact path={['/kyc-intro']} component={IntroKYC} />
              {/* <PrivateRoute exact path={['/cv/:tabId?']} component={CVComponent} />
              <PrivateRoute exact path={['/cv/:alias/:tabId?']} component={CVComponent} />
              <PrivateRoute exact path={['/cv-creation/:cvUniqId?']} component={CvCreation} />
              <PrivateRoute exact path={['/cv-item/:workId?/:cvUniqId?']} component={CVItem} />
              <PrivateRoute exact path={['/cv-requests']} component={CVRequests} />
              <PrivateRoute exact path={['/cv-requests-item/:tabId?/:cvId?/:requestId?']} component={CvRequestsItem} />
              <PrivateRoute
                exact
                path={['/cv-requests-work-item/:workId?/:cvUniqId?']}
                component={CvRequestsWorkItem}
              /> */}
              <PrivateRoute exact path={['/.well-known/apple-app-site-association']} component={AppDownloader} />
              <PrivateRoute exact path={['/.well-known/assetlinks.json']} component={AppDownloader} />

              {/* {isBusinessUser && <PrivateRoute exact path={['/cv-list']} component={CVList} />}
              {isBusinessUser && <PrivateRoute exact path={['/cv-list-profile/:cvId?']} component={CvListProfile} />} */}
              {isBusinessUser && <PrivateRoute exact path={['/user-management']} component={UserManagement} />}
              {isBusinessUser && (
                <PrivateRoute exact path={['/user-management-profile/:userId?']} component={UserManagementProfile} />
              )}
              {isBusinessUser && <PrivateRoute exact path={['/warranties']} component={Warranties} />}
              {isBusinessUser && !isEmployee && <PrivateRoute exact path={['/billing']} component={Billing} />}
              {isBusinessUser && <PrivateRoute exact path={['/payment']} component={Payment} />}
              {isBusinessUser && <PrivateRoute exact path={['/products/edit/:alias']} component={CreateProduct} />}
              {isBusinessUser && <PrivateRoute exact path={['/bulk/edit/:id']} component={EditBulk} />}
              {isBusinessUser && <PrivateRoute exact path={['/bulk/:id']} component={BulkDetails} />}
              {isBusinessUser && (
                <PrivateRoute
                  exact
                  path={['/products/create-from-template/:id']}
                  component={CreateProductFromTemplate}
                />
              )}
              {isBusinessUser && <PrivateRoute exact path={['/template/edit/:id']} component={EditTemplate} />}
              {isBusinessUser && <PrivateRoute exact path={['/template/:id']} component={TemplateDetails} />}
              {/* todo Alex */}
              {isBusinessUser && <PrivateRoute exact path={['/api']} component={ApiPage} />}

              {!isBusinessUser && <PrivateRoute exact path={['/scan']} component={Scan} />}
              {!isBusinessUser && <PrivateRoute exact path={['/history-scan']} component={ScanHistory} />}
            </Switch>
          </LayoutAuthenticated>
        </Route>
      ) : null}

      <Route exact path={unauthorizedRoutes}>
        <LayoutAnonymous>
          <Switch>
            <Route exact path={['/login', '/']} component={Login} />
            <Route exact path={['/confirm-security-questions']} component={ConfirmSecQuestions} />
            <Route exact path={['/forgot-password']} component={ForgotPassword} />

            <Route exact path={['/signup']} component={SignUp} />
            <Route exact path={['/email-verification']} component={EmailVerification} />
            <Route exact path={['/set-security-answers']} component={SetSecAnswers} />
            <Route exact path={['/role-selection']} component={RoleSelection} />
            <Route exact path={['/subscription-plan']} component={Subscription} />
            <Route exact path={['/organisation-details']} component={OrganisationDetails} />
            <Route exact path={['/kyb']} component={KYB} />
            <Route exact path={['/kyb-finish']} component={KYB_Response} />
            <Route exact path={['/profile-details']} component={ProfileDetails} />
            <Route exact path={['/intro-kyc']} component={IntroKYC} />
            <Route exact path={['/kyc']} component={KYC} />
            <Route exact path={['/kyc-finish']} component={KYC_Response} />
            <Route exact path={['/wallet-creation']} component={WalletCreation} />
            <Route exact path={['/wallet-connect']} component={ConnectWallet} />

            <Route exact path={['/p/:alias']} component={PublicProduct} />
            <Route exact path={['/product']} component={PublicProduct} />
          </Switch>
        </LayoutAnonymous>
      </Route>

      {!loading && (
        <Route path="*">
          <LayoutAnonymous>
            <Switch>
              <Route component={NotFound} />
            </Switch>
          </LayoutAnonymous>
        </Route>
      )}
    </Switch>
  );
};
