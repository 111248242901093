/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Auth } from 'aws-amplify';
import cn from 'classnames';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import NoItem from '../../components/NoItem';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { BaseSecureStorage } from '../../storage/persistence/BaseSecureStorage';
// import { useDispatch } from 'react-redux';
// import { userLogin } from '../../modules/auth/actions';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { Timer } from '../ForgotPassword/components/Timer';
import './SignUp.scss';

const EmailVerification: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [confirmationCode, setConfirmationCode] = useState<string>('');
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const [errors, setErrors] = useState(null);
  const [isDisabledResendButton, setIsDisabledResendButton] = useState<boolean>(true);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [data, setData] = useState<{ email: string; password: string }>({
    email: '',
    password: '',
  });
  const ref = useRef();

  useEffect(() => {
    localStorage.setItem('authFlow', 'registration');
    const oneTimePass = localStorage.getItem('oneTimePass');
    if (oneTimePass) {
      const { email, password } = JSON.parse(BaseSecureStorage.decrypKey(oneTimePass) as string);
      setData({ email, password });
    }
  }, []);

  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);

  const handleSubmit = async () => {
    setIsButtonLoading(true);
    try {
      await Auth.confirmSignUp(data.email, confirmationCode);
      setIsButtonLoading(false);
      history.push(`/set-security-answers`);
    } catch (e) {
      setErrors({ code: e.message });
      setIsButtonLoading(false);
    }
  };

  const resendCode = async () => {
    setIsDisabledResendButton(true);
    try {
      setIsButtonLoading(true);
      await Auth.resendSignUp(data.email);
      setIsButtonLoading(false);
      Toast.success(t('_ConfirmationCodeHasBeenResent_'));
      setIsRestart(true);
    } catch (e) {
      setIsButtonLoading(false);
      Toast.error(`${t('_SomethingWrong_')}: ${e.message}`);
    }
  };

  const handleFocus = useCallback(() => {
    const elems: any = ref.current;
    elems.inputs.forEach((input) => {
      if (input.value) return;
      input.placeholder = '';
    });
  }, []);

  const handleBlur = useCallback(() => {
    const elems: any = ref.current;
    elems.inputs.forEach((input) => {
      if (input.value) return;
      input.placeholder = '0';
    });
  }, []);

  return (
    <div className="login signup">
      <div className="signup__container">
        <div className="signup__back-button" onClick={() => history.push('/signup')}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <div className={cn('login__wrapper', 'signup-verification__wrapper')}>
          <h2 className="auth__title">{'Email Verification'}</h2>
          <h4 className="auth__description">
            <div>
              {t('_AnEmailConfirmationCodeWasSentTo_')}
              <span className="email-text"> {CommonHelpers.hideEmail(data.email)}</span>.{' '}
              {t('_ToVerifyThatThisIsYourEmailAddres_')}
            </div>
          </h4>

          <NoItem type="email" className="react-pin-fields-wrapper">
            <div className="react-pin-fields-container">
              <ReactPinField
                length={6}
                validate="0123456789"
                className="enter-code-input"
                placeholder={'0'}
                ref={ref}
                onFocus={handleFocus}
                onBlur={handleBlur}
                onChange={(value) => setConfirmationCode(value)}
              />
              {errors && <p className="errors">{errors.code}</p>}
            </div>
          </NoItem>

          <div className="forgot-password__actions">
            <Button
              isPrimary
              isDisabled={confirmationCode.length !== 6}
              className="login__button"
              text={t('_Next_')}
              onClick={handleSubmit}
              isLoading={isButtonLoading}
            />
          </div>
          <div className="login__link-signup step_two">
            <span>{t('_DidNotReceiveTheLetter_')}</span>
            <p
              className={cn({ disabled: isDisabledResendButton })}
              onClick={() => !isDisabledResendButton && resendCode()}
            >
              {t('_Resend_')}
            </p>
            <div className="forgot-password__timer enter-code-step">
              <Timer
                expiryTimestamp={time}
                isRestarted={isRestart}
                showResendButton={() => setIsDisabledResendButton(false)}
                onChangeReset={() => {
                  setIsRestart(false);
                  setIsDisabledResendButton(true);
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailVerification;
