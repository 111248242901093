import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button';
import Text from '../../../../components/common/Text';
import { useRedirect } from '../../../../hooks/useRedirect';
import styles from './styles.module.scss';

const NoOrganization = () => {
  const redirect = useRedirect('/company');
  const { t } = useTranslation();

  return (
    <div className={styles.noOrganizationContainer}>
      <Text type="subtitle_m" color="ultra-dark-gray">
        {t('_You_dont_have_organization_')}
      </Text>
      <Button appearence="primary" text={t('_Create_organization_')} onClick={redirect} />
    </div>
  );
};

export default NoOrganization;
