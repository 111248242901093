import { CognitoUser } from '@aws-amplify/auth';
import { Auth } from 'aws-amplify';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Text from '../../components/common/Text';
import { useAction } from '../../hooks';
import { logoutActionRequest, userLogin } from '../../modules/auth/actions';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { PaymentsApi } from '../../services/api/PaymentsApi';
import { BaseSecureStorage } from '../../storage/persistence/BaseSecureStorage';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { Toast } from '../../utils/toastHelper';
import ConfirmSecQStep from '../Settings/ChangeEmail/ConfirmSecQStep';
import styles from './styles.module.scss';

const ConfirmSecQuestions: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const logOut = useAction(logoutActionRequest);
  const tryCurrentCount = useRef(0);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const userCognitoObj = useSelector((state: any) => state.auth);
  const { userData } = useSelector((state: any) => state.user);
  const questions =
    userCognitoObj.challengeParam && userCognitoObj.challengeParam.encoded
      ? JSON.parse(userCognitoObj.challengeParam.encoded)
      : [];

  if (!questions.length) {
    history.push('/');
  }

  const handleCheckAnswers = async (answers: { questionId: string; answer: string }[]) => {
    const oneTimePass = localStorage.getItem('oneTimePass');
    if (oneTimePass) {
      setDisableButton(true);
      const { email: username, password } = JSON.parse(BaseSecureStorage.decrypKey(oneTimePass) as string);
      const cognitoUser: CognitoUser = await Auth.signIn({ username, password });
      await Auth.sendCustomChallengeAnswer(cognitoUser, JSON.stringify(answers))
        .then((user) => {
          dispatch(userLogin.request(user));

          if (!user?.signInUserSession.idToken.payload['cognito:groups']) {
            history.push('/role-selection');
          }
        })
        .catch(() => {
          if (tryCurrentCount.current === 2) {
            Toast.error(t('_IncorrectAnswersAllAttempts_'));
            logOut({ onSuccess: () => history.push('/') });
          } else {
            Toast.error(t('_IncorrectAnswers_'));
          }
          tryCurrentCount.current += 1;
          setDisableButton(false);
        });
    } else {
      logOut({ onSuccess: () => history.push('/') });
    }
  };

  useEffect(() => {
    (async () => {
      if (!userData) return;

      if (userData.group === 'company' && userData.role !== 'employee') {
        try {
          await PaymentsApi.getCurrentSubscription();
        } catch (error) {
          history.push('/subscription-plan');
          return;
        }

        const organizationsResponse = await OrganizationApi.getOrganizations();
        if (!organizationsResponse.data.data.length) {
          history.push('/organisation-details');
          return;
        }

        if (organizationsResponse.data.data[0].approvalStatus === 'not verified' && userData.isVerificationNeeded) {
          history.push('/organisation-details');
          return;
        }
      }

      if (!userData.info.firstName) {
        history.push('/profile-details');
        return;
      }

      if (userData.group !== 'company' && userData.kyc_status === 'not verified') {
        sessionStorage.setItem('userData', JSON.stringify(userData));
        history.push('/intro-kyc');
        return;
      }

      if (userData.isVerificationNeeded && userData.kyc_status === 'not verified') {
        sessionStorage.setItem('userData', JSON.stringify(userData));
        history.push('/intro-kyc');
        return;
      }

      if (localStorage.getItem('authFlow') === 'registration') {
        history.push('/wallet-creation');
        return;
      }

      if (!localStorage.getItem('MNEMONIC_MODEL')) {
        history.push('/wallet-connect');
        return;
      }

      const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
      const mnemonic = mnemonicModel.mnemonic;
      dispatch(
        saveWalletAction({
          mnemonic,
          onSuccess: () => {
            dispatch(networkInitStateAction());
            history.push('/products');
          },
          onError: () => {
            history.push('/wallet-connect');
          },
        }),
      );
    })();
  }, [userData]);

  return (
    <div className={styles.questionsContainer}>
      <Text className={styles.mainLabel} type="h1">
        {t('_SecurityQuestions_')}
      </Text>
      <ConfirmSecQStep
        waitingState={disableButton}
        questions={questions}
        next={(answers) => handleCheckAnswers(answers)}
      />
    </div>
  );
};

export default ConfirmSecQuestions;
