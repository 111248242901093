import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import { Button } from '../../components/Button';
import Rectangle from '../../components/common/Rectangle';
import Text from '../../components/common/Text';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import AboutImg from './../../icons/Veritise-about.png';
import RectangleContent from './RectangleContent';
import styles from './styles.module.scss';
import './styles.scss';

const Tutorial = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [swiper, setSwiper] = useState(null);
  const [step, setStep] = useState(0);

  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, []);

  const handleBack = useCallback(() => {
    history.goBack();
  }, []);
  const onNext = (skip) => {
    if (step === 4) {
      handleBack();
    }
    if (skip) {
      swiper.slideTo(4);
      setStep(4);
    } else {
      swiper.slideNext();
    }
  };
  const onBack = () => {
    setStep(swiper.activeIndex - 1);
    swiper.slideTo(swiper.activeIndex - 1);
  };

  const onSwipe = (_swiper) => {
    setStep(_swiper.activeIndex);
  };

  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title Visby_Bold authenticated-page-title">
          {t('_Tutorial_')}
        </Text>
      </div>
      <div className={styles.contantContainer}>
        <img src={AboutImg} alt="Veritise" />
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: false,
            bulletActiveClass: 'active-dot',
          }}
          navigation={true}
          className="swiperStyle"
          onSwiper={setSwiper}
          onSlideChange={onSwipe}
        >
          <SwiperSlide>
            <Rectangle isOdd>
              <RectangleContent title={t('_Welcome_to_Veritise_')}>
                <div className={styles.contentDesc}>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_tutorial_1_')}
                  </Text>
                  <span className={styles.blueText}>{t('_verify_products_')}</span>
                </div>
              </RectangleContent>
            </Rectangle>
          </SwiperSlide>
          <SwiperSlide>
            <Rectangle>
              <RectangleContent title={t('_Products_verification_')}>
                <div className={styles.contentDesc}>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_Claim_')}
                  </Text>
                  <span className={styles.greenText}>{t('_ownership_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_and_activate_product_')}
                  </Text>
                  <span className={styles.blueText}>{t('_warranty_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_in_few_seconds_')}
                  </Text>
                </div>
              </RectangleContent>
            </Rectangle>
          </SwiperSlide>
          <SwiperSlide>
            <Rectangle isOdd>
              <RectangleContent title={t('_Gift_products_to_others_')}>
                <div className={styles.contentDesc}>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_Transfer_')}
                  </Text>
                  <span className={styles.greenText}>{t('_ownership_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_of_the_products_to_')}
                  </Text>
                  <span className={styles.blueText}>{t('_other_users_')}</span>
                </div>
              </RectangleContent>
            </Rectangle>
          </SwiperSlide>
          <SwiperSlide>
            <Rectangle>
              <RectangleContent title={t('_Human_resource_management_')}>
                <div className={styles.contentDesc}>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_Add_a_CV_')}.
                  </Text>
                  <span className={styles.greenText}>{t('_Verify_the_CV_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_of_other_')}
                  </Text>
                  <span className={styles.blueText}>{t('_employees_')}</span>
                </div>
              </RectangleContent>
            </Rectangle>
          </SwiperSlide>
          <SwiperSlide>
            <Rectangle isOdd>
              <RectangleContent title={t('_Authorized_check_of_places_of_work_')}>
                <div className={styles.contentDesc}>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_View_only_verified_')}
                  </Text>
                  <span className={styles.greenText}>{t('_employee_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_CVs_to_')}
                  </Text>
                  <span className={styles.blueText}>{t('_verify_')}</span>
                  <Text type="h4" color="secondary-dark-blue">
                    {t('_their_real_statuses_')}
                  </Text>
                </div>
              </RectangleContent>
            </Rectangle>
          </SwiperSlide>
        </Swiper>
        <div className={styles.buttonsContainer}>
          <Button text={step === 4 ? t('_Finish_') : t('_Next_')} appearence="primary" onClick={() => onNext(false)} />
          {step !== 0 && <Button text={t('_Back_')} appearence="primary" onClick={() => onBack()} />}
          {step !== 4 && <Button text={t('_Skip_')} appearence="blueBorder" onClick={() => onNext(true)} />}
        </div>
      </div>
    </>
  );
};
export default Tutorial;
