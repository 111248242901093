import { createAction } from 'deox';

export const setChangeLogs = {
  request: createAction('cv/SAVE_CHANGELOGS_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_CHANGELOGS_SUCCESS', (resolve) => (payload: any) => resolve(payload)),
  fail: createAction('cv/SAVE_CHANGELOGS_FAIL', (resolve) => (payload: any) => resolve(payload)),
};

export const setDocumentation = {
  request: createAction('cv/SAVE_DOCUMENTATION_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_DOCUMENTATION_SUCCESS', (resolve) => (payload: any) => resolve(payload)),
  fail: createAction('cv/SAVE_DOCUMENTATION_FAIL', (resolve) => (payload: any) => resolve(payload)),
};

export const setApiKeys = {
  request: createAction('cv/SAVE_APIKEYS_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_APIKEYS_SUCCESS', (resolve) => (payload: any) => resolve(payload)),
  fail: createAction('cv/SAVE_APIKEYS_FAIL', (resolve) => (payload: any) => resolve(payload)),
};
