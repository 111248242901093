import { Amplify, Auth } from 'aws-amplify';
import 'normalize.css';
import { Suspense, useEffect } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from './components/Loader';
import store from './redux/store/index';
import { Routes } from './Routes';
import { Api } from './services/api/Api';
import ErrorBoundaryComponent from './services/ErrorBoundary';
import './shared/interceptors';
import './styles/main.scss';

export const App = () => {
  useEffect(() => {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: process.env.REACT_APP_AWS_COGNITO_REGION,
        userPoolId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_ID,
        userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_USERPOOL_WEB_CLIENT_ID,
        authenticationFlowType: 'CUSTOM_AUTH', // ON Security Questions
      },
      API: {
        endpoints: [
          {
            authorizationType: 'NONE',
            name: 'MyAPIGatewayAPI',
            endpoint: process.env.REACT_APP_GATEWAY_API,
            custom_header: authorizationHeader,
          },
        ],
      },
      Analytics: {
        disabled: true,
      },
    });

    Api.init();

    const hasCountry_code = Boolean(localStorage.getItem('country_code'));
    if (!hasCountry_code) {
      fetch('https://geolocation-db.com/json/')
        .then((res) => res.json())
        .then((res) => {
          localStorage.setItem('country_code', JSON.stringify(res.country_code));
        })
        .catch((e) => console.error(e));
    }
  }, []);

  const authorizationHeader = async () => {
    try {
      const session = await Auth.currentSession();
      const jwt = session.getAccessToken().getJwtToken();
      if (jwt) {
        return { Authorization: `Bearer ${jwt}` };
      }
    } catch {
      return { Authorization: 'None' };
    }
  };

  return (
    <div className="wrapper">
      <ErrorBoundaryComponent>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Provider store={store}>
          <BrowserRouter>
            <Suspense fallback={Loader}>
              <Routes />
            </Suspense>
          </BrowserRouter>
        </Provider>
      </ErrorBoundaryComponent>
    </div>
  );
};
