/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Modal } from '@mui/material';
import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import { ReactComponent as AlertIcon } from '../../../icons/alert.svg';
import styles from './DeleteEntityModal.module.scss';

interface DeleteEntityModalProps {
  open: boolean;
  entityId: string;
  onClose: (status: boolean) => void;
  onDelete: (entityId: string) => void;
  title: string;
}

export const DeleteEntityModal: FC<DeleteEntityModalProps> = ({ onClose, onDelete, open, entityId, title }) => {
  const { t } = useTranslation();

  return (
    <Modal open={open} onBackdropClick={() => onClose(false)} onClose={onClose} className={styles.modal}>
      <div className={cn(styles.modal__content, styles.delete)}>
        <h2>{title}</h2>

        <AlertIcon className={styles.delete__alert} />

        <p className={styles.delete__description}>{t('_ThisActionCannotBeUndone_')}</p>

        <div className={styles.delete__actions}>
          <Button appearence="alert" text={t('_Delete_')} onClick={() => onDelete(entityId)} />
          <p onClick={() => onClose(false)}>{t('_Cancel_')}</p>
        </div>
      </div>
    </Modal>
  );
};
