import { Auth } from 'aws-amplify';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { Toast } from '../../../../utils/toastHelper';

interface IProps {
  next: () => void;
  email: string;
  isNewPasswordStep?: boolean;
  confirmationCode?: string;
}

const PasswordStep: FC<IProps> = ({ next, email, isNewPasswordStep, confirmationCode }) => {
  const { t } = useTranslation();
  const [passes, setPasses] = useState({
    password: '',
    confirmPassword: '',
  });
  const handleSetPass = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasses({ ...passes, [e.target.name]: e.target.value });
  };
  const handleSubmit = async () => {
    try {
      if (isNewPasswordStep) {
        await Auth.forgotPasswordSubmit(email, confirmationCode, passes.password);
        Toast.success(t('_PasswordHasBeenChanged_'));
      }
      next();
    } catch (e) {
      return Toast.error(e.message);
    }
  };
  const placeholder = t(isNewPasswordStep ? '_EnterNewPassword_' : '_EnterPassword_');
  const confirmPlaceholder = t(isNewPasswordStep ? '_ConfirmNewPassword_' : '_ConfirmPassword_');
  const buttonText = t(isNewPasswordStep ? '_Confirm_' : '_Change_');
  return (
    <div className="oldPasswordContainer">
      {isNewPasswordStep && (
        <Text type="body_l" color="ultra-dark-gray" fontWeight="regular" className="form-title">
          {t('_SetANewPassword_')}
        </Text>
      )}
      <Input
        type="password"
        size="lg"
        placeholder={placeholder}
        label={t('_Password_')}
        name="password"
        isPassword
        onChange={handleSetPass}
      />
      <Input
        type="password"
        isPassword
        size="lg"
        placeholder={confirmPlaceholder}
        label={t('_Password_')}
        name="confirmPassword"
        onChange={handleSetPass}
      />
      <div className="login__button-wrapper">
        <Button
          isPrimary
          className="login__button"
          type="submit"
          text={buttonText}
          isDisabled={!passes.password || passes.password.length < 6 || passes.password !== passes.confirmPassword}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default PasswordStep;
