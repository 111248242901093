import { useFormik } from 'formik';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '../../components/Button/Button';
import Label from '../../components/common/Label';
import Text from '../../components/common/Text';
import CostumUploader from '../../components/ImageUpload/CostumUploader';
import { Input } from '../../components/Input/Input';
import Loader from '../../components/Loader';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import noImg from '../../icons/no-img.jpeg';
import { addOrganization, getOrganizations, updateOrganization } from '../../modules/organization/actions';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { JURISDICTION_CODES } from '../../utils/constants';
import { Toast } from '../../utils/toastHelper';
import { OrganizationInfoValidation as validationSchema } from '../../utils/validations';
import './OrganizationInfo.scss';

const OrganizationInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { attributes } = useSelector((state: any) => state.auth);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [organization, setOrganization] = useState<any>({});
  const [isRemoved, setRemoved] = useState<boolean>(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const { loading, organizations } = useSelector((state: any) => state.organization);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const { userData } = useSelector((state: any) => state.user);

  useEffect(() => {
    dispatch(getOrganizations.req());
  }, []);
  useEffect(() => {
    if (organizations && organizations.data?.length) {
      setOrganization(organizations.data[0]);
    } else if (organizations?.data?._id) {
      setOrganization(organizations.data);
    }
  }, [organizations]);

  const fetEmployeeOrganization = useCallback(async () => {
    try {
      const res = await OrganizationApi.getOrganizationInfo(userData.inviteCode);
      setOrganization(res.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (isEmployee) {
      fetEmployeeOrganization();
    }
  }, [isEmployee]);

  const company_jurisdiction_codeOptions: { label: string; value: any }[] = useMemo(
    () =>
      JURISDICTION_CODES.map((item) => {
        const key = Object.keys(item)[0];
        return {
          label: key,
          value: item[key],
        };
      }),
    [],
  );
  const formatJurisdictionCodeValue = (codeStr) => {
    return company_jurisdiction_codeOptions.find((item) => item.value === codeStr);
  };

  const handleBack = useCallback(() => {
    setIsEdit(false);
  }, []);

  const existOrganisation = organizations?.data[0] || organizations?.data?._id;

  const onSubmit = () => {
    setButtonLoading(true);
    if (!existOrganisation) {
      const payload = {
        name: values.organizationName,
        jurisdictionCode: CommonHelpers.getTextWithoutSpaces(values.jurisdictionCode),
        registrationNumber: CommonHelpers.getTextWithoutSpaces(values.registrationNumber),
        info: {
          images: values.organizationPhoto,
        },
      };
      dispatch(
        addOrganization.req({
          data: payload,
          onSuccess: () => {
            setIsEdit(false);
            Toast.success(t('_OrganizationInformationHasBeenChanged_'));
            setButtonLoading(false);
          },
        }),
      );
    } else {
      if (!values.organizationPhoto.length && organization?.info?.images.length) {
        const url = organization?.info?.images[0].location.startsWith('https')
          ? organization?.info?.images[0].location
          : process.env.REACT_APP_ATTACHMENTS_DOMAIN + organization?.info?.images[0].location;
        fetch(url)
          .then((resp) => {
            resp.blob().then((blob) => {
              const reader = new FileReader();
              reader.readAsDataURL(blob);
              reader.onloadend = function () {
                const base64data: any = reader.result;
                const payload = {
                  name: values.organizationName !== '' ? values.organizationName : organization.name,
                  jurisdictionCode:
                    values.jurisdictionCode !== ''
                      ? CommonHelpers.getTextWithoutSpaces(values.jurisdictionCode)
                      : CommonHelpers.getTextWithoutSpaces(organization.jurisdictionCode),
                  registrationNumber:
                    values.registrationNumber !== ''
                      ? CommonHelpers.getTextWithoutSpaces(values.registrationNumber)
                      : CommonHelpers.getTextWithoutSpaces(organization.registrationNumber),
                  info: {
                    images: isRemoved ? [] : [{ src: base64data }],
                  },
                };
                dispatch(
                  updateOrganization.req({
                    updatedData: payload,
                    id: organization._id,
                    onSuccess: () => {
                      setIsEdit(false);
                      setButtonLoading(false);
                      Toast.success(t('_Organization_info_changed_'));
                    },
                    onFail: () => {
                      setIsEdit(false);
                      setButtonLoading(false);
                      Toast.error(t('_Organization_info_not_changed_'));
                    },
                  }),
                );
              };
            });
          })
          .catch((e) => {
            console.error(e);
          });
      } else {
        const payload = {
          name: values.organizationName !== '' ? values.organizationName : organization.name,
          jurisdictionCode:
            values.jurisdictionCode !== ''
              ? CommonHelpers.getTextWithoutSpaces(values.jurisdictionCode)
              : CommonHelpers.getTextWithoutSpaces(organization.jurisdictionCode),
          registrationNumber:
            values.registrationNumber !== ''
              ? CommonHelpers.getTextWithoutSpaces(values.registrationNumber)
              : CommonHelpers.getTextWithoutSpaces(organization.registrationNumber),
          info: {
            images: values.organizationPhoto.length ? values.organizationPhoto : [],
          },
        };

        dispatch(
          updateOrganization.req({
            updatedData: payload,
            id: organization._id,
            onSuccess: () => {
              setIsEdit(false);
              setButtonLoading(false);
              Toast.success(t('_Organization_info_changed_'));
            },
            onFail: () => {
              setIsEdit(false);
              setButtonLoading(false);
              Toast.error(t('_Organization_info_not_changed_'));
            },
          }),
        );
      }
    }
  };

  const { setFieldValue, values, errors } = useFormik({
    initialValues: {
      organizationName: organization.name || '',
      organizationPhoto: organization?.info?.images || [],
      jurisdictionCode: organization.jurisdictionCode || '',
      registrationNumber: organization.registrationNumber || '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });
  const handleChange = (key, value) => {
    setFieldValue(key, value);
    setOrganization({
      ...organization,
      [key]: value,
    });
  };
  const getImagePreview = useMemo(() => {
    let images = [];

    if (values.organizationPhoto.length) {
      images = values.organizationPhoto;
    } else if (organization?.info?.images) {
      const _ = organization.info.images.map((img) => ({ src: img.location }));
      images = _;
    }
    return images;
  }, [organizations]);

  // eslint-disable-next-line no-console
  console.log('values', values);
  // eslint-disable-next-line no-console
  console.log('organization', organization);

  return (
    <div className="page-header organizationInfo">
      {isEdit && (
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
      )}
      <Text type="h1" className="page__title authenticated-page-title">
        {isEdit
          ? `${t(existOrganisation ? '_Edit_' : '_Create_')} ${t('_OrganizationInformation_')}`
          : t('_OrganizationInformation_')}
      </Text>
      {loading ? (
        <div className="loader_wrapper">
          <Loader />
        </div>
      ) : organization ? (
        <>
          <div className="organizationInfo__wrapper">
            {organization.approvalStatus === 'accepted' && (
              <div className="organizationInfo__wrapper__label">
                <Label status="Verified" text={t('_Verified_')} />
              </div>
            )}

            {organization.approvalStatus === 'pending' && (
              <div className="organizationInfo__wrapper__label">
                <div className="label-alert">{t('_Pending_')}</div>
              </div>
            )}

            {organization.approvalStatus !== 'accepted' && organization.approvalStatus !== 'pending' && (
              <div className="organizationInfo__notice">
                <div className="label-alert">{t('_NotVerified_')}</div>
                {!isEmployee && (
                  <>
                    <div className="organizationInfo__notice-description">{t('_YourAccountHasNotBeenVerified_')}</div>
                    {userData?.isVerificationNeeded ? (
                      <Button
                        isPrimary
                        text={t('_Try_again_')}
                        onClick={() => {
                          localStorage.setItem(
                            'orgInfo',
                            JSON.stringify({
                              company_registration_number: values.registrationNumber || organization.registrationNumber,
                              company_jurisdiction_code: values.jurisdictionCode.value || organization.jurisdictionCode,
                            }),
                          );
                          history.push('/kyb?fromOrgPage');
                        }}
                      />
                    ) : (
                      <div className="organizationInfo__notice-description">{t('_BuySubscriptionToAccessKYB_')}</div>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="organizationInfo__data">
              <Input
                type="text"
                label={t('_CompanyName_')}
                value={!isEdit ? organization.name : undefined}
                isReadOnly={!isEdit}
                isDisabled={!isEdit}
                isRequired={isEdit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('organizationName', e.target.value)}
              />
              {errors && <p className="errors">{errors.organizationName}</p>}
              <Input
                type="text"
                isRequired={isEdit}
                label={t('_Company_registration_number_')}
                value={!isEdit ? organization.registrationNumber : undefined}
                isReadOnly={!isEdit}
                isDisabled={!isEdit}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setFieldValue('registrationNumber', e.target.value)
                }
              />
              {errors && <p className="errors">{errors.organizationName}</p>}
              {!isEdit ? (
                <Input
                  type="text"
                  isRequired={isEdit}
                  isDisabled={!isEdit}
                  label={t('_CountryRegistration_')}
                  value={formatJurisdictionCodeValue(organization.jurisdictionCode)?.label}
                  isReadOnly={!isEdit}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange('jurisdictionCode', e.target.value);
                  }}
                />
              ) : (
                <div className="input last-input">
                  <p>
                    {t('_CountryRegistration_')}
                    <span>*</span>
                  </p>
                  <Select
                    value={company_jurisdiction_codeOptions.find((item) =>
                      values.jurisdictionCode
                        ? item.value === values.jurisdictionCode
                        : item.value === organization.jurisdictionCode,
                    )}
                    // @ts-ignore
                    onChange={(selectedOption: { value: any; label: string }) => {
                      handleChange('jurisdictionCode', selectedOption.value);
                    }}
                    // @ts-ignore
                    options={company_jurisdiction_codeOptions}
                    name="jurisdictionCode"
                    className="input-select"
                    classNamePrefix="input-select"
                    maxMenuHeight={240}
                  />
                  {errors && <p className="errors">{errors.jurisdictionCode}</p>}
                </div>
              )}

              <div className="organizationInfo__data-img">
                {isEdit ? (
                  <CostumUploader
                    label="Logo"
                    isRequered={false}
                    onChange={(images) => setFieldValue('organizationPhoto', images)}
                    isMakeRemove={() => setRemoved(true)}
                    images={getImagePreview}
                  />
                ) : (
                  <div className="organizationInfo__data-img-preview">
                    <img
                      src={
                        organization?.info?.images.length > 0
                          ? organization?.info?.images[0].location.startsWith('https')
                            ? organization?.info?.images[0].location
                            : process.env.REACT_APP_ATTACHMENTS_DOMAIN + organization?.info?.images[0].location
                          : noImg
                      }
                      alt={attributes.name}
                    />
                  </div>
                )}
              </div>
              {errors && <p>{errors.organizationPhoto}</p>}
              {!isEmployee &&
                (isEdit ? (
                  <Button isPrimary text={t('_Save_')} onClick={onSubmit} isLoading={buttonLoading} />
                ) : (
                  <Button
                    isPrimary
                    text={t(existOrganisation ? '_Edit_' : '_Create_')}
                    onClick={() => setIsEdit(true)}
                  />
                ))}
            </div>
          </div>
        </>
      ) : (
        <div>{t('_NoOrganization_')}</div>
      )}
    </div>
  );
};

export default OrganizationInfo;
