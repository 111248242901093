import { useTranslation } from 'react-i18next';
import { Button } from '../Button/Button';
import Text from '../common/Text';
import styles from '../HiddenBankCardInfo/HiddenBankCardInfo.module.scss';
import { Modal } from '../Modal/Modal';

const WarningModal = ({ onSubmit, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={onClose}>
      <div className={styles.deleteCardModal}>
        <Text type="h1" className={styles.deleteCardModalTitle}>
          {t('_ConfirmNavigation_')}
        </Text>
        <div className={styles.descirption}>{t('_AreYouSureYouWantToLeavePage_')}</div>
        <div className="flexible aCenter jCenter">
          <Button className={styles.buttonsStyle} onClick={onSubmit} isAlert>
            {t('_LeaveThisPage_')}
          </Button>
          <Button className={styles.buttonsStyle} isPrimaryOutline onClick={onClose}>
            {t('_StayOnThisPage_')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default WarningModal;
