import { Api } from './Api';

export class CvApi {
  static async getCv(filterParams?: any) {
    const { data } = await Api.get(`/user/cv${filterParams ? filterParams : ''}`);
    return data;
  }

  static async getSingleCV(Id: string | number) {
    const { data } = await Api.get(`/user/cv/${Id}`);
    return data;
  }

  static async geCvByAlias(Id: string | number) {
    const { data } = await Api.get(`/c/${Id}`);
    return data;
  }

  static async createCv(params: any) {
    const { data } = await Api.post('/user/cv', params);
    return data;
  }

  static async updateCv(params: any, Id: string | number) {
    const { data } = await Api.put(`/user/cv/${Id}`, params);
    return data;
  }

  static async deleteCv(Id: string | number) {
    const { data } = await Api.delete(`/user/cv/${Id}`);
    return data;
  }

  static async getWorkExperiences(Id: string | number) {
    const { data } = await Api.get(`/user/cv/${Id}/item`);
    return data;
  }

  static async getSingleWorkExperience(Id: string | number, itemId: string | number) {
    const { data } = await Api.get(`/user/cv/${Id}/item/${itemId}`);
    return data;
  }

  static async deleteSingleWorkExperience(Id: string | number, itemId: string | number) {
    const { data } = await Api.delete(`/user/cv/${Id}/item/${itemId}`);
    return data;
  }

  static async createWorkExperience(params: any, Id?: string | number) {
    const { data } = await Api.post(`/user/cv/${Id}/item`, params);
    return data;
  }

  static async updateWorkExperience(params: any, Id: string | number, itemId: string | number) {
    const { data } = await Api.put(`/user/cv/${Id}/item/${itemId}`, params);
    return data;
  }

  static async createCvRequests(params: any, Id: string | number, itemId: string | number) {
    const { data } = await Api.post(`/user/cv/${Id}/item/${itemId}/request`, params);
    return data;
  }

  static async updateCvRequest(params: any, Id: string | number) {
    const { data } = await Api.put(`/user/cv/request/${Id}`, params);
    return data;
  }

  static async getCvRequests(filterParams?: any) {
    const { data } = await Api.get(`/user/cv/request${filterParams || ''}`);
    return data;
  }

  static async getSingleCvRequests(Id?: string | number) {
    const { data } = await Api.get(`/user/cv/request/${Id}`);
    return data;
  }

  static async createRecommendation(Id?: string | number, itemId?: string | number, params?: any) {
    const { data } = await Api.post(`/user/cv/${Id}/item/${itemId}/recommendation`, params);
    return data;
  }

  static async createEducation(params: any, Id: string | number) {
    const { data } = await Api.post(`/user/cv/${Id}/education`, params);
    return data;
  }

  static async updateEducation(params: any, Id: string | number, itemId?: string | number) {
    const { data } = await Api.put(`/user/cv/${Id}/education/${itemId}`, params);
    return data;
  }
}
