/*eslint-disable*/
import Dexie, { Table } from 'dexie';
// import { BaseSecureStorage } from '../storage/persistence/BaseSecureStorage';
import { ProductDB } from './productDB.interface';

const VERSION_DB = 1;
const NAME_DB = 'products_db';

export class ProductsDB extends Dexie {
  products!: Table<ProductDB.Product>;

  constructor() {
    super(NAME_DB);
    this.version(VERSION_DB).stores({
      products: '&publicKey, userId, bulkId',
    });
  }
}

export const db = new ProductsDB();
