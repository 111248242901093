import { createAction } from 'deox';

export const setDataLoading = createAction('organization/SET_USER_DATA_LOADING', (res) => (params) => res(params));

export const getOrganizations = {
  req: createAction('organization/GET_ORGANIZATIONS'),
  success: createAction('organization/GET_ORGANIZATIONS_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('organization/RESET_ORGANIZATIONS_SUCCESS'),
};
export const updateOrganization = {
  req: createAction('organization/UPDATE_ORGANIZATION', (res) => (params) => res(params)),
};
export const addOrganization = {
  req: createAction('organization/ADD_ORGANIZATION', (res) => (params) => res(params)),
};
