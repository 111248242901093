import moment from 'moment';
import { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Text from '../../../../components/common/Text';
import { ProductBulk } from '../../../../modules/product/types';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { BulkActions } from '../BulkActions';
import { ReactComponent as NoProductImage } from './../../../../icons/no-product-image.svg';

const ProductBulkRow = memo(
  ({
    item,
    openRemoveBulk,
    openEditBulk,
  }: {
    item: ProductBulk;
    openRemoveBulk: (id: string) => void;
    openEditBulk: (id: string) => void;
  }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [isEditable, setIsEditable] = useState(false);

    const handleClick = () => {
      history.push(`/bulk/${item.alias}`);
    };

    useEffect(() => {
      void (async () => {
        if (!item.isEditable) return;
        if (item.isLocked) return;

        const productsKeys = await ProductsSecureStorage.getProductByBulkId(item._id);
        setIsEditable(!!productsKeys.length);
      })();
    }, []);

    return (
      <div className="products__item">
        <div className="products__item__first_col" onClick={handleClick}>
          <div className="products__preview products__item__first_col-sm-col">
            {item.info.images?.length ? (
              <img
                src={
                  item.info.images
                    ? process.env.REACT_APP_ATTACHMENTS_DOMAIN +
                      (item.info.images[0]?.src || item.info.images[0]?.location)
                    : ''
                }
                alt={item.name}
              />
            ) : (
              <NoProductImage />
            )}
          </div>
          <Text
            id="product-id"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
            className="products__item__first_col-lg-col"
          >
            {item.name}
          </Text>
          <Text
            id="product-id"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
            className="products__item__first_col-lg-col"
          >
            {item.category ? item.category.name : t('_NoCategory_')}
          </Text>
          <Text
            id="product-id"
            className="products__item__first_col-md-col"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
          >
            {moment(item.createdAt).format('DD/MM/YYYY')}
          </Text>
        </div>
        <div>
          <BulkActions
            bulk={item}
            onDeleteClicked={openRemoveBulk}
            onEditClicked={openEditBulk}
            isEditable={isEditable}
          />
        </div>
      </div>
    );
  },
);

export default ProductBulkRow;
