import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import BusyLoader from '../../../../components/BusyLoader';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { ImageUpload } from '../../../../components/ImageUpload/ImageUpload';
import { Input } from '../../../../components/Input/Input';
import Loader from '../../../../components/Loader';
import ProductField from '../../../../components/ProductField';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import WarningModal from '../../../../components/WarningModal';
import { useRedirect } from '../../../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../../../icons/arrow.svg';
import { ReactComponent as CloseIcon } from '../../../../icons/closeX.svg';
import { getOrganizations } from '../../../../modules/organization/actions';
import { getCategories } from '../../../../modules/product/actions';
import { loadAccountAction, loadAccountsBalancesAction } from '../../../../modules/wallet/actions';
import { CommonApi } from '../../../../services/api';
import { ProductApi } from '../../../../services/api/ProductApi';
import { CommonHelpers } from '../../../../utils/commonHelpers';
import { ATTACHMENTS_IMAGES_AMOUNT_LIMIT } from '../../../../utils/constants';
import { Toast } from '../../../../utils/toastHelper';
import { AddProductValidation } from '../../../../utils/validations';
import {
  createEmptyProductState,
  createRequestPayloadForProduct,
  mapProductDetailsToPrdouctState,
} from '../../helpers';
import { WarrantyModal } from '../WarrantyModal';
import './styles.scss';

interface Option {
  label: string;
  value: string;
}

const EditTemplate: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const params: any = useParams();
  const [isValid, setIsValid] = useState<boolean>(false);
  const [formValues, setFormValues] = useState(createEmptyProductState());
  const [fields, setFileds] = useState([]);
  const [warrantyOptions, setWarrantyOptions] = useState<any>([]);
  const [fieldsLoading, setFiledsLoading] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [isOpenWarning, setIsOpenWarning] = useState(false);
  const [requestBack, setRequestBack] = useState(null);
  const { userWarranties, categories } = useSelector((state: any) => state.products);
  const { organizations } = useSelector((state: any) => state.organization);
  const redirectToProducts = useRedirect('/products');
  const [categoriesOptions, setCategoriesOptions] = useState<any>([]);
  const [isCategoiresCalled, setIsCategoriesCalled] = useState<any>(false);
  const { username } = useSelector((state: any) => state.auth);

  useEffect(() => {
    void (async () => {
      try {
        setIsBusy(true);
        if (!isCategoiresCalled) {
          dispatch(getOrganizations.req());
        }
        dispatch(loadAccountAction({}));

        const res = await ProductApi.getProductTemplateById(params.id);

        const productState = mapProductDetailsToPrdouctState(res.data);
        setFormValues(productState);
      } catch (error) {
        console.error(error);
      } finally {
        setIsBusy(false);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(loadAccountsBalancesAction());
  }, [username]);

  useEffect(() => {
    if (organizations && !isCategoiresCalled) {
      if (organizations.data[0]) {
        setIsCategoriesCalled(true);
        dispatch(getCategories.req(organizations.data[0]._id));
      }
    }
  }, [organizations]);

  useEffect(() => {
    const options =
      userWarranties?.warranties.map((item: any) => {
        return {
          value: item?._id,
          label: item?.title,
        };
      }) || [];
    options.push({ value: 'add', label: t('_AddNewWarranty_') });
    setWarrantyOptions(options);
  }, [userWarranties]);

  useEffect(() => {
    if (organizations) {
      const options = categories.map((item) => ({ value: item._id, label: item.name }));
      options.push({ value: 'add', label: t('_AddNewCategory_') });
      setCategoriesOptions(options);
    }
  }, [categories]);

  useEffect(() => {
    async function getCategoryFields() {
      setFiledsLoading(true);
      try {
        const _fieldsRes = await CommonApi.getFiledsForCategory(formValues.productCategory.value);
        if (CommonHelpers.isOkStatus(_fieldsRes.status)) {
          setFileds(_fieldsRes.data.fields);
        }
        setFiledsLoading(false);
      } catch {
        setFiledsLoading(false);
      }
    }
    if (formValues?.productCategory?.value) {
      getCategoryFields();
    }
  }, [formValues]);

  const saveChanges = async (payload) => {
    await ProductApi.editProductTemplate(params.id, payload);
    redirectToProducts();
  };

  const onSubmit = async (data: any) => {
    try {
      const payload = createRequestPayloadForProduct(data);
      setIsBusy(true);
      !payload.warrantyId && delete payload.warrantyId;

      if (payload.info.images.length) {
        const images = [];
        const urlImages = [];
        for (let i = 0; i < payload.info.images.length; i++) {
          if (payload.info.images[i].src.startsWith('data')) {
            images.push(payload.info.images[i]);
          } else {
            if (payload.info.images[i].src.includes('http')) {
              urlImages.push(payload.info.images[i]);
            } else {
              urlImages.push({
                src: process.env.REACT_APP_ATTACHMENTS_DOMAIN + payload.info.images[i].src,
              });
            }
          }
        }
        const responses = await Promise.all(urlImages.map((img) => fetch(img.src)));

        responses.forEach((resObj) => {
          resObj.blob().then((blob) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async function () {
              const base64data: any = reader.result;
              images.push({ src: base64data });
              if (images.length !== payload.info.images.length) return;

              payload.info.images = images;
              await saveChanges(payload);
            };
          });
        });
      } else {
        payload.info.images = [];
        await saveChanges(payload);
      }
    } catch (e) {
      console.error(e);
      setIsBusy(false);
      Toast.error(`${t('_ErrorWhileUpdatingProduct_')} ${JSON.stringify(e)}`);
    }
  };

  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: formValues,
    validateOnChange: false,
    validationSchema: AddProductValidation,
    onSubmit,
    enableReinitialize: true,
  });

  useEffect(() => {
    const { productName } = values;
    if (productName) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [values]);

  const onAddWarranty = (selectedOption: Option) => {
    setWarrantyOpened(true);
    setFieldValue('warranty', selectedOption);
  };

  const historyGoBack = () => {
    history.goBack();
  };
  const historyFieldManagment = () => {
    history.push({
      pathname: '/fields-management',
      state: { from: 'fromCagtegoryCreate' },
    });
  };

  const onAddCategory = () => {
    if (hasChange) {
      setIsOpenWarning(true);
      setRequestBack(false);
    } else {
      historyFieldManagment();
    }
  };

  const [warrantyOpened, setWarrantyOpened] = useState(false);

  const handleWarrantyCreate = () => {
    setWarrantyOpened(false);
  };

  const handleSetCategory = async (key, category) => {
    setFieldValue(key, category);
    setHasChange(true);
    setFiledsLoading(true);
    try {
      const _fieldsRes = await CommonApi.getFiledsForCategory(category.value);
      if (CommonHelpers.isOkStatus(_fieldsRes.status)) {
        setFileds(_fieldsRes.data.fields);
      }
      setFiledsLoading(false);
    } catch {
      setFiledsLoading(false);
    }
  };

  const handleSetProductFiled = (name, value, fieldInfo) => {
    setHasChange(true);
    const fields = {
      ...values.fields,
      [name]: { ...fieldInfo, value },
    };
    setFieldValue('fields', fields);
  };

  const onCancel = () => {
    if (hasChange) {
      setIsOpenWarning(true);
      setRequestBack(true);
    } else {
      historyGoBack();
    }
  };

  return (
    <div className="page-header CvCreation">
      <WarrantyModal
        onSubmit={handleWarrantyCreate}
        open={warrantyOpened}
        onClose={(warranty: any) => {
          if (warranty) {
            setFieldValue('warranty', {
              label: warranty.title,
              value: warranty._id,
            });
          }
          setWarrantyOpened(false);
        }}
      />
      <div className="signup__back-button" onClick={historyGoBack}>
        <ArrowIcon />
        <span>{t('_Back_')}</span>
      </div>
      <Text type="h1" className="page__title">
        {t('_Edit_template_')}
      </Text>
      <BusyLoader className="creation-container product-creation-container" isBusy={isBusy}>
        <div className="creation-submit-content flexible jBetween aStart create_container">
          <h2 className="modal__subtitle main_info-title">{t('_Details_')}</h2>
          <div className="create-product__row">
            <ImageUpload
              max={ATTACHMENTS_IMAGES_AMOUNT_LIMIT}
              isRequered
              label={t('_Photos_')}
              className="image-upload create-product-upload"
              images={values.images}
              onChange={(files) => {
                setFieldValue('images', files);
                setHasChange(true);
              }}
            />
            {errors && <p className="errors image-errors">{errors.images}</p>}
          </div>
          <div className="create-product__row">
            <div className="create-product__item">
              <Input
                type="text"
                labelClassName="product-create-label"
                name="productName"
                className="product-create-input"
                placeholder={t('_Name_')}
                isRequired
                label={t('_Name_')}
                value={values.productName}
                onChange={(e: any) => {
                  setFieldValue('productName', e.target.value);
                  setHasChange(true);
                }}
              />
              {errors && <p className="errors">{errors.productName}</p>}
            </div>
            <div className="create-product__item">
              <p className="label product-create-label">{t('_Warranty_')}</p>
              <Select
                value={
                  values.warranty?.value === 'add'
                    ? ''
                    : warrantyOptions.filter((option: any) => option.label === values.warranty.label)
                }
                onChange={(selectedOption: Option | any) => {
                  selectedOption.value === 'add'
                    ? onAddWarranty(selectedOption)
                    : setFieldValue('warranty', selectedOption);
                  setHasChange(true);
                }}
                options={warrantyOptions ? warrantyOptions : [{}]}
                name="warranty"
                className="product-create-input"
                classNamePrefix="input-select"
                placeholder={t('_startTyping_')}
                maxMenuHeight={240}
              />
              {values.warranty.label && values.warranty.value !== 'add' && (
                <div
                  className="removeWarrantyIcon"
                  onClick={() => {
                    setFieldValue('warranty', {});
                    setHasChange(true);
                  }}
                >
                  <CloseIcon />
                </div>
              )}
              {errors && <p className="errors">{errors.warranty}</p>}
            </div>
          </div>
          <div className="create-product__row">
            <div className="create-product__item create-product__item_full">
              <p className="label product-create-label">{t('_Category_')}</p>
              <Select
                value={categoriesOptions.filter((option: any) => {
                  return option.label === values.productCategory.label || option.value === values.productCategory.value;
                })}
                onChange={(selectedOption) => {
                  selectedOption.value === 'add'
                    ? onAddCategory()
                    : handleSetCategory('productCategory', selectedOption);
                }}
                options={categoriesOptions ? categoriesOptions : [{}]}
                name="productCategory"
                className="product-create-input"
                classNamePrefix="input-select"
                placeholder={t('_startTyping_')}
                maxMenuHeight={240}
              />
              {/* @ts-ignore */}
              {errors && <p className="errors">{errors.productCategory?.value}</p>}
            </div>
          </div>
          <div className="create-product__row">
            <div className="create-product__item_full">
              <TextEditor
                placeholder={t('_Description_')}
                onChange={(text) => {
                  setFieldValue('description', text);
                  setHasChange(true);
                }}
                initialState={values.description}
              />

              {errors && <p className="errors">{errors.description}</p>}
            </div>
            {!!fields.length && (
              <>
                <h2 className="modal__subtitle custom_fields-title">{t('Custom fields')}</h2>
                <div className="create-product__row">
                  {fieldsLoading ? (
                    fields.map((field) => {
                      return (
                        <ProductField
                          key={field._id}
                          value={values.fields ? values.fields[field.name]?.value || '' : ''}
                          setFieldValue={handleSetProductFiled}
                          fieldInfo={field}
                        />
                      );
                    })
                  ) : (
                    <div className="loader_wrapper">
                      <Loader />
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </BusyLoader>
      <div className="flexible buttons-block">
        <Button isPrimary isDisabled={!isValid || isBusy} onClick={handleSubmit}>
          {t('_Save_')}
        </Button>
        <Button isPrimaryOutline onClick={onCancel}>
          {t('_Cancel_')}
        </Button>
      </div>
      {isOpenWarning && (
        <WarningModal
          onClose={() => {
            setIsOpenWarning(false);
            setRequestBack(null);
          }}
          onSubmit={() => {
            requestBack ? historyGoBack() : historyFieldManagment();
          }}
        />
      )}
    </div>
  );
};

export default EditTemplate;
