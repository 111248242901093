import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../components/Button';
import Label from '../../../components/common/Label';
import Text from '../../../components/common/Text';
import TextGroup from '../../../components/common/TextGroup';
import ImageSlderModal from '../../../components/ImageSliderModal';
import { dateCheckerAndConverter, leftDates } from '../../../utils/dates';
import styles from './styles.module.scss';

const WarrantyModal: FC<any> = ({ product, handleChangeStatus }) => {
  const { t } = useTranslation();
  const [modalVisible, setModalVisible] = useState(false);

  const imageSrc = product.info.images
    ? process.env.REACT_APP_ATTACHMENTS_DOMAIN + (product.info.images[0]?.src || product.info.images[0]?.location)
    : '';

  return (
    <>
      <div className={styles.modalContainer}>
        <div className={styles.modalContainer__leftCol}>
          <Label
            status={product?.warranty?.status === 'Not active' ? 'expired' : product.warranty.status}
            text={product?.warranty?.status === 'Not active' ? t('_NotActive_') : null}
            classname={styles.modalContainer__leftCol__label}
            isWarranty
          />
          <div className={styles.modalContainer__leftCol__dateContainer}>
            <div className={styles.modalContainer__preview} onClick={() => setModalVisible(true)}>
              {product.info.images?.length ? <img src={imageSrc} alt={product.name} /> : null}
            </div>
            <TextGroup label={t('_StartDate_')} text={dateCheckerAndConverter(product.warranty.startDate)} />
            <TextGroup label={t('_EndDate_')} text={dateCheckerAndConverter(product.warranty.endDate)} />
            <TextGroup label={t('_Period_')} text={`${product.warranty.period} ${t('_days_')}`} />
            <TextGroup label={t('_Left_')} text={leftDates(product?.warranty?.endDate)} />
          </div>
          <div className={styles.modalContainer__leftCol__description}>
            <TextGroup label={t('_Description_')} text={product.warranty.description} />
          </div>
          <div className={styles.modalContainer__leftCol__userDatails}>
            <TextGroup label={t('_UserDetails_')}>
              <Text type="subtitle_m" fontWeight="bold" color="dark-primary">
                {product?.warranty?.info?.email}
              </Text>
            </TextGroup>
          </div>
          <div className={styles.modalContainer__leftCol__actionButtons}>
            <Button
              text={t('_Accept_')}
              appearence="primary"
              onClick={() => handleChangeStatus('Active')}
              disabled={product.warranty.status !== 'Pending'}
            />
            <Button
              text={t('_Decline_')}
              appearence={product.warranty.status !== 'Pending' ? 'primary' : 'blueBorder'}
              onClick={() => handleChangeStatus('Declined')}
              disabled={product.warranty.status !== 'Pending'}
            />
          </div>
        </div>
        <div className={styles.modalContainer__rightCol}>
          <div className={styles.modalContainer__rightCol__email}>
            <TextGroup label={t('_Email_')} text={product?.warranty?.info?.email} />
          </div>
          <div className={styles.modalContainer__rightCol__phone}>
            <TextGroup label={t('_Phone_')} text={product?.warranty?.info?.phone} />
          </div>
          <div className={styles.modalContainer__rightCol__address}>
            <TextGroup label={t('_Address_')} text={product?.warranty?.info?.address} />
          </div>
          <div className={styles.modalContainer__rightCol__requestDate}>
            <TextGroup label={t('_RequestDate_')} text={dateCheckerAndConverter(product?.warranty?.createdAt)} />
          </div>
          <div className={styles.modalContainer__rightCol__requestDate}>
            <TextGroup label={t('_PurchaseDate_')} text={dateCheckerAndConverter(product?.purchasedDate)} />
          </div>
        </div>
      </div>
      <ImageSlderModal
        initialSlide={0}
        images={[{ src: imageSrc, alt: product.name }]}
        open={modalVisible}
        onClose={() => setModalVisible(false)}
      />
    </>
  );
};

export default WarrantyModal;
