import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../common/Text';
import AppStore from './../../icons/app-store.png';
import PlayMarket from './../../icons/google-play.png';
import ScanImg from './../../icons/scanImg.png';
import styles from './styles.module.scss';

const EmptyScan: FC<{ fromHistory?: boolean }> = ({ fromHistory }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.emptyScanContainer}>
      <img src={ScanImg} alt="scan" className={styles.scanImg} />
      <div className={styles.emptyScanContainer__textContainer}>
        {fromHistory && (
          <Text type="h4" fontWeight="light_bold">
            {t('_YourScanHistoryIsEmpty_')}
          </Text>
        )}
        <Text type="h4" fontWeight="light_bold">
          {t('_ForScanningPleaseUseMobileApp_')}
        </Text>
      </div>
      <div className={styles.marketsContainer}>
        <a href="/" target="_blank">
          <img src={AppStore} alt="app store" />
        </a>
        <a href="/" target="_blank">
          <img src={PlayMarket} alt="play market" />
        </a>
      </div>
    </div>
  );
};

export default EmptyScan;
