import { Component, ErrorInfo } from 'react';

interface Props {
  children: any;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryComponent extends Component<Props, State> {
  public state: State = {
    hasError: false,
  };

  public static getDerivedStateFromError(): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return <div>We’re sorry - something has gone wrong. Please try again or contact the Support.</div>;
    }

    return children;
  }
}

export default ErrorBoundaryComponent;
