export enum LoginActions {
  REQUEST = 'REQUEST',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export const COPY_SEED_PHRASE_STEP = {
  stepNumber: 1,
  title: '_Wallet_creation_step_1_title_',
  subtitle: '_Wallet_creation_step_1_subtitle_',
  description: '_Wallet_creation_step_1_description_',
  warningText: '_Wallet_creation_step_1_warning_',
  buttonValue: '_Wallet_creation_step_1_button_',
  nextStep: () => CHECK_SEED_PHRASE_STEP,
};

export const CHECK_SEED_PHRASE_STEP = {
  stepNumber: 2,
  title: '_Wallet_creation_step_2_title_',
  subtitle: '_Wallet_creation_step_2_subtitle_',
  description: '_Wallet_creation_step_2_description_',
  warningText: '_Wallet_creation_step_2_warning_',
  buttonValue: '_Wallet_creation_step_2_button_',
  nextStep: () => CHECK_SEED_PHRASE_STEP,
};
