import moment from 'moment';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../../../components/common/Label';
import TextGroup from '../../../../../components/common/TextGroup';
import styles from './styles.module.scss';

const DetailsStep: FC<any> = ({ warranty }) => {
  const { t } = useTranslation();
  const startDate = warranty.startDate ? moment(warranty.startDate).format('DD MMM YYYY') : '';
  const endDate = warranty.endDate ? moment(warranty.endDate).format('DD MMM YYYY') : '';

  const period =
    warranty.period > 365
      ? `${Math.floor(warranty.period / 365)} ${t('_Year_')} ${
          warranty.period - warranty.period / 365 > 0
            ? ` ${Math.floor(warranty.period - Math.floor(warranty.period / 365) * 365)} ${t('_days_')}`
            : ''
        }`
      : warranty.period + 'days';
  const isActive = warranty.status === 'Activated';
  const isPending = warranty.status === 'Pending';
  return (
    <div className={styles.detailsStepContainer}>
      <div className={styles.detailsStepContainer__warrantyInfoCol}>
        <div className={styles.detailsStepContainer__warrantyInfoCol__label}>
          {warranty.status && (
            <Label
              text={t(isPending ? '_Pending_' : isActive ? '_WarrantyIsActive_' : warranty.status)}
              status={isPending ? 'pending' : isActive ? 'active' : warranty.status}
              classname={styles.detailsStepContainer__warrantyInfoCol__label__item}
            />
          )}
        </div>
        {warranty.info && (
          <div className={styles.detailsStepContainer__warrantyInfoCol__dates}>
            <TextGroup label={t('_StartDate_')} text={startDate} />
            <TextGroup label={t('_EndDate_')} text={endDate} />
            <TextGroup label={t('_Period_')} text={period} />
          </div>
        )}
        <div className={styles.detailsStepContainer__warrantyInfoCol__desc}>
          <TextGroup label={t('_HowTo_')} text={warranty.description} />
        </div>
      </div>
      {warranty.info && (
        <div className={styles.detailsStepContainer__ownerInfoCol}>
          <TextGroup label={t('_Email_')} text={warranty.info.email || '-'} />
          <TextGroup label={t('_Phone_')} text={warranty.info.phone || '-'} />
          <TextGroup label={t('_Address_')} text={warranty.info.address || '-'} />
        </div>
      )}
    </div>
  );
};

export default DetailsStep;
