/* eslint-disable @typescript-eslint/no-explicit-any */

import { createAction } from 'deox';
import { AccountModel } from '../../storage/models/AccountModel';

export const walletInitStateAction = createAction('wallet/WALLET_INIT_STATE');
export const saveWalletAction = createAction(
  'wallet/SAVE_WALLET',
  (res) => (payload: { mnemonic: string; onSuccess?: () => void; onError?: (e) => void }) => res(payload),
);

export const loadAccountsBalancesAction = createAction('wallet/LOAD_ACCOUNTS_BALANCES');

export const setMnemonicAction = createAction(
  'wallet/SET_MNEMONIC',
  (res) => (data: { mnemonic: string }) => res(data),
);
export const setNameAction = createAction('wallet/SET_NAME', (res) => (name: string) => res(name));
export const setAccountsAction = createAction(
  'wallet/SET_ACCOUNTS',
  (res) => (data: { accounts: AccountModel[] }) => res(data),
);
export const setAccountBalancesAction = createAction(
  'wallet/SET_ACCOUNT_BALANCES',
  (res) => (data: { accountBalances: any }) => res(data),
);
export const setSelectedAccountAction = createAction(
  'wallet/SET_SELECTED_ACCOUNT',
  (res) => (data: { accountModel: AccountModel }) => res(data),
);
export const saveSetSelectedNISNodeAction = createAction(
  'wallet/SAVE_SET_SELECTED_NIS',
  (res) => (data: any) => res(data),
);
export const setLoading = createAction('wallet/SET_LOADING_PERCENT', (res) => (data: number) => res(data));
export const setLoader = createAction('wallet/SET_LOADER', (res) => (data: boolean) => res(data));
export const setInitialState = createAction('wallet/SET_INITIAL_STATE');
export const loadAccountAction = createAction(
  'wallet/LOAD_ACCOUNT_ACTION',
  (res) => (payload: { id?: any }) => res(payload),
);
