import cn from 'classnames';
import React, { FC, useEffect, useRef, useState } from 'react';
import styles from './TabMenu.module.scss';
import { TabMenuProps, TabProps } from './types';

export const TabMenu: FC<TabMenuProps> = ({ children, tabsContentClass, onClick }) => {
  //@ts-ignore
  const [activeTab, setActiveTab] = useState(children[0].props.label);

  const [tabLinePosition, setTabLinePosition] = useState(0);
  const [tabLineWidth, setTabLineWidth] = useState(0);

  const onClickTabItem = (e: React.FormEvent<HTMLDivElement>, tab: string) => {
    if (onClick) {
      onClick(tab);
    }
    setActiveTab(tab);
    setTabLinePosition(e.currentTarget.offsetLeft);
    setTabLineWidth(e.currentTarget.offsetWidth);
  };

  const tabsRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (tabsRef.current) {
      //@ts-ignore
      setTabLineWidth(tabsRef.current.firstChild?.offsetWidth);
    }
  }, []);

  return (
    <>
      <nav className={cn(styles.container)}>
        <div className={styles.tabs} ref={tabsRef}>
          {children.map((tab) => {
            //@ts-ignore
            const { label } = tab?.props || {};
            return <Tab activeTab={activeTab} label={label} key={label} onClick={onClickTabItem} />;
          })}
          <div className={styles['active-line']} style={{ width: tabLineWidth, left: tabLinePosition }} />
        </div>
      </nav>
      <div className={cn(styles['tabs-content'], tabsContentClass)}>
        {children.filter((child) => {
          //@ts-ignore
          return child?.props.label === activeTab;
        })}
      </div>
    </>
  );
};

export const Tab: FC<TabProps> = ({ onClick, label, activeTab }) => {
  const handleClick = (e: React.FormEvent<HTMLDivElement>) => {
    onClick(e, label);
  };

  return (
    <div
      onClick={handleClick}
      className={cn(styles['tabs-tab'], {
        [styles.active]: label === activeTab,
      })}
    >
      {label}
    </div>
  );
};

export const TabContent: FC<Pick<TabProps, 'label'>> = (props) => {
  return <React.Fragment>{props.children}</React.Fragment>;
};
