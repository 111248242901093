import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadIcon } from '../../../../icons/download-file-gray.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/trash.svg';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { APP_URL } from '../../../../utils/constants';
import { Toast } from '../../../../utils/toastHelper';
import exportZipArchive from '../../helpers/exportZipArchive';
import { ProductCombined } from '../../interfaces/productCombined.interface';

interface ProductActionsProps {
  product: ProductCombined.Public;
  onEditClicked: (id: string) => void;
  onDeleteClicked: (id: string) => void;
  isEditable?: boolean;
  alias: string;
}

export const ProductActions: FC<ProductActionsProps> = ({
  product,
  onDeleteClicked,
  onEditClicked,
  isEditable,
  alias,
}) => {
  const { t } = useTranslation();
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const exportHandle = async () => {
    try {
      const _productPrivateCollection = await ProductsSecureStorage.getProduct(product._id);
      const combined: ProductCombined.Combined = {
        productLink: `${process.env.REACT_APP_URL}${product.publicQrCode.link}`,
        name: product.name,
        category: product.category ? product.category.name : '',
        publicQR: `${APP_URL}/${product.publicQrCode.link}`,
        privateQR: _productPrivateCollection.privateKey,
        publicQRFile: `${product.alias}_public.svg`,
        privateQRFile: `${product.alias}_private.svg`,
      };

      exportZipArchive([combined]);
    } catch (error) {
      console.error(error);
      Toast.error(t('_ExportProductsError_'));
    }
  };

  return (
    <div className="actions-col">
      {!isEmployee && isEditable && (
        <>
          <IconButton className="icon-button" onClick={exportHandle}>
            <DownloadIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onEditClicked(alias)}>
            <EditIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onDeleteClicked(product._id)}>
            <TrashIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};
