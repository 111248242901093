import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { useRedirect } from '../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { ProductApi } from '../../services/api/ProductApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { ProductSummary } from './components';
import styles from './ProductDetails.module.scss';

export const BulkDetails: FC = () => {
  const { t } = useTranslation();
  const [bulkData, setBulkData] = useState<any>();
  const userType = useDetectUserType();
  const isBusinessUser = userType === 'company';
  const [loading, setLoading] = useState(false);
  const { pathname } = useLocation();
  const id = CommonHelpers.getParamFromURL(pathname, 2);
  const [productCategory, setProductCategory] = useState<undefined | string>();
  const handleBack = useRedirect('/products');

  useEffect(() => {
    void (async () => {
      try {
        setLoading(true);

        const res = await ProductApi.getBulkById(id);
        setBulkData(res.data);

        if (res.data.categoryId) {
          ProductApi.getProductCategory(bulkData.categoryId)
            .then(({ data: category }) => category.name)
            .then(setProductCategory);
        }
      } catch (error) {
        //
      } finally {
        setLoading(false);
      }
    })();
  }, [id]);

  const productOwner = useMemo(
    () => (bulkData?.owner?.email && bulkData?.owner?.info ? bulkData.owner : null),
    [bulkData],
  );

  return (
    <>
      <div className={styles['product-page-header']}>
        {!isBusinessUser && (
          <div className={cn(styles['back-button'], 'signup__back-button')} onClick={handleBack}>
            <ArrowIcon />
            <span>{t('_Back_')}</span>
          </div>
        )}
        <h1 className={styles['page-title']}>{bulkData?.name}</h1>
      </div>
      {loading && !bulkData ? (
        <div className="loader_wrapper">
          <Loader />
        </div>
      ) : (
        bulkData && (
          <ProductSummary
            product={bulkData}
            category={productCategory}
            owner={productOwner}
            isBusinessUser={isBusinessUser}
            createdViaBulk
          />
        )
      )}
    </>
  );
};
