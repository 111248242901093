import { ResponseError } from '../../modules/errors';

export enum Status {
  Authentic = 'authentic',
  Registered = 'registered',
  Transferred = 'transferred',
}

export type Category = {
  _id: string;
  createdAt: string;
  description: string;
  isActive: boolean;
  name: string;
  organizationId: string;
  organization_id: string;
  parentId: string | null;
  updatedAt: string;
};

export type Product = {
  id: string;
  title: string;
  description: string;
  date: Date;
  createdAt?: string;
  images: string[];
  category: Category;
  company: string;
  companyLogo: string;
  isVerified: boolean;
  isCV: boolean;
  isPurchased: boolean;
  isOnWarranty: boolean;
  warrantyDays: number;
  ownerID: string;
  status: Status;
};

export type ProductBulk = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  alias: string;
  isEditable: boolean;
  isLocked: boolean;
  warrantyId: string | null;
  ownerId: string | null;
  manufacturerId: string;
  categoryId: string | null;
  name: string;
  description: string;
  info: {
    fields: any[];
    images: {
      src: string;
      location: string;
    }[];
  };
  warranty_id: string | null;
  category_id: string | null;
  warranty: any;
  category: any;
};

export type ProductTemplate = {
  _id: string;
  createdAt: string;
  updatedAt: string;
  warrantyId: string | null;
  // ownerId: string | null;
  // manufacturerId: string;
  categoryId: string | null;
  name: string;
  description: string;
  info: {
    fields: any[];
    images: {
      src: string;
      location: string;
    }[];
  };
  warranty_id: string | null;
  category_id: string | null;
  warranty: any;
  category: any;
};

export type ProductsState = {
  loading: boolean;
  dataLoading: boolean;
  productsLoading: boolean;
  firstLoad: boolean;
  products: { data: Product[]; total: number };
  warranties: { [productID: string]: WarrantyDetails };
  categories: any;
  error: ResponseError | null;
  product: Product;
  productHistory: any[];
  userWarranties: {
    warranties: any[];
    total: number;
  };
  scanItem: any;
  scanHistory: {
    data: any[];
    total: number;
  };
  bulksLoading: boolean;
  bulks: {
    data: ProductBulk[];
    total: number;
  };
  templatesLoading: boolean;
  templates: {
    data: ProductTemplate[];
    total: number;
  };
};

export enum ProductTraceType {
  RegisteredByManufacturer = 'registeredByManufacturer',
  OwnershipRegistered = 'ownershipRegistered',
  SendOwnershipProfs = 'endOwnershipProf', // has a param
  WarrantyIsActive = 'warrantyIsActive', // has a param
  WarrantyIsExpired = 'warrantyIsExpired',
}

export interface ProductTrace {
  id: string;
  type: string;
  text: string;
  param: string;
  date: Date;
}

export interface WarrantyDetails {
  id: string;
  productID: string;
  ownerID: string;
  isActivated: boolean;
  startDate: Date;
  endDate: Date;
  howTo: string;
  phone: string;
  address: string;
  email: string;
  title?: string;
}

export interface UserDetails {
  id: string;
  images: string[];
  title: string;
  description: string;
  phone: string;
  isUser: boolean;
}
