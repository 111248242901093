import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../components/common/Label';
import Text from '../../../components/common/Text';
import { ReactComponent as SuccessIcon } from '../../../icons/kybAccepted.svg';
import { ReactComponent as FailIcon } from '../../../icons/kybDeclined.svg';
import { OrganizationApi } from '../../../services/api/OrganizationApi';
import { CommonHelpers } from '../../../utils/commonHelpers';
import { ProductImagesSlider } from '../../ProductDetails/components/ProductImagesSlider/ProductImagesSlider';
import { ProductOwnerModal } from '../../ProductDetails/components/ProductOwnerModal/ProductOwnerModal';
import { ReactComponent as DefaultImage } from './../../../icons/no-org-logo.svg';
import styles from './ProductSummary.module.scss';

interface ProductSummaryProps {
  category?: string;
  owner: any;
  product: {
    description?: string;
    category_id?: string;
    info: any;
    warranty: any;
    customFields: { name: string; value: string }[];
    images?: { src: string }[];
    status: string;
    title?: string;
    name?: string;
    manufacturer: {
      info: {
        logo: string;
        fullName: string;
      };
      organizationId: string;
    };
  };
  isBusinessUser: boolean;
}

export const ProductSummary: FC<ProductSummaryProps> = ({ product, owner }) => {
  const { t } = useTranslation();
  const [productOwnerModalOpened, setOpened] = useState(false);
  const [manufacturer, setManufacturer] = useState(null);

  useEffect(() => {
    if (!product.manufacturer.organizationId) return;
    OrganizationApi.getOrganizationInfo(product.manufacturer.organizationId).then((res) => {
      const { status, data } = res;
      if (CommonHelpers.isOkStatus(status)) {
        setManufacturer(data);
      }
    });
  }, []);

  const getKybIcon = () => {
    const isAccepted = manufacturer.approvalStatus === 'accepted';

    return <div className={styles.kybStatus}>{isAccepted ? <SuccessIcon /> : <FailIcon />}</div>;
  };

  const { title, info, description, status, name } = product;

  const images = useMemo(
    () => info?.images?.map((img) => ({ src: process.env.REACT_APP_ATTACHMENTS_DOMAIN + img.location })),
    [product],
  );
  return (
    <div className={styles.product}>
      <div className={styles.product__info}>
        <Text type="h3" className={styles.product__info}>
          {name || title}
        </Text>

        <h2 className={styles['product__info_sub-title']}>
          {manufacturer?.info &&
            (manufacturer?.info?.images[0] ? (
              <img src={process.env.REACT_APP_ATTACHMENTS_DOMAIN + manufacturer?.info?.images[0].location} alt="" />
            ) : (
              <DefaultImage />
            ))}
          {manufacturer?.name}
          {manufacturer && getKybIcon()}
        </h2>

        <div className={styles.product__images}>
          <div className={styles['label']}>
            <Label status={status} />
          </div>
          {images?.length > 0 ? (
            <ProductImagesSlider images={images} imgFit="contain" />
          ) : (
            <div>{t('_NoImages_')} </div>
          )}
        </div>

        <div className={styles['product__info_required-fields']}>
          <div className={styles['required-field']}>
            <div className={styles.row__title}>{t('_Description_')}</div>
            <div className={cn(styles['required-field__value'], styles.description)}>{description || '-'}</div>
          </div>

          {owner?.info?.fullName && (
            <div className={styles['required-field']}>
              <div className={styles['required-field__key']}>{t('_Owner_')}</div>
              <div className={cn(styles['required-field__value'], styles.owner)} onClick={() => setOpened(true)}>
                {owner?.info?.fullName}
              </div>
              <ProductOwnerModal open={productOwnerModalOpened} onClose={() => setOpened(false)} owner={owner} />
            </div>
          )}

          {info?.fields?.length ? (
            <div className={styles['product__info_custom-fields']}>
              <div className={cn(styles.row__title, styles.costum_row__title)}>{t('_ItemSpecifics_')}</div>
              {info?.fields
                .sort((a, b) => a.sort > b.sort)
                .map((field, index) => {
                  return (
                    <div
                      className={cn(styles['custom-field'], styles[`custom-field-${field.type}`])}
                      key={field.name + index}
                    >
                      <Text className={cn(styles['custom-field__key'])}>{field.name}</Text>
                      <Text className={cn(styles['custom-field__value'])}>{field.value}</Text>
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};
