import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button/Button';
import styles from './CategoryForm.module.scss';

interface CategoryFormProps {
  onSubmit: (values: any, isEditMode: boolean) => void;
  loading?: boolean;
  selected?: any;
}

export const CategoryForm: FC<CategoryFormProps> = ({ onSubmit, loading = false, selected }) => {
  const { t } = useTranslation();
  const [form, setForm] = useState({
    name: '',
    description: '',
  });

  const [nameIsInvalid, setNameIsInvalid] = useState(false);

  const isEditMode = useMemo(() => !!selected, [selected]);

  useEffect(() => {
    if (isEditMode) {
      setForm({ ...form, name: selected.name });
    }
  }, [isEditMode]);

  const onChange = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setForm({ ...form, [e.currentTarget.name]: e.currentTarget.value });
  };

  const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (!form.name.trim() || form.name.trim().length < 2) {
      setNameIsInvalid(true);
      return;
    }

    setNameIsInvalid(false);

    onSubmit(form, isEditMode);
  };

  return (
    <form className={styles['category-form']}>
      <h1>{t(isEditMode ? '_EditCategoryCapitalize_' : '_AddCategory_')}</h1>

      <div className={styles.fields}>
        <div className={styles.field}>
          <span>
            {t('_CategoryName_')} <span className={styles.required}>*</span>
          </span>
          <input
            className={cn({
              [styles['required-input']]: nameIsInvalid && form.name.length < 2,
            })}
            name="name"
            type="text"
            placeholder={t('_CategoryName_')}
            onChange={onChange}
            value={form.name}
          />

          <span className={styles.error}>
            {nameIsInvalid && form.name.length < 2 && t('_ThisFieldRequiredAndGreaterThan5_')}
          </span>
        </div>

        <div className={styles.field}>
          <span>{t('_Description_')}</span>
          <textarea name="description" placeholder={t('_Description_')} onChange={onChange} />
        </div>

        <Button
          isSmall
          isPrimary
          text={t(isEditMode ? '_EditCategoryCapitalize_' : '_AddCategory_')}
          onClick={handleSubmit}
          isDisabled={loading}
          isLoading={loading}
        />
      </div>
    </form>
  );
};
