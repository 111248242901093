import React from 'react';
import { toast } from 'react-toastify';
import ToastErrorIcon from '../icons/toast-error.png';
import ToastInfoIcon from '../icons/toast-info.png';
import ToastSuccessIcon from '../icons/toast-success.png';

export class Toast {
  static success = (message) => {
    const isString = typeof message === 'string';
    const iconData = { icon: <img width="24" height="24" src={ToastSuccessIcon} alt="success" /> };
    return toast.success(isString ? message : { ...message, iconData }, isString && iconData);
  };

  static error = (message) => {
    const isString = typeof message === 'string';
    const iconData = { icon: <img width="24" height="24" src={ToastErrorIcon} alt="error" /> };
    return toast.error(isString ? message : { ...message, iconData }, isString && iconData);
  };

  static info = (message) => {
    const isString = typeof message === 'string';
    const iconData = { icon: <img width="24" height="24" src={ToastInfoIcon} alt="info" /> };
    return toast.info(isString ? message : { ...message, iconData }, isString && iconData);
  };
}
