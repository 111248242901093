import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ReactComponent as DownloadIcon } from '../../../../icons/download-file-gray.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/trash.svg';
import { ProductBulk } from '../../../../modules/product/types';
import { ProductApi } from '../../../../services/api/ProductApi';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { APP_URL } from '../../../../utils/constants';
import { Toast } from '../../../../utils/toastHelper';
import exportZipArchive from '../../helpers/exportZipArchive';
import { ProductCombined } from '../../interfaces/productCombined.interface';

interface BulkActionsProps {
  bulk: ProductBulk;
  onEditClicked: (id: string) => void;
  onDeleteClicked: (id: string) => void;
  isEditable?: boolean;
}

export const BulkActions: FC<BulkActionsProps> = ({ bulk, onDeleteClicked, onEditClicked, isEditable }) => {
  const { t } = useTranslation();
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const exportHandle = async () => {
    try {
      const productsFromDB = await ProductsSecureStorage.getProductByBulkId(bulk._id);
      const productsKeys = {};
      productsFromDB.forEach((item) => (productsKeys[item.publicKey] = item));
      const _productsPublic = await ProductApi.getProductByBulkId(bulk._id);
      const combinedArray: ProductCombined.Combined[] = _productsPublic.data.map((product: ProductCombined.Public) => {
        return {
          productLink: `${process.env.REACT_APP_URL}/p/${product.alias}`,
          name: bulk.name,
          category: bulk.category ? bulk.category.name : '',
          publicQR: `${APP_URL}/p/${product.alias}`,
          privateQR: productsKeys[product._id]?.privateKey,
          publicQRFile: `${product.alias}_public.svg`,
          privateQRFile: `${product.alias}_private.svg`,
        };
      });
      await exportZipArchive(combinedArray);
    } catch (error) {
      console.error(error);
      Toast.error(t('_ExportProductsError_'));
    }
  };

  return (
    <div className="actions-col">
      {!isEmployee && isEditable && (
        <>
          <IconButton className="icon-button" onClick={exportHandle}>
            <DownloadIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onEditClicked(bulk._id)}>
            <EditIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onDeleteClicked(bulk._id)}>
            <TrashIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};
