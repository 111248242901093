import { createReducer } from 'deox';
import produce from 'immer';
import { setBillingInvoices, setCurrentSubscription, setPaymentInfo, setSubscriptions } from './actions';
import { SubscriptionsState } from './types';

const initialState: SubscriptionsState = {
  subscriptions: {},
  billingInvoices: {},
  currentPlan: {},
  paymentInfo: {},
};

export const paymentsReducer = createReducer(initialState, (handle) => [
  handle(setSubscriptions.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.subscriptions = payload;
    }),
  ),
  handle(setBillingInvoices.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.billingInvoices = payload;
    }),
  ),
  handle(setCurrentSubscription.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.currentPlan = payload;
    }),
  ),
  handle(setPaymentInfo.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.paymentInfo = payload;
    }),
  ),
]);
