import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../../components/Button/Button';
import { Input } from '../../../components/Input/Input';
import { ReactComponent as CopyIcon } from '../../../icons/copy.svg';
import { setApiKeys } from '../../../modules/apiDoc/actions';
import { Toast } from '../../../utils/toastHelper';
import GenerateModal from './GenerateModal';
import './styles.scss';

const ApiKeys = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { apiKeys } = useSelector((state: any) => state.apiDoc);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBusyRequest, setIsBusyRequest] = useState<boolean>(false);

  const copyToClipboard = useCallback((text) => {
    text?.length &&
      navigator.clipboard.writeText(text as string).then(() => {
        Toast.info(t('_CopiedToClipboard_'));
      });
  }, []);

  const onFinish = () => {
    setIsBusyRequest(false);
    setIsOpen(false);
  };

  const onSubmit = () => {
    setIsBusyRequest(true);
    dispatch(setApiKeys.request({ onFinish }));
  };

  return (
    <div className="ApiKeys flexible jCenter">
      <div className="form-content flexible vertical aCenter">
        <div className="input-block">
          <Input type="text" value={apiKeys?.live_key} label={t('_LiveAPIKey_')} isDisabled />
          <CopyIcon className="copy-icon" onClick={() => copyToClipboard(apiKeys?.live_key)} />
        </div>
        <div className="input-block">
          <Input type="text" value={apiKeys?.sandbox_key} label={t('_SandboxAPIKey_')} isDisabled />
          <CopyIcon className="copy-icon" onClick={() => copyToClipboard(apiKeys?.sandbox_key)} />
        </div>
        <Button className="generate-button" onClick={() => setIsOpen(true)} isPrimary>
          {t('_Regenerate_')}
        </Button>
      </div>
      {isOpen && <GenerateModal onClose={() => setIsOpen(false)} onSubmit={onSubmit} isBusyRequest={isBusyRequest} />}
    </div>
  );
};

export default ApiKeys;
