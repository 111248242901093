import { getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'typed-redux-saga';
import { ApiDoc } from '../../services/api/ApiDoc';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { setApiKeys, setChangeLogs, setDocumentation } from './actions';

function* changeLogsSaga(params): SagaIterator {
  const { onFinish, filterParams } = params.payload;
  try {
    const data = yield* call(ApiDoc.getChangeLogs, filterParams ? CommonHelpers.getFilterParams(filterParams) : '');
    yield* put(setChangeLogs.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setChangeLogs.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* documentationSaga(params): SagaIterator {
  const { onFinish } = params.payload;
  try {
    const data = yield* call(ApiDoc.getDocumentation);
    yield* put(setDocumentation.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setDocumentation.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* apiKeySaga(params): SagaIterator {
  const { onFinish } = params.payload;
  try {
    const data = yield* call(ApiDoc.generateApiKey);
    yield* put(setApiKeys.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setApiKeys.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

export function* watchApiDocs(): SagaIterator {
  yield* takeLatest(getType(setChangeLogs.request), changeLogsSaga);
  yield* takeLatest(getType(setDocumentation.request), documentationSaga);
  yield* takeLatest(getType(setApiKeys.request), apiKeySaga);
}
