import { useTranslation } from 'react-i18next';
import BusyLoader from '../../../components/BusyLoader';
import { Button } from '../../../components/Button/Button';
import { Modal } from '../../../components/Modal/Modal';

const GenerateModal = ({ onSubmit, isBusyRequest, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={onClose}>
      <BusyLoader isBusy={isBusyRequest} className="GenerateModal flexible vertical aCenter">
        <div className="modal-description">{t('_AreYouWantRegenerateAPIKeys_')}</div>
        <div className="flexible aCenter jCenter buttons-content">
          <Button onClick={onSubmit} isPrimary>
            {t('_Yes_')}
          </Button>
          <Button isPrimaryOutline onClick={onClose}>
            {t('_Cancel_')}
          </Button>
        </div>
      </BusyLoader>
    </Modal>
  );
};

export default GenerateModal;
