/* eslint-disable @typescript-eslint/no-unused-vars */
import { Badge } from '@mui/material';
import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ReactComponent as NotificationIcon } from '../../icons/notifications-icon.svg';
import { UserApi } from '../../services/api/UserApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import NotificationDialog from '../NotificationDialog';
import styles from './Header.module.scss';

export interface HeaderLocationsProps {
  [key: string]: string;
}

export const HeaderLocations: HeaderLocationsProps = {
  '/products': 'Products',
};

export interface HeaderProps {
  pageTitle: string;
  className?: string;
}

export const Header: FC<HeaderProps> = ({ className }) => {
  // const _notifications = [
  //   {
  //   createdAt: "2021-11-26T06:48:26.550Z",
  //   updatedAt: "2021-11-26T06:48:26.550Z",
  //   info: '',
  //   message: "Product Veritise was successfully claimed on blockchain by James",
  //   notificationId:"8743b52063cd84097a65d1633f5c74f5",
  //   status: "sent",
  //   subject: "Claim ownership of the Product",
  //   ttl: 1638843656,
  //   type: "proposal-ownership",
  //   userId: "f648342df943",
  // },
  // {
  //   createdAt: "2021-11-26T06:48:26.550Z",
  //   updatedAt: "2021-11-26T06:48:26.550Z",
  //   info: '',
  //   message: "Product Veritise was successfully claimed on blockchain by James",
  //   notificationId:"8743b52063cd84097a65d1633f5c74f15",
  //   status: "sent",
  //   subject: "Claim ownership of the Product",
  //   ttl: 1638843656,
  //   type: "proposal-ownership",
  //   userId: "f648342df943",
  // },
  // {
  //   createdAt: "2021-11-26T06:48:26.550Z",
  //   updatedAt: "2021-11-26T06:48:26.550Z",
  //   info: '',
  //   message: "Product Veritise was successfully claimed on blockchain by James",
  //   notificationId:"8743b52063cd84097a65d12633f5c74f5",
  //   status: "viewed",
  //   subject: "Claim ownership of the Product",
  //   ttl: 1638843656,
  //   type: "proposal-ownership",
  //   userId: "f648342df943",
  // }
  // ]

  const [notificationsIsOpen, setNotificationIsOpen] = useState<boolean>(false);
  const [notifications, setNotifications] = useState({
    data: [],
    total: 0,
  });
  const loginData = useSelector((state: any) => state.auth.attributes);
  const { userData } = useSelector((state: any) => state.user);
  const { pathname } = useLocation();

  useEffect(() => {
    UserApi.getUserNotifications().then((res) => {
      if (CommonHelpers.isOkStatus(res.status)) {
        setNotifications({
          data: res.data.data,
          total: res.data.total,
        });
      }
    });
  }, [pathname]);

  const showNotifCount = useMemo(() => {
    return notifications.data.reduce((acc, cur) => (cur.status !== 'viewed' ? acc + 1 : acc), 0);
  }, [notifications]);

  // const fakeNotifications = {data: [{
  //   notificationId:1,
  //   userId:1,
  //   subject: '',
  //   message: 'notif 1',
  //   ttl:11112,
  //   updatedAt: new Date().toISOString(),
  //   createdAt: new Date().toISOString(),
  //   status: 'viewed'
  // }, {
  //   notificationId:2,
  //   userId:2,
  //   subject: '',
  //   message: 'notif 1',
  //   ttl:11112,
  //   updatedAt: new Date().toISOString(),
  //   createdAt: new Date().toISOString(),
  // }, {
  //   notificationId:3,
  //   userId:3,
  //   subject: '',
  //   message: 'notif 1',
  //   ttl:11112,
  //   updatedAt: new Date().toISOString(),
  //   createdAt: new Date().toISOString(),
  // }]}
  return (
    <div className={cn(styles.header, className)}>
      <div className={styles.header__user}>
        <div className={styles['header__user-name']}>
          {`${userData?.info?.firstName} ${userData?.info?.middleName} ${userData?.info?.lastName}`
            .replace('  ', ' ')
            .trim() || loginData?.name}
        </div>
        <div className={styles['header__user-notifications']}>
          <Badge badgeContent={showNotifCount} classes={{ badge: styles.badge }}>
            <NotificationIcon onClick={() => setNotificationIsOpen(true)} />
          </Badge>
          {notificationsIsOpen && (
            <div className={styles.notification__overlay} onClick={() => setNotificationIsOpen(false)} />
          )}
          {notificationsIsOpen && (
            <NotificationDialog notifications={notifications} setNotifications={setNotifications} />
          )}
        </div>
      </div>
    </div>
  );
};
