import { AxiosError } from 'axios';
import { createAction } from 'deox';
import { ResponseError } from '../../modules/errors';
import { CommonState } from './types';

export const commonAction = {
  request: createAction(
    'user/LOGIN_REQUEST',
    (res) =>
      (payload: {
        requestData: CommonState;
        onSuccessCb?: () => void;
        registerData?: () => void;
        onFailCb?: (e?: AxiosError) => void;
      }) =>
        res(payload),
  ),
  success: createAction('user/LOGIN_SUCCESS', (res) => (data: CommonState) => res(data)),
  fail: createAction('user/LOGIN_FAIL', (res) => (err: ResponseError) => res(err)),
};
export const menuAction = {
  request: createAction('menu/MENU_REQUEST', (res) => (params?: any) => res(params)),
  success: createAction('menu/MENU_SUCCESS', (res) => (params?: any) => res(params)),
};
