import { FC } from 'react';
import './styles.scss';

interface IProps {
  onChange: () => void;
  defaultChecked: boolean;
}

const Checkbox: FC<IProps> = ({ onChange, defaultChecked }) => {
  return <input className="input_checkobox" type="checkbox" defaultChecked={defaultChecked} onChange={onChange} />;
};

export default Checkbox;
