import { Api } from './Api';

export class OrganizationApi {
  static getOrganizations() {
    return Api.get(`/organization`);
  }
  static addOrganization(payload: any) {
    return Api.post(`/organization`, payload);
  }
  static getCategory(categoryId: string) {
    return Api.get(`/category/${categoryId}`);
  }
  static getOrganizationInfo(accountID: string) {
    return Api.get(`/organization/${accountID}`);
  }
  static updateOrganisation(organisationId: string, payload: any) {
    return Api.put(`/organization/${organisationId}`, payload);
  }

  static async inviteUser(params: any, organizationId?: string | number) {
    const { data } = await Api.post(`/organization/${organizationId}/user/invite`, params);
    return data;
  }

  static async getUserByOrganization(organizationId?: string | number, query?: string) {
    const { data } = await Api.get(`/organization/${organizationId}/user${query || ''}`);
    return data;
  }

  static async reInviteUser(params: any, organizationId?: string | number) {
    const { data } = await Api.put(`/organization/${organizationId}/user/invite`, params);
    return data;
  }

  static async assignUser(organizationId?: string | number) {
    const { data } = await Api.put(`/organization/${organizationId}/user/assign`);
    return data;
  }

  static async getUserManagmentList(organizationId?: string | number, filterParams?: any) {
    const { data } = await Api.get(`/organization/${organizationId}/user${filterParams ? filterParams : ''}`);
    return data;
  }

  static async getUserManagmentInfo(organizationId?: string | number, id?: string | number) {
    const { data } = await Api.get(`/organization/${organizationId}/user/${id}`);
    return data;
  }

  static async deleteUserManagmentInfo(organizationId?: string | number, id?: string | number) {
    const { data } = await Api.delete(`/organization/${organizationId}/user/${id}`);
    return data;
  }

  static async getOrganizationCvList(organizationId?: string | number, filterParams?: any) {
    const { data } = await Api.get(`/organization/${organizationId}/user/cv${filterParams ? filterParams : ''}`);
    return data;
  }
}
