import { produce } from 'immer';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/Loader';
import { TabContent, TabMenu } from '../../components/TabMenu/TabMenu';
import { getOrganizations } from '../../modules/organization/actions';
import { OrganizationApi as OrganizationInfoApi } from '../../services/api/OrganizationApi';
import { OrganizationApi } from '../../services/api/_OrganizationApi';
import { Toast } from '../../utils/toastHelper';
import { Categories } from './components/Categories/Categories';
import { CustomFields } from './components/CustomFields/CustomFields';
import NoOrganization from './components/NoOrganization';
import styles from './FieldsConfiguration.module.scss';
import { Category, CustomField } from './types';

export const FieldsConfiguration: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [categories, setCategories] = useState<Category[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [organization, setOrganization] = useState<any>({});
  const [title, setTitle] = useState(t('_Fields_management_'));
  const { organizations, loading } = useSelector((state: any) => state.organization);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const { userData } = useSelector((state: any) => state.user);
  const [existOrganisation, setExistOrganisation] = useState<any>(null);

  useEffect(() => {
    setExistOrganisation(
      organizations?.data[0] || organizations?.data?._id || organization?._id || userData.inviteCode,
    );
  }, [organizations, organization, userData]);

  const fetEmployeeOrganization = useCallback(async () => {
    try {
      const res = await OrganizationInfoApi.getOrganizationInfo(userData.inviteCode);
      setOrganization(res.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (isEmployee) {
      fetEmployeeOrganization();
    }
  }, [isEmployee]);

  const onCategoryDeleted = (categoryId: string) => {
    setCategories(produce(categories, (draft) => draft.filter((category) => category.id !== categoryId)));
  };

  const onCategoryCreated = (category: Category) => {
    setCategories(
      produce(categories, (draft) => {
        draft.unshift(category);
      }),
    );
  };

  const handleCategoryFetch = useCallback(() => {
    setIsLoading(true);
    Promise.all([
      OrganizationApi.getOrganizationCategories(organizations?.data[0]?._id || userData.inviteCode)
        .then(({ data }) => data.data)
        .then((data) =>
          data.map((category: { _id: string; name: string; description: string; isCanBeDeleted?: boolean }) => ({
            id: category._id,
            name: category.name,
            description: category.description,
            isCanBeDeleted: category.isCanBeDeleted,
          })),
        )
        .then(setCategories)
        .catch((e) => {
          Toast.error(JSON.stringify(e));
        }),
      OrganizationApi.getOrganizationFields(organizations?.data[0]?._id || userData.inviteCode)
        .then(({ data }) => data.data)
        .then((data) =>
          data
            .map((field: any) => ({
              id: field._id,
              name: field.name,
              type: field.type,
              sort: field.sort | 0,
            }))
            .sort((a, b) => b.sort - a.sort),
        )
        .then(setCustomFields),
    ]).finally(() => {
      setIsLoading(false);
    });
  }, [organizations, userData.inviteCode]);

  useEffect(() => {
    if (existOrganisation) {
      handleCategoryFetch();
    } else {
      dispatch(getOrganizations.req());
    }
  }, [existOrganisation]);

  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const onCustomFieldDeleted = (customFieldId: string) => {
    setCustomFields(produce(customFields, (draft) => draft.filter((customField) => customField.id !== customFieldId)));
  };

  const onCustomFieldCreated = (dto: any) => {
    setCustomFields(
      produce(customFields, (draft) => {
        draft.unshift(dto);
        return draft;
      }),
    );
  };
  const onCustomFieldUpdated = (customFieldId: string, dto: any) => {
    setCustomFields(
      produce(customFields, (draft) => {
        const index = draft.findIndex((d) => d.id === customFieldId);

        if (index !== -1) {
          draft[index] = { ...draft[index], ...dto };
        }

        return draft;
      }),
    );
  };

  const handleClickTabItem = useCallback((tab) => {
    if (tab === t('_Categories_')) {
      setTitle(t('_Fields_management_'));
    } else if (tab === t('_CustomFields_')) {
      setTitle(t('_Fields_configuration_'));
    }
  }, []);

  return (
    <>
      <h1 className="page__title authenticated-page-title">{title}</h1>

      {loading ? (
        <div className={styles.loader_wrapper}>
          <Loader />
        </div>
      ) : existOrganisation ? (
        <>
          <TabMenu onClick={handleClickTabItem}>
            <TabContent label={t('_Categories_')}>
              {isLoading ? (
                <div className={styles.loader}>
                  <Loader />
                </div>
              ) : (
                <Categories
                  onCategoryDeleted={onCategoryDeleted}
                  onCategoryCreated={onCategoryCreated}
                  handleCategoryFetch={handleCategoryFetch}
                  categories={categories}
                  organizationId={organizations?.data[0]?._id}
                />
              )}
            </TabContent>
            <TabContent label={t('_CustomFields_')}>
              <CustomFields
                onCustomFieldUpdated={onCustomFieldUpdated}
                onCustomFieldDeleted={onCustomFieldDeleted}
                onCustomFieldCreated={onCustomFieldCreated}
                customFields={customFields}
                organizationId={organizations?.data[0]?._id}
              />
            </TabContent>
          </TabMenu>
        </>
      ) : (
        !isEmployee && <NoOrganization />
      )}
    </>
  );
};
