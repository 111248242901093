import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../../components/common/Text';
import EmptyScan from '../../components/EmptyScan';

const Scan: FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_Scan_')}
      </Text>
      <EmptyScan />
    </div>
  );
};

export default Scan;
