import { put, select, takeLatest } from 'redux-saga/effects';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { addOrganization, getOrganizations, setDataLoading, updateOrganization } from './actions';

export function* getOrganizationsSaga() {
  try {
    yield put(setDataLoading(true));
    const res: any = yield OrganizationApi.getOrganizations();
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getOrganizations.success(data));
    }
  } catch (e) {
    console.error(e);
    return Toast.error(e);
  } finally {
    yield put(setDataLoading(false));
  }
}
export function* updateOrganizationSaga({ payload }: any) {
  const { id, updatedData, onSuccess, onFail } = payload;
  try {
    yield put(setDataLoading(true));
    const res: any = yield OrganizationApi.updateOrganisation(id, updatedData);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      const state: any = yield select();
      const orgData = state.organization.organizations;
      const newPayload = CommonHelpers.updateInArray(
        orgData.data,
        (o) => o._id === id,
        () => data,
      );
      yield put(getOrganizations.success({ total: orgData.total, data: newPayload }));
      onSuccess();
    }
  } catch (e) {
    onFail && onFail();
    return Toast.error(e);
  } finally {
    yield put(setDataLoading(false));
  }
}

export function* addOrganizationSaga({ payload }: any) {
  try {
    yield put(setDataLoading(true));
    const res: any = yield OrganizationApi.addOrganization(payload.data);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getOrganizations.success({ total: 1, data: [data] }));
      if (payload.onSuccess) {
        payload.onSuccess();
      }
    }
  } catch (e) {
    if (payload.onFail) {
      payload.onFail();
    }
    Toast.error(JSON.stringify(e.message) || 'Something wrong');
  } finally {
    yield put(setDataLoading(false));
  }
}

export function* watchOrganization(): Generator<any> {
  yield takeLatest(getOrganizations.req, getOrganizationsSaga);
  yield takeLatest(updateOrganization.req, updateOrganizationSaga);
  yield takeLatest(addOrganization.req, addOrganizationSaga);
}
