/* eslint-disable */
import sortby from 'lodash.sortby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BusyLoader from '../../components/BusyLoader';
import Text from '../../components/common/Text';
import HiddenBankCardInfo from '../../components/HiddenBankCardInfo';
import SubscriptionCard from '../../components/SubscriptionCard';
import Switcher from '../../components/Switcher';
import Table from '../../components/Table';
import { TabContent, TabMenu } from '../../components/TabMenu/TabMenu';
import currencies from '../../config/currencies';
import {
  createPaymentInfo,
  setBillingInvoices,
  setCurrentSubscription,
  setPaymentInfo,
  setSubscriptions,
} from '../../modules/payment/actions';
import { getUserData } from '../../modules/user/actions';
import { PaymentsApi } from '../../services/api/PaymentsApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import './Billing.scss';
import ChangeSubscriptionModal from './ChangeSubscriptionModal/ChangeSubscriptionModal';
import columns from './columns.json';
import DeleteModal from './DeleteModal';

const color = ['#F6CF2F', '#55db75', '#56d0fd', '#766fe6'];

const Billing = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subscriptions, billingInvoices, currentPlan, paymentInfo } = useSelector((state: any) => state.payments);
  const { userData } = useSelector((state: any) => state.user);
  const { username } = useSelector((state: any) => state.auth);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isChangePlanLoading, setChangePlanLoading] = useState<boolean>(false);
  const [isBusyRequest, setIsBusyRequest] = useState<boolean>(false);
  const [isBusySub, setIsBusySub] = useState<boolean>(false);
  const [isCurrentBusy, setIsCurrentBusy] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [needToClose, setNeedToClose] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');
  const [isOpenDelete, setIsOpenDelete] = useState<boolean>(false);
  const [isOpenChangePlan, setIsOpenChangePlan] = useState<boolean>(false);

  const onFinish = () => {
    setIsBusySub(false);
  };

  const fetchPaymentInfo = useCallback(() => {
    setIsBusySub(true);
    setTimeout(() => {
      dispatch(setPaymentInfo.request({ onFinish }));
    }, 3000);
  }, [dispatch]);
  const fetchCurrentSubscription = useCallback(() => {
    setIsBusySub(true);
    setIsCurrentBusy(true);
    dispatch(
      setCurrentSubscription.request({
        onFinish: () => {
          setTimeout(() => {
            setIsBusySub(false);
            setIsCurrentBusy(false);
          }, 3000);
        },
      }),
    );
  }, [dispatch]);

  const fetchSubscriptions = useCallback(() => {
    setIsBusySub(true);
    dispatch(setSubscriptions.request({ onFinish }));
  }, [dispatch]);

  const fetchBillingInvoices = useCallback(() => {
    setIsBusySub(true);
    dispatch(setBillingInvoices.request({ onFinish }));
  }, [dispatch]);

  const handleUpdate = () => {
    fetchPaymentInfo();
    userData?._id && dispatch(getUserData.req(username));
  };

  useEffect(() => {
    if (currentPlan?.plan) {
      const isActive = currentPlan?.plan?.amount / 100 > 1000;
      setIsActive(isActive);
    }
  }, [currentPlan]);

  useEffect(() => {
    currentPlan?.plan?.priceId && setSelected(currentPlan?.plan?.priceId);
  }, [currentPlan?.plan?.priceId]);

  useEffect(() => {
    fetchBillingInvoices();
    handleUpdate();
    fetchSubscriptions();
    fetchCurrentSubscription();
  }, []);

  const changedData = useMemo(
    () =>
      subscriptions?.data?.map((item) => ({
        ...item,
        metadata: Object.keys(item.metadata).map((el, index) => ({
          isActive: item.title !== 'Free' || index !== 1,
          name: el,
          limit: item.title === 'Free' ? 0 : item?.metadata?.[el],
        })),
        prices: item.prices.filter((item) => item.active),
      })) || [],
    [subscriptions],
  );

  const handleMessage = (e) => {
    const message = CommonHelpers.erorHandlerMessage(e);
    console.error(message);
    setIsBusy(false);
    setIsBusyRequest(false);
    return Toast.error(message);
  };

  const removeSubIdFromUser = () => {
    const user = { ...userData };
    delete user.subscriptionId;
    dispatch(getUserData.success(user));
    dispatch(setCurrentSubscription.success({}));
  };
  // null, null, false
  const onUnSubscribeHandler = async (requestFunction, args, noNeedToast) => {
    !noNeedToast && setIsBusy(true);
    try {
      await PaymentsApi.deleteSubscription();
      removeSubIdFromUser();
      !noNeedToast && handleUpdate();
      !noNeedToast && Toast.success(t('_SuccessfullyUnSubscribed_'));
      setIsBusy(false);
      setIsOpenDelete(false);
    } catch (e) {
      handleMessage(e);
      e?.response?.data?.message === 'No such subscription' && removeSubIdFromUser();
    }
    fetchSubscriptions();
    !!requestFunction && requestFunction(args);
  };

  const onChangeSubscriptionPlanHandler = async () => {
    const planId = selected;
    if (planId) {
      setChangePlanLoading(true);
      try {
        await PaymentsApi.deleteSubscription();
        removeSubIdFromUser();
        await onSubscribeHandler(planId);
        setChangePlanLoading(false);
        setIsOpenChangePlan(false);
      } catch (e) {
        console.error(e);
      }
    } else {
      console.error('Plan not selected');
    }
  };

  const onSubscribeHandler = async (priceId) => {
    setIsBusy(true);
    try {
      const redirectUrl = `${process.env.REACT_APP_URL}billing`;
      const res = await PaymentsApi.createCheckoutSession({
        priceId: priceId || selected,
        successUrl: redirectUrl,
        cancelUrl: redirectUrl,
      });
      handleUpdate();
      if (res?.checkoutUrl) {
        dispatch(setCurrentSubscription.request({ onFinish }));
        window.location.href = res?.checkoutUrl;
      }
    } catch (e) {
      handleMessage(e);
    }
  };

  const handleClick = async (item) => {
    setSelected(item.prices?.[isActive ? 0 : 1]?._id);
    if (currentPlan.status === 'incomplete' || currentPlan.status === 'ended') {
      onUnSubscribeHandler(onSubscribeHandler, item.prices?.[isActive ? 0 : 1]?._id, true);
    } else {
      if (currentPlan?.plan?.priceId === item.prices?.[isActive ? 0 : 1]?._id && currentPlan.status === 'active') {
        setIsOpenDelete(true);
      } else {
        try {
          if (currentPlan?.plan?.productId) {
            setIsOpenChangePlan(true);
          } else {
            onSubscribeHandler(item.prices?.[isActive ? 0 : 1]?._id);
          }
        } catch (e) {
          handleMessage(e);
        }
      }
    }
  };

  const onSuccessPaymentInfo = () => {
    setIsBusyRequest(false);
    setNeedToClose(true);
  };

  const onDeletePaymentInfo = async (id: string) => {
    setIsBusyRequest(true);
    try {
      await PaymentsApi.deletePaymentInfo(id);
      dispatch(setPaymentInfo.request({ onSuccess: onSuccessPaymentInfo }));
      Toast.success(t('_PaymentMethodDeleted_'));
    } catch (e) {
      handleMessage(e);
    }
  };

  const onSuccess = () => {
    dispatch(setPaymentInfo.request({ onSuccess: onSuccessPaymentInfo }));
    Toast.success(t(!paymentInfo?.data?.length ? '_PaymentMethodCreated_' : '_PaymentMethodUpdated_'));
  };

  const onError = () => {
    setIsBusyRequest(false);
  };

  const onAddCardPaymentInfo = async (card, isEdit) => {
    setIsBusyRequest(true);
    if (!isEdit) {
      dispatch(createPaymentInfo({ data: { type: 'card', card }, onSuccess, onError }));
    } else {
      try {
        const res = await PaymentsApi.updatePaymentInfo({ month: card.month, year: card.year });
        if (res) {
          onSuccess();
        }
      } catch (e) {
        const message = CommonHelpers.erorHandlerMessage(e);
        console.error(message);
        onError();
      }
    }
  };

  const sortedData = sortby(changedData, (item) => item?.prices?.[0]?.amount);
  const getSubscriptionPlanPrice = (item) => {
    const newPrices = item?.prices.filter((price) => price.active);
    return `${currencies[newPrices[isActive ? 0 : 1]?.currency?.toLocaleUpperCase()]?.sign}${
      newPrices?.[isActive ? 0 : 1]?.amount / 100
    }`;
  };

  return (
    <div className="page-header Billing">
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_Billing_')}
      </Text>

      <TabMenu tabsContentClass={'product-description'}>
        <TabContent label="Subscription Plan">
          {!isBusySub && (
            <div className="switcher-block">
              <span style={{ ...(!isActive && { color: '#0072FD' }) }} className="month-yearly-text">
                {t('_MONTHLY_')}
              </span>
              <Switcher isActive={isActive} onChange={setIsActive} />
              <div className="yearly-block">
                <span className="month-yearly-text" style={{ ...(isActive && { color: '#0072FD' }) }}>
                  {t('_YEARLY_')}
                </span>{' '}
                <span className="yearly-info">{t('_SaveONBillsAnnually_')}</span>
              </div>
            </div>
          )}
          <BusyLoader className="subscription-items" isBusy={isBusySub || isCurrentBusy}>
            {sortedData?.map((item, index) => {
              return (
                <SubscriptionCard
                  key={item._id}
                  isBusy={isBusy && selected === item.prices?.[isActive ? 0 : 1]?._id}
                  disabled={isBusy && selected !== item.prices?.[isActive ? 0 : 1]?._id}
                  title={item.title}
                  color={color[index]}
                  subtitle={item.description}
                  active={
                    (currentPlan?.plan?.priceId === item.prices?.[isActive ? 0 : 1]?._id &&
                      currentPlan.status === 'active') ||
                    (currentPlan.status !== 'active' && item.title === 'Free')
                  }
                  unit={!isActive ? 'month' : 'year'}
                  // price={`${currencies[item?.prices?.[isActive ? 0 : 1]?.currency?.toLocaleUpperCase()]?.sign}${
                  //   item?.prices?.[isActive ? 0 : 1]?.amount / 100
                  // }`}
                  price={getSubscriptionPlanPrice(item)}
                  planes={item.metadata}
                  onClick={() => handleClick(item)}
                />
              );
            })}
          </BusyLoader>
        </TabContent>
        <TabContent label="Payment Information">
          {!!billingInvoices?.data?.length && !isBusySub && (
            <Table
              data={
                billingInvoices?.data?.filter((item) => !!item.total && !!item.charge && item.status === 'paid') || []
              }
              columns={columns}
              needEmpty={false}
            />
          )}

          {!isBusySub && (
            <div className="change-payment-method">
              <Text type="h3" className="">
                {t('_PaymentInformation_')}
              </Text>
              <HiddenBankCardInfo
                needToClose={needToClose}
                isBusyRequest={isBusyRequest}
                onDelete={onDeletePaymentInfo}
                onAddCard={onAddCardPaymentInfo}
                setNeedToClose={setNeedToClose}
                cardNumber={paymentInfo?.data?.[0]?.card?.last4}
                className="hiiden-card-info flexible vertical"
                data={paymentInfo?.data?.[0]}
              />
            </div>
          )}
        </TabContent>
      </TabMenu>

      {isOpenDelete && (
        <DeleteModal
          isBusyRequest={isBusy}
          onDelete={() => onUnSubscribeHandler(null, null, false)}
          onClose={() => {
            setIsOpenDelete(false);
            setSelected(null);
          }}
        />
      )}

      {isOpenChangePlan && (
        <ChangeSubscriptionModal
          isBusyRequest={isChangePlanLoading}
          onAgree={onChangeSubscriptionPlanHandler}
          onClose={() => {
            setIsOpenChangePlan(false);
            setSelected(null);
          }}
        />
      )}
    </div>
  );
};

export default Billing;
