/* eslint-disable @typescript-eslint/no-explicit-any */

import _ from 'lodash';
import { ProductDB, productsDBService } from '../../indexedDB';
import type { ProductsModel } from '../models/ProductsModel';
import { BaseSecureStorage } from './BaseSecureStorage';

export class ProductsSecureStorage extends BaseSecureStorage {
  /** ACCOUNTS DB KEY **/
  static PRODUCTS_KEY = 'PRODUCTS';

  // /**
  //  * Creates a new account
  //  * @returns {Promise<ProductsModel>}
  //  * @param product
  //  */
  // static async createNewProduct(product: ProductsModel): Promise<ProductsModel> {
  //   const products = await this.getAllProducts();
  //   return this.saveProducts({ ...products, ...product });
  // }
  static async createNewProduct(product: ProductDB.Product): Promise<number> {
    return productsDBService.add(product);
  }

  /**
   * Creates a new account
   * @returns {Promise<any>}
   * @param products
   */
  static async saveProducts(products: ProductsModel): Promise<any> {
    return this.secureSaveAsync(this.PRODUCTS_KEY, JSON.stringify(products));
  }

  /**
   * Removes an account by its id and network
   * @param id
   * @param network
   * @returns {Promise<*>}
   */
  static async removeProduct(publicKey: string): Promise<ProductsModel[]> {
    const allProducts = await this.getAllProducts();
    const filteredProducts = _.flow([
      Object.entries,
      (arr) => arr.filter(([, value]: string[]) => publicKey !== value),
      Object.fromEntries,
    ])(allProducts);
    // const filteredProducts = Object.keys(allProducts).filter(key => publicKey !== key);
    await this.saveProducts(filteredProducts);
    return filteredProducts;
  }
  static async removeCv(): Promise<ProductsModel[]> {
    const allProducts = await this.getAllProducts();
    const filteredProducts = _.flow([
      Object.entries,
      (arr) => arr.filter(([key]: string[]) => key !== 'cv'),
      Object.fromEntries,
    ])(allProducts);
    // const filteredProducts = Object.keys(allProducts).filter(key => publicKey !== key);
    await this.saveProducts(filteredProducts);
    return filteredProducts;
  }

  // static async getAllProducts(): Promise<ProductsModel> {
  //   // Not decrypted
  //   try {
  //     const productsString = await this.secureRetrieveAsync(this.PRODUCTS_KEY);
  //     if (productsString) {
  //       // @ts-ignore
  //       return JSON.parse(productsString);
  //     }
  //     return {};
  //   } catch (e) {
  //     return {};
  //   }
  // }
  static async getAllProducts(): Promise<ProductDB.Product[]> {
    return productsDBService.getAll();
  }

  static async getProductByBulkId(bulkId: string): Promise<ProductDB.Product[]> {
    return productsDBService.getByBulkId(bulkId);
  }

  static async getProduct(publicKey: string): Promise<ProductDB.Product | undefined> {
    if (publicKey) {
      return productsDBService.get(publicKey);
    } else {
      Promise.resolve();
    }
  }

  /**
   * Clear all keys
   * @returns {Promise<string | null>}
   */
  static clear() {
    return this.removeKey(this.PRODUCTS_KEY);
  }
}
