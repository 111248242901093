import { parseISO } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '../../components/Button/Button';
import Text from '../../components/common/Text';
import { DatePickerInput } from '../../components/DatePickerInput/DatePickerInput';
import { Input } from '../../components/Input/Input';
import Loader from '../../components/Loader';
import MenuBtn from '../../components/MenuBtn/MenuBtn';
import NoItem from '../../components/NoItem';
import Pagination from '../../components/Pagination/Pagination';
import { TabContent, TabMenu } from '../../components/TabMenu/TabMenu';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { useRedirect } from '../../hooks/useRedirect';
import {
  getProductBulksForCompany,
  getProductsForCompany,
  getProductsForUser,
  getProductTemplatesForCompany,
  getWarrianties,
  removeProductForCompany,
  removeTemplate,
} from '../../modules/product/actions';
import { Product, ProductBulk, ProductTemplate } from '../../modules/product/types';
import store, { GlobalListener } from '../../redux/store';
import { ProductApi } from '../../services/api/ProductApi';
import AccountService from '../../services/symbol/AccountService';
import ListenerService from '../../services/symbol/ListenerService';
import NetworkService from '../../services/symbol/NetworkService';
import TransactionService from '../../services/symbol/TransactionService';
import { DeleteEntityModal } from '../../shared/components';
import { MosaicModel } from '../../storage/models/MosaicModel';
import { TransactionModel } from '../../storage/models/TransactionModel';
import { ProductsSecureStorage } from '../../storage/persistence/ProductsSecureStorage';
import { APP_URL } from '../../utils/constants';
import { Toast } from '../../utils/toastHelper';
import BusinessProductRow from './components/BusinessProductRow';
import ExportProductKeysModal from './components/ExportProductKeysModal';
import FreeAccountModal from './components/FreeAccountModal';
import ImportProductKeysModal from './components/ImportProductKeysModal';
import IndividualProductRow from './components/IndividualProductRow';
import ProductBulkRow from './components/ProductBulkRow';
import ProductTemplateRow from './components/ProductTemplateRow';
import { generateCategoryOptions, getPageSize, statusOptions } from './constants';
import exportZipArchive from './helpers/exportZipArchive';
import { ProductCombined } from './interfaces/productCombined.interface';
import './Products.scss';

enum MenuActions {
  EXPORT_PRODUCTS_KEYS = 'Export Product Keys',
  IMPORT_PRODUCTS_KEYS = 'Import Product Keys',
  EXPORT_QR_CODES = 'Export QR Codes',
}

const Products: FC = memo(() => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [disableMenuButton, setDisableMenuButton] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>('');
  const userType = useDetectUserType();
  const redirectToProductCreate = useRedirect('/products/create');
  const redirectToProductEdit = (id) => history.push(`/products/edit/${id}`);
  const redirectToBulkEdit = (id) => history.push(`/bulk/edit/${id}`);
  const redirectToTemplateEdit = (id) => history.push(`/template/edit/${id}`);
  //pagination state
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentPageOfWarriaty, setCurrentPageOfWarriaty] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState({
    statusFilter: null,
    categoryFilter: null,
    searchFilter: '',
    dateRangeFilter: {
      start: null,
      end: null,
    },
  });

  // auth selectors from redux
  const { username: accountID } = useSelector((state: any) => state.auth);
  const isBusinessUser = userType === 'company';
  const products = useSelector((state: any) => state.products?.products?.data);
  const totalProducts = useSelector((state: any) => state.products?.products?.total);
  const productsLoading = useSelector((state: any) => state.products.productsLoading);
  const bulks = useSelector((state: any) => state.products?.bulks?.data);
  const totalBulks = useSelector((state: any) => state.products?.bulks?.total);
  const bulksLoading = useSelector((state: any) => state.products.bulksLoading);
  const templates = useSelector((state: any) => state.products?.templates?.data);
  const totalTemplates = useSelector((state: any) => state.products?.templates?.total);
  const templatesLoading = useSelector((state: any) => state.products.templatesLoading);
  const warranties = useSelector((state: any) => state.products?.userWarranties?.warranties);
  const { username: manufacturerId } = useSelector((state: any) => state.auth);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const isKycAccepted = useSelector((state: any) => state.user?.userData?.kyc_status === 'accepted');
  const isVerificationNeeded = useSelector((state: any) => state.user?.userData?.isVerificationNeeded);
  const inviteCode = useSelector((state: any) => state.user?.userData?.inviteCode);
  const approvalStatus = useSelector(
    (state: any) =>
      state.organization?.organizations?.data?.[0]?.approvalStatus ||
      state.organization?.organizations?.data?.approvalStatus,
  );

  const [range, setRange] = useState([0, getPageSize(isBusinessUser)]);
  const [isExportKeysModalOpen, setIsExportKeysModalOpen] = useState(false);
  const [isImportKeysModalOpen, setIsImportKeysModalOpen] = useState(false);
  const [productsKeys, setProductsKeys] = useState({});
  const [activeTab, setActiveTab] = useState('Products');
  const [isFreeAccountModalOpen, setIsFreeAccountModalOpen] = useState(false);

  useEffect(() => {
    if (isBusinessUser && !isVerificationNeeded && !localStorage.getItem('freeAccountModalShown')) {
      setIsFreeAccountModalOpen(true);
      localStorage.setItem('freeAccountModalShown', 'true');
    }
  }, []);

  const handleCreateClick = () => {
    if (isVerificationNeeded && !isKycAccepted) {
      Toast.error(t('_Verify_account_first_'));
      return;
    }
    if (isVerificationNeeded && approvalStatus !== 'accepted') {
      Toast.error(t('_Verify_organization_first_'));
      return;
    }
    redirectToProductCreate();
  };

  const handleSelect = (action: MenuActions) => {
    switch (action) {
      case MenuActions.EXPORT_PRODUCTS_KEYS:
        setIsExportKeysModalOpen(true);
        break;
      case MenuActions.IMPORT_PRODUCTS_KEYS:
        setIsImportKeysModalOpen(true);
        break;
      case MenuActions.EXPORT_QR_CODES:
        exportQRsHandle('company', accountID, filters);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const _productsKeys = async () => {
      const products = await ProductsSecureStorage.getAllProducts();
      const productsKeys = {};
      products.forEach((item) => (productsKeys[item.publicKey] = item));
      setProductsKeys(productsKeys);
    };
    _productsKeys();
  }, []);

  useEffect(() => {
    if (isBusinessUser) {
      setIsFirstLoad(false);
    }
    return () => {
      dispatch(getProductsForCompany.reset());
      dispatch(getProductBulksForCompany.reset());
      dispatch(getProductTemplatesForCompany.reset());
    };
  }, [isBusinessUser]);

  useEffect(() => {
    setLoading(true);
    if (!isBusinessUser) {
      dispatch(getProductsForUser.req({ range }));
    }
  }, [accountID, range]);
  const { postTransactionData } = useSelector((state: any) => state.common);
  const { ownedMosaics } = useSelector((state: any) => state.account);
  const [checksum, setChecksum] = useState('');
  useEffect(() => {
    if (ownedMosaics.length && postTransactionData && (checksum !== postTransactionData.checksum || !checksum)) {
      TransactionService.makeTransaction(postTransactionData.account, postTransactionData.checksum, ownedMosaics).then(
        async ({ transactionId }) => {
          setChecksum(postTransactionData.checksum);
          await ProductApi.updateProduct(postTransactionData.account.id, { transactionId });
        },
      );
    }
  }, [postTransactionData, ownedMosaics]);

  useEffect(() => {
    if (isBusinessUser) {
      if (
        (filters.dateRangeFilter.start && filters.dateRangeFilter.end) ||
        (!filters.dateRangeFilter.start && !filters.dateRangeFilter.end)
      ) {
        if (activeTab === t('_Products_')) {
          dispatch(
            getProductsForCompany.req({
              type: 'company',
              accountID,
              filters,
              inviteCode: isEmployee ? inviteCode : '',
              range,
            }),
          );
        }
        if (activeTab === t('_Product_bulks_')) {
          dispatch(
            getProductBulksForCompany.req({
              accountID,
              filters,
              inviteCode: isEmployee ? inviteCode : '',
              range,
            }),
          );
        }
        if (activeTab === t('_Product_templates_')) {
          dispatch(
            getProductTemplatesForCompany.req({
              accountID,
              filters,
              inviteCode: isEmployee ? inviteCode : '',
              range,
            }),
          );
        }
      }
    }
  }, [filters, isBusinessUser, range, accountID]);

  useEffect(() => {
    if (products && loading) {
      setLoading(false);
    }

    if (isBusinessUser) {
      const _categories = generateCategoryOptions(products);
      setCategoryOptions(_categories);
      dispatch(
        getWarrianties.req({ params: { manufacturerId: isEmployee ? inviteCode : manufacturerId, isClone: false } }),
      );
    } else {
      dispatch(getWarrianties.req({ params: { manufacturerId: isEmployee ? inviteCode : manufacturerId } }));
    }
    return () => {
      getWarrianties.reset();
    };
  }, [products]);

  const setNewRange = (page) => {
    const firstPageIndex = page === 0 ? 0 : page * getPageSize(isBusinessUser);
    setRange([firstPageIndex, getPageSize(isBusinessUser)]);
    setCurrentPage(page + 1);
  };

  const exportQRsHandle = async (
    type: string,
    accountID: string,
    filters: {
      statusFilter: any;
      categoryFilter: any;
      searchFilter: string;
      dateRangeFilter: {
        start: any;
        end: any;
      };
    },
  ) => {
    setDisableMenuButton(true);
    try {
      const products = await ProductsSecureStorage.getAllProducts();
      const productsKeys = {};
      products.forEach((item) => (productsKeys[item.publicKey] = item));

      const _productsPublic = await ProductApi.getOwnedProducts(type, accountID, filters, [0, 100000]); //@TODO: Change endpoint, get all values
      const combinedArray: ProductCombined.Combined[] = _productsPublic.data.data.map(
        (product: ProductCombined.Public) => {
          return {
            productLink: `${process.env.REACT_APP_URL}${product.publicQrCode.link}`,
            name: product.name,
            category: product.category ? product.category.name : '',
            publicQR: `${APP_URL}/${product.publicQrCode.link}`,
            privateQR: productsKeys[product._id]?.privateKey,
            publicQRFile: `${product.alias}_public.svg`,
            privateQRFile: `${product.alias}_private.svg`,
          };
        },
      );

      await exportZipArchive(combinedArray);
      setDisableMenuButton(false);
    } catch (error) {
      setDisableMenuButton(false);
      console.error(error);
      Toast.error(t('_ExportProductsError_'));
    }
  };

  const currentDataOfWarrianties = useMemo(() => {
    const firstPageIndex = (currentPageOfWarriaty - 1) * getPageSize(isBusinessUser);
    const lastPageIndex = firstPageIndex + getPageSize(isBusinessUser);
    const currentArr = warranties?.slice(firstPageIndex, lastPageIndex) || [];

    const sortedArr = currentArr.sort((a, b) => {
      return moment(b.createdAt).unix() - moment(a.createdAt).unix();
    });
    return sortedArr;
  }, [warranties, currentPageOfWarriaty]);

  const handleSearchInput = useCallback(
    (e) => {
      const searchFilter = e.target.value;
      setSearchInput(searchFilter);

      if (searchFilter.length > 3) {
        setTimeout(() => {
          setFilters({
            ...filters,
            searchFilter,
          });
        }, 2000);
      } else if (!searchFilter) {
        setFilters({
          ...filters,
          searchFilter,
        });
      }
    },
    [filters],
  );

  const handleSetStatusFilter = useCallback(
    (statusFilter: { value: string; label: string }) => {
      setFilters({
        ...filters,
        statusFilter,
      });
    },
    [filters],
  );
  const handleSetCategoryFilter = useCallback(
    (categoryFilter: { value: string; label: string }) => {
      setFilters({
        ...filters,
        categoryFilter,
      });
    },
    [filters],
  );
  const onDatePickerChange = (dates: any) => {
    setFilters({
      ...filters,
      dateRangeFilter: {
        start: dates.start ? parseISO(moment(dates.start).format('YYYY-MM-DD')) : null,
        end: dates.end ? parseISO(moment(dates.end).format('YYYY-MM-DD')) : null,
      },
    });
  };

  const [removeProductModal, setRemoveProductModal] = useState<{ id: string; opened: boolean }>({
    id: '',
    opened: false,
  });

  const [removeProductBulkModal, setRemoveProductBulkModal] = useState<{ id: string; opened: boolean }>({
    id: '',
    opened: false,
  });

  const [removeProductTemplateModal, setRemoveProductTemplateModal] = useState<{ id: string; opened: boolean }>({
    id: '',
    opened: false,
  });

  const toggleRemoveModal = useCallback((opened: boolean, id = '') => {
    setRemoveProductModal({ id, opened });
  }, []);

  const toggleRemoveBulkModal = useCallback((opened: boolean, id = '') => {
    setRemoveProductBulkModal({ id, opened });
  }, []);

  const toggleRemoveTemplateModal = useCallback((opened: boolean, id = '') => {
    setRemoveProductTemplateModal({ id, opened });
  }, []);

  const handleDeleteRequest = async (productId: string) => {
    TransactionService.productWalletTransfer({
      payload: {
        product: {},
        productId: productId,
        transactionPrice: 'ALL',
        onSuccess: () => {
          dispatch(
            removeProductForCompany.req(productId, () => {
              Toast.success(t('_ProductSuccessfullyRemoved_'));
              toggleRemoveModal(false);
            }),
          );
        },
      },
    });
  };

  const handleDeleteBulkRequest = async (bulkId: string) => {
    const productKeys = await ProductsSecureStorage.getProductByBulkId(bulkId);
    const {
      account: { ownedMosaics, selectedAccountAddress },
      network: { selectedNetwork, network, selectedNode },
    } = store.getState();
    const mosaicName = selectedNetwork.currencyMosaicId;
    const mosaic: MosaicModel = _.cloneDeep(
      ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
    );

    let i = 0;

    const onTransactionSuccess = async () => {
      i++;
      if (i === productKeys.length) {
        try {
          await ProductApi.deleteProductBulk(bulkId);
          toggleRemoveBulkModal(false);
          blockChain.close();
          GlobalListener.listen(selectedAccountAddress);
        } catch (error) {
          console.error(error);
        }
      }
    };

    GlobalListener.close();
    const blockChain: ListenerService = new ListenerService(
      () => {
        onTransactionSuccess();
      },
      (e) => {
        console.error(e);
        onTransactionSuccess();
      },
    );
    const networkS = await NetworkService.getNetworkModelFromNode(selectedNode);
    blockChain.setNetwork(networkS);
    await blockChain.listen(selectedAccountAddress);

    productKeys.forEach((productKey) => {
      void (async () => {
        const account = AccountService.createFromPrivateKey(productKey.privateKey, 'product', network);
        const { balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
          AccountService.getAddressByAccountModelAndNetwork(account, network),
          selectedNetwork,
        );
        mosaic.amount = parseFloat(balance.toString()) * Math.pow(10, mosaic?.divisibility);

        const transactionWithDefaultFee = {
          recipientAddress: selectedAccountAddress,
          mosaics: [mosaic] as MosaicModel[],
          messageText: '',
          messageEncrypted: false,
          type: 'transfer',
          fee: 0,
        };
        mosaic.amount = parseFloat(balance.toString()) * Math.pow(10, mosaic.divisibility);

        //@ts-ignore
        const transaction = {
          ...transactionWithDefaultFee,
          mosaics: [mosaic],
          resolvedFee: 0,
          fee: 0,
        } as TransactionModel;
        await TransactionService.signAndBroadcastTransactionModel(transaction, account, selectedNetwork);
      })();
    });
  };

  const handleDeleteTemplateRequest = async (templateId: string) => {
    await ProductApi.deleteProductTemplate(templateId);
    toggleRemoveTemplateModal(false);
    dispatch(removeTemplate(templateId));
  };

  const onTabChange = (tab) => {
    setFilters({
      statusFilter: null,
      categoryFilter: null,
      searchFilter: '',
      dateRangeFilter: {
        start: null,
        end: null,
      },
    });
    setRange([0, getPageSize(isBusinessUser)]);
    setCurrentPage(1);
    setActiveTab(tab);
  };

  return (
    <div className="products">
      <Text type="h1" className="page__title authenticated-page-title">
        {t(isBusinessUser ? '_Products_' : '_MyProducts_')}
      </Text>
      {
        <>
          {isBusinessUser ? (
            <>
              <TabMenu tabsContentClass={'product-description'} onClick={onTabChange}>
                <TabContent label={t('_Products_')}>
                  <div className="products__filters">
                    <div className="products__filters__inputs_container">
                      <div>
                        <Input type="text" placeholder="Search" onChange={handleSearchInput} value={searchInput} />
                      </div>
                      <Select
                        value={filters.statusFilter}
                        onChange={handleSetStatusFilter}
                        options={statusOptions || [{}]}
                        className="input-select products-filter-input-select"
                        classNamePrefix="input-select"
                        placeholder={t('_Status_')}
                        isDisabled={products?.length === 0 && isFirstLoad}
                        isSearchable
                        isClearable
                      />

                      <Select
                        value={filters.categoryFilter}
                        onChange={handleSetCategoryFilter}
                        options={categoryOptions}
                        className="input-select products-filter-input-select"
                        classNamePrefix="input-select"
                        placeholder={t('_Category_')}
                        isDisabled={products?.length === 0 && isFirstLoad}
                        isSearchable
                        isClearable
                      />

                      <DatePickerInput
                        startDate={filters.dateRangeFilter.start}
                        endDate={filters.dateRangeFilter.end}
                        dataCallback={onDatePickerChange}
                        isRange
                        className="products-datepicker-button"
                        withIcon
                      />
                    </div>
                    {!isEmployee && <Button isPrimary text={t('_Create_')} onClick={handleCreateClick} />}
                    <MenuBtn
                      isLoading={disableMenuButton}
                      id="menu-btn"
                      title={t('_Options_')}
                      data={[
                        {
                          key: MenuActions.EXPORT_PRODUCTS_KEYS,
                          name: t('_Export_private_keys_'),
                        },
                        {
                          key: MenuActions.IMPORT_PRODUCTS_KEYS,
                          name: t('_Import_private_keys_'),
                        },
                        {
                          key: MenuActions.EXPORT_QR_CODES,
                          name: t('_Export_QR_codes_'),
                        },
                      ]}
                      onSelect={handleSelect}
                    />
                  </div>
                  <ExportProductKeysModal
                    isOpen={isExportKeysModalOpen}
                    onClose={() => setIsExportKeysModalOpen(false)}
                  />
                  <ImportProductKeysModal
                    isOpen={isImportKeysModalOpen}
                    onClose={() => setIsImportKeysModalOpen(false)}
                  />
                  {productsLoading ? (
                    <div className="products__wrapper">
                      <div className="loader_wrapper">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <div className="products__wrapper">
                      {products?.length > 0 ? (
                        <>
                          <div className="products__item header">
                            <div className="products__item__first_col">
                              <Text
                                className="products__item__first_col-sm-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Preview_')}
                              </Text>
                              <Text
                                className="products__item__first_col-lg-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Name_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-lg-col"
                              >
                                {t('_Category_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-md-col"
                              >
                                {t('_CreationDate_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-products__status"
                              >
                                {t('_Status_')}
                              </Text>
                            </div>
                          </div>

                          {products?.map((item: Product | any) => (
                            <BusinessProductRow
                              item={item}
                              key={item._id}
                              productsKeys={productsKeys}
                              openEditProduct={(id) => redirectToProductEdit(id)}
                              openRemoveProduct={(id) => toggleRemoveModal(true, id)}
                            />
                          ))}
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalProducts}
                            pageSize={getPageSize(isBusinessUser)}
                            onPageChange={(page: number) => setNewRange(page - 1)}
                          />
                        </>
                      ) : (
                        <NoItem />
                      )}
                    </div>
                  )}
                </TabContent>

                <TabContent label={t('_Product_bulks_')}>
                  <div className="products__filters">
                    <div className="products__filters__inputs_container">
                      <div>
                        <Input type="text" placeholder="Search" onChange={handleSearchInput} value={searchInput} />
                      </div>

                      <Select
                        value={filters.categoryFilter}
                        onChange={handleSetCategoryFilter}
                        options={categoryOptions}
                        className="input-select products-filter-input-select"
                        classNamePrefix="input-select"
                        placeholder={t('_Category_')}
                        isDisabled={products?.length === 0 && isFirstLoad}
                        isSearchable
                        isClearable
                      />

                      <DatePickerInput
                        startDate={filters.dateRangeFilter.start}
                        endDate={filters.dateRangeFilter.end}
                        dataCallback={onDatePickerChange}
                        isRange
                        className="products-datepicker-button"
                        withIcon
                      />
                    </div>
                  </div>
                  {bulksLoading ? (
                    <div className="products__wrapper">
                      <div className="loader_wrapper">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <div className="products__wrapper">
                      {bulks?.length > 0 ? (
                        <>
                          <div className="products__item header">
                            <div className="products__item__first_col">
                              <Text
                                className="products__item__first_col-sm-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Preview_')}
                              </Text>
                              <Text
                                className="products__item__first_col-lg-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Name_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-lg-col"
                              >
                                {t('_Category_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-md-col"
                              >
                                {t('_CreationDate_')}
                              </Text>
                            </div>
                          </div>

                          {bulks?.map((item: ProductBulk | any) => (
                            <ProductBulkRow
                              item={item}
                              key={item._id}
                              openEditBulk={(id) => redirectToBulkEdit(id)}
                              openRemoveBulk={(id) => toggleRemoveBulkModal(true, id)}
                            />
                          ))}
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalBulks}
                            pageSize={getPageSize(isBusinessUser)}
                            onPageChange={(page: number) => setNewRange(page - 1)}
                          />
                        </>
                      ) : (
                        <NoItem />
                      )}
                    </div>
                  )}
                </TabContent>

                <TabContent label={t('_Product_templates_')}>
                  <div className="products__filters">
                    <div className="products__filters__inputs_container">
                      <div>
                        <Input type="text" placeholder="Search" onChange={handleSearchInput} value={searchInput} />
                      </div>

                      <Select
                        value={filters.categoryFilter}
                        onChange={handleSetCategoryFilter}
                        options={categoryOptions}
                        className="input-select products-filter-input-select"
                        classNamePrefix="input-select"
                        placeholder={t('_Category_')}
                        isDisabled={products?.length === 0 && isFirstLoad}
                        isSearchable
                        isClearable
                      />

                      <DatePickerInput
                        startDate={filters.dateRangeFilter.start}
                        endDate={filters.dateRangeFilter.end}
                        dataCallback={onDatePickerChange}
                        isRange
                        className="products-datepicker-button"
                        withIcon
                      />
                    </div>
                  </div>
                  {templatesLoading ? (
                    <div className="products__wrapper">
                      <div className="loader_wrapper">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <div className="products__wrapper">
                      {templates?.length > 0 ? (
                        <>
                          <div className="products__item header">
                            <div className="products__item__first_col">
                              <Text
                                className="products__item__first_col-sm-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Preview_')}
                              </Text>
                              <Text
                                className="products__item__first_col-lg-col"
                                color="ultra-dark-gray"
                                type="subtitle_s"
                              >
                                {t('_Name_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-lg-col"
                              >
                                {t('_Category_')}
                              </Text>
                              <Text
                                color="ultra-dark-gray"
                                type="subtitle_s"
                                className="products__item__first_col-md-col"
                              >
                                {t('_CreationDate_')}
                              </Text>
                            </div>
                          </div>

                          {templates?.map((item: ProductTemplate | any) => (
                            <ProductTemplateRow
                              item={item}
                              key={item._id}
                              openEditTemplate={(id) => redirectToTemplateEdit(id)}
                              openRemoveTemplate={(id) => toggleRemoveTemplateModal(true, id)}
                            />
                          ))}
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={totalTemplates}
                            pageSize={getPageSize(isBusinessUser)}
                            onPageChange={(page: number) => setNewRange(page - 1)}
                          />
                        </>
                      ) : (
                        <NoItem />
                      )}
                    </div>
                  )}
                </TabContent>
              </TabMenu>

              <DeleteEntityModal
                open={removeProductModal.opened}
                onClose={toggleRemoveModal}
                title={`${t('_DeleteProduct_')} ?`}
                onDelete={handleDeleteRequest}
                entityId={removeProductModal.id}
              />

              <DeleteEntityModal
                open={removeProductBulkModal.opened}
                onClose={toggleRemoveBulkModal}
                title={`${t('_DeleteProductBulk_')} ?`}
                onDelete={handleDeleteBulkRequest}
                entityId={removeProductBulkModal.id}
              />

              <DeleteEntityModal
                open={removeProductTemplateModal.opened}
                onClose={toggleRemoveTemplateModal}
                title={`${t('_DeleteProductTemplate_')} ?`}
                onDelete={handleDeleteTemplateRequest}
                entityId={removeProductTemplateModal.id}
              />

              <FreeAccountModal isOpen={isFreeAccountModalOpen} onClose={() => setIsFreeAccountModalOpen(false)} />
            </>
          ) : (
            <div>
              <TabMenu tabsContentClass={'product-description'}>
                <TabContent label={t('_Own_')}>
                  {productsLoading ? (
                    <div className="products__wrapper">
                      <div className="loader_wrapper">
                        <Loader />
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        {products.length
                          ? products?.map((item: any) => <IndividualProductRow key={item._id} item={item} />)
                          : !productsLoading && <NoItem />}
                      </div>

                      <div className="pagination-container">
                        <Pagination
                          className="pagination-bar"
                          currentPage={currentPage}
                          totalCount={totalProducts}
                          pageSize={getPageSize(isBusinessUser)}
                          onPageChange={(page: number) => setNewRange(page - 1)}
                        />
                      </div>
                    </>
                  )}
                </TabContent>
                <TabContent label={t('_Warranty_')}>
                  {currentDataOfWarrianties?.length
                    ? currentDataOfWarrianties.map((item: any) => {
                        return <IndividualProductRow key={item._id} item={item} fromWarrianty />;
                      })
                    : !productsLoading && <NoItem />}
                  <div className="pagination-container">
                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPageOfWarriaty}
                      totalCount={warranties?.length}
                      pageSize={3}
                      onPageChange={(page: any) => setCurrentPageOfWarriaty(page)}
                    />
                  </div>
                </TabContent>
              </TabMenu>
            </div>
          )}
        </>
      }
    </div>
  );
});

export default Products;
