import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/Loader';
import Pagination from '../../../../components/Pagination/Pagination';
import { getExplorerURL } from '../../../../config/environment';
import { ReactComponent as CalendarIcon } from '../../../../icons/calendar.svg';
import { getProductHistory } from '../../../../modules/product/actions';
import styles from './ProductHistory.module.scss';

dayjs.extend(utc);
dayjs.extend(tz);

export const ProductHistory: FC<{ productId: string }> = memo(({ productId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const timeZone = dayjs.tz.guess();
  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    dispatch(getProductHistory.req(productId));
    return () => {
      dispatch(getProductHistory.reset());
    };
  }, [productId]);
  const { productHistory, dataLoading } = useSelector((state: any) => state.products);
  //const { scanHistory } = useSelector((state: any) => state.products);
  const { network } = useSelector((state: any) => state.network);

  const getDescription = (desc: string, date?: Date, count?: number) => {
    let result: string = desc;
    if (result.includes('{{date}}')) {
      result = result.replace('{{date}}', dayjs(date).format('DD/MM/YYYY'));
    }
    if (result.includes('{{count}}')) {
      result = result.replace('{{count}}', String(count));
    }
    return result;
  };

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 6;
    const lastPageIndex = firstPageIndex + 6;

    const sordenArray = [...productHistory]?.sort((a, b) => {
      const sortA = new Date(a.createdAt).getTime();
      const sortB = new Date(b.createdAt).getTime();
      return sortB - sortA;
    });

    const currentArr = sordenArray.slice(firstPageIndex, lastPageIndex);

    return currentArr;
  }, [productHistory, currentPage]);

  return dataLoading ? (
    <div className="loader_wrapper">
      <Loader />
    </div>
  ) : currentData?.length > 0 ? (
    <>
      <div className="tableView">
        {currentData?.map((product: any) => {
          return (
            <div className={styles['history-card']} key={product._id}>
              <span className={styles['history-card__description']}>
                {product?.type === 'scanned-public-qr-code'
                  ? getDescription(product.description, product.info.variables.date, product.info.variables.count)
                  : getDescription(product.description, product.info?.variables?.date)}
              </span>
              <div className={styles['history-card__date']}>
                <CalendarIcon /> <span>{dayjs.utc(product.createdAt).tz(timeZone).format('DD/MM/YYYY, HH:mm')}</span>
              </div>
              {product?.eventTransactionId && (
                <a
                  href={`${getExplorerURL(network)}/accounts/${product?.eventTransactionId}`}
                  rel="noreferrer"
                  target="_blank"
                  className={styles.blockExplorer}
                >
                  {t('_ShowInTheBlockExplorer_')}
                </a>
              )}
            </div>
          );
        })}
      </div>
      <div className={styles['pagination-container']}>
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={productHistory.length}
          pageSize={6}
          onPageChange={(page: any) => setCurrentPage(page)}
        />
      </div>
    </>
  ) : (
    <div className="tableView">
      <div className={styles['history-card']}>
        <span className={styles['history-card__description']}>{t('_ProductHistoryIsEmpty_')}</span>
      </div>
    </div>
  );
});
