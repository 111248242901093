import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { ReactComponent as AlertIcon } from '../../icons/error.svg';
import BusyLoader from '../BusyLoader';
import { Button } from '../Button/Button';
import Text from '../common/Text';
import { Modal } from '../Modal/Modal';
import styles from './HiddenBankCardInfo.module.scss';

const DeleteModal = ({ cardNumber, onDelete, isBusyRequest, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={onClose}>
      <BusyLoader
        isBusy={isBusyRequest}
        className={cn(styles.deleteCardModal, { [styles.deleteCardModal_busy]: isBusyRequest })}
      >
        <Text type="h1" className={styles.deleteCardModalTitle}>
          {t('_DeletePaymentMethod_')}
        </Text>
        <AlertIcon className={styles.alertIcon} />
        <div className={styles.descirption}>
          {t('_AreYouSureYouWantToDelete_')} **** {cardNumber}? {t('_ThisActionCantBeUndone_')}
        </div>
        <div className="flexible aCenter jCenter">
          <Button className={styles.buttonsStyle} onClick={onDelete} isAlert>
            {t('_Delete_')}
          </Button>
          <Button className={styles.buttonsStyle} isPrimaryOutline onClick={onClose}>
            {t('_Cancel_')}
          </Button>
        </div>
      </BusyLoader>
    </Modal>
  );
};

export default DeleteModal;
