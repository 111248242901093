import { produce } from 'immer';
import { FC, useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { Button } from '../../../../components/Button/Button';
import EmptyData from '../../../../components/EmptyData';
import Loader from '../../../../components/Loader';
import { Modal } from '../../../../components/Modal/Modal';
import { OrganizationApi } from '../../../../services/api/_OrganizationApi';
import { DeleteEntityModal as DeleteCustomFieldModal } from '../../../../shared/components';
import { Toast } from '../../../../utils/toastHelper';
import styles from './CategoryCustomFields.module.scss';
import { CustomFieldCard } from './CustomFieldCard';

interface CategoryCustomFieldsProps {
  open: boolean;
  categoryName: string;
  categoryId: string;
  onClose: () => void;
  organizationId: any;
}

type Option = {
  label: string;
  value: string;
};

const diff = (categoryFields: any[], orgFeilds: any[]) => {
  return orgFeilds.filter(
    (orgField: any) => !categoryFields.some((categoryField: any) => categoryField.id === orgField.id),
  );
};

export const CategoryCustomFields: FC<CategoryCustomFieldsProps> = ({
  open,
  onClose,
  categoryName,
  categoryId,
  organizationId,
}) => {
  const { t } = useTranslation();
  const [categoryFields, setCategoryFields] = useState<{ id: string; name: string }[]>([]);
  const [organizationFields, setOrganizationFields] = useState<{ id: string; name: string }[]>([]);

  const [fieldsOptions, setFieldsOptions] = useState<Option[]>([]);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = categoryFields[dragIndex];

      setCategoryFields(
        produce(categoryFields, (draft) => {
          draft.splice(dragIndex, 1);
          draft.splice(hoverIndex, 0, dragCard);
          return draft;
        }),
      );
    },
    [categoryFields],
  );

  const [deleteCustomField, setDeleteCustomField] = useState({ open: false, customFieldId: '' });

  const deleteCustomFieldFromCategory = async (customFieldId: string) => {
    try {
      await OrganizationApi.deleteFieldFromCategory(categoryId, customFieldId);

      setCategoryFields(
        produce(categoryFields, (draft) => {
          return draft.filter((field) => field.id !== customFieldId);
        }),
      );

      setFieldsOptions(
        produce(fieldsOptions, (draft) => {
          const removedField = organizationFields.find((field) => field.id === customFieldId);

          if (removedField) {
            draft.unshift({ label: removedField.name, value: removedField.id });
          }
        }),
      );
      setDeleteCustomField({ open: false, customFieldId: '' });

      Toast.success(`${t('_FieldSuccessfullyRemovedFromCategory_')}: ${categoryName}`);
    } catch (e) {
      Toast.error(`${t('_ErrorDELETE_FROM_CATEGORY_')} ${JSON.stringify(e)}`);
    }
  };

  const onSelect = async (option: Option) => {
    const updatedCategoryFields = produce(categoryFields, (draft) => {
      draft.push({ id: option.value, name: option.label });
    });

    try {
      await OrganizationApi.addToCategory({
        categoryId,
        fields: [{ _id: option.value, sort: updatedCategoryFields.length }],
      });

      setFieldsOptions(
        produce(fieldsOptions, (draft) => {
          const index = draft.findIndex((orgFields: any) => orgFields.value === option.value);
          draft.splice(index, 1);
        }),
      );

      setCategoryFields(updatedCategoryFields);
    } catch (e) {
      Toast.error(`${t('_ErrorADD_TO_CATEGORY_')}: ${JSON.stringify(e)}`);
    }
  };

  const initFields = async () => {
    setIsBusy(true);
    try {
      const { data: category } = await OrganizationApi.getCategoryFields(categoryId);
      const categoryFieldsList = category.fields.map((field: any) => ({ id: field._id, name: field.name }));

      let { data: organizationFeildsList } = await OrganizationApi.getOrganizationFields(organizationId);

      organizationFeildsList = organizationFeildsList.data.map((field: any) => ({
        id: field._id,
        name: field.name,
      }));

      const selectOptions = diff(categoryFieldsList, organizationFeildsList).map((field: any) => ({
        label: field.name,
        value: field.id,
      }));

      setIsBusy(false);
      setOrganizationFields(organizationFeildsList);
      setCategoryFields(categoryFieldsList);
      setFieldsOptions(selectOptions as Option[]);
    } catch (e) {
      setIsBusy(false);
      Toast.error(JSON.stringify(e));
    }
  };

  useEffect(() => {
    if (!categoryId || !open) {
      return;
    }
    initFields();
  }, [categoryId, open]);

  const handleClose = () => {
    setOrganizationFields([]);
    setCategoryFields([]);
    onClose();
  };

  const handleSave = () => {
    setSaving(true);
    Promise.all(
      categoryFields.map((field, sort) =>
        OrganizationApi.updateField(organizationId, field.id, {
          sort,
        }),
      ),
    ).then(() => {
      setSaving(false);
      handleClose();
    });
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <div className={styles.category}>
        <h2 className={styles['category-name']}>{categoryName}</h2>

        <DeleteCustomFieldModal
          entityId={deleteCustomField.customFieldId}
          title={t('_DeleteCustomField_')}
          open={deleteCustomField.open}
          onClose={() => setDeleteCustomField({ open: false, customFieldId: '' })}
          onDelete={deleteCustomFieldFromCategory}
        />

        {isBusy ? (
          <div className={styles.loaderWrapper}>
            <Loader />
          </div>
        ) : categoryFields.length ? (
          <DndProvider backend={HTML5Backend}>
            <div className={styles['category-fields']}>
              {categoryFields.map((card, index) => {
                return (
                  <CustomFieldCard
                    onDelete={(customFieldId) => setDeleteCustomField({ open: true, customFieldId })}
                    key={card.id}
                    index={index}
                    id={card.id}
                    text={card.name}
                    moveCard={moveCard}
                  />
                );
              })}
            </div>
          </DndProvider>
        ) : (
          <div className={styles['category-slect-empty']}>
            <EmptyData />
          </div>
        )}
        <div className={styles['category-select-fields']}>
          <div>
            <legend>{t('_SelectFieldToAdd_')}</legend>
            <Select
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderRadius: '10px',
                  height: '44px',
                }),
                indicatorsContainer: (styles) => ({
                  ...styles,
                  paddingTop: '2px',
                }),
                indicatorSeparator: (styles) => ({
                  ...styles,
                  background: 'none',
                }),
              }}
              className={styles.select}
              placeholder={t('_SelectFromTheList_')}
              options={isBusy ? [{ label: t('_Loading_'), value: '' }] : fieldsOptions}
              onChange={(option: Option | any) => onSelect(option)}
              closeMenuOnSelect
            />
          </div>
          <Button isPrimary onClick={handleSave} isLoading={saving}>
            {t('_Save_')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
