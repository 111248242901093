import { IconButton } from '@mui/material';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { ReactComponent as CopyIcon } from '../../../../icons/copy.svg';
import { ReactComponent as EditIcon } from '../../../../icons/edit.svg';
import { ReactComponent as TrashIcon } from '../../../../icons/trash.svg';
import { ProductTemplate } from '../../../../modules/product/types';

interface TemplateActionsProps {
  template: ProductTemplate;
  onEditClicked: (id: string) => void;
  onDeleteClicked: (id: string) => void;
}

export const TemplateActions: FC<TemplateActionsProps> = ({ template, onDeleteClicked, onEditClicked }) => {
  const router = useHistory();
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const onCopyClicked = (id: string) => {
    router.push(`/products/create-from-template/${id}`);
  };

  return (
    <div className="actions-col">
      {!isEmployee && (
        <>
          <IconButton className="icon-button" onClick={() => onCopyClicked(template._id)}>
            <CopyIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onEditClicked(template._id)}>
            <EditIcon />
          </IconButton>

          <IconButton className="icon-button" onClick={() => onDeleteClicked(template._id)}>
            <TrashIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};
