import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { userLogin } from '../../modules/auth/actions';
import { BaseSecureStorage } from '../../storage/persistence/BaseSecureStorage';
import { LoginValidation as validationSchema } from '../../utils/validations';
import './Login.scss';

const Login: FC<any> = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [failedAttempts, setFailedAttempts] = useState(0);

  useEffect(() => {
    Object.keys({ ...localStorage }).forEach((key) => {
      if (key.includes('CognitoIdentityServiceProvider')) {
        localStorage.removeItem(key);
      }
    });
    localStorage.removeItem('closed_session_timestamp');
    localStorage.removeItem('logout_timestamp');
    localStorage.removeItem('oneTimePass');
    localStorage.removeItem('orgInfo');
    localStorage.removeItem('invited-organizationId');
    localStorage.removeItem('accountIndex');

    localStorage.setItem('authFlow', 'login');

    if (location.search.includes('invite')) {
      history.push('/signup');
    }
  }, []);

  const onSubmit = async (data: { email: string; password: string }, { setErrors }: any) => {
    const username = data.email.trim();
    setLoading(true);
    try {
      const user = await Auth.signIn({ username, password: data.password });
      dispatch(userLogin.request(user));

      if (user && user.challengeName === 'CUSTOM_CHALLENGE') {
        localStorage.setItem(
          'oneTimePass',
          BaseSecureStorage.encrytKey(JSON.stringify({ ...data, email: username })) as string,
        );
        if (user.challengeParam?.['$set2FA']) {
          history.push('/set-security-answers');
        } else {
          history.push('/confirm-security-questions');
        }
      }
    } catch (e) {
      if (e.message === 'Password attempts exceeded') {
        history.push('/forgot-password');
      }
      if (e.code === 'UserNotConfirmedException') {
        await Auth.resendSignUp(username);
        localStorage.setItem('oneTimePass', JSON.stringify({ ...data, email: username }));
        history.push('/email-verification');
      }
      // @ts-ignore
      if (e.code.match(/NotAuthorizedException/gi) || e.code.match(/UserNotFoundException/gi)) {
        setFailedAttempts((prev) => prev + 1);
        setErrors({
          password: t('_Email_and_password_do_not_match_'),
        });
      }
      if (failedAttempts >= 3) {
        history.push('/forgot-password');
      }
    } finally {
      setLoading(false);
    }
  };

  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login">
      <div className="login__wrapper">
        <h2 className="auth__title">{t('_SignIn_')}</h2>
        <h4 className="auth__description">{t('_ToLoginEnterYourEmailPassword_')}</h4>
        <form className="login__form" onSubmit={handleSubmit}>
          <Input
            type="text"
            name="email"
            size="lg"
            placeholder={t('_EnterYourEmail_')}
            label={t('_Email_')}
            value={values.email}
            hasError={!!errors.email}
            onChange={(e: any) => setFieldValue('email', e.target.value)}
          />
          {errors && <p className="errors">{errors.email}</p>}
          <Input
            type="password"
            name="password"
            size="lg"
            placeholder={t('_Password_')}
            isPassword
            label={t('_Password_')}
            value={values.password}
            hasError={!!errors.password}
            onChange={(e: any) => setFieldValue('password', e.target.value)}
          />
          {errors && <p className="errors">{errors.password}</p>}
          <Link to="/forgot-password" className="login__forgot-password">
            {t<string>('_ForgotPassword_')}
          </Link>
          <div className="login__button-wrapper">
            <Button isPrimary className="login__button" type="submit" text={t('_Login_')} isLoading={loading} />
          </div>
        </form>
        <div className="login__link-signup">
          <span>{t('_DonthaveAnAccount_')}</span>
          <Link to="/signup">{t<string>('_SignUp_')}</Link>
        </div>
      </div>
    </div>
  );
};

export default Login;
