import { Api } from './Api';

export const OrganizationApi = {
  addCategory(category: { name: string; description?: string }, organizationId: string) {
    return Api.post(`/category`, { ...category, organizationId });
  },
  editCategory(category: { name: string; description?: string }, categoryId: string) {
    return Api.put(`/category/${categoryId}`, { ...category });
  },
  getCategory(categoryId: string) {
    return Api.get(`/category/${categoryId}`);
  },
  removeCategory(categoryId: string) {
    return Api.delete(`/category/${categoryId}`);
  },
  getOrganizationCategories(organizationId: string) {
    return Api.get(`/category?filter[organizationId]=${organizationId}`);
  },

  getOrganizationFields(orgnizationId: string) {
    return Api.get(`/organization/${orgnizationId}/field`);
  },

  getCategoryFields(categoryId: string) {
    return Api.get(`/category/${categoryId}/field`);
  },

  createField(dto: { name: string; type: string; sort?: number; organizationId: string }) {
    return Api.post(`/organization/${dto.organizationId}/field`, {
      name: dto.name,
      type: dto.type,
      sort: dto.sort ? dto.sort : 0,
    });
  },

  updateField(organizationId: string, fieldId: string, dto: { name?: string; type?: string; sort?: number }) {
    return Api.put(`/organization/${organizationId}/field/${fieldId}`, dto);
  },

  deleteField(organizationId: string, fieldId: string) {
    return Api.delete(`/organization/${organizationId}/field/${fieldId}`);
  },

  addToCategory(dto: { categoryId: string; fields: any[] }) {
    return Api.post(`/category/${dto.categoryId}/field`, dto);
  },

  deleteFieldFromCategory(categoryId: string, fieldId: string) {
    return Api.delete(`/category/${categoryId}/field/${fieldId}`);
  },
};
