import { END, eventChannel } from 'redux-saga';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { getDefaultNetworkType, getNISNodes, getNodes } from '../../config/environment';
import { walletInitStateAction } from '../../modules/wallet/actions';
import { GlobalListener } from '../../redux/store';
import NetworkService from '../../services/symbol/NetworkService';
import {
  checkNetworkAction,
  networkInitStateAction,
  networkUpdateAction,
  registerNodeCheckJobAction,
  setCheckNodeJobAction,
  setIsUpAction,
  setNodeFailedAttemptsAction,
} from './actions';

const MAX_FAILED_NODE_ATTEMPTS = 10;
function* networkInitStateSaga() {
  try {
    const networkType = getDefaultNetworkType();
    const selectedNode: any = getNodes(networkType)[0];
    const network = yield NetworkService.getNetworkModelFromNode(selectedNode);
    try {
      GlobalListener.setNetwork(network);
    } catch (e) {
      console.error(e);
    }

    try {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const nisNodes = getNISNodes(network.type);
      // yield put(saveSetSelectedNISNodeAction(nisNodes[0]));
    } catch (e) {
      console.error(e);
    }

    yield put(
      networkUpdateAction({
        generationHash: network.generationHash,
        type: network.type,
        selectedNode,
        network,
        isLoaded: true,
      }),
    );

    yield put(walletInitStateAction());
  } catch (error) {
    console.error(error);
  }
}
function* checkNetworkSaga() {
  const { network } = yield select();
  const selectedNetwork = network.selectedNetwork;
  let isUp = false;
  if (selectedNetwork) {
    try {
      isUp = yield NetworkService.isNetworkUp(selectedNetwork);
    } catch {
      isUp = network.nodeFailedAttempts < MAX_FAILED_NODE_ATTEMPTS;
    }
    if (isUp) {
      yield put(setNodeFailedAttemptsAction(0));
    } else {
      yield put(setNodeFailedAttemptsAction(network.nodeFailedAttempts + 1));
    }
  }
  yield put(setIsUpAction({ isUp }));
}
const NETWORK_JOB_INTERVAL = 10000;
function countdown(secs: number) {
  return eventChannel((emitter) => {
    const iv = setInterval(() => {
      secs -= 1;
      if (secs > 0) {
        emitter(secs);
      } else {
        // this causes the channel to close
        emitter(END);
      }
    }, 1000);
    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(iv);
    };
  });
}
function* registerNodeCheckJobSaga() {
  const { network } = yield select();
  if (!network.checkNodeJob) {
    // const job = setInterval(() => {
    //
    //     dispatchAction({type: 'network/checkNetwork'})
    // }, NETWORK_JOB_INTERVAL);
    // @ts-ignore
    const channel = yield call(countdown, NETWORK_JOB_INTERVAL);
    yield takeEvery(channel, function* () {
      yield put(checkNetworkAction());
    });
    yield put(setCheckNodeJobAction({ job: channel }));
  }
}

export function* watchNetwork() {
  yield takeLatest(networkInitStateAction, networkInitStateSaga);
  yield takeLatest(checkNetworkAction, checkNetworkSaga);
  yield takeLatest(registerNodeCheckJobAction, registerNodeCheckJobSaga);
}
