import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '../../components/Button';
import Text from '../../components/common/Text';
import { Input } from '../../components/Input/Input';
import { useRedirect } from '../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { loadAccountsBalancesAction, saveWalletAction } from '../../modules/wallet/actions';
import AccountService from '../../services/symbol/AccountService';
import { Toast } from '../../utils/toastHelper';
import './styles.scss';

const ImportWallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { accounts } = useSelector((state: any) => state.wallet);
  const handleBack = useRedirect('/settings');
  const [mnemonic, setMnemonic] = useState('');
  const [isValidMnemonic, setIsValidMnemonic] = useState(false);
  const handleFill = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMnemonic(e.target.value);
  };

  const handleConnect = () => {
    const onSuccess = () => {
      const message = t(accounts.length ? '_WalletSuccessfulyUpdated_' : '_WalletSuccessfulyImported_');
      Toast.success(message);
      dispatch(loadAccountsBalancesAction());
      handleBack();
    };
    const onError = () => {
      return Toast.error(t('_SeedphraseDoesNotMatch_'));
    };
    dispatch(saveWalletAction({ mnemonic, onSuccess, onError }));
  };

  useEffect(() => {
    setIsValidMnemonic(AccountService.isValidMnemonic(mnemonic));
  }, [mnemonic]);

  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title authenticated-page-title">
          {t('_ImportNewWallet_')}
        </Text>
        <div className="wallet-creation__form-wrapper import-wallet-form-wrapper">
          <Input
            type="text"
            value={mnemonic}
            label={t('_YourSeedPhrase_')}
            placeholder={t('_EnterYourSeedPhrase_')}
            name="mnemonic"
            onChangeTextArea={handleFill}
            isTextArea
          />
          <div className="import-wallet-button-container">
            <Button text={t('_Connect_')} appearence="primary" disabled={!isValidMnemonic} onClick={handleConnect} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportWallet;
