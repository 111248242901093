import dayjs from 'dayjs';
import { getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'typed-redux-saga';
import { CvApi } from '../../services/api/CvApi';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import {
  createCvRequests,
  createCvWorkExperience,
  createEducation,
  createMyCv,
  getMyCv,
  setCvRequests,
  setCvWorkExperience,
  setMyCv,
  setOrganizationsCVList,
  setUserManagmentInfo,
  setUserManagmentList,
  updateCvWorkExperience,
  updateEducation,
  updateMyCv,
} from './actions';

function* myCvSaga(params): SagaIterator {
  const { onFinish, filterParams } = params.payload;
  try {
    const data = yield* call(CvApi.getCv, filterParams ? CommonHelpers.getFilterParams(filterParams) : '');
    yield* put(setMyCv.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setMyCv.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* myCvListSaga(): SagaIterator {
  const data = yield* call(CvApi.getCv);
  yield* put(getMyCv.success(data));
}

function* myCvCreatonSaga(params): SagaIterator {
  const { data, onSuccess, onError, myCV } = params.payload;
  const { items } = data;
  delete data.items;
  delete data.educations;
  if (data.birthdate) {
    data.birthdate = dayjs(data.birthdate).format();
  }
  try {
    const result = yield* call(CvApi.createCv, data);
    const newRes = { ...(myCV || {}), total: (myCV?.total || 0) + 1, data: [...(myCV?.data || []), result] };
    yield* put(setMyCv.success(newRes));
    if (items?.length && !!onSuccess) {
      items.forEach((item, index) => onSuccess(item, result, createCvWorkExperience.request, index === 0));
    } else {
      onSuccess(null, result, null, true);
    }
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* myCvUpdateSaga(params): SagaIterator {
  const { data, onSuccess, onError } = params.payload;
  const { items } = data;
  const { _id } = data;
  delete data.items;
  delete data._id;
  delete data.userId;
  delete data.publicQrCode;
  delete data.updatedAt;
  delete data.createdAt;
  delete data.educations;
  delete data.rating;
  if (data.birthdate) {
    data.birthdate = dayjs(data.birthdate).format();
  }
  try {
    const result = yield* call(CvApi.updateCv, data, _id);
    yield* put(updateMyCv.success(result));
    !!onSuccess && onSuccess(items, result, null, true);
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* workExperiencesSaga(params): SagaIterator {
  try {
    const result = yield* call(CvApi.getWorkExperiences, params.payload);
    yield* put(setCvWorkExperience.success(result));
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    console.error(message);
    return Toast.error(message);
  }
}

function* workExperienceCreationSaga(params): SagaIterator {
  const { data, onSuccess, onError, id } = params.payload;
  try {
    const result = yield* call(CvApi.createWorkExperience, data, id);
    yield* put(setCvWorkExperience.success(result));
    !!onSuccess && onSuccess(true);
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* workExperienceUpdateSaga(params): SagaIterator {
  const { data, onSuccess, onError } = params.payload;
  const { cv_id, _id } = data;
  delete data._id;
  delete data.cv_id;
  delete data.updatedAt;
  delete data.createdAt;
  delete data.rating;
  delete data.info;
  delete data.status;
  try {
    yield* call(CvApi.updateWorkExperience, data, cv_id, _id);
    if (!onSuccess) {
      return;
    }
    !!onSuccess && onSuccess(true);
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* cvRequestsSaga(params): SagaIterator {
  const { onFinish, filterParams } = params.payload;
  try {
    const result = yield* call(CvApi.getCvRequests, filterParams ? CommonHelpers.getFilterParams(filterParams) : null);
    yield* put(setCvRequests.success(result));
    !!onFinish && onFinish();
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* cvRequestCreationSaga(params): SagaIterator {
  const { data, onSuccess, onError, id, itemId, t } = params.payload;
  try {
    const result = yield* call(CvApi.createCvRequests, data, id, itemId);
    yield* put(setCvRequests.success(result));
    !!onSuccess && onSuccess();
    Toast.success(t('_YourRequestSuccessfullySent_'));
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* educationCreationSaga(params): SagaIterator {
  const { data, onSuccess, onError, id } = params.payload;
  try {
    const result = yield* call(
      CvApi.createEducation,
      { institutional: data?.institutional || '', degree: 'Master Degree', startYear: 2004 },
      id,
    );
    !!onSuccess && onSuccess(result);
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* educationUpdateSaga(params): SagaIterator {
  const { id, data = {}, onSuccess, onError } = params.payload;
  try {
    yield* call(
      CvApi.updateEducation,
      {
        startYear: data?.startYear || 2004,
        degree: data?.degree || 'Master Degree',
        institutional: data?.institutional || '',
      },
      id,
      data?._id,
    );
    if (!onSuccess) {
      return;
    }
    onSuccess(true);
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

function* userManagementListSaga(params): SagaIterator {
  const { organizationId, onFinish, filterParams } = params.payload;
  try {
    const data = yield* call(
      OrganizationApi.getUserManagmentList,
      organizationId,
      filterParams ? CommonHelpers.getFilterParams(filterParams) : '',
    );
    yield* put(setUserManagmentList.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setUserManagmentList.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* userManagementInfoSaga(params): SagaIterator {
  const { organizationId, id, onFinish } = params.payload;
  try {
    const data = yield* call(OrganizationApi.getUserManagmentInfo, organizationId, id);
    yield* put(setUserManagmentInfo.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setUserManagmentInfo.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* organizationCVListSaga(params): SagaIterator {
  const { onFinish, organizationId, filterParams } = params.payload;
  try {
    const data = yield* call(
      OrganizationApi.getOrganizationCvList,
      organizationId,
      filterParams ? CommonHelpers.getFilterParams(filterParams) : '',
    );
    yield* put(setOrganizationsCVList.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    !!onFinish && onFinish();
    yield* put(setOrganizationsCVList.success({}));
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

export function* watchCvs(): SagaIterator {
  yield* takeLatest(getType(setMyCv.request), myCvSaga);
  yield* takeLatest(getType(getMyCv.request), myCvListSaga);
  yield* takeLatest(getType(createMyCv.request), myCvCreatonSaga);
  yield* takeLatest(getType(updateMyCv.request), myCvUpdateSaga);
  yield* takeLatest(getType(setCvWorkExperience.request), workExperiencesSaga);
  yield* takeLatest(getType(createCvWorkExperience.request), workExperienceCreationSaga);
  yield* takeLatest(getType(updateCvWorkExperience.request), workExperienceUpdateSaga);
  yield* takeLatest(getType(setCvRequests.request), cvRequestsSaga);
  yield* takeLatest(getType(createCvRequests.request), cvRequestCreationSaga);
  yield* takeLatest(getType(createEducation), educationCreationSaga);
  yield* takeLatest(getType(updateEducation), educationUpdateSaga);
  yield* takeLatest(getType(setUserManagmentList.request), userManagementListSaga);
  yield* takeLatest(getType(setUserManagmentInfo.request), userManagementInfoSaga);
  yield* takeLatest(getType(setOrganizationsCVList.request), organizationCVListSaga);
}
