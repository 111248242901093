import { useEffect, useRef } from 'react';

export const useUpdate = (callback, dependencies) => {
  const mounted = useRef(false);
  // eslint-disable-next-line
  useEffect(
    mounted.current
      ? callback
      : () => {
          mounted.current = true;
        },
    dependencies,
  );
};
