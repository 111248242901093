import { useTranslation } from 'react-i18next';
import Text from '../../components/common/Text';
import { TabContent, TabMenu } from '../../components/TabMenu/TabMenu';
import ApiDescription from './ApiDescription';
import ApiKeys from './ApiKeys';
import ChangeLog from './Changelog';
import styles from './styles.module.scss';
const ApiPage = () => {
  const { t } = useTranslation();
  return (
    <>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_API_')}
      </Text>
      <TabMenu tabsContentClass={styles['product-description']}>
        <TabContent label={t('_API_description_')}>
          <ApiDescription />
        </TabContent>
        <TabContent label={t('_Changelog_')}>
          <ChangeLog />
        </TabContent>
        <TabContent label={t('_API_keys_')}>
          <ApiKeys />
        </TabContent>
      </TabMenu>
    </>
  );
};

export default ApiPage;
