import { Tooltip } from '@mui/material';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Checkbox from '../../components/common/Checkbox';
import { Input } from '../../components/Input/Input';
import { ReactComponent as InfoIcon } from '../../icons/info.svg';
import { BaseSecureStorage } from '../../storage/persistence/BaseSecureStorage';
import { Toast } from '../../utils/toastHelper';
import { SignupValidation as validationSchema } from '../../utils/validations';
import './SignUp.scss';
import { IStepOneFormData } from './types';

const SignUp = () => {
  const { t } = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const history = useHistory();

  const onSubmit = async (data: IStepOneFormData, { setErrors }: any) => {
    setIsButtonLoading(true);
    try {
      await Auth.signOut({ global: true });
      const username = data.email.trim();
      const responseData = await Auth.signUp({
        username,
        password: data.password,
        attributes: {
          email: username,
        },
      });
      setIsButtonLoading(false);

      if (responseData) {
        localStorage.setItem(
          'oneTimePass',
          BaseSecureStorage.encrytKey(JSON.stringify({ ...data, email: username })) as string,
        );
        history.push('/email-verification');
      } else {
        Toast.error(t('_SomethingWrong_'));
      }
    } catch (e) {
      if (e.code === 'UsernameExistsException') {
        setErrors({ email: t('_User_already_registered_') });
      } else {
        setErrors({ password: e.message });
      }
      setIsButtonLoading(false);
    }
  };
  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: {
      email: '',
      password: '',
      termsChecked: false,
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login signup">
      <div className="signup__container">
        <div className={'login__wrapper'}>
          <h2 className="auth__title">{t('_SignUp_')}</h2>
          <h4 className="auth__description">{t('_To_sign_up_enter_email_and_password_')}</h4>

          <>
            <form className="login__form" onSubmit={handleSubmit}>
              <Input
                type="text"
                placeholder={t('_EnterYourEmail_')}
                label={t('_Email_')}
                size="lg"
                hasError={!!errors.email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('email', e.target.value)}
              />
              {errors && <p className="errors">{errors.email}</p>}
              <div className="login__password">
                <Input
                  type="password"
                  placeholder={t('_CreatePassword_')}
                  label={t('_Password_')}
                  isPassword
                  hasError={!!errors.password}
                  size="lg"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
                />
                <div className="password_tooltip_container">
                  <Tooltip title={t('_Password_tooltip_')} placement="top" arrow>
                    <InfoIcon />
                  </Tooltip>
                </div>
              </div>

              {errors && <p className="errors">{errors.password}</p>}
              <div className="signup__agreement">
                <div>
                  <Checkbox
                    defaultChecked={values.termsChecked}
                    onChange={() => setFieldValue('termsChecked', !values.termsChecked)}
                  />
                  <span>
                    {t('_IAgreeToThe_')}{' '}
                    <a href="https://veritise.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
                      {t('_TermsAndConditions_')}
                    </a>{' '}
                    {t('_AndThe_')}{' '}
                    <a href="https://veritise.com/privacy-policy-2/" target="_blank" rel="noopener noreferrer">
                      {t('_PrivacyPolicy_')}
                    </a>
                  </span>
                </div>
              </div>

              <div className="button__wrapper">
                <Button
                  isPrimary
                  className="login__button"
                  type="submit"
                  text={t('_SignUp_')}
                  isDisabled={values.email === '' || values.password === '' || !values.termsChecked}
                  isLoading={isButtonLoading}
                />
              </div>
            </form>

            <div className="login__link-signup">
              <span>{t('_AlreadyHaveAnAccount_')}</span>
              <Link to="/">{t('_SignIn_')}</Link>
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
