/* eslint-disable @typescript-eslint/no-unused-vars */
import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import QRCode from 'react-qr-code';
import { ReactComponent as CopyIcon } from '../../../../icons/copy.svg';
import { Toast } from '../../../../utils/toastHelper';
import styles from './ProductQr.module.scss';

export const QrCodeLink: FC<{
  link: string;
  title?: string;
  qrImageLink?: string | null;
  className?: string;
}> = ({ link, title, qrImageLink, className }) => {
  const { t } = useTranslation();
  const copyToClipBoard = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      Toast.info(t('_CopiedToClipboard_'));
    });
  };

  return (
    <div className={cn(styles['qr-codes__item'], className)}>
      <span className={styles['qr-codes__item-title']}>{title}</span>
      {qrImageLink ? (
        <img src={qrImageLink} alt="" className={styles['qr-codes__item-image']} />
      ) : (
        <QRCode value={link} size={179} />
      )}
      <div className={styles['qr-codes__item-link']}>
        <legend>{t('_Link_')}</legend>
        <div className={styles['copy-input']}>
          <input value={link} disabled />
          <CopyIcon className={styles['copy-input-icon']} onClick={() => copyToClipBoard(link)} />
        </div>
      </div>
    </div>
  );
};
