/* eslint-disable no-case-declarations */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { sha256 } from 'js-sha256';
import _ from 'lodash';
import { Dispatch } from 'redux';
import { TransactionQR } from 'symbol-qr-library';
import {
  Account,
  Address,
  CosignatureSignedTransaction,
  CosignatureTransaction,
  Deadline,
  EncryptedMessage,
  Mosaic,
  MosaicId,
  NetworkType,
  PlainMessage,
  PublicAccount,
  RepositoryFactoryHttp,
  SignedTransaction,
  Transaction,
  TransactionGroup,
  TransactionHttp,
  TransferTransaction,
  UInt64,
} from 'symbol-sdk';
import { getDefaultNetworkType, getNodes } from '../../config/environment';
import i18n from '../../i18n';
import { loadAllDataAction } from '../../modules/account/actions';
import store, { GlobalListener } from '../../redux/store';
import type { AccountModel, AccountState } from '../../storage/models/AccountModel';
import type { MosaicModel } from '../../storage/models/MosaicModel';
import type { NetworkModel, NetworkState } from '../../storage/models/NetworkModel';
import type {
  AggregateTransactionModel,
  TransactionModel,
  TransferTransactionModel,
} from '../../storage/models/TransactionModel';
import { ProductsSecureStorage } from '../../storage/persistence/ProductsSecureStorage';
import { Toast } from '../../utils/toastHelper';
import AccountService from './AccountService';
import ListenerService from './ListenerService';
import NetworkService from './NetworkService';

export interface FeesConfig {
  free: number;
  slow: number;
  normal: number;
  fast: number;
}

export const defaultFeesConfig: FeesConfig = {
  free: 0,
  slow: 1,
  normal: 1.2,
  fast: 2,
};

export default class TransactionService {
  /**
   * Transforms a model to an sdk object
   * @param transaction
   * @param signer
   * @param networkModel
   * @returns {TransferTransaction}
   */
  static async transactionModelToTransactionObject(
    transaction: TransactionModel,
    signer: AccountModel,
    networkModel: NetworkModel,
  ): Promise<Transaction> {
    let transactionObj: Transaction;
    switch (transaction.type) {
      case 'transfer':
        transactionObj = await this._transferTransactionModelToObject(
          // @ts-ignore
          transaction,
          signer,
          networkModel,
        );
        break;
      default:
        throw new Error('Not implemented');
    }

    return transactionObj;
  }

  /**
   * Signs and broadcasts a transaction model
   * @param transaction
   * @param signer
   * @param networkModel
   */
  static signAndBroadcastTransactionModel = async (
    transaction: TransactionModel,
    signer: AccountModel,
    networkModel: NetworkModel,
  ) => {
    const transactionObject = await this.transactionModelToTransactionObject(transaction, signer, networkModel);

    const networkType = networkModel.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
    const signedTransaction = signerAccount.sign(transactionObject, networkModel.generationHash);

    // @ts-ignore
    store.dispatch({
      type: 'transfer/SET_TRANSACTION_HASH',
      payload: signedTransaction.hash,
    });
    await this.broadcastSignedTransaction(signedTransaction, networkModel);
    return signedTransaction;
  };

  /**
   * Transforms a transfer transaction model to an sdk object
   * @param transaction
   * @param signer
   * @param networkModel
   * @returns {Promise<TransferTransaction>}
   * @private
   */
  static _transferTransactionModelToObject = async (
    transaction: TransferTransactionModel,
    signer: AccountModel,
    networkModel: NetworkModel,
  ): Promise<TransferTransaction> => {
    const recipientAddress = Address.createFromRawAddress(transaction.recipientAddress);

    const networkType = networkModel.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const mosaics = [
      new Mosaic(new MosaicId(transaction.mosaics[0].mosaicId), UInt64.fromUint(transaction.mosaics[0].amount)),
    ];

    if (!transaction.messageEncrypted) {
      const message = PlainMessage.create(transaction.messageText);
      return TransferTransaction.create(
        Deadline.create(networkModel.epochAdjustment),
        recipientAddress,
        mosaics,
        message,
        networkType,
        UInt64.fromUint(transaction.fee),
      );
    } else {
      const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
      const repositoryFactory = await new RepositoryFactoryHttp(networkModel.node);
      const accountHttp = repositoryFactory.createAccountRepository();
      try {
        const accountInfo = await accountHttp.getAccountInfo(recipientAddress).toPromise();
        // @ts-ignore
        const message = signerAccount.encryptMessage(transaction.messageText, accountInfo);
        return TransferTransaction.create(
          Deadline.create(networkModel.epochAdjustment, 2),
          recipientAddress,
          mosaics,
          message,
          networkType,
          UInt64.fromUint(transaction.fee),
        );
      } catch (e) {
        throw Error('Recipient address has not a public key');
      }
    }
  };

  /**
   * Cosign and broadcast aggregate transactionModel
   * @param transaction
   * @param signer
   * @param network
   */
  static cosignAndBroadcastAggregateTransactionModel(
    transaction: AggregateTransactionModel,
    signer: AccountModel,
    network: NetworkModel,
  ) {
    const cosignatureTransaction = CosignatureTransaction.create(transaction.signTransactionObject);
    const networkType = network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const signerAccount = Account.createFromPrivateKey(signer.privateKey, networkType);
    const signedTransaction = signerAccount.signCosignatureTransaction(cosignatureTransaction);

    return this.broadcastCosignatureSignedTransaction(signedTransaction, network);
  }

  static async broadcastSignedTransaction(tx: SignedTransaction, network: NetworkModel) {
    const transactionHttp = new TransactionHttp(network.node);
    return transactionHttp.announce(tx).toPromise();
  }

  static async broadcastCosignatureSignedTransaction(tx: CosignatureSignedTransaction, network: NetworkModel) {
    const transactionHttp = new TransactionHttp(network.node);
    return transactionHttp.announceAggregateBondedCosignature(tx).toPromise();
  }

  /**
   * Receive QR Data
   * @param recipientAddress
   * @param amount
   * @param network
   * @param message
   * @returns {Promise<void>}
   */
  // @ts-ignore
  static getReceiveSvgQRData = async (recipientAddress, amount, network, message) => {
    const netwrokType = network.type === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET;
    const transferTransaction = TransferTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      Address.createFromRawAddress(recipientAddress),
      [new Mosaic(new MosaicId(network.currencyMosaicId), UInt64.fromUint(amount * Math.pow(10, 6)))],
      PlainMessage.create(message),
      netwrokType,
      UInt64.fromUint(1000000),
    );
    const txQR = new TransactionQR(transferTransaction, netwrokType, network.generationHash);
    return txQR.toBase64().toPromise();
  };

  /**
   * Decrypt message
   * @param current
   * @param network
   * @param transaction
   * @returns {Promise<void>}
   */
  static decryptMessage = async (
    current: AccountModel,
    network: NetworkModel,
    transaction: TransferTransactionModel,
  ) => {
    try {
      const transactionHash = transaction.hash || '';
      const repositoryFactory = new RepositoryFactoryHttp(network.node);
      const transactionHttp = repositoryFactory.createTransactionRepository();
      let tx;
      try {
        tx = await transactionHttp.getTransaction(transactionHash, TransactionGroup.Confirmed).toPromise();
      } catch (e) {
        //
      }
      if (!tx) {
        try {
          tx = await transactionHttp.getTransaction(transactionHash, TransactionGroup.Unconfirmed).toPromise();
        } catch (e) {
          //
        }
      }
      if (tx && tx instanceof TransferTransaction && tx.message.type === 1) {
        const networkType = NetworkService.getNetworkTypeFromModel(network);
        const currentAccount = Account.createFromPrivateKey(current.privateKey, networkType);
        if (tx.recipientAddress.plain() === currentAccount.address.plain()) {
          // @ts-ignore
          return EncryptedMessage.decrypt(tx.message, current.privateKey, tx.signer).payload;
        } else {
          const accountHttp = repositoryFactory.createAccountRepository();

          const recipientAccountInfo = await accountHttp
            // @ts-ignore
            .getAccountInfo(tx.recipientAddress)
            .toPromise();
          const recipientAccount = PublicAccount.createFromPublicKey(recipientAccountInfo.publicKey, networkType);
          return EncryptedMessage.decrypt(tx.message, current.privateKey, recipientAccount).payload;
        }
      } else {
        return '';
      }
    } catch (e) {
      //
      return '';
    }
  };

  static getTransaction = async (hash: string, network: NetworkModel): Promise<Transaction | undefined> => {
    const transactionHttp = new TransactionHttp(network.node);
    let tx;
    try {
      tx = await transactionHttp.getTransaction(hash, TransactionGroup.Confirmed).toPromise();
    } catch (e) {
      //
    }
    if (!tx) {
      try {
        tx = await transactionHttp.getTransaction(hash, TransactionGroup.Unconfirmed).toPromise();
      } catch (e) {
        //
      }
    }
    if (!tx) {
      try {
        tx = await transactionHttp.getTransaction(hash, TransactionGroup.Partial).toPromise();
      } catch (e) {
        //
      }
    }
    return tx;
  };

  static claimOwnership = async ({
    network: n,
    account,
    dispatch,
    payload,
  }: {
    network: NetworkState;
    account: AccountState;
    dispatch: Dispatch<any>;
    payload: {
      privateKey: string;
      name: string;
      ownerShip: any;
      onSuccess: (socket: ListenerService) => void;
      onError: (e: any, socket: ListenerService) => void;
    };
  }) => {
    try {
      const { selectedNetwork, network } = n;
      const { selectedAccountAddress, ownedMosaics } = account;

      const ownerShip = payload.ownerShip;
      let hashSum = '';
      if (ownerShip) {
        const normal = JSON.stringify(ownerShip);
        hashSum = sha256(normal);
      }
      const { selectedAccount, transaction } = await this.calculateFee(
        payload,
        selectedNetwork,
        network,
        dispatch,
        ownedMosaics,
        selectedAccountAddress,
        hashSum,
      );

      //@ts-ignore
      const trans = {
        ...transaction,
        resolvedFee: 0,
        fee: 0,
      } as TransactionModel;

      await TransactionService.signAndBroadcastTransactionModel(trans, selectedAccount, selectedNetwork);

      dispatch(loadAllDataAction());
    } catch (e) {
      console.error(`claim ownership general error: ${e}`);
    }
  };
  static activateWarranty = async ({
    network: n,
    account,
    dispatch,
    payload,
  }: {
    network: NetworkState;
    account: AccountState;
    dispatch: Dispatch<any>;
    payload: {
      privateKey: string;
      name: string;
      warranty: any;
      onSuccess: (socket: ListenerService) => void;
      onError: (e: any, socket: ListenerService) => void;
    };
  }) => {
    try {
      const { selectedNetwork, network } = n;
      const { selectedAccountAddress, ownedMosaics } = account;
      const warranty = payload.warranty;
      let hashSum = '';
      if (warranty) {
        const normal = JSON.stringify(warranty);
        hashSum = sha256(normal);
      }
      const { selectedAccount, transaction } = await this.calculateFee(
        payload,
        selectedNetwork,
        network,
        dispatch,
        ownedMosaics,
        selectedAccountAddress,
        hashSum,
      );

      //@ts-ignore
      const trans = {
        ...transaction,
        resolvedFee: 0,
        fee: 0,
      } as TransactionModel;

      await TransactionService.signAndBroadcastTransactionModel(trans, selectedAccount, selectedNetwork);

      dispatch(loadAllDataAction());
    } catch (e) {
      //
    }
  };

  private static async calculateFee(
    payload: {
      privateKey: string;
      name: string;
      warranty?: any;
      ownerShip?: any;
      onSuccess: (socket: ListenerService) => void;
      onError: (e: any, socket: ListenerService) => void;
    },
    selectedNetwork: NetworkModel,
    network: 'testnet' | 'mainnet',
    dispatch: Dispatch<any>,
    ownedMosaics: [],
    selectedAccountAddress: string,
    hashSum: string,
  ) {
    const selectedAccount = await this.socket(payload, selectedNetwork, network);

    const mosaicName = selectedNetwork.currencyMosaicId;
    //@ts-ignore
    const mosaic: MosaicModel = _.cloneDeep(
      ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
    );
    mosaic.amount = parseFloat('0') * Math.pow(10, mosaic.divisibility);
    const transaction = {
      recipientAddress: selectedAccountAddress,
      mosaics: [mosaic] as MosaicModel[],
      messageText: hashSum,
      messageEncrypted: false,
      type: 'transfer',
      fee: 0,
    };
    return { selectedAccount, transaction };
  }

  private static async socket(
    payload: {
      privateKey: string;
      name: string;
      onSuccess: (socket: ListenerService) => void;
      onError: (e: any, socket: ListenerService) => void;
    },
    selectedNetwork: NetworkModel,
    network: 'testnet' | 'mainnet',
  ) {
    //@ts-ignore
    const blockChain: ListenerService = new ListenerService(
      () => payload.onSuccess(blockChain),
      (err) => payload.onError(err, blockChain),
    );
    const networkType = getDefaultNetworkType();
    const selectedNode = getNodes(networkType)[0];
    const networkS = await NetworkService.getNetworkModelFromNode(selectedNode);
    blockChain.setNetwork(networkS);

    const selectedAccount = AccountService.createFromPrivateKey(payload.privateKey, payload.name, selectedNetwork.type);
    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(selectedAccount, network);
    await blockChain.listen(rawAddress);
    return selectedAccount;
  }

  static newCVWallet = async ({
    payload,
  }: {
    payload: {
      onSuccess?: (publickKey?: string, privateKey?: string) => void;
      onError?: () => void;
      transactionPrice?: string;
      cv: any;
    };
  }) => {
    const { wallet: w, account: a, network: n } = store.getState();
    const { selectedNetwork, network } = n;
    const { ownedMosaics } = a;
    const { selectedAccount } = w as any;
    try {
      const mosaicName = selectedNetwork.currencyMosaicId;
      // @ts-ignore
      const mosaic: MosaicModel = _.cloneDeep(
        ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
      );

      const products = await ProductsSecureStorage.getAllProducts();
      if ('cv' in products) {
        // const cvPrivate = products.cv;
        // const account = AccountService.createFromPrivateKey(cvPrivate, 'cv', network);
        // const { balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
        //   AccountService.getAddressByAccountModelAndNetwork(selectedAccount, network),
        //   selectedNetwork,
        // );
        // if (balance === 0) {
        //   Toast.error(i18n.t('_Your_balance_is_X_', { balance }));
        //   if (payload.onError) {
        //     payload.onError();
        //   }
        //   return;
        // }
        // GlobalListener.close();
        // const blockChain: ListenerService = new ListenerService(
        //   () => {
        //     blockChain.close();
        //     GlobalListener.listen(a.selectedAccountAddress);
        //     if (payload.onSuccess) {
        //       payload.onSuccess();
        //     }
        //   },
        //   () => {
        //     blockChain.close();
        //     GlobalListener.listen(a.selectedAccountAddress);
        //     if (payload.onError) {
        //       payload.onError();
        //     }
        //   },
        // );
        // const selectedAccountAddress = a.selectedAccountAddress;
        // const networkS = await NetworkService.getNetworkModelFromNode(n.selectedNode);
        // blockChain.setNetwork(networkS);
        // await blockChain.listen(selectedAccountAddress);
        // mosaic.amount = parseFloat(balance.toString()) * Math.pow(10, mosaic?.divisibility);
        // let hashSum = '';
        // const normal = JSON.stringify(payload.cv);
        // hashSum = sha256(normal);
        // const transaction = {
        //   recipientAddress: selectedAccountAddress,
        //   mosaics: [mosaic] as MosaicModel[],
        //   messageText: hashSum,
        //   messageEncrypted: false,
        //   type: 'transfer',
        //   fee: 0,
        // };
        // // @ts-ignore
        // mosaic.amount = parseFloat('0') * Math.pow(10, mosaic.divisibility);
        // //@ts-ignore
        // const trans = {
        //   ...transaction,
        //   mosaics: [mosaic],
        //   resolvedFee: 0,
        //   fee: 0,,
        // } as TransactionModel;
        // await TransactionService.signAndBroadcastTransactionModel(trans, account, selectedNetwork);
      } else {
        const mnemonicModel = AccountService.createRandomMnemonic();
        const mainnetAccountModel = AccountService.createFromMnemonicAndIndex(
          mnemonicModel.mnemonic,
          0,
          'wallet',
          'mainnet',
        );
        const testnetAccountModel = AccountService.createFromMnemonicAndIndex(
          mnemonicModel.mnemonic,
          0,
          'wallet',
          'testnet',
        );
        const account = network === 'testnet' ? testnetAccountModel : mainnetAccountModel;

        const { balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
          AccountService.getAddressByAccountModelAndNetwork(selectedAccount, network),
          selectedNetwork,
        );

        if (balance === 0) {
          Toast.error(i18n.t('_Your_balance_is_X_', { balance }));
          if (payload.onError) {
            payload.onError();
          }
        }
        GlobalListener.close();
        const blockChain: ListenerService = new ListenerService(
          async () => {
            await GlobalListener.listen(a.selectedAccountAddress);
            // await ProductsSecureStorage.createNewProduct({ cv: account.privateKey }); // @TODO: WTF! Why cv??? account.privateKey is string??? AAAA!
            if (payload.onSuccess) {
              payload.onSuccess();
            }
          },
          () => {
            blockChain.close();
            GlobalListener.listen(a.selectedAccountAddress);
            if (payload.onError) {
              Toast.error(i18n.t('_Please_check_your_balance_'));
              payload.onError();
            }
          },
        );
        const networkS = await NetworkService.getNetworkModelFromNode(n.selectedNode);
        blockChain.setNetwork(networkS);

        await blockChain.listen(a.selectedAccountAddress);
        let hashSum = '';
        const normal = JSON.stringify(payload.cv);
        hashSum = sha256(normal);

        const {
          // @ts-ignore
          address: { address: recipientAddress },
        } = PublicAccount.createFromPublicKey(
          account.id,
          network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET,
        );

        mosaic.amount = parseFloat('0.0001') * Math.pow(10, mosaic?.divisibility);
        const transaction = {
          recipientAddress,
          mosaics: [mosaic] as MosaicModel[],
          messageText: hashSum,
          messageEncrypted: false,
          type: 'transfer',
          fee: 0,
        };
        //@ts-ignore
        const trans = {
          ...transaction,
          mosaics: [mosaic],
          resolvedFee: 0,
          fee: 0,
        } as TransactionModel;
        await TransactionService.signAndBroadcastTransactionModel(trans, selectedAccount, selectedNetwork);
      }
    } catch (e) {
      //
    }
  };
  static confirmCvRequest = async ({
    payload,
  }: {
    payload: {
      onSuccess?: (publickKey?: string, privateKey?: string) => void;
      onError?: () => void;
      cv: any;
      cvPublickKey: string;
    };
  }) => {
    const { wallet: w, account: a, network: n } = store.getState();
    const { selectedNetwork, network } = n;
    const { ownedMosaics } = a;
    const { selectedAccount } = w as any;
    try {
      const mosaicName = selectedNetwork.currencyMosaicId;
      // @ts-ignore
      const mosaic: MosaicModel = _.cloneDeep(
        ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
      );

      const { balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
        AccountService.getAddressByAccountModelAndNetwork(selectedAccount, network),
        selectedNetwork,
      );

      if (balance === 0) {
        Toast.error(i18n.t('_Your_balance_is_X_', { balance }));
        payload.onError();
      }
      GlobalListener.close();
      const blockChain: ListenerService = new ListenerService(
        async () => {
          blockChain.close();
          await GlobalListener.listen(a.selectedAccountAddress);
          if (payload.onSuccess) {
            payload.onSuccess();
          }
        },
        () => {
          blockChain.close();
          GlobalListener.listen(a.selectedAccountAddress);
          if (payload.onError) {
            payload.onError();
          }
        },
      );
      const networkS = await NetworkService.getNetworkModelFromNode(n.selectedNode);
      blockChain.setNetwork(networkS);

      await blockChain.listen(a.selectedAccountAddress);
      let hashSum = '';
      const normal = JSON.stringify(payload.cv);
      hashSum = sha256(normal);

      const {
        // @ts-ignore
        address: { address: recipientAddress },
      } = PublicAccount.createFromPublicKey(
        payload.cvPublickKey,
        network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET,
      );

      mosaic.amount = parseFloat('0') * Math.pow(10, mosaic?.divisibility);
      const transaction = {
        recipientAddress,
        mosaics: [mosaic] as MosaicModel[],
        messageText: hashSum,
        messageEncrypted: false,
        type: 'transfer',
        fee: 0,
      };
      //@ts-ignore
      const trans = {
        ...transaction,
        mosaics: [mosaic],
        resolvedFee: 0,
        fee: 0,
      } as TransactionModel;
      await TransactionService.signAndBroadcastTransactionModel(trans, selectedAccount, selectedNetwork);
    } catch (e) {
      //
    }
  };
  static _generateHash(data: any, type: string) {
    let hash = '';
    switch (type) {
      case 'product':
        const obj: any = {
          productName: data.name || data.productName || '',
          description: data.description || '',
          _id: data.publicKey,
          infoFields: [],
        };
        const fields = data.fields || data.info?.fields || [];
        if (Object.keys(fields).length) {
          Object.keys(fields).forEach((name) => {
            obj.infoFields.push({
              name,
              value: fields[name].value,
            });
          });
        }

        const normal = JSON.stringify(obj);
        hash = sha256(normal);
        break;
    }
    return hash;
  }
  static productWalletTransfer = async ({
    payload,
  }: {
    payload: {
      onSuccess?: (
        hash?: string,
        account?: { publicKey: string; privateKey: string; account?: any; transactionHash?: string },
        // isFirstTime?: boolean,
      ) => void;
      onError?: () => void;
      transactionPrice: string;
      privateId?: string;
      product: any;
      productId?: string;
    };
  }) => {
    const { product, productId, privateId, transactionPrice, onSuccess, onError } = payload;

    const {
      account: { ownedMosaics, selectedAccountAddress },
      network: { selectedNetwork, network, selectedNode },
    } = store.getState();

    try {
      const mosaicName = selectedNetwork.currencyMosaicId;
      const mosaic: MosaicModel = _.cloneDeep(
        ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
      );

      const _product: any = await ProductsSecureStorage.getProduct(productId);

      if (_product || privateId) {
        const productPrivate: any = privateId || _product.privateKey;
        const account = AccountService.createFromPrivateKey(productPrivate, 'product', network);
        const { balance } = await AccountService.getBalanceAndOwnedMosaicsFromAddress(
          AccountService.getAddressByAccountModelAndNetwork(account, network),
          selectedNetwork,
        );

        const hashSum = TransactionService._generateHash({ ...product, publicKey: account.id }, 'product');

        GlobalListener.close();
        const blockChain: ListenerService = new ListenerService(
          () => {
            blockChain.close();
            GlobalListener.listen(selectedAccountAddress);
            if (onSuccess) {
              onSuccess(hashSum, {
                publicKey: account.id,
                privateKey: account.privateKey,
                account,
                transactionHash: signedTransaction?.hash,
              });
            }
          },
          () => {
            blockChain.close();
            GlobalListener.listen(selectedAccountAddress);
            if (onError) {
              onError();
            }
          },
        );

        const networkS = await NetworkService.getNetworkModelFromNode(selectedNode);
        blockChain.setNetwork(networkS);

        await blockChain.listen(selectedAccountAddress);
        mosaic.amount = parseFloat(balance.toString()) * Math.pow(10, mosaic?.divisibility);

        const transaction = {
          recipientAddress: selectedAccountAddress,
          mosaics: [mosaic] as MosaicModel[],
          messageText: hashSum,
          messageEncrypted: false,
          type: 'transfer',
          fee: 0,
        };

        if (transactionPrice === 'ALL') {
          mosaic.amount = parseFloat(balance.toString()) * Math.pow(10, mosaic.divisibility);
        } else {
          mosaic.amount = parseFloat(transactionPrice) * Math.pow(10, mosaic.divisibility);
        }

        //@ts-ignore
        const trans = {
          ...transaction,
          mosaics: [mosaic],
          resolvedFee: 0,
          fee: 0,
        } as TransactionModel;
        const signedTransaction = await TransactionService.signAndBroadcastTransactionModel(
          trans,
          account,
          selectedNetwork,
        );
      } else {
        const mnemonicModel = AccountService.createRandomMnemonic();
        const account = AccountService.createFromMnemonicAndIndex(
          mnemonicModel.mnemonic,
          0,
          'wallet',
          network === 'testnet' ? 'testnet' : 'mainnet',
        );

        const hashSum = TransactionService._generateHash({ ...product, publicKey: account.id }, 'product');
        const { signedTransaction } = await TransactionService.makeTransaction(account, hashSum, ownedMosaics);
        if (onSuccess) {
          onSuccess(hashSum, {
            publicKey: account.id,
            privateKey: account.privateKey,
            account,
            transactionHash: signedTransaction?.hash,
          });
        }
      }
    } catch (e) {
      //
    }
  };

  static async makeTransaction(productWalletAccount, hashSum, ownedMosaics) {
    try {
      const { network: n, wallet: w } = store.getState();
      const { selectedNetwork, network } = n;
      const { selectedAccount } = w as any;
      const {
        // @ts-ignore
        address: { address: recipientAddress },
      } = PublicAccount.createFromPublicKey(
        productWalletAccount.id,
        network === 'testnet' ? NetworkType.TEST_NET : NetworkType.MAIN_NET,
      );
      const mosaicName = selectedNetwork.currencyMosaicId;
      // @ts-ignore
      const mosaic: MosaicModel = _.cloneDeep(
        ownedMosaics.find((mos: { mosaicId: string }) => mos.mosaicId === mosaicName),
      );

      mosaic.amount = parseFloat('0.0001') * Math.pow(10, mosaic?.divisibility);

      const transaction = {
        recipientAddress,
        mosaics: [mosaic] as MosaicModel[],
        messageText: hashSum,
        messageEncrypted: false,
        type: 'transfer',
        fee: 0,
      };

      //@ts-ignore
      const trans = {
        ...transaction,
        mosaics: [mosaic],
        resolvedFee: 0,
        fee: 0,
      } as TransactionModel;
      const signedTransaction = await TransactionService.signAndBroadcastTransactionModel(
        trans,
        selectedAccount,
        selectedNetwork,
      );
      return { signedTransaction, transactionId: recipientAddress };
    } catch (e) {
      //
    }
  }
}
