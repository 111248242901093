import { Auth } from 'aws-amplify';
import { put, takeLatest } from 'redux-saga/effects';
import { resetStore } from '../app/actions';
import { getOrganizations } from '../organization/actions';
import { getUserData } from '../user/actions';
import { logoutAction, logoutActionRequest, userLogin } from './actions';
import { LoginStateWithUser } from './types';

function* userLoginSaga({ payload }: any) {
  sessionStorage.setItem('active_session', '1');
  yield put(getUserData.req(payload.username));
  yield put(userLogin.success(payload as unknown as LoginStateWithUser));
}

function* logOutSaga({ payload }: any) {
  try {
    // @ts-ignore
    localStorage.removeItem('closed_session_timestamp');
    const user = yield Auth.currentAuthenticatedUser();
    yield Auth.updateUserAttributes(user, {
      'custom:notificationToken': '',
    });
    yield { type: getOrganizations.reset() };
    yield Auth.signOut({ global: true });
  } catch (e) {
    console.error(e);
  } finally {
    yield put(resetStore());
    yield put(logoutAction());
    payload.onSuccess();
  }
}
export function* watchLogin() {
  yield takeLatest(userLogin.request, userLoginSaga);
  yield takeLatest(logoutActionRequest, logOutSaga);
}
