import cn from 'classnames';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as StarIcon } from '../../icons/active-star.svg';
import { ReactComponent as DonwloadIcon } from '../../icons/download-file.svg';
import { CommonHelpers } from '../../utils/commonHelpers';
import EmptyData from '../EmptyData';
import './Table.scss';

type columnType = {
  name?: string;
  dataKey?: string;
  dataKeys?: string[];
  type?: string;
};

interface TableProps {
  data: any;
  columns: columnType[];
  actions?: any;
  needColNames?: boolean;
  needEmpty?: boolean;
  onItemKeyClick?: (data?: any) => void;
}

const getPhoto = (p) => {
  if (p.startsWith('https') | p.startsWith('data')) {
    return p;
  } else {
    return process.env.REACT_APP_ATTACHMENTS_DOMAIN + p;
  }
};

const formatter = (type: string, value: any, item: any, t: any, onItemKeyClick: any) => {
  switch (type) {
    case 'DATE':
      return dayjs(value).format('DD/MM/YYYY HH:MM A');
    case 'PHOTO':
      return value?.[0]?.location ? (
        <img src={getPhoto(value?.[0]?.location)} alt="user" width="44" height="44" className="user-image" />
      ) : (
        '-'
      );
    case 'DATE_TIME':
      return value ? dayjs(new Date(value * 1000)).format('DD/MM/YYYY') : '-';
    case 'EMAIL':
      return <div className={cn('email-field', { active: item.status === 'Active' })}>{value}</div>;
    case 'DATE_FORMAT':
      return value ? dayjs(value).format('DD/MM/YYYY') : '-';
    case 'DATE_RANGE':
      return `${item.startDate ? dayjs(item.startDate).format('DD/MM/YYYY') : '-'}${
        item.endDate ? ` - ${dayjs(item.endDate).format('DD/MM/YYYY')}` : ` - ${t('_CurrentTime_')}`
      }`;
    case 'AMOUNT':
      return value ? `€${value / 100}` : '-';
    case 'AGE':
      return value ? `${dayjs().year() - dayjs(value).year()} y.o.` : '-';
    case 'STATUS':
      return (
        <div className="status-value" style={{ backgroundColor: CommonHelpers.getStatusColor(value) }}>
          {CommonHelpers.getStatusName(value)}
        </div>
      );
    case 'RATING':
      return value ? (
        <div className="rating flexible aCenter jCenter">
          <StarIcon width={16} className="star-icon" />
          <span className="star-value">{value || '-'}</span>
        </div>
      ) : (
        '-'
      );
    case 'RESENT_INVITE':
      return value !== 'Active' ? (
        <div className="resent-invite" onClick={() => onItemKeyClick(item)}>
          {t('_ResentInvite_')}
        </div>
      ) : (
        ''
      );
    case 'DOWNLOAD':
      return value ? (
        <a href={value} download className="download">
          <DonwloadIcon /> {t('_DownloadReceipt_')}
        </a>
      ) : (
        '-'
      );
    default:
      return value || '-';
  }
};

const getDeepValue = (key, item) => {
  if (!key) return item;

  const keysArr = key.split('.');
  const newItem = item[keysArr[0]];
  keysArr.shift();
  return getDeepValue(keysArr.join('.'), newItem);
};

const getCorrectValue = (dataKey, item) => {
  if (Array.isArray(dataKey)) {
    return dataKey.map((el) => getDeepValue(el, item)).join(' ');
  } else {
    if (dataKey.includes('.')) {
      return getDeepValue(dataKey, item);
    } else {
      return item[dataKey];
    }
  }
};

const Table = ({
  data = [],
  columns = [],
  actions,
  needColNames = true,
  needEmpty = true,
  onItemKeyClick,
}: TableProps) => {
  const { t } = useTranslation();
  const tableColumns = useMemo(() => columns?.map(({ name }) => <th key={Math.random()}>{t(name)}</th>), [columns]);

  const tableData = useMemo(
    () =>
      data?.map((item) => (
        <tr key={item._id}>
          {columns.map(({ dataKey, type, dataKeys }) => (
            <td key={Math.random()}>
              <div className="flexible aCenter jCenter">
                {formatter(type, getCorrectValue(dataKeys || dataKey, item), item, t, onItemKeyClick)}
              </div>
            </td>
          ))}
          {actions && <td>{actions(item)}</td>}
        </tr>
      )),
    [data, columns, formatter],
  );

  if (!data?.length && needEmpty) {
    return <EmptyData />;
  }

  return (
    !!data?.length && (
      <div className="Table">
        <table>
          {needColNames && (
            <thead>
              <tr>{tableColumns}</tr>
            </thead>
          )}
          <tbody>{tableData}</tbody>
        </table>
      </div>
    )
  );
};

export default Table;
