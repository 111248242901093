import { createReducer } from 'deox';
import produce from 'immer';
import {
  resetStateAction,
  setHarvestedBlocksAction,
  setHarvestedBlockStatsAction,
  setHarvestingModelAction,
  setNodesAction,
  setStatusAction,
} from '../../modules/harvesting/actions';
import HarvestingService from '../../services/symbol/HarvestingService';
import { HarvestingState } from './types';

const MIN_REQUIRED_BALANCE = 10000;
const initialState: HarvestingState = {
  initialized: false,
  harvestedBlocks: null,
  isFetchingHarvestedBlocks: false,
  harvestedBlocksPageInfo: { pageNumber: 1, isLastPage: false },
  status: 'INACTIVE',
  harvestedBlockStats: {
    totalBlockCount: 0,
    totalFeesEarned: 0,
  },
  isFetchingHarvestedBlockStats: false,
  minRequiredBalance: MIN_REQUIRED_BALANCE,
  harvestingModel: null,
  nodes: HarvestingService.getHarvestingNodeList(),
};

export const harvestingReducer = createReducer(initialState, (handle) => [
  handle(setHarvestedBlocksAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.harvestedBlocks = payload;
      draft.initialized = true;
    }),
  ),
  handle(setStatusAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.status = payload;
    }),
  ),
  handle(setHarvestedBlockStatsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.harvestedBlockStats = payload;
    }),
  ),
  handle(setHarvestingModelAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.harvestingModel = payload;
    }),
  ),
  handle(setNodesAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.nodes = payload;
    }),
  ),
  handle(resetStateAction, () => initialState),
]);
