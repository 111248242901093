import { put, select, takeLatest } from 'redux-saga/effects';
import { from } from 'rxjs';
import FetchTransactionService from '../../services/symbol/FetchTransactionService';
import { TransactionModel } from '../../storage/models/TransactionModel';
import {
  checkPendingSignaturesAction,
  initTransactionAction,
  loadNextPageAction,
  resetTransactionAction,
  setAddressFilterAction,
  setAddTransactionsAction,
  setIsLastPageAction,
  setLoadingAction,
  setLoadingNextAction,
  setPageAction,
  setPendingSignatureAction,
  setSubscriptionAction,
  setTransactionsAction,
} from './actions';

// { payload }: ReturnType<typeof transactionAction.request>
function* initTransactionsSaga() {
  try {
    const { account } = yield select();
    yield put(setAddressFilterAction(account.selectedAccountAddress));
    yield put(resetTransactionAction());
    try {
      yield put(loadNextPageAction());
      yield put(checkPendingSignaturesAction());
    } catch (e) {
      yield put(resetTransactionAction());
    }
  } catch (e) {
    console.error(e);
  }
}
function* resetTransactionSaga() {
  try {
    yield put(setPageAction(0));
    yield put(setLoadingAction(false));
    yield put(setIsLastPageAction(false));
    yield put(setTransactionsAction([]));
  } catch (e) {
    console.error(e);
  }
}
function* handleSubscriptionSaga(transactions: TransactionModel[], nextPage: number) {
  if (transactions.length === 0) {
    yield put(setIsLastPageAction(true));
  } else {
    yield put(setAddTransactionsAction(transactions));
  }
  yield put(setPageAction(nextPage));
  yield put(setLoadingAction(false));
  yield put(setLoadingNextAction(false));
}
function* handleSubscriptionErrorSaga() {
  yield put(setLoadingAction(false));
  yield put(setLoadingNextAction(false));
}
function* loadNextPageSaga() {
  const { transaction, network } = yield select();
  if (transaction.loading) {
    return;
  }
  yield put(setLoadingAction(true));
  yield put(setLoadingNextAction(true));

  const nextPage = transaction.page + 1;
  const subscription = from(
    FetchTransactionService.getTransactionsFromAddress(
      transaction.addressFilter,
      nextPage,
      transaction.directionFilter,
      network.selectedNetwork,
    ),
  ).subscribe(
    (transactions) => {
      handleSubscriptionSaga(transactions, nextPage);
    },
    (error) => {
      console.error(error);
      handleSubscriptionErrorSaga();
    },
  );
  yield put(setSubscriptionAction(subscription));
}
function* checkPendingSignaturesSaga(): Generator<any> {
  const { account, network }: any = yield select();
  let has: any = yield FetchTransactionService.hasAddressPendingSignatures(
    account.selectedAccountAddress,
    network.selectedNetwork,
  );
  for (const address of account.cosignatoryOf) {
    const cosigHas: any = yield FetchTransactionService.hasAddressPendingSignatures(address, network.selectedNetwork);
    has = has || cosigHas;
  }
  yield put(setPendingSignatureAction(has));
}
export function* watchTransactions() {
  yield takeLatest(initTransactionAction, initTransactionsSaga);
  yield takeLatest(resetTransactionAction, resetTransactionSaga);
  yield takeLatest(loadNextPageAction, loadNextPageSaga);
  yield takeLatest(checkPendingSignaturesAction, checkPendingSignaturesSaga);
}
