import Text from '../../components/common/Text';
import styles from './styles.module.scss';

const RectangleContent = ({ children, title }) => {
  return (
    <div className={styles.rectangleContent}>
      <Text type="h2" color="secondary-dark-blue" fontWeight="bolder" className="Visby_Bold">
        {title}
      </Text>
      {children}
    </div>
  );
};

export default RectangleContent;
