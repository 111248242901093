/* eslint-disable no-console */
import axios from 'axios';
import { FileStorageApi } from '../../services/api';
import { API_PAYLOAD_LIMIT } from '../../utils/constants';

axios.interceptors.request.use(async (request) => {
  if (request.data?.length > API_PAYLOAD_LIMIT) {
    const { data: fileStorage } = await FileStorageApi.createUploadUrl();

    const blobData = new Blob([request.data], { type: 'application/json' });

    await fetch(fileStorage.url, {
      method: 'PUT',
      body: blobData,
    });

    request.data = {
      $payload: fileStorage.fileKey,
    };
  }

  return request;
});
