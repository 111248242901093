import { createReducer } from 'deox';
import produce from 'immer';
import {
  createProductForCompany,
  createWarranty,
  getCategories,
  getProduct,
  getProductBulksForCompany,
  getProductHistory,
  getProductsForCompany,
  getProductTemplatesForCompany,
  getScanHistory,
  getScanItem,
  getWarrianties,
  removeTemplate,
  setDataLoading,
  setFirstLoadAction,
  setLoadingAction,
  setProductBulksLoadingAction,
  setProductsLoadingAction,
  setProductTemplatesLoadingAction,
  setProductWarrantiesUpdated,
} from '../../modules/product/actions';
import { ProductsState } from './types';

const initialState: ProductsState = {
  loading: true,
  dataLoading: false,
  productsLoading: false,
  firstLoad: true,
  products: {
    data: [],
    total: 0,
  },
  product: null,
  warranties: {},
  categories: [],
  error: null,
  productHistory: [],
  userWarranties: {
    warranties: [],
    total: 0,
  },
  scanItem: {},
  scanHistory: {
    data: [],
    total: 0,
  },
  bulksLoading: false,
  bulks: {
    data: [],
    total: 0,
  },
  templatesLoading: false,
  templates: {
    data: [],
    total: 0,
  },
};

export const productReducer = createReducer(initialState, (handle) => [
  handle(setDataLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.dataLoading = payload;
    }),
  ),
  handle(setProductsLoadingAction, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.productsLoading = payload;
    }),
  ),
  handle(getScanHistory.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.scanHistory = payload;
    }),
  ),
  handle(getScanHistory.reset, (state) =>
    produce(state, (draft) => {
      draft.scanHistory = {
        data: [],
        total: 0,
      };
    }),
  ),
  handle(getProductsForCompany.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.products = payload;
    }),
  ),
  handle(getProductsForCompany.reset, (state) =>
    produce(state, (draft) => {
      draft.products = {
        data: [],
        total: 0,
      };
    }),
  ),
  handle(createWarranty.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userWarranties = {
        warranties: [...draft.userWarranties.warranties, payload],
        total: draft.userWarranties.total + 1,
      };
    }),
  ),
  handle(getProduct.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.product = payload;
    }),
  ),
  handle(getProduct.reset, (state) =>
    produce(state, (draft) => {
      draft.product = null;
    }),
  ),
  handle(getWarrianties.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userWarranties = payload;
    }),
  ),
  handle(getProductHistory.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.productHistory = payload.data;
    }),
  ),
  handle(getProductHistory.reset, (state) =>
    produce(state, (draft) => {
      draft.productHistory = [];
    }),
  ),
  handle(createProductForCompany.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.products = {
        data: [...draft.products.data, payload],
        total: draft.products.total + 1,
      };
    }),
  ),
  handle(setProductWarrantiesUpdated, (state, { payload }) =>
    produce(state, (draft) => {
      draft.warranties = payload;
    }),
  ),
  handle(getCategories.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.categories = payload;
    }),
  ),
  handle(setLoadingAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(setFirstLoadAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.firstLoad = payload;
    }),
  ),
  handle(getScanItem.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.scanItem = payload;
    }),
  ),
  handle(getProductBulksForCompany.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.bulks = payload;
    }),
  ),
  handle(getProductBulksForCompany.reset, (state) =>
    produce(state, (draft) => {
      draft.bulks = {
        data: [],
        total: 0,
      };
    }),
  ),
  handle(setProductBulksLoadingAction, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.bulksLoading = payload;
    }),
  ),

  handle(getProductTemplatesForCompany.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.templates = payload;
    }),
  ),
  handle(getProductTemplatesForCompany.reset, (state) =>
    produce(state, (draft) => {
      draft.templates = {
        data: [],
        total: 0,
      };
    }),
  ),
  handle(setProductTemplatesLoadingAction, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.templatesLoading = payload;
    }),
  ),
  handle(removeTemplate, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.templates.data = draft.templates.data.filter((template) => template._id !== payload);
    }),
  ),
]);
