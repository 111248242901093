import { createReducer } from 'deox';
import produce from 'immer';
import { setError } from '../../modules/errors/action';
import { commonAction, menuAction } from './actions';
import { CommonState } from './types';

const initialState: CommonState = {
  loading: false,
  error: null,
  activeProductMenu: true,
  postTransactionData: null,
};

export const commonReducer = createReducer(initialState, (handle) => [
  handle(commonAction.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = null;
    }),
  ),
  handle(commonAction.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      draft.data = payload.data;
    }),
  ),
  handle(commonAction.fail, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload;
    }),
  ),

  handle(setError, (state, { payload }) =>
    produce(state, (draft) => {
      draft.error = payload;
    }),
  ),
  handle(menuAction.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.activeProductMenu = payload;
    }),
  ),
]);
