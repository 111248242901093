import { FC } from 'react';
import { Input } from '../Input/Input';

type FieldInfo = {
  type: string;
  name: string;
  _id: string;
  sort: number;
};

interface IProps {
  setFieldValue: (name: string, value: string, fieldInfo: FieldInfo) => void;
  fieldInfo: FieldInfo;
  value: string | number;
}

const ProductField: FC<IProps> = ({ setFieldValue, fieldInfo, value }) => {
  const { type, name } = fieldInfo;
  return (
    <div className={`create-product__item ${type === 'textarea' ? 'fullWidth' : ''}`}>
      <Input
        type={type}
        name={name}
        placeholder={name}
        label={name}
        value={value}
        isTextArea={type === 'textarea'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue(name, e.target.value, fieldInfo)}
      />
    </div>
  );
};

export default ProductField;
