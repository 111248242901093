import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ActiveIcon } from '../../icons/active.svg';
import { ReactComponent as InActiveIcon } from '../../icons/inactive.svg';
import { Button } from '../Button/Button';
import Loader from '../Loader';
import styles from './SubscriptionCard.module.scss';

type PlanesItem = {
  isActive: boolean;
  name: string;
  limit: string;
};

interface SubscriptionCardProps {
  title: string;
  color: string;
  subtitle: string;
  price: string;
  planes: PlanesItem[];
  active: boolean;
  disabled?: boolean;
  isBusy?: boolean;
  unit: string;
  onClick?: (props: SubscriptionCardProps) => void;
}

const getPlaneName = (name, limit, t) => {
  const planObj = {
    CREATE_PRODUCT_LIMIT: limit ? t('_UpToXproductsPerMonth_', { amount: limit }) : t('_Create5FreeProducts_'),
    REVIEW_CV_LIMIT: `${t('_Review_')} ${limit || ''} ${t('_CV_')}`,
  };
  return planObj[name];
};

const SubscriptionCard = (props: SubscriptionCardProps) => {
  const { t } = useTranslation();
  const { title, subtitle, price, planes, color, onClick, active, isBusy, unit, disabled } = props;
  const [selected, setSelected] = useState(active);

  useEffect(() => {
    setSelected(active);
  }, [active]);

  return (
    <div className={styles.container}>
      <div className={styles.divider} style={{ backgroundColor: color }} />
      <h2 className={styles.title}>{title}</h2>
      <h3 className={styles.subtitle}>{subtitle}</h3>
      <div className={styles.price}>
        <span className={styles.priceItem}>{price}</span>/{unit}
      </div>
      <div className={styles.planes}>
        {planes
          ?.filter((plane) => plane.name !== 'REVIEW_CV_LIMIT') // hide review cv limit
          .map(({ name, isActive, limit }: PlanesItem, index: number) => (
            <div className={styles.planItem} key={index}>
              <div>{isActive ? <ActiveIcon /> : <InActiveIcon />}</div>
              <span className={styles.planItemText}>{getPlaneName(name, limit, t)}</span>
            </div>
          ))}
      </div>
      <div className={styles.buttonBlock}>
        {!!onClick &&
          (!isBusy ? (
            active && title === 'Free' ? (
              <div className={styles.currentPlan}>{t('_CurrentPlan_')}</div>
            ) : (
              <Button
                isPrimary={!selected}
                isPrimaryOutline={selected}
                className="login__button"
                type="submit"
                isDisabled={disabled}
                text={t(selected ? '_Unsubscribe_' : '_Subscribe_')}
                onClick={() => onClick(props)}
              />
            )
          ) : (
            <Loader className={styles.loader} />
          ))}
      </div>
    </div>
  );
};

export default SubscriptionCard;
