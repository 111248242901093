import moment from 'moment';
import { FC, memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Label from '../../../../components/common/Label';
import Text from '../../../../components/common/Text';
import StarRating from '../../../../components/StarRating';
import { useRedirect } from '../../../../hooks/useRedirect';
import { ReactComponent as CalendarIcon } from '../../../../icons/calendar.svg';
import { ReactComponent as SuccessIcon } from '../../../../icons/success.svg';
import { ReactComponent as NoProductImage } from './../../../../icons/no-product-image.svg';
import styles from './styles.module.scss';

const IndividualProductRow: FC<any> = memo(({ item, fromWarranty, isCV_item, fromScanHistory }) => {
  const { t } = useTranslation();
  const redirectProduct = useRedirect(`/products/${item?.alias}`);
  const redirectCv = useRedirect(`/cv/${item?.alias}/info`);
  const handleRedirect = () => {
    if (!fromWarranty && !isCV_item) {
      redirectProduct();
    } else if (isCV_item) {
      redirectCv();
    }
  };
  const name = useMemo(() => {
    if (isCV_item) return `${item.firstName} ${item.lastName}`;
    return item.name;
  }, [isCV_item, item]);
  const description = useMemo(() => {
    if (isCV_item) return item.position;
    return item.description;
  }, [isCV_item, item]);

  const renderIcon = () => {
    // !isCV_item
    if (fromScanHistory) {
      if (item.status === 'authentic') {
        return <SuccessIcon />;
      } else {
        return null;
      }
    }
  };

  return (
    <div className={styles.individualProductContainer} onClick={handleRedirect}>
      <div className={styles.individualProductContainer__leftCol}>
        <div className={styles.individualProductContainer__leftCol__imgContainer}>
          {item.info.images?.length ? (
            <img
              src={
                item.info.images
                  ? process.env.REACT_APP_ATTACHMENTS_DOMAIN +
                    (item.info.images[0]?.src || item.info.images[0]?.location)
                  : ''
              }
              alt={item.name}
            />
          ) : (
            <NoProductImage />
          )}
        </div>
      </div>
      <div className={styles.individualProductContainer__rightCol}>
        <div className={styles.individualProductContainer__rightCol__title}>
          <Text type="h5" color="dark-primary" fontWeight="bold">
            {name}
          </Text>
          {renderIcon()}
        </div>
        <Text
          type="subtitle_s"
          color="ultra-dark-gray"
          fontWeight="regular"
          className={styles.individualProductContainer__rightCol__desc}
        >
          {description}
        </Text>
        <div className={styles.individualProductContainer__rightCol__footer}>
          <div className={styles.individualProductContainer__rightCol__footer__date}>
            <CalendarIcon />
            <Text color="gray-darkness" type="body_m" fontWeight="bold">
              {moment(item.createdAt).format('DD MMM YYYY')}
            </Text>
          </div>
          <div className={styles.individualProductContainer__rightCol__footer__label}>
            {fromWarranty ? (
              !isNaN(moment(item.warranty?.endDate).diff(moment(new Date()), 'days')) ? (
                <Text color="dark-primary" type="body_m" fontWeight="bold">
                  {moment(item.warranty.endDate).diff(moment(new Date()), 'days')} {t('_days_left_')}
                </Text>
              ) : null
            ) : item.status ? (
              <Label status={item.status} />
            ) : (
              <StarRating readonly initialRating={item.rating} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
});

export default IndividualProductRow;
