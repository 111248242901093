import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Loader from '../../components/Loader';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { CommonApi } from '../../services/api';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';

const KybVerificationButtons = ({ payload }) => {
  const history = useHistory();
  const [processing, setProcessing] = useState(false);
  const [orgId, setOrgId] = useState('');
  const dispatch = useDispatch();
  const fromOrgPage = window.location.search.includes('fromOrgPage');
  const { userData } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (payload.id) {
      setOrgId(payload.id);
    }
  }, [payload.id]);

  const createOrg = async (status?: boolean) => {
    try {
      const orgResponse = await OrganizationApi.addOrganization(payload);
      setOrgId(orgResponse.data._id);

      const res = await CommonApi.setIdentity({
        _id: orgResponse.data._id,
        kybStatus: status ? 'accepted' : 'declined',
      });
      if (CommonHelpers.isOkStatus(res.status)) {
        return Toast.success('updated');
      }
    } catch (e) {
      //
    } finally {
      setProcessing(false);
    }
  };
  const handleSimulateKYB = async (status?: boolean) => {
    if (!orgId) {
      await createOrg(status);
    }

    if (orgId) {
      setProcessing(true);
      try {
        const res = await CommonApi.setIdentity({ _id: orgId, kybStatus: status ? 'accepted' : 'declined' });
        if (CommonHelpers.isOkStatus(res.status)) {
          return Toast.success('updated');
        }
      } catch (e) {
        //
      } finally {
        setProcessing(false);
      }
    }
  };

  const onNext = async () => {
    if (fromOrgPage) {
      history.push('/products');
      return;
    }

    if (!userData.info.firstName) {
      history.push('/profile-details');
      return;
    }

    if (userData.kyc_status === 'not verified') {
      sessionStorage.setItem('userData', JSON.stringify(userData));
      history.push('/intro-kyc');
      return;
    }

    if (localStorage.getItem('authFlow') === 'registration') {
      history.push('/wallet-creation');
      return;
    }

    if (!localStorage.getItem('MNEMONIC_MODEL')) {
      history.push('/wallet-connect');
      return;
    }

    const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
    const mnemonic = mnemonicModel.mnemonic;
    dispatch(
      saveWalletAction({
        mnemonic,
        onSuccess: () => {
          dispatch(networkInitStateAction());
          history.push('/products');
        },
        onError: () => {
          history.push('/wallet-connect');
        },
      }),
    );
  };

  return (
    <div
      style={{
        position: 'absolute',
        left: 10,
        top: 300,
        height: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
      }}
    >
      <Button isPrimary text={'Simulate KYB Approved'} onClick={() => handleSimulateKYB(true)} />
      <Button isPrimary text={'Simulate KYB Declined'} onClick={() => handleSimulateKYB(false)} />
      <Button isPrimary text={'Next'} onClick={onNext} />
      {processing && <Loader />}
    </div>
  );
};

export default KybVerificationButtons;
