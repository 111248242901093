import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Text from '../../../components/common/Text';
import Loader from '../../../components/Loader';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg';
import { UserManagementInfoType } from '../../../modules/cv/types';
import { OrganizationApi } from '../../../services/api/OrganizationApi';
import { CommonHelpers } from '../../../utils/commonHelpers';
import './UserManagementProfile.scss';

function formatDate(date) {
  if (!date) return '';
  const [year, month, day] = date.split('-');
  return `${day}/${month}/${year}`;
}

const UserManagementProfile = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const params: any = useParams();
  const { userData } = useSelector((state: any) => state.user);
  const [data, setData] = useState<UserManagementInfoType>({});
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const fetchData = useCallback(async () => {
    setIsBusy(true);
    try {
      const res = await OrganizationApi.getUserManagmentInfo(
        userData.organizationId || userData.inviteCode,
        params.userId,
      );
      setIsBusy(false);
      setData(res);
    } catch (e) {
      setIsBusy(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="page-header UserManagementProfile">
      <div className="signup__back-button" onClick={() => history.goBack()}>
        <ArrowIcon />
        <span>{t('_Back_')}</span>
      </div>
      <Text type="h1" className="page__title">
        {t('_Profile_')}
      </Text>
      {isBusy ? (
        <div className="flexible aCenter jCenter loader-content">
          <Loader />
        </div>
      ) : (
        <div className="info-block">
          <div className="flexible row-item aCenter">
            {data?.info?.fullName && <div className="name">{data?.info?.fullName}</div>}
            <div className="key status" style={{ backgroundColor: CommonHelpers.getStatusColor(data?.status) }}>
              {CommonHelpers.getStatusName(data?.status)}
            </div>
          </div>
          <div className="flexible row-item">
            <div className="key-value flexible vertical">
              <div className="key">{dayjs(data?.updatedAt).format('DD/MM/YYYY HH:mm')}</div>
            </div>
          </div>
          {data?.role && (
            <div className="flexible row-item">
              <div className="key-value flexible vertical">
                <div className="key">{t('_OrganisationRole_')}</div>
                <div className="value">{data?.role}</div>
              </div>
            </div>
          )}
          {data?.info?.birthdate && (
            <div className="flexible row-item">
              <div className="key-value flexible vertical">
                <div className="key">{t('_DateOfBirth_')}</div>
                {data?.info?.birthdate && <div className="value">{formatDate(data?.info?.birthdate)}</div>}
              </div>
            </div>
          )}
          <div className="flexible row-item">
            <div className="key-value flexible vertical">
              <div className="key">{t('_Email_')}</div>
              <div className="value">{data?.email}</div>
            </div>
          </div>
          {data?.info?.address && (
            <div className="flexible row-item">
              <div className="key-value flexible vertical">
                <div className="key">{t('_Address_')}</div>
                <div className="value">{data?.info?.address}</div>
              </div>
            </div>
          )}
          {data?.info?.phone && (
            <div className="flexible row-item">
              <div className="key-value flexible vertical">
                <div className="key">{t('_Phone_')}</div>
                <div className="value">{data?.info?.phone}</div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserManagementProfile;
