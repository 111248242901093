import cn from 'classnames';
import './Loader.scss';

interface LoaderProps {
  className?: any;
}

const Loader = ({ className }: LoaderProps) => (
  <div className={cn('Loader', { className })}>
    <div className="lds-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
