import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import AccountService from '../../services/symbol/AccountService';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { Toast } from '../../utils/toastHelper';
import './styles.scss';

const ConnectWallet = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [mnemonic, setMnemonic] = useState('');
  const [isValidMnemonic, setIsValidMnemonic] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(true);
    dispatch(
      saveWalletAction({
        mnemonic,
        onSuccess: () => history.push('/products'),
        onError: () => {
          Toast.error(t('_SeedphraseDoesNotMatch_'));
          setIsLoading(false);
        },
      }),
    );
    dispatch(networkInitStateAction());
  };

  useEffect(() => {
    (async () => {
      if (localStorage.getItem('authFlow') === 'registration') {
        history.push('/wallet-creation');
        return;
      }

      setIsLoading(true);
      const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
      if (mnemonicModel) {
        dispatch(
          saveWalletAction({
            mnemonic: mnemonicModel.mnemonic,
            onSuccess: () => history.push('/products'),
          }),
        );
        dispatch(networkInitStateAction());
      }
      setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    setIsValidMnemonic(AccountService.isValidMnemonic(mnemonic));
  }, [mnemonic]);

  return (
    <div className="wallet-creation">
      <div className="page-header">
        <div className={'signup__back-button'} onClick={() => history.push('/')}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>

        <h2 className="auth__title">{t('_ConnectWallet_')}</h2>
        <p className="auth__subtitle">{t('_SeedPhraseIsMissing_')}</p>
        <p className="auth__description">{t('_DidntFindSeedPhrase_')}</p>
      </div>
      <div className="wallet-creation__form-wrapper">
        <Input
          type="text"
          value={mnemonic}
          label={t('_YourSeedPhrase_')}
          placeholder={t('_EnterYourSeedPhrase_')}
          name="mnemonic"
          onChangeTextArea={(e: React.ChangeEvent<HTMLTextAreaElement>) => setMnemonic(e.target.value)}
          isTextArea
        />

        <p className="wallet-creation__warning">
          <span>{t('_IfYouLoseYourWallet_')}</span>
          <span>{t('_WhenRestoringOldWallet_')}</span>
        </p>
        <div className="wallet-connect-buttons-container">
          <Button
            text={t('_Connect_')}
            isDisabled={!isValidMnemonic}
            onClick={onSubmit}
            isPrimary
            isLoading={isLoading}
          />
          <Button
            text={t('_CreateNewWallet_')}
            onClick={() => {
              history.push('/wallet-creation');
            }}
            isGhost
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectWallet;
