import { ProductState } from '../constants';

export const mapProductDetailsToPrdouctState = (data: any): any => {
  const { images } = data.info;

  const imagesToDisplay =
    Array.isArray(images) && images.length > 0 ? images.map((image) => ({ src: image.location })) : [];

  return {
    warranty: {
      label: data.warranty.title,
      value: data.warranty._id,
    },
    productName: data.name,
    productCategory: {
      label: '',
      value: data.categoryId,
    },
    description: data.description,
    images: imagesToDisplay,
  };
};

export const createEmptyProductState = (): any => ({
  warranty: {
    label: '',
    value: '',
  },
  productCategory: {
    label: '',
    value: '',
  },
  productName: '',
  description: '',
  images: [],
  fields: {},
});

export const createRequestPayloadForProduct = (productState: ProductState) => {
  const { warranty, productCategory, productName, description, fields, images } = productState;
  const newFields = [];
  if (fields) {
    Object.keys(fields).forEach((key) => {
      if (fields[key] && fields[key].value) {
        delete fields[key]._id;
        newFields.push(fields[key]);
      }
    });
  }
  const resp = {
    warrantyId: warranty.value !== 'add' && warranty.value,
    ...(productCategory !== null && productCategory.value !== '' && { categoryId: productCategory.value || undefined }),
    name: productName,
    description: description,
    info: {
      images: images && Array.isArray(images) ? images : null,
      fields: newFields,
    },
  };
  if (!resp.warrantyId) delete resp.warrantyId;
  if (!resp.description) delete resp.description;
  return resp;
};
