import { t } from 'i18next';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Modal } from '../../../../components/Modal/Modal';
import styles from './FreeAccountModal.module.scss';

interface ExportProductKeysModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const FreeAccountModal = ({ isOpen, onClose }: ExportProductKeysModalProps) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.modalContent}>
        <Text type="body_l" className={styles.text}>
          {t('_Free_Account_Modal_Text_')}
        </Text>

        <Button onClick={onClose} isPrimary>
          {t('_OK_')}
        </Button>
      </div>
    </Modal>
  );
};

export default FreeAccountModal;
