import cn from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/common/Text';
import Loader from '../../components/Loader';
import NoItem from '../../components/NoItem';
import Pagination from '../../components/Pagination/Pagination';
import { getWarrianties } from '../../modules/product/actions';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import styles from './styles.module.scss';
import WarrantyRow from './WarrantyRow';

const Warranties: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    dataLoading,
    userWarranties: { warranties, total },
  } = useSelector((state: any) => state.products);
  const { username: manufacturerId } = useSelector((state: any) => state.auth);
  const { userData } = useSelector((state: any) => state.user);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [range, setRange] = useState([0, 7]);

  const fetchData = (manufacturerId) => {
    dispatch(
      getWarrianties.req({
        params: { manufacturerId },
        isAPI_One: true,
        range: range,
      }),
    );
  };

  const setNewRange = (page) => {
    const firstPageIndex = page === 0 ? 0 : page * 7;
    setRange([firstPageIndex, 7]);
    setCurrentPage(page + 1);
  };

  const fetchUserByOrganization = useCallback(async () => {
    const res = await OrganizationApi.getUserByOrganization(userData.inviteCode, '?filter[role]=owner');
    res?.data?.[0] && fetchData(res?.data?.[0]?.userId);
  }, [userData.inviteCode]);

  const fetchWarranties = () => {
    isEmployee ? fetchUserByOrganization() : fetchData(manufacturerId);
  };

  useEffect(() => {
    fetchWarranties();
  }, [manufacturerId, range]);

  if (warranties[0] && !warranties[0].warranty) {
    return null;
  }

  return (
    <>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_WarrantyRequest_')}
      </Text>
      {dataLoading ? (
        <div className="loader_wrapper">
          <Loader />
        </div>
      ) : warranties.length === 0 ? (
        <NoItem />
      ) : (
        <div className={styles.tableView}>
          <div className={styles.warrantiesTableGrid}>
            <Text
              className={styles.warrantiesTableGrid__md_col}
              color="ultra-dark-gray"
              type="subtitle_s"
              fontWeight="regular"
            >
              {t('_Product_')}
            </Text>
            <Text
              className={styles.warrantiesTableGrid__md_col}
              color="ultra-dark-gray"
              type="subtitle_s"
              fontWeight="regular"
            >
              {t('_ProductOwner_')}
            </Text>
            <Text
              className={styles.warrantiesTableGrid__md_col}
              color="ultra-dark-gray"
              type="subtitle_s"
              fontWeight="regular"
            >
              {t('_WarrantyPeriod_')}
            </Text>
            <Text
              className={styles.warrantiesTableGrid__md_col}
              color="ultra-dark-gray"
              type="subtitle_s"
              fontWeight="regular"
            >
              {t('_WarrantyExpireDate_')}
            </Text>
            <Text
              color="ultra-dark-gray"
              type="subtitle_s"
              fontWeight="regular"
              className={cn(styles.warrantiesTableGrid__sm_col, styles.status)}
            >
              {t('_Status_')}
            </Text>
          </div>
          {warranties?.map((warranty) => {
            return (
              <WarrantyRow key={warranty._id} item={warranty} manufacturerId={manufacturerId} fetchData={fetchData} />
            );
          })}
        </div>
      )}
      <div className="pagination-container">
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={total}
          pageSize={7}
          onPageChange={(page: number) => setNewRange(page - 1)}
        />
      </div>
    </>
  );
};

export default Warranties;
