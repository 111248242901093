import _ from 'lodash';
import moment from 'moment';
import { FC, memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Label from '../../../../components/common/Label';
import Text from '../../../../components/common/Text';
import { Status } from '../../../../modules/product/types';
import { ProductActions } from '../ProductActions';
import { ReactComponent as NoProductImage } from './../../../../icons/no-product-image.svg';

const BusinessProductRow: FC<any> = memo(({ item, openRemoveProduct, openEditProduct, productsKeys }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push(`/products/${item.alias}`);
  };

  const showActions = useCallback(() => {
    if (item.status !== Status.Authentic) return false;
    if (productsKeys && !_.isEmpty(productsKeys)) {
      return item._id in productsKeys || !!item.privateQrCode;
    }
    return !!item.privateQrCode;
  }, [productsKeys]);

  return (
    <div className="products__item">
      <div className="products__item__first_col" onClick={handleClick}>
        <div className="products__preview products__item__first_col-sm-col">
          {item.info.images?.length ? (
            <img
              src={
                item.info.images
                  ? process.env.REACT_APP_ATTACHMENTS_DOMAIN +
                    (item.info.images[0]?.src || item.info.images[0]?.location)
                  : ''
              }
              alt={item.name}
            />
          ) : (
            <NoProductImage />
          )}
        </div>
        <Text
          id="product-id"
          type="subtitle_s"
          color="dark-primary"
          fontWeight="regular"
          className="products__item__first_col-lg-col"
        >
          {item.name}
        </Text>
        <Text
          id="product-id"
          type="subtitle_s"
          color="dark-primary"
          fontWeight="regular"
          className="products__item__first_col-lg-col"
        >
          {item.category ? item.category.name : t('_NoCategory_')}
        </Text>
        <Text
          id="product-id"
          className="products__item__first_col-md-col"
          type="subtitle_s"
          color="dark-primary"
          fontWeight="regular"
        >
          {moment(item.createdAt).format('DD/MM/YYYY')}
        </Text>
        <Label id="product-id" status={item.status} classname="products__item__first_col-products__status" />
      </div>
      <div>
        <ProductActions
          product={item}
          onDeleteClicked={openRemoveProduct}
          onEditClicked={openEditProduct}
          isEditable={showActions()}
          alias={item.alias}
        />
      </div>
    </div>
  );
});

export default BusinessProductRow;
