import { FC } from 'react';
import Text from '../Text';
import styles from './styles.module.scss';

interface IProps {
  step: number;
  isLast?: boolean;
  text: string;
}

const Step: FC<IProps> = ({ step, isLast, text }) => {
  return (
    <div className={styles.stepContainer}>
      <div className={styles.stepNumber}>
        <Text color="primary-blue" type="subtitle_m">
          {step}
        </Text>
      </div>
      <Text color="ultra-dark-gray" type="subtitle_m" className={styles.stepText}>
        {text}
      </Text>
      {!isLast && <div className={styles.verticalDashed} />}
    </div>
  );
};

export default Step;
