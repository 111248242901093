import cn from 'classnames';
import _ from 'lodash';
import React from 'react';
import styles from './styles.module.scss';

export interface ITextProps {
  children: React.ReactNode | string | number;
  type?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'subtitle_m' | 'subtitle_s' | 'body_l' | 'body_m' | 'caption';
  fontWeight?: 'light' | 'medium' | 'bold' | 'regular' | 'light_bold' | 'bolder';
  textTransform?: 'lowercase' | 'uppercase' | 'capitalize';
  align?: 'inherit' | 'left' | 'center' | 'right' | 'justify';
  className?: string;
  display?: 'block' | 'inline';
  as?: string;
  color?:
    | 'inherit'
    | 'primary'
    | 'dark-primary'
    | 'primary-blue'
    | 'secondary-dark-blue'
    | 'light-primary'
    | 'ultra-light-primary'
    | 'orange'
    | 'red'
    | 'green'
    | 'black'
    | 'ultra-dark-gray'
    | 'dark-gray'
    | 'gray'
    | 'light-gray'
    | 'ultra-light-gray'
    | 'white'
    | 'gray-dark'
    | 'gray-darkness'
    | 'blue-lighter'
    | 'system-blue'
    | 'tertiary-orange'
    | 'system-error';
  onClick?: (t?: any) => void;
  pt?: boolean;
  noWrap?: boolean;
  height?: string;
  disableCheckWindows?: boolean;
  id?: string;
}

const Text = React.forwardRef<HTMLSpanElement, ITextProps>(
  (
    {
      children,
      type,
      as,
      fontWeight,
      textTransform,
      align,
      display,
      className,
      color,
      onClick,
      pt,
      noWrap,
      height,
      id,
    },
    ref,
  ) => {
    const tagMapping = {
      subtitle_m: 'h5',
      subtitle_s: 'h6',
      body_l: 'p',
      body_m: 'p',
      caption: 'p',
    };

    const Tag = as ?? (tagMapping[type] || type || 'p');

    let modifiedChildren: React.ReactNode | string | number;

    if (textTransform === 'capitalize' && typeof children === 'string') {
      modifiedChildren = _.capitalize(children);
    }

    const textClass = cn(
      styles.text,
      {
        [styles[`text_type_${type}`]]: type,
        [styles[`text_fontWeight_${fontWeight}`]]: fontWeight,
        [styles[`text_textTransform_${textTransform}`]]: textTransform,
        [styles[`text_align_${align}`]]: align,
        [styles[`text_display_${display}`]]: display,
        [styles[`text_color_${color}`]]: color,
        [styles[`text_pt`]]: pt,
        [styles.text_nowrap]: noWrap,
      },
      className,
    );

    const hasHeight = !!height;

    return (
      <Tag ref={ref} className={textClass} onClick={onClick} id={id}>
        {hasHeight ? <div style={{ height }}>{modifiedChildren || children}</div> : modifiedChildren || children}
      </Tag>
    );
  },
);

export default Text;
