/* eslint-disable no-console */
import React from 'react';
import styles from './LayoutAnonymous.module.scss';

export const LayoutAnonymous: React.FC = (props) => {
  // const user = useSelector((state: any) => state?.auth);
  // const redirect = useRedirect('/products');
  // useEffect(() => {
  //   if (user?.signInUserSession?.accessToken?.jwtToken) {
  //     redirect();
  //   }
  // }, [user]);

  return (
    <div className={styles.auth}>
      {/* <VeritiseIcon className="veritise-icon" /> */}
      {props.children}
    </div>
  );
};
