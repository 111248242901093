import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import PhoneInput from 'react-phone-input-2';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { Button } from '../../../../components/Button/Button';
import { Input } from '../../../../components/Input/Input';
import { ReactComponent as CloseIcon } from '../../../../icons/close.svg';
import { createWarranty } from '../../../../modules/product/actions';
import { Toast } from '../../../../utils/toastHelper';
import { CreateWarrantyValidation as validationSchema } from '../../../../utils/validations';
import { Option, periodOptions } from '../../constants';

interface WarrantyModalProps {
  open: boolean;
  onClose: (warranty: any) => void;
  onSubmit: () => void;
}

export const WarrantyModal = (props: WarrantyModalProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    period: '',
  });
  const onSubmit = async (data: any) => {
    const { title, period, description, email, phone_number, address } = data;
    if (!title) {
      setErrors((prevState) => ({
        ...prevState,
        title: t('_TitleCanNotbeEmpty_'),
      }));
    }
    if (!period) {
      setErrors((prevState) => ({
        ...prevState,
        period: t('_PeriodCanNotbeEmpty_'),
      }));
    }
    const payload = {
      title,
      period: period?.value,
      description,
      info: {
        ...(phone_number !== '' && { phone: '+' + phone_number }),
        ...(email !== '' && { email }),
        ...(address !== '' && { address }),
      },
    };

    if (!payload.description) delete payload.description;
    setLoading(true);
    dispatch(
      createWarranty.req({
        params: payload,
        onSuccess: (warranty) => {
          Toast.success(t('_WarrantyHasBeenCreatedSuccessfully_'));
          setLoading(false);
          props.onClose(warranty);
        },
        onFail: () => {
          setLoading(false);
        },
      }),
    );
  };
  const { setFieldValue, values } = useFormik({
    initialValues: {
      title: '',
      period: null,
      description: '',
      email: '',
      phone_number: '',
      address: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });
  const handleChange = (key, val) => {
    if (errors[key]) {
      setErrors({
        ...errors,
        [key]: '',
      });
    }
    setFieldValue(key, val);
  };
  return (
    <Modal
      isOpen={props.open}
      onRequestClose={props.onClose}
      contentLabel={t('_CreateWarranty_')}
      className="create-warranty__modal"
      overlayClassName="create-warranty__modal-overlay"
      ariaHideApp={false}
    >
      <h1 className="modal__title">{t('_CreateWarranty_')}</h1>
      <CloseIcon className="create-warranty__modal-close" onClick={props.onClose} />
      <div>
        <div className="create-warranty__row">
          <div className="create-warranty__item">
            <Input
              type="text"
              name="title"
              isRequired
              placeholder={t('_WarrantyName_')}
              label={t('_WarrantyName_')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange('title', e.target.value)}
            />
            {errors.title && <div className="errors">{errors.title}</div>}
          </div>
          <div className="create-warranty__item">
            <span>{t('_Period_')}</span>
            <span className="create-warranty__item__period">*</span>
            <Select
              value={values.period}
              onChange={(selectedOption: Option | any) => handleChange('period', selectedOption)}
              options={periodOptions}
              name="warrantyPeriod"
              className="input-select warranty-period-select"
              classNamePrefix="input-select"
              placeholder={t('_SelectFromTheList_')}
            />
            {errors.period && <div className="errors">{errors.period}</div>}
          </div>
        </div>
        <div className="create-warranty__row create-warranty__row-custom">
          <div className="create-warranty__row-left">
            <div className="create-warranty__item">
              <Input
                type="text"
                name="email"
                label={t('_Email_')}
                placeholder={t('_Email_')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange('email', e.target.value);
                }}
              />
            </div>
            <div className="create-warranty__item">
              <div className="input phone-row-item">
                <p>{t('_PhoneNumber_')}</p>
                <PhoneInput
                  country={'fr'}
                  onChange={(phone_number) => handleChange('phone_number', phone_number)}
                  value={values.phone_number}
                />
              </div>
            </div>
            <div className="create-warranty__item">
              <Input
                type="text"
                name="address"
                placeholder={'enter address'}
                label={t('_Address_')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange('address', e.target.value);
                }}
              />
            </div>
          </div>
          <div className="create-warranty__row-right">
            <div className="create-warranty__item">
              <Input
                type="text"
                label={t('_Description_')}
                placeholder={t('_Write_a_description_')}
                isTextArea
                name="description"
                onChangeTextArea={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                  handleChange('description', e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="create-product__row">
          <Button type="submit" isPrimary text={t('_Create_')} onClick={() => onSubmit(values)} isLoading={loading} />
        </div>
      </div>
    </Modal>
  );
};
