import cn from 'classnames';
import { FC } from 'react';
import styles from './styles.module.scss';

interface IProps {
  isOdd?: boolean;
  children: any;
}

const Rectangle: FC<IProps> = ({ children, isOdd }) => {
  const clipEachClass = isOdd ? styles.clipEach_odd : styles.clipEach;
  return (
    <div className={styles.clipWrap}>
      <div className={cn(clipEachClass, styles.borderStyle)}>
        <div className={styles.absoulte}>{children}</div>
      </div>
    </div>
  );
};
export default Rectangle;
