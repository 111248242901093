import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CommonHelpers } from '../../utils/commonHelpers';
import BusyLoader from '../BusyLoader';
import { Button } from '../Button/Button';
import Text from '../common/Text';
import { Input } from '../Input/Input';
import { Modal } from '../Modal/Modal';
import StarRating from '../StarRating';
import './EmployeeAssessment.scss';

interface EmployeeAssessment {
  title?: string;
  emailCheck?: boolean;
  inputValue?: string;
  inputLabel?: string;
  description?: string;
  inputPlaceHolder?: string;
  textareaLabel?: string;
  textareaPlaceHolder?: string;
  buttonText?: string;
  withoutRate?: boolean;
  onClose: () => void;
  onRequestCheck: (data: any, isApprove: boolean) => void;
  isBusyRequest: boolean;
  isApprove?: boolean;
  isCommentAdditional?: boolean;
}

const EmployeeAssessment = ({
  title,
  onClose,
  isApprove,
  inputLabel,
  inputValue,
  buttonText,
  emailCheck,
  withoutRate,
  description,
  textareaLabel,
  isBusyRequest,
  onRequestCheck,
  inputPlaceHolder,
  textareaPlaceHolder,
}: EmployeeAssessment) => {
  const { t } = useTranslation();
  const [positionName, setPositionName] = useState<string>('');
  const [comment, setComment] = useState<string>('');
  const [rating, setRating] = useState<number>(null);

  useEffect(() => {
    inputValue && setPositionName(inputValue);
  }, [inputValue]);

  const isValid = useMemo(
    () => (emailCheck ? CommonHelpers.isEmail(positionName) : !!positionName),
    [emailCheck, positionName, withoutRate],
  );

  return (
    <Modal open onClose={onClose}>
      <BusyLoader isBusy={isBusyRequest} className="EmployeeAssessment flexible vertical aCenter">
        <Text type="h1">{title || t('_EmployeeAssessment_')}</Text>
        {description && <div className="description">{description}</div>}
        <Input
          type="text"
          value={positionName}
          label={inputLabel || t('_YourPositionInTheCompany_')}
          placeholder={inputPlaceHolder || t('_YourPositionInTheCompany_')}
          onChange={({ target }) => setPositionName(target.value)}
        />
        <Input
          type="text"
          isTextArea
          value={comment}
          label={textareaLabel || t('_YourComment_')}
          placeholder={textareaPlaceHolder || t('_LeaveCommentAboutTheEmployee_')}
          onChangeTextArea={({ target }) => setComment(target.value)}
        />
        {!withoutRate && (
          <div className="flexible vertical aCenter rating-block">
            <Text type="h3">{t('_RateTheEmployee_')}</Text>
            <StarRating initialRating={rating} onChange={setRating} />
          </div>
        )}
        <Button
          onClick={() =>
            onRequestCheck(
              {
                positionName,
                ...(!!comment?.length && { comment }),
                rating,
              },
              isApprove,
            )
          }
          isPrimary
          className="send-work"
          isDisabled={!isValid}
        >
          {buttonText || t('_LeaveAnAssessment_')}
        </Button>
      </BusyLoader>
    </Modal>
  );
};

export default EmployeeAssessment;
