import styles from './Switcher.module.scss';

interface SwitcherProps {
  isActive?: boolean;
  onChange?: (isActive: boolean) => void;
}

const Switcher = ({ isActive, onChange }: SwitcherProps) => {
  return (
    <div className={styles.container} onClick={() => onChange(!isActive)}>
      <div className={styles.switch} style={{ ...(!isActive && { left: '2px' }) }} />
    </div>
  );
};

export default Switcher;
