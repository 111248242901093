import { createReducer } from 'deox';
import produce from 'immer';
import {
  getMyCv,
  setCvRequests,
  setCvWorkExperience,
  setMyCv,
  setOrganizationsCVList,
  setUserManagmentInfo,
  setUserManagmentList,
} from './actions';
import { CvStateType } from './types';

const initialState: CvStateType = {
  myCV: {},
  workexperiences: {},
  cvRequests: {},
  cvList: {},
  userManagmentList: {},
  userManagmentInfo: {},
  organizationCvList: {},
};

export const cvReducer = createReducer(initialState, (handle) => [
  handle(setMyCv.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.myCV = payload;
    }),
  ),
  handle(setOrganizationsCVList.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.organizationCvList = payload;
    }),
  ),
  handle(getMyCv.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cvList = payload;
    }),
  ),
  handle(setCvWorkExperience.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.workexperiences = payload;
    }),
  ),
  handle(setCvRequests.success, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cvRequests = payload;
    }),
  ),
  handle(setUserManagmentList.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userManagmentList = payload;
    }),
  ),
  handle(setUserManagmentInfo.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.userManagmentInfo = payload;
    }),
  ),
]);
