import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import Text from '../../components/common/Text';
import { VERITISE_URL } from '../../utils/constants';
import { ReactComponent as LogoIcon } from './../../icons/Veritise-Blue.svg';
import styles from './styles.module.scss';

const About = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirect = useCallback((path) => {
    history.push(`/${path}`);
  }, []);
  return (
    <>
      <div className="page-header">
        <Text type="h1" className="page__title Visby_Bold authenticated-page-title" fontWeight="bolder">
          {t('_About_The_APP_')}
        </Text>
      </div>

      <div className={styles.aboutPageContent}>
        <LogoIcon />
        <div className={styles.aboutPageContent__text}>
          <Text color="gray-darkness" align="center" type="h5" className={styles.version}>
            App version: 1.3.8
          </Text>
          <Text color="ultra-dark-gray" type="body_m" fontWeight="regular" align="center">
            {t('_Veritise_about_desc_')}
            <a className="email-text" rel="noreferrer" href={VERITISE_URL} target="_blank">
              {' '}
              veritise.com
            </a>
          </Text>
        </div>

        <div className={styles.aboutPageContent__buttons}>
          <Button appearence="white" text={t('_Tutorial_')} onClick={() => redirect('tutorial')} />
          <a href="https://veritise.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">
            <Button appearence="white" text={t('_TermsAndConditions_')} onClick={() => redirect('terms')} />
          </a>

          <a href="https://veritise.com/privacy-policy-2/" target="_blank" rel="noopener noreferrer">
            <Button appearence="white" text={t('_PrivacyPolicy_')} onClick={() => redirect('privacy')} />
          </a>
        </div>
      </div>
    </>
  );
};

export default About;
