import { createAction } from 'deox';

export const loadAllDataAction = createAction('account/LOAD_ALL_DATA', (res) => (data?: boolean) => res(data));
export const setSelectedAccountAddressAction = createAction(
  'account/SET_SELECTED_ACCOUNT_ADDRESS',
  (res) => (address?: string) => res(address),
);
export const setLoadingAction = createAction('account/SET_LOADING', (res) => (status?: boolean) => res(status));
export const setBalanceAction = createAction('account/SET_BALANCE', (res) => (balance: number) => res(balance));
export const setOwnedMosaicsAction = createAction('account/SET_OWNED_MOSAICS', (res) => (mosaics: []) => res(mosaics));
export const setCosignatoryOfAction = createAction(
  'account/SET_COSIGNATORY_OF',
  (res) => (cosignatoryOf: string[]) => res(cosignatoryOf),
);
export const setIsMultisigAction = createAction('account/SET_IS_MULTISIG', (res) => (status: boolean) => res(status));
export const loadBalanceAction = createAction('account/LOAD_BALANCE');
export const loadCosignatoryOfAction = createAction('account/LOAD_COSIGNATORY_OF');
