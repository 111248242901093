/* eslint-disable no-console */
import { put, select, takeLatest } from 'redux-saga/effects';
import { GlobalListener } from '../../redux/store';
import AccountService from '../../services/symbol/AccountService';
import { harvestingInitAction } from '../harvesting/actions';
import { initTransactionAction } from '../transaction/actions';
import { setLoading } from '../wallet/actions';
import {
  loadAllDataAction,
  loadBalanceAction,
  loadCosignatoryOfAction,
  setBalanceAction,
  setCosignatoryOfAction,
  setIsMultisigAction,
  setLoadingAction,
  setOwnedMosaicsAction,
  setSelectedAccountAddressAction,
} from './actions';

function* loadAllDataSaga(reset: ReturnType<typeof loadAllDataAction>) {
  const { wallet, network, account } = yield select();
  try {
    yield put(setLoading(35));
    yield put(setLoadingAction(true));
    // @ts-ignore
    const address = yield AccountService.getAddressByAccountModelAndNetwork(wallet.selectedAccount, network.network);
    yield put(setSelectedAccountAddressAction(address));
    yield put(initTransactionAction());
    if (reset) {
      yield put(setBalanceAction(0));
      yield put(setOwnedMosaicsAction([]));
    }
    if (account.refreshingObs) {
      account.refreshingObs.unsubscribe();
    }
    yield put(loadBalanceAction());
    yield put(harvestingInitAction());
    //yield put(loadCosignatoryOfAction());
  } catch (e) {
    console.error(e);
  }
}
function* loadBalanceSaga() {
  const { network, wallet } = yield select();
  // @ts-ignore
  const address = yield AccountService.getAddressByAccountModelAndNetwork(wallet.selectedAccount, network.network);
  const { balance, ownedMosaics } = yield AccountService.getBalanceAndOwnedMosaicsFromAddress(
    address,
    network.selectedNetwork,
  );
  yield put(setBalanceAction(balance));
  yield put(setOwnedMosaicsAction(ownedMosaics));
}

function* loadCosignatoryOfSaga() {
  const { network, wallet } = yield select();
  // @ts-ignore
  const address = yield AccountService.getAddressByAccountModelAndNetwork(wallet.selectedAccount, network.network);
  // @ts-ignore
  const msigInfo = yield AccountService.getCosignatoryOfByAddress(address, network.selectedNetwork);
  try {
    for (const cosignatoryOf of msigInfo.cosignatoryOf) {
      GlobalListener.addConfirmed(cosignatoryOf);
      GlobalListener.addUnconfirmed(cosignatoryOf);
    }
  } catch (e) {
    console.error(e);
  }

  yield put(setCosignatoryOfAction(msigInfo.cosignatoryOf));
  yield put(setIsMultisigAction(msigInfo.isMultisig));
}
export function* watchAccount() {
  yield takeLatest(loadAllDataAction, loadAllDataSaga);
  yield takeLatest(loadBalanceAction, loadBalanceSaga);
  yield takeLatest(loadCosignatoryOfAction, loadCosignatoryOfSaga);
}
