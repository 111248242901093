import cn from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string;
  appearence?: 'primary' | 'secondary' | 'alert' | 'white' | 'blueBorder';
}

export const Button: FC<ButtonProps> = ({ className, text = 'submit', appearence, ...props }) => {
  return (
    <button
      className={cn(styles.button, className, {
        [styles['button-primary']]: appearence === 'primary',
        [styles['button-secondary']]: appearence === 'secondary',
        [styles['button-alert']]: appearence === 'alert',
        [styles['button-white']]: appearence === 'white',
        [styles['button-blue-border']]: appearence === 'blueBorder',
      })}
      {...props}
    >
      {text}
    </button>
  );
};
