import React, { FC } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

export const PrivateRoute: FC<any> = ({
  component: Component,
  ...rest
}: {
  component: React.ComponentType<RouteProps>;
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        localStorage.getItem('amplify-signin-with-hostedUI') ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
      }
    />
  );
};
