import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Step from '../../../../../components/common/Step';
import Text from '../../../../../components/common/Text';
import styles from './styles.module.scss';

const InfoStep = memo(() => {
  const { t } = useTranslation();
  return (
    <div className={styles.infoStepContainer}>
      <Text type="h3" fontWeight="bold" color="dark-primary" className={styles.infoStepTitle}>
        {t('_ToClaimOwnershipPlease_')}
      </Text>
      <div className={styles.stepListContainer}>
        <Step step={1} text={t('_PurchaseTheProduct_')} />
        <Step step={2} text={t('_ThenRemoveTheTopQRCodeSticker_')} />
        <Step step={3} text={t('_AndScanThePrivateQRCodeBelowIt_')} isLast />
      </div>
    </div>
  );
});

export default InfoStep;
