import { Radio } from '@mui/material';
import cn from 'classnames';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/Button/Button';
import GradientBorder from '../../../../components/common/GradientBorder';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { OrganizationApi } from '../../../../services/api/_OrganizationApi';
import { CommonHelpers } from '../../../../utils/commonHelpers';
import { CustomFieldFormState as State } from '../../types';
import styles from './CustomFieldForm.module.scss';

interface CustomFieldFormProps extends State {
  action: 'create' | 'edit';
  organizationId?: string;
  onSubmit?: any;
  customFieldId?: string;
  onSuccessCallBack: (data: any, isEdit?: boolean) => void;
}

export const CustomFieldForm: FC<CustomFieldFormProps> = ({
  name = '',
  type = 'text',
  value = '',
  organizationId,
  customFieldId,
  action,
  onSuccessCallBack,
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    name,
    type,
    value,
  });
  const [errorMessage, setErroMessage] = useState('');
  const { userData } = useSelector((state: any) => state.user);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setErroMessage('');
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement | HTMLFormElement>) => {
    e.preventDefault();
    if (form.name.length === 0) {
      return setErroMessage(t('_Name_cant_be_empty_'));
    }
    if (form.name.length > 100) {
      return setErroMessage(t('_Name_length_must_be_100_or_less_'));
    }
    try {
      setLoading(true);
      if (action === 'edit') {
        const { data } = await OrganizationApi.updateField(
          isEmployee ? userData.inviteCode : organizationId,
          customFieldId,
          {
            name: form.name,
            type: form.type,
          },
        );
        onSuccessCallBack(data, true);
      } else {
        const { data: fields } = await OrganizationApi.getOrganizationFields(
          isEmployee ? userData.inviteCode : organizationId,
        );
        const { data } = await OrganizationApi.createField({
          organizationId: isEmployee ? userData.inviteCode : organizationId,
          name: form.name,
          type: form.type,
          sort: fields?.data?.length,
        });

        onSuccessCallBack(data);
      }
    } catch (e) {
      console.error(CommonHelpers.erorHandlerMessage(e));
      setErroMessage(t('_Field_name_already_exists_'));
    } finally {
      setLoading(false);
    }
  };

  return (
    <form className={styles['custom-field-form']} onSubmit={handleSubmit}>
      <div>
        <Text className={styles.title}>{t('_Add_custom_field_')}</Text>
      </div>
      <div className={styles.field}>
        <Input
          label={t('_Name_')}
          placeholder={t('_Name_')}
          isRequired
          value={form.name}
          name="name"
          onChange={handleChange}
          errorMessage={errorMessage}
        />
      </div>

      <div className={styles.choiceble}>
        <div className={styles.choiceble__radio_container}>
          <Radio
            className={styles.choice}
            checked={form.type === 'text'}
            value="text"
            onChange={handleChange}
            name="type"
          />
          <Text type="subtitle_m" color="dark-primary">
            {t('_TextField_')}
          </Text>
        </div>
        <div className={styles.field}>
          <div className={styles.rectangle} />
          <div className={cn(styles.input, styles.fakeInput)}>
            <div className={styles.rectangle} />
          </div>
          {/* <Input className={styles.input} name="value" value={''} isDisabled onChange={handleChange} /> */}
        </div>
      </div>
      <div className={styles.line}>
        <GradientBorder />
      </div>
      <div className={styles.choiceble}>
        <div className={styles.choiceble__radio_container}>
          <Radio
            className={styles.choice}
            checked={form.type === 'textarea'}
            value="textarea"
            onChange={handleChange}
            name="type"
          />
          <Text type="subtitle_m" color="dark-primary">
            {t('_TextArea_')}
          </Text>
        </div>
        <div className={styles.field}>
          <div className={styles.rectangle} />
          <div className={cn(styles.textarea)}>
            <div className={styles.rectangle} />
          </div>
        </div>
      </div>

      <Button
        className={styles.button}
        isDisabled={loading}
        isLoading={loading}
        text={t('_Save_')}
        isPrimary
        onClick={handleSubmit}
      />
    </form>
  );
};
