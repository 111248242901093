import { Listener } from 'symbol-sdk';
import { APP_URL } from './constants';

const SUCCESS_STATUSES = [200, 201, 204];
// eslint-disable-next-line
const reg =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

interface URLEntry {
  path: string;
  publicKey: string;
  privateKey: string;
}

export class CommonHelpers {
  /**
   * Helper method to sleep for ms miliseconds
   * @param {string} text
   * @return {boolean}
   */
  static sleep(ms: number): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, ms);
    });
  }

  /**
   * Helper method to retry opening websocket n times asynchronously
   */
  static async retryNTimes(listener: Listener, trials: number, interval: number) {
    if (trials < 1) {
      throw new Error('could not connect');
    }
    let attemptCount = 0;
    while (!listener.isOpen()) {
      try {
        return await listener.open();
      } catch (error) {
        if (++attemptCount >= trials) {
          throw error;
        }
      }
      await this.sleep(interval);
    }
  }

  static isEmail = (email) => reg.test(String(email).toLowerCase());

  static URLEntry(url: string): URLEntry {
    const entryPoint = new URL(url);
    const entry = entryPoint.pathname;

    const publicKey = entryPoint.searchParams.get('public_key') || '';
    const privateKey = entryPoint.searchParams.get('private_key') || '';

    return {
      path: entry,
      publicKey,
      privateKey,
    };
  }

  static getBase64(file: File | any) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  static async tobase64Handler(files: any) {
    const filePathsPromises: any = [];
    files.forEach((file: any) => {
      filePathsPromises.push(this.getBase64(file));
    });
    const filePaths = await Promise.all(filePathsPromises);
    const mappedFiles = filePaths.map((base64File) => ({ src: base64File }));
    return mappedFiles;
  }

  static hideEmail(email = '') {
    const hide = email.split('@')[0].length - 2; //<-- number of characters to hide

    const r = new RegExp('.{' + hide + '}@', 'g');

    return email.replace(r, '***@');
  }
  static isOkStatus(status) {
    return SUCCESS_STATUSES.includes(status);
  }
  static updateInArray(array, findFn, updateFn) {
    const index = array.findIndex(findFn);
    const elem = array.find(findFn);
    const newArray = [...array];
    newArray.splice(index, 1, updateFn(elem));
    return newArray;
  }
  static removeFromArray(array, findFn) {
    const index = array.findIndex(findFn);
    const newArray = [...array];
    newArray.splice(index, 1);
    return newArray;
  }
  static erorHandlerMessage(e) {
    return e?.response ? e.response.data.message : e;
  }
  static removeObjectEmptyFillds<T>(obj: T): Partial<T> {
    const res: Partial<T> = {};
    for (const key in obj) {
      if (obj[key]) {
        res[key] = obj[key];
      }
    }
    return res;
  }
  static getFilterParams(data) {
    const length = Object.keys(data).length;
    function getCurrectValue(item: any) {
      if (Array.isArray(item)) {
        return item.join(',');
      } else {
        return encodeURIComponent(item);
      }
    }
    const keysStr = Object.keys(data)
      .map(
        (key, index) => `filter[${key}]=${getCurrectValue(data[key])}${length > 1 && index !== length - 1 ? '&' : ''}`,
      )
      .join('');

    return `?${keysStr}`;
  }

  static getStatusColor = (status) => {
    switch (status) {
      case 'Registered':
        return '#E6FAF7';
      case 'Viewed':
        return '#E8F2FF';
      case 'Authentic':
        return '#D4EDF5';
      case 'Transferred':
        return '#EAE8FF';
      case 'Active':
        return '#D8F0EF';
      case 'Declined':
        return '#FDECF0';
      case 'Expired':
        return '#CBCBDA';
      case 'Not active':
        return '#E2E8F0';
      case 'Pending':
        return '#FDEBD9';
      case 'Invitation sent':
        return '#E2E8F0';
      case 'Sent':
        return '#E2E8F0';
      case 'New':
        return '#E8F2FF';
      case 'Approved':
        return '#B0EDD0';
      case 'Not approved':
        return '#E8F2FF';
      case 'Waiting for approval':
        return '#FACEA4';
      default:
        return '#FDECF0';
    }
  };

  static getStatusName = (name) => {
    switch (name) {
      case 'Sent':
        return 'Invitation sent';
      default:
        return name;
    }
  };

  static getErrorText = (errors) => {
    const arr = Object.keys(errors);

    for (let i = 0; i < arr.length; i++) {
      if (errors[arr[i]]) {
        return arr[i];
      }
    }

    return '';
  };
  static generateQrLink = (alias: string, type: 'p' | 'c') => {
    return `${APP_URL}/${type}/${alias}`;
  };
  // @TODO: Not use
  // static generateUniversalLink = (id: string, type: 'public' | 'private') => {
  //   const products = localStorage.getItem('PRODUCTS') ? JSON.parse(localStorage.getItem('PRODUCTS')) : {};
  //   return `https://veritise-dev.herokuapp.com/product?${type}_key=${products[id][`${type}Key`]}`;
  // };

  static getParamFromURL = (url: string, number: number) => {
    const splited = url.split('/');
    return splited[number].includes('?') ? splited[number].split('?')[0] : splited[number];
  };

  static getTextWithoutSpaces = (str: string): string => {
    let res = '';
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char !== ' ') {
        res += char;
      }
    }
    return res;
  };
}
