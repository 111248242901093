import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Text from '../../components/common/Text';
import { ReactComponent as EllipseIcon } from '../../icons/ellipse.svg';
import { ReactComponent as FileIcon } from '../../icons/file.svg';
import './styles.scss';

export const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { activeProductMenu } = useSelector((state: any) => state.common);

  return (
    <div className="NotFound flexible vertical aCenter">
      <div className="flexible aCenter vertical title-block">
        <span>404</span>
        <span>{t('_PageNotFound_')}</span>
      </div>
      <div className="container">
        <div className="empty-image-content">
          <EllipseIcon className="empty-image" />
          <FileIcon className="file-image" />
          <div className="empty-info-content flexible vertical aCenter">
            <Text type="h3" className="empty-screen-title">
              {t('_SorryWeCantFindThatPage_')}
            </Text>
            <Text type="h4" className="empty-screen-subtitle">
              {t('_EitherSomethingWentWrong_')}
            </Text>
            <Button isPrimary onClick={() => history.push(!activeProductMenu ? '/company' : '/products')}>
              {t('_HomePage_')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
