import cn from 'classnames';
import { useFormik } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '../../components/Button/Button';
import CostumUploader from '../../components/ImageUpload/CostumUploader';
import { Input } from '../../components/Input/Input';
import { networkInitStateAction } from '../../modules/network/actions';
import { addOrganization, getOrganizations, updateOrganization } from '../../modules/organization/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { JURISDICTION_CODES } from '../../utils/constants';
import { OrganizationInfoValidation as validationSchema } from '../../utils/validations';
import KybVerificationButtons from './KybVerificationButtons';
import './SignUp.scss';

const OrganisationDetails: FC = () => {
  const { t } = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { organizations } = useSelector((state: any) => state.organization);
  const [organization, setOrganization] = useState<any>({});
  const { userData } = useSelector((state: any) => state.user);

  useEffect(() => {
    dispatch(getOrganizations.req());
  }, []);

  useEffect(() => {
    if (organizations && organizations.data?.length) {
      setOrganization(organizations.data[0]);
    } else if (organizations?.data?._id) {
      setOrganization(organizations.data);
    }
  }, [organizations]);

  const onDispatchSuccess = async () => {
    setIsButtonLoading(false);
    localStorage.setItem(
      'orgInfo',
      JSON.stringify({
        company_registration_number: CommonHelpers.getTextWithoutSpaces(values.company_registration_number),
        company_jurisdiction_code: CommonHelpers.getTextWithoutSpaces(values.company_jurisdiction_code),
        country: CommonHelpers.getTextWithoutSpaces(values.company_jurisdiction_code).split('_')[0],
      }),
    );

    if (userData.isVerificationNeeded) {
      history.push('/kyb');
      return;
    }

    if (!userData.info.firstName) {
      history.push('/profile-details');
      return;
    }

    if (userData.group !== 'company' && userData.kyc_status === 'not verified') {
      sessionStorage.setItem('userData', JSON.stringify(userData));
      history.push('/intro-kyc');
      return;
    }

    if (userData.isVerificationNeeded && userData.kyc_status === 'not verified') {
      sessionStorage.setItem('userData', JSON.stringify(userData));
      history.push('/intro-kyc');
      return;
    }

    if (localStorage.getItem('authFlow') === 'registration') {
      history.push('/wallet-creation');
      return;
    }

    if (!localStorage.getItem('MNEMONIC_MODEL')) {
      history.push('/wallet-connect');
      return;
    }

    const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
    const mnemonic = mnemonicModel.mnemonic;
    dispatch(
      saveWalletAction({
        mnemonic,
        onSuccess: () => {
          dispatch(networkInitStateAction());
          history.push('/products');
        },
        onError: () => {
          history.push('/wallet-connect');
        },
      }),
    );
  };

  const onSubmit = async () => {
    setIsButtonLoading(true);
    const payload = {
      name: values.organizationName,
      registrationNumber: CommonHelpers.getTextWithoutSpaces(values.company_registration_number),
      jurisdictionCode: CommonHelpers.getTextWithoutSpaces(values.company_jurisdiction_code),
      info: {
        images: values.organizationPhoto,
      },
    };

    if (organizations?.data?.length || organizations?.data?._id) {
      dispatch(
        updateOrganization.req({
          updatedData: payload,
          id: organization._id,
          onSuccess: onDispatchSuccess,
          onFail: () => {
            setIsButtonLoading(false);
          },
        }),
      );
    } else {
      dispatch(
        addOrganization.req({
          data: payload,
          onSuccess: onDispatchSuccess,
          onFail: () => {
            setIsButtonLoading(false);
          },
        }),
      );
    }
  };

  const company_jurisdiction_codeOptions: { label: string; value: any }[] = useMemo(
    () =>
      JURISDICTION_CODES.map((item) => {
        const key = Object.keys(item)[0];
        return {
          label: key,
          value: item[key],
        };
      }),
    [],
  );

  const { setFieldValue, values, errors, handleSubmit }: any = useFormik({
    initialValues: {
      organizationName: organization.name || '',
      company_registration_number: organization.registrationNumber || '',
      company_jurisdiction_code: organization.jurisdictionCode || '',
      organizationPhoto: [],
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(organization);
    if (organization) {
      setFieldValue('organizationName', organization.name || '');
      setFieldValue('company_registration_number', organization.registrationNumber || '');
      setFieldValue('company_jurisdiction_code', organization.jurisdictionCode || '');
      setFieldValue('organizationPhoto', organization.info?.images || []);
    }
  }, [organization]);

  const isDev = process.env.REACT_APP_STAGE === 'development' || process.env.REACT_APP_STAGE === 'stage';

  return (
    <div className="login signup">
      <div className="signup__container">
        <div className={cn('login__wrapper', 'signup-create-organisation')}>
          <h2 className="auth__title">{t('_Create_business_account_')}</h2>
          <h4 className="auth__description">{''}</h4>
          <Input
            type="text"
            placeholder={t('_CompanyName_')}
            label={t('_CompanyName_')}
            name="organizationName"
            size="lg"
            isRequired
            hasError={!!errors.organizationName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('organizationName', e.target.value)}
            errorMessage={errors?.organizationName}
            value={values.organizationName}
          />
          <Input
            type="text"
            placeholder={t('_Registration_number_')}
            label={t('_Registration_number_')}
            name="company_registration_number"
            size="lg"
            isRequired
            hasError={!!errors.company_registration_number}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue('company_registration_number', e.target.value)
            }
            errorMessage={errors?.company_registration_number}
            value={values.company_registration_number}
          />
          <div className="input lastinput">
            <p>
              {t('_Registration_country_')}
              <span>*</span>
            </p>
            <Select
              value={company_jurisdiction_codeOptions.find((item) => item.value === values.company_jurisdiction_code)}
              onChange={(selectedOption) => setFieldValue('company_jurisdiction_code', selectedOption.value)}
              options={company_jurisdiction_codeOptions}
              name="warranty"
              className={`input-select ${
                errors.company_jurisdiction_code || errors.company_jurisdiction_code ? 'select-hasError' : ''
              }`}
              classNamePrefix="input-select"
              placeholder={t('_startTyping_')}
              maxMenuHeight={240}
            />
            {errors && (
              <span className="errors">{errors.company_jurisdiction_code || errors.company_jurisdiction_code}</span>
            )}
          </div>
          <CostumUploader
            label="Logo"
            isRequered={false}
            onChange={(files) => setFieldValue('organizationPhoto', files)}
            classname="upload-wrapper"
            images={values.organizationPhoto}
          />
          <Button
            isPrimary
            className="login__button withMargin"
            text="Next"
            onClick={handleSubmit}
            isLoading={isButtonLoading}
          />
          {isDev && (
            <KybVerificationButtons
              payload={{
                name: values.organizationName,
                registrationNumber: values.company_registration_number,
                jurisdictionCode: values.company_jurisdiction_code,
                info: {
                  images: values.organizationPhoto,
                },
                id: organization._id,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default OrganisationDetails;
