import { createAction } from 'deox';
import { WarrantyDetails } from './types';

export const loadAllDataAction = createAction('product/LOAD_ALL_DATA', (res) => (data?: string) => res(data));

export const setProductWarrantiesUpdated = createAction(
  'product/PRODUCT_WARRANTIES_UPDATED',
  (res) => (data: { [id: string]: WarrantyDetails }) => res(data),
);

export const setCategoriesUpdated = createAction('product/CATEGORIES_UPDATED', (res) => (data: any) => res(data));

export const setLoadingAction = createAction('product/SET_LOADING', (res) => (status?: boolean) => res(status));
export const setProductsLoadingAction = createAction(
  'product/SET_PRODUCTS_LOADING',
  (res) => (status?: boolean) => res(status),
);
export const setFirstLoadAction = createAction('product/SET_FIRST_LOAD', (res) => (payload: boolean) => res(payload));

export const getProductsForCompany = {
  req: createAction('product/GET_COMPANY_PRODUCTS', (res) => (params) => res(params)),
  success: createAction('product/GET_COMPANY_PRODUCTS_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_PRODUCTS_SUCCESS'),
};
export const getProductsForUser = {
  req: createAction('product/GET_USER_PRODUCTS', (res) => (params) => res(params)),
};
export const createProductForCompany = {
  req: createAction('product/CREATE_COMPANY_PRODUCT', (res) => (params) => res(params)),
  success: createAction('product/CREATE_COMPANY_PRODUCT_SUCCESS', (res) => (params) => res(params)),
};
export const removeProductForCompany = {
  req: createAction('product/REMOVE_COMPANY_PRODUCT', (res) => (params, onSuccess) => {
    return res({
      params,
      onSuccess,
    });
  }),
  success: createAction('product/REMOVE_COMPANY_PRODUCT_SUCCESS'),
};
export const editProductForCompany = {
  req: createAction('product/EDIT_COMPANY_PRODUCT', (res) => (params, onSuccess, onFail) => {
    return res({
      params,
      onSuccess,
      onFail,
    });
  }),
  success: createAction('product/EDIT_COMPANY_PRODUCT_SUCCESS'),
};
export const getProduct = {
  req: createAction('product/GET_PRODUCT', (res) => (params, onSuccess?) => {
    return res({
      params,
      onSuccess,
    });
  }),
  success: createAction('product/GET_PRODUCT_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_PRODUCT_SUCCESS'),
};

export const getCategories = {
  req: createAction('product/GET_CATEGORIES', (res) => (params, onSuccess?) => {
    return res({
      params,
      onSuccess,
    });
  }),
  success: createAction('product/GET_CATEGORIES_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_CATEGORIES_SUCCESS'),
};
export const getProductHistory = {
  req: createAction('product/GET_PRODUCT_HISTORY', (res) => (params) => res(params)),
  success: createAction('product/GET_PRODUCT_HISTORY_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_PRODUCT_HISTORY_SUCCESS'),
};
export const getWarrianties = {
  req: createAction('product/GET_WARRIANTIES', (res) => (params) => res(params)),
  success: createAction('product/GET_WARRIANTIES_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_WARRIANTIES_SUCCESS'),
};
export const registerWarranty = {
  req: createAction('product/REGISTER_WARRANTY', (res) => (params) => res(params)),
  success: createAction('product/REGISTER_WARRANTY_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_REGISTER_WARRANTY_SUCCESS'),
};
export const updateWarrianty = {
  req: createAction('product/UPDATE_WARRIANTY', (res) => (params) => res(params)),
  success: createAction('product/UPDATE_WARRIANTY_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_UPDATE_WARRIANTY_SUCCESS', (res) => (params) => res(params)),
};
export const createWarranty = {
  req: createAction('product/ADD_WARRANTY', (res) => (params) => res(params)),
  success: createAction('product/ADD_WARRANTY_SUCCESS', (res) => (params) => res(params)),
};
export const setLoading = createAction('product/SET_LOADING', (res) => (params) => res(params));
export const getScanHistory = {
  req: createAction('product/GET_SCAN_HISTORY'),
  success: createAction('product/GET_SCAN_HISTORY_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_SCAN_HISTORY_SUCCESS'),
};
export const getScanItem = {
  req: createAction('product/GET_SCAN_ITEM', (res) => (params) => res(params)),
  success: createAction('product/GET_SCAN_ITEM_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_SCAN_ITEM_SUCCESS', (res) => (params) => res(params)),
};
export const createCategory = {
  req: createAction('product/ADD_CATEGORY', (res) => (params) => res(params)),
  success: createAction('product/ADD_CATEGORY_SUCCESS', (res) => (params) => res(params)),
};
export const setDataLoading = createAction('product/SET_DATA_LOADING', (res) => (params) => res(params));
export const getProductBulksForCompany = {
  req: createAction('product/GET_BULKS', (res) => (params) => res(params)),
  success: createAction('product/GET_BULKS_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_BULKS_SUCCESS'),
};
export const setProductBulksLoadingAction = createAction(
  'product/SET_PRODUCT_BULKS_LOADING',
  (res) => (status?: boolean) => res(status),
);
export const getProductTemplatesForCompany = {
  req: createAction('product/GET_TEMPLATES', (res) => (params) => res(params)),
  success: createAction('product/GET_TEMPLATES_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('product/RESET_TEMPLATES_SUCCESS'),
};
export const setProductTemplatesLoadingAction = createAction(
  'product/SET_PRODUCT_TEMPLATES_LOADING',
  (res) => (status?: boolean) => res(status),
);
export const removeTemplate = createAction('product/REMOVE_TEMPLATE', (res) => (params) => res(params));
