/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import AWS from 'aws-sdk';
import cn from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ButtonLoader from '../../components/ButtonLoader';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { setUserGroup } from '../../modules/user/actions';
import { UserApi } from '../../services/api/UserApi';
import { DEFAULT_PHONE_NUMBER, VERITISE_URL } from '../../utils/constants';
import './SignUp.scss';

const RoleSelection = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();

  const addUserToGroup = async (groupName: string) => {
    setSelected(groupName);
    setIsLoading(true);
    try {
      // const user = await Auth.currentAuthenticatedUser();
      AWS.config.update({
        credentials: new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL_ID,
        }),
      });

      const info = {
        phone: DEFAULT_PHONE_NUMBER,
      };
      await UserApi.updateUser({
        info,
        group: groupName,
      });
      dispatch(setUserGroup(groupName));
      if (groupName === 'company') {
        history.push('/subscription-plan');
      } else {
        history.push('/profile-details');
      }
    } catch (e) {
      // setUserGroup(groupName);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login signup">
      <div className="signup__container">
        <div className={cn('login__wrapper', 'signup-verification__wrapper')}>
          <h2 className="auth__title">{t('_Choose_account_type_')}</h2>
          <h4 className="auth__description">
            <div>
              {t('_Choose_how_you_will_use_')}{' '}
              <Link className="email-text" to={{ pathname: VERITISE_URL }} target="_blank">
                Veritise
              </Link>
            </div>
          </h4>
          <div className="signup__roles">
            <div
              className={cn('individual user-role', { active: selected === 'user' })}
              onClick={() => addUserToGroup('user')}
            >
              <div className="line" />
              <span>{t('_Individual_')}</span>
              {isLoading && selected === 'user' ? (
                <div className="ButtonLoader">
                  <ButtonLoader />
                </div>
              ) : (
                <ArrowIcon />
              )}
            </div>
            <div
              className={cn('business user-role', { active: selected === 'company' })}
              onClick={() => addUserToGroup('company')}
            >
              <div className="line" />
              <span>{t('_Business_')}</span>
              {isLoading && selected === 'company' ? (
                <div className="ButtonLoader">
                  <ButtonLoader />
                </div>
              ) : (
                <ArrowIcon />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoleSelection;
