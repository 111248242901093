/* eslint-disable no-console */
import cn from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Upload from 'react-images-uploading';
import { ReactComponent as CloseIcon } from '../../icons/close-filled.svg';
import { ReactComponent as PlusIcon } from '../../icons/plus.svg';
import { ATTACHMENTS_IMAGES_AMOUNT_LIMIT, ATTACHMENTS_IMAGES_SIZE_LIMIT } from '../../utils/constants';
import styles from './ImageUpload.module.scss';

interface ImageUploadProps {
  label?: string;
  onChange?: (images: any) => void;
  images?: any[];
  max?: number;
  className?: string;
  showOne?: boolean;
  showContaineStyle?: boolean;
  isMakeRemove?: () => void;
  isRequered?: boolean;
  fromProductModal?: boolean;
  acceptType?: string[];
}

export const ImageUpload: FC<ImageUploadProps> = ({
  onChange,
  label,
  max = ATTACHMENTS_IMAGES_AMOUNT_LIMIT,
  images = [],
  showOne,
  className,
  showContaineStyle,
  isMakeRemove,
  isRequered,
  fromProductModal,
  acceptType = ['jpeg', 'jpg', 'png'],
}) => {
  const { t } = useTranslation();
  const ref = useRef<null | HTMLDivElement>(null);

  const [imagesList, setImagesList] = useState<{ src: string }[]>(images);

  useEffect(() => {
    setImagesList(images);
  }, [images]);

  const handleChange = (newImages: any) => {
    setImagesList(newImages);
    setScrollToRight(newImages.length);
    onChange(newImages.map((image: any) => ({ src: image.src })));
  };

  const setScrollToRight = (imagesCount: number) => {
    if (ref.current && imagesCount >= 4) {
      ref.current.scrollLeft = 9999999;
    }
  };

  useEffect(() => {
    setScrollToRight(images.length);
  }, []);

  return (
    <div className={cn(styles['upload-wraper'], className)}>
      {!!label && (
        <p
          className={
            fromProductModal
              ? cn(styles['upload-wraper__label'], styles['upload-wraper__productModalLabel'])
              : styles['upload-wraper__label']
          }
        >
          {label} {isRequered && <span className="isRequired">*</span>}
        </p>
      )}
      <Upload
        acceptType={acceptType}
        dataURLKey="src"
        multiple
        maxNumber={max}
        onChange={handleChange}
        value={imagesList}
        maxFileSize={ATTACHMENTS_IMAGES_SIZE_LIMIT}
      >
        {({ imageList, onImageUpload, onImageRemove, dragProps, errors }) => (
          <div className="flexible vertical withoutScroll">
            <div
              className={
                showContaineStyle
                  ? cn(
                      styles['image-upload'],
                      styles['image-upload__hiddenOverflow'],
                      fromProductModal ? styles['image-upload__fromProductModal'] : '',
                    )
                  : cn(styles['image-upload'], fromProductModal ? styles['image-upload__fromProductModal'] : '')
              }
              ref={ref}
            >
              {!fromProductModal &&
                imageList.map((image, index) => (
                  <div className={cn(styles['image-upload__item'], 'image-item')} key={index}>
                    <CloseIcon
                      className={styles['image-upload__item-close']}
                      onClick={() => {
                        onImageRemove(index);
                        isMakeRemove && isMakeRemove();
                      }}
                    />
                    <img
                      src={
                        image.src.startsWith('data') ? image.src : process.env.REACT_APP_ATTACHMENTS_DOMAIN + image.src
                      }
                      alt="foo"
                      id={index.toString()}
                      className={showContaineStyle ? styles['image-contain'] : ''}
                    />
                  </div>
                ))}
              {(showOne && imageList.length === 1) || imageList.length === max ? null : (
                <div>
                  <div
                    className={cn(
                      styles['image-upload__item'],
                      styles['image-upload__item-add'],
                      errors ? styles['image-upload__item-hasError'] : '',
                      fromProductModal ? styles['image-upload__item_fromProductModal'] : '',
                    )}
                    {...dragProps}
                    onClick={onImageUpload}
                  >
                    <PlusIcon />
                  </div>
                  {errors && (
                    <div className={styles.errorBlock}>
                      {errors.maxNumber && (
                        <span className={styles.errors}>
                          {t('_MaxNumberMessage_')} {max}
                        </span>
                      )}
                      {errors.acceptType && (
                        <span className={styles.errors}>
                          {t('_AllowedFileTypes_')}:{' '}
                          {acceptType.reduce(
                            (acc, cur, currentIndex) =>
                              acc + `.${cur}${currentIndex !== acceptType.length - 1 ? ', ' : ''}`,
                            '',
                          )}
                        </span>
                      )}
                      {errors.maxFileSize && <span className={styles.errors}>{t('_MaxFileSize_')}</span>}
                    </div>
                  )}
                </div>
              )}
              <div className={styles['image-upload__preview_items_container']}>
                {fromProductModal &&
                  imageList.map((image, index) => (
                    <div className={cn(styles['image-upload__item'], styles['image-upload__item_160'])} key={index}>
                      <CloseIcon
                        className={styles['image-upload__item-close']}
                        onClick={() => {
                          onImageRemove(index);
                          isMakeRemove && isMakeRemove();
                        }}
                      />
                      <img
                        src={image.src}
                        alt="img"
                        id={index.toString()}
                        className={showContaineStyle ? styles['image-contain'] : ''}
                      />
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )}
      </Upload>
    </div>
  );
};
