import { useFormik } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { ConfirmAnswersValidation as validationSchema } from '../../../../utils/validations';
import styles from './styles.module.scss';

interface IProps {
  next: (answers: { questionId: string; answer: string }[]) => void;
  questions: { questionId: string; name: string }[];
  waitingState?: boolean;
}

const ConfirmSecQStep: FC<IProps> = ({ next, questions, waitingState = false }) => {
  const { t } = useTranslation();

  const onSubmit = () => {
    const payload: { questionId: string; answer: string }[] = [
      { questionId: questions[0].questionId, answer: values.answerOne.trim() },
      { questionId: questions[1].questionId, answer: values.answerTwo.trim() },
      { questionId: questions[2].questionId, answer: values.answerThree.trim() },
    ];
    next(payload);
  };

  const { setFieldValue, values, errors, handleSubmit }: any = useFormik({
    initialValues: {
      answerOne: '',
      answerTwo: '',
      answerThree: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.questionsContainer}>
      <section className={styles.questionsBlock}>
        <Text type="h5">1. {t('_SecurityQuestion_')}</Text>
        <p>{questions[0] ? questions[0].name : '---'}</p>
        <Input
          type="text"
          value={values.answerOne}
          isTextArea
          isRequired
          isExpanding
          label={t('_Answer_')}
          hasError={!!errors.answerOne}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerOne', e.target.value)}
          errorMessage={errors?.answerOne}
        />
      </section>
      <section className={styles.questionsBlock}>
        <Text type="h5">2. {t('_SecurityQuestion_')}</Text>
        <p>{questions[1] ? questions[1].name : '---'}</p>
        <Input
          type="text"
          value={values.answerTwo}
          isTextArea
          isRequired
          isExpanding
          label={t('_Answer_')}
          hasError={!!errors.answerTwo}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerTwo', e.target.value)}
          errorMessage={errors?.answerTwo}
        />
      </section>
      <section className={styles.questionsBlock}>
        <Text type="h5">3. {t('_SecurityQuestion_')}</Text>
        <p>{questions[2] ? questions[2].name : '---'}</p>
        <Input
          type="text"
          value={values.answerThree}
          isTextArea
          isRequired
          isExpanding
          label={t('_Answer_')}
          hasError={!!errors.answerThree}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerThree', e.target.value)}
          errorMessage={errors?.answerThree}
        />
      </section>
      <Button
        onClick={handleSubmit}
        isPrimary
        isLoading={waitingState}
        isDisabled={!values.answerThree || !values.answerTwo || !values.answerOne}
      >
        {t('_Next_')}
      </Button>
    </div>
  );
};

export default ConfirmSecQStep;
