import { Api } from './Api';

export class CommonApi {
  static getCommon1() {
    return Api.get('/getUsers');
  }
  static getCommon(id: string) {
    return Api.get(`/getUser/${id}`);
  }
  static getConfiguration() {
    return Api.get('/service/configuration');
  }
  static setIdentity(data) {
    return Api.put('/service/identity', data, {}, { 'X-SERVICE-API-KEY': process.env.REACT_APP_SERVICE_API_KEY });
  }
  static getFiledsForCategory(id: string) {
    return Api.get(`/category/${id}/field`);
  }
  static KYC_identity(data) {
    return Api.post('/identity/kyc', data);
  }
  static KYB_identity(data) {
    return Api.post('/identity/kyb', data);
  }
  static addWallet(data) {
    return Api.post('/user/wallet', data);
  }
  static getWallets() {
    return Api.get('/user/wallet');
  }
}
