/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Modal } from '../../../../components/Modal/Modal';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (saveAsTemplate: boolean) => void;
}

const CreateTemplateModal = ({ isOpen, onClose, onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className="createTemplateModal">
        <Text align="center">{t('_Do_you_want_to_save_as_template_')}</Text>
        <Button isPrimary className="createTemplateModalButton" onClick={() => onSubmit(true)}>
          {t('_Create_products_and_save_template_')}
        </Button>
        <Button isPrimary className="createTemplateModalButton" onClick={() => onSubmit(false)}>
          {t('_Only_create_products_')}
        </Button>
      </div>
    </Modal>
  );
};

export default CreateTemplateModal;
