import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe(
  'pk_test_51KZFZwCNUX3u9YV6fU7JdcDFzMU5d30ZJiiBd1fL4ge9lPpdYDAShUZz9onYBEgtEu0FzO4E0nmoHrW7evnu0Jme009oFDCt2u',
);

const StripeComponent = () => {
  return (
    <div className="StripeComponent">
      <Elements stripe={stripePromise}>
        <CheckoutForm />
      </Elements>
    </div>
  );
};

export default StripeComponent;
