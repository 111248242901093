import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import BusyLoader from '../../components/BusyLoader';
import { Button } from '../../components/Button/Button';
import Text from '../../components/common/Text';
import styles from '../../components/HiddenBankCardInfo/HiddenBankCardInfo.module.scss';
import { Modal } from '../../components/Modal/Modal';
import { ReactComponent as AlertIcon } from '../../icons/error.svg';
import './Billing.scss';

const DeleteModal = ({ onDelete, isBusyRequest, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={onClose}>
      <BusyLoader
        isBusy={isBusyRequest}
        className={cn(styles.deleteCardModal, { [styles.deleteCardModal_busy]: isBusyRequest })}
      >
        <Text type="h1" className={styles.deleteCardModalTitle}>
          {t('_Unsubscribe_')}?
        </Text>
        <AlertIcon className={styles.alertIcon} />
        <div className={styles.descirption}>{t('_CancelCurrentSubscription_')}</div>
        <div className="flexible aCenter jCenter">
          <Button className={styles.buttonsStyle} onClick={onDelete} isAlert>
            {t('_Unsubscribe_')}
          </Button>
          <Button className={styles.buttonsStyle} isPrimaryOutline onClick={onClose}>
            {t('_Cancel_')}
          </Button>
        </div>
      </BusyLoader>
    </Modal>
  );
};

export default DeleteModal;
