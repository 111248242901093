import { createAction } from 'deox';
import { BillingInvoiceType, PaymentInfoType, SingleSubscrionsType, SubscrionsType } from './types';

export const setSubscriptions = {
  request: createAction('payment/SAVE_SUBSCRIPTIONS_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction(
    'payment/SAVE_SUBSCRIPTIONS_SUCCESS',
    (resolve) => (payload: SubscrionsType) => resolve(payload),
  ),
  fail: createAction('payment/SAVE_SUBSCRIPTIONS_FAIL', (resolve) => (payload: SubscrionsType) => resolve(payload)),
};

export const setCurrentSubscription = {
  request: createAction('payment/SAVE_CURRENT_SUBSCRIPTIONS_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction(
    'payment/SAVE_CURRENT_SUBSCRIPTIONS_SUCCESS',
    (resolve) => (payload: SingleSubscrionsType) => resolve(payload),
  ),
  fail: createAction(
    'payment/SAVE_CURRENT_SUBSCRIPTIONS_FAIL',
    (resolve) => (payload: SingleSubscrionsType) => resolve(payload),
  ),
};

export const setBillingInvoices = {
  request: createAction('payment/SAVE_BILLING_INVOICES_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction(
    'payment/SAVE_BILLING_INVOICES_SUCCESS',
    (resolve) => (payload: BillingInvoiceType) => resolve(payload),
  ),
  fail: createAction(
    'payment/SAVE_BILLING_INVOICES_FAIL',
    (resolve) => (payload: BillingInvoiceType) => resolve(payload),
  ),
};

export const setPaymentInfo = {
  request: createAction('payment/SAVE_PAYMENTINFO_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction(
    'payment/SAVE_PAYMENTINFO_SUCCESS',
    (resolve) => (payload: PaymentInfoType) => resolve(payload),
  ),
  fail: createAction('payment/SAVE_PAYMENTINFO_FAIL', (resolve) => (payload: PaymentInfoType) => resolve(payload)),
};

export const createPaymentInfo = createAction(
  'cv/CREATE_PAYMENTINFO_REQUEST',
  (resolve) => (params?: any) => resolve(params),
);
