import { Mosaic, MosaicHttp, MosaicInfo, NamespaceHttp, NamespaceId } from 'symbol-sdk';
import type { MosaicModel } from '../../storage/models/MosaicModel';
import type { NetworkModel } from '../../storage/models/NetworkModel';

export default class MosaicService {
  /**
   * Gets MosaicModel from a Mosaic
   * @param mosaic
   * @param network
   * @return {Promise<{amount: string, mosaicId: string, mosaicName: *, divisibility: *}>}
   */
  static async getMosaicModelFromMosaicId(mosaic: Mosaic, network: NetworkModel): Promise<MosaicModel> {
    let mosaicInfo = {},
      mosaicName = {};
    try {
      // @ts-ignore
      mosaicInfo = await new MosaicHttp(network.node).getMosaic(mosaic.id).toPromise();
      // @ts-ignore
      [mosaicName] = await new NamespaceHttp(network.node).getMosaicsNames([mosaic.id]).toPromise();
    } catch (e) {
      console.error(e, 26);
    }
    //Mosaic info not found -> let's try for its namespace
    // @ts-ignore
    if (!mosaicInfo.divisibility) {
      try {
        const namespaceInfo = await new NamespaceHttp(network.node)
          .getNamespace(mosaic.id as unknown as NamespaceId)
          .toPromise();
        if (namespaceInfo.alias.mosaicId) {
          mosaicInfo = await new MosaicHttp(network.node).getMosaic(namespaceInfo.alias.mosaicId).toPromise();
          [mosaicName] = await new NamespaceHttp(network.node)
            .getMosaicsNames([namespaceInfo.alias.mosaicId])
            .toPromise();
        }
      } catch (e) {
        console.error(e);
      }
    }

    return {
      mosaicId: mosaic.id.toHex(),
      mosaicName:
        // @ts-ignore
        mosaicName && mosaicName.names && mosaicName.names.length > 0
          ? // @ts-ignore
            mosaicName.names[0].name
          : mosaic.id.toHex(),
      // @ts-ignore
      amount: mosaic.amount.toString(),
      // @ts-ignore
      divisibility: mosaicInfo.divisibility,
      // @ts-ignore
      expired: mosaicInfo.duration ? this._checkExpirationDate(mosaicInfo, network) : false,
    };
  }

  /**
   * Checks expiration date of a mosaic
   * @param mosaicInfo
   * @param network
   * @returns {string|boolean}
   * @private
   */
  static _checkExpirationDate(mosaicInfo: MosaicInfo, network: NetworkModel) {
    const duration = mosaicInfo.duration.compact();
    const startHeight = mosaicInfo.startHeight.compact();

    // unlimited duration mosaics are flagged as duration == 0
    if (duration === 0) {
      return false;
    }

    // get current height
    // calculate expiration
    const expiresIn = startHeight + duration - (network.chainHeight || 0);
    if (expiresIn <= 0) {
      return true;
    }
    return false;
    // number of blocks remaining
    // return durationToRelativeTime(expiresIn, network.blockGenerationTargetTime);
  }
}
