import { Api } from './Api';

export class ApiDoc {
  static async getChangeLogs(filterParams?: any) {
    const { data } = await Api.get(`/changelog${filterParams ? filterParams : ''}`);
    return data;
  }

  static async generateApiKey() {
    const { data } = await Api.post('/user/key');
    return data;
  }

  static async getSingleChangeLog(Id: string | number) {
    const { data } = await Api.get(`/changelog/${Id}`);
    return data;
  }

  static async getDocumentation() {
    const { data } = await Api.get('/documentation');
    return data;
  }
}
