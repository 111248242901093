import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import BusyLoader from '../../../components/BusyLoader';
import { Button } from '../../../components/Button/Button';
import Text from '../../../components/common/Text';
import { Modal } from '../../../components/Modal/Modal';
import { ReactComponent as WarningIcon } from '../../../icons/warning.svg';
import styles from './ChangeSubscriptionModal.module.scss';

const ChangeSubscriptionModal = ({ onAgree, isBusyRequest, onClose }) => {
  const { t } = useTranslation();
  return (
    <Modal open onClose={onClose}>
      <BusyLoader
        isBusy={isBusyRequest}
        className={cn(styles.changeSubscriptionModal, { [styles.changeSubscriptionModal_busy]: isBusyRequest })}
      >
        <Text type="h1" className={styles.modalTitle}>
          {t('_Change_Subscription_')}
        </Text>
        <WarningIcon className={styles.alertIcon} />

        <div className={styles.description}>{t('_Change_Subscription_Warning_')}</div>
        <div className={styles.buttonsGroup}>
          <Button className={styles.button} isPrimaryOutline onClick={onClose}>
            {t('_Cancel_')}
          </Button>
          <Button className={styles.button} onClick={onAgree} isPrimary>
            {t('_Next_')}
          </Button>
        </div>
      </BusyLoader>
    </Modal>
  );
};

export default ChangeSubscriptionModal;
