import { createReducer } from 'deox';
import produce from 'immer';
import { setError } from '../../modules/errors/action';
import {
  networkInitStateAction,
  networkUpdateAction,
  setCheckNodeJobAction,
  setIsUpAction,
  setNodeFailedAttemptsAction,
} from './actions';
import { NetworkState } from './types';

const initialState: NetworkState = {
  loading: false,
  error: null,
  isUp: true,
  nodeFailedAttempts: 0,
  checkNodeJob: null,
  isLoaded: false,
  generationHash: '',
  network: 'testnet',
  selectedNode: '',
  selectedNetwork: {
    type: 'testnet',
    generationHash: '',
    node: '',
    currencyMosaicId: '',
    // currencyDivisibility: 6,
    chainHeight: 0,
    blockGenerationTargetTime: 0,
    epochAdjustment: 0,
    transactionFees: {},
    defaultDynamicFeeMultiplier: 0,
  },
};

export const networkReducer = createReducer(initialState, (handle) => [
  handle(networkInitStateAction, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = null;
    }),
  ),
  handle(setCheckNodeJobAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.checkNodeJob = payload.job;
    }),
  ),
  handle(setIsUpAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.isUp = payload.isUp;
    }),
  ),
  handle(setNodeFailedAttemptsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.nodeFailedAttempts = payload;
    }),
  ),
  handle(networkUpdateAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.generationHash = payload.generationHash;
      draft.network = payload.type;
      draft.selectedNode = payload.selectedNode;
      draft.selectedNetwork = payload.network;
      draft.isLoaded = payload.isLoaded;
    }),
  ),
  handle(setError, (state, { payload }) =>
    produce(state, (draft) => {
      draft.error = payload;
    }),
  ),
]);
