import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button/Button';
import NoItem from '../../components/NoItem';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { useRedirect } from '../../hooks/useRedirect';
// import { ReactComponent as VerificationIcon } from '../../icons/kyc.svg';
import './IntroKYC.scss';
import KycVerificationButtons from './KycVerificationButtons';

const IntroKYC: FC = () => {
  const { t } = useTranslation();
  const redirectToKYC = useRedirect('/kyc');
  const redirectToProducts = useRedirect('/products');

  const userType = useDetectUserType();
  const isIndividualUser = userType === 'user';

  const isDev = process.env.REACT_APP_STAGE === 'development' || process.env.REACT_APP_STAGE === 'stage';

  return (
    <div className="login intro-kyc">
      {isDev && <KycVerificationButtons />}
      <div className="login__wrapper intro-kyc__wrapper">
        <h2 className="auth__title">{t('_IdentityVerification_')}</h2>
        <h4 className="auth__description">{t('_NeedToPassIdentityVerification_')}</h4>
        <div className="verification__wrapper">
          <NoItem type="identify" className="start-verification-icon-block">
            <Button
              isPrimary
              className="start-verification__button"
              text={t('_StartVerification_')}
              onClick={redirectToKYC}
            />
            {isIndividualUser && (
              <Button
                isPrimaryOutline
                className="skip-verification__button"
                text={t('_SkipVerification_')}
                onClick={redirectToProducts}
              />
            )}
          </NoItem>
        </div>
      </div>
    </div>
  );
};

export default IntroKYC;
