import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../../../components/Button/Button';
import { Input } from '../../../../components/Input/Input';
import { CommonHelpers } from '../../../../utils/commonHelpers';
import styles from './styles.module.scss';

interface IProps {
  next: (email: string) => void;
  waitingState?: boolean;
}

const SetEmailStep: FC<IProps> = ({ next, waitingState = false }) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState<string>('');

  return (
    <div className={styles.emailContainer}>
      <Input
        className={styles.input}
        type="text"
        value={email}
        label={t('_Email_')}
        placeholder={t('_EnterNewEmail_')}
        onChange={({ target }) => setEmail(target.value)}
      />
      <Button
        onClick={() => next(email)}
        isLoading={waitingState}
        isPrimary
        isDisabled={!email || !CommonHelpers.isEmail(email)}
      >
        {t('_Next_')}
      </Button>
    </div>
  );
};

export default SetEmailStep;
