import { createReducer } from 'deox';
import produce from 'immer';
import { getOrganizations, setDataLoading } from './actions';
import { OrganizationState } from './types';

const initialState: OrganizationState = {
  loading: false,
  organizations: null,
};

export const organizationReducer = createReducer(initialState, (handle) => [
  handle(setDataLoading, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(getOrganizations.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.organizations = payload;
    }),
  ),
  handle(getOrganizations.reset, (state) =>
    produce(state, (draft) => {
      draft.organizations = null;
    }),
  ),
]);
