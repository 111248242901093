import { createAction } from 'deox';
import { ResponseError } from '../../modules/errors';
import { LoginStateWithUser } from './types';

export const userLogin = {
  request: createAction('user/LOGIN_REQUEST', (res) => (payload: any) => res(payload)),
  success: createAction('user/LOGIN_SUCCESS', (res) => (data: LoginStateWithUser) => res(data)),
  fail: createAction('user/LOGIN_FAIL', (res) => (err: ResponseError) => res(err)),
};

export const logoutActionRequest = createAction('LOGOUT_REQUEST', (res) => (payload: any) => res(payload));
export const setPrevUsernameAction = createAction('user/PREV_USERNAME', (res) => (data: string) => res(data));

export const logoutAction = createAction('LOGOUT');

export const refreshTokens = createAction(
  'TOKEN_REFRESH',
  (res) => (payload: { access: string; refresh: string }) => res(payload),
);
export const setIdAction = createAction('user/SET_ID', (res) => (payload: string) => res(payload));
export const setNotificationTokenAction = createAction(
  'user/SET_NOTIFICATION_TOKEN',
  (res) => (payload: string) => res(payload),
);
export const setUserType = createAction('user/SET_TYPE', (res) => (payload: string) => res(payload));
