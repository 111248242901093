/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Address, IListener, Listener, RepositoryFactoryHttp } from 'symbol-sdk';
import i18n from '../../i18n';
import store from '../../redux/store';
import type { NetworkModel } from '../../storage/models/NetworkModel';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';

export default class ListenerService {
  network!: NetworkModel;
  repositoryFactory!: RepositoryFactoryHttp;
  listener!: IListener;
  _onSuccess?: () => void;
  _onError?: (err: any) => void;

  constructor(onSuccess?: () => void, onError?: (e?: Error | string) => void) {
    if (onSuccess) {
      console.log('on success handler is set');
      this._onSuccess = onSuccess;
    }
    if (onError) {
      console.log('on error handler is set');
      this._onError = onError;
    }
  }

  onSuccess = () => {
    if (this._onSuccess) {
      this._onSuccess();
    }
  };

  onError = (err?: Error | string) => {
    if (this._onError) {
      this._onError(err);
    }
  };

  setNetwork = (network: NetworkModel) => {
    if (this.listener) {
      this.listener.close();
    }
    this.network = network;

    this.repositoryFactory = new RepositoryFactoryHttp(network.node.replace('https', 'wss'), {
      websocketInjected: WebSocket,
    });
    console.log('---', this.repositoryFactory);
    this.listener = this.repositoryFactory.createListener();
  };

  listen = (rawAddress: string) => {
    console.log('rawAddress listen :::', rawAddress);
    if (this.listener) {
      this.listener.close();
    }

    this.listener = this.repositoryFactory.createListener();
    const address = Address.createFromRawAddress(rawAddress);

    return this.listener
      .open(async (event: { client: string; code: any; reason: any }) => {
        console.log('listener', event);
        if (event && event.code !== 1005) {
          if (this.listener instanceof Listener) {
            await CommonHelpers.retryNTimes(this.listener, 3, 5000);
          }
        } else {
          this.showMessage('ws_connection_failed', 'danger');
        }
      })
      .then(() => {
        console.log('Listening ' + address.pretty());

        this.addConfirmed(rawAddress);
        this.addUnconfirmed(rawAddress);

        this.listener.aggregateBondedAdded(address).subscribe(() => {
          store.dispatch({ type: 'account/LOAD_ALL_DATA' });
        });

        this.listener.status(address).subscribe((error) => {
          this.showMessage(error.code, 'danger');
          store.dispatch({ type: 'account/LOAD_ALL_DATA' });
        });
      });
  };

  addConfirmed = (rawAddress: string) => {
    const address = Address.createFromRawAddress(rawAddress);
    this.listener.confirmed(address).subscribe((data: any) => {
      if (
        data?.recipientAddress?.address === rawAddress.split('-').join('') &&
        !!data?.transactionInfo?.hash &&
        data?.message?.type < 0
      ) {
        Toast.success(i18n.t('_New_confirmed_transaction_'));
      }
      this.showMessage('notification.newConfirmed', 'success');
      store.dispatch({ type: 'account/LOAD_ALL_DATA' });
    });
  };

  addUnconfirmed = (rawAddress: string) => {
    console.log('Adding unconfirmed listener: ' + rawAddress);
    const address = Address.createFromRawAddress(rawAddress);
    this.listener.unconfirmedAdded(address).subscribe(() => {
      store.dispatch({ type: 'account/LOAD_ALL_DATA' });
    });
  };

  showMessage = (message: string, type: 'danger' | 'warning' | 'success' = 'success') => {
    let newTpe = 'notification';
    switch (type) {
      case 'danger':
        newTpe = 'error';
        break;
      case 'success':
        newTpe = 'info';
        break;
      case 'warning':
        newTpe = 'notification';
        break;
    }
    console.log('newTpe :::', newTpe);
    console.log('message :::', message);
    if (type === 'danger') {
      this.onError(message);
    } else if (type === 'success') {
      this.onSuccess();
    }
  };
  close = () => this.listener?.close();
}
