import NoItem from '../NoItem';

const EmptyData = () => {
  return (
    <div className="EmptyData flexible aCenter jCenter">
      <NoItem />
    </div>
  );
};

export default EmptyData;
