import cn from 'classnames';
import { FC, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import './Timer.scss';

interface TimeStampProps {
  expiryTimestamp: Date | any;
  isRestarted?: boolean;
  onChangeReset?: () => void;
  showResendButton?: () => void;
}

export const Timer: FC<TimeStampProps> = ({ expiryTimestamp, isRestarted, showResendButton, onChangeReset }) => {
  const [isDisplayTimer, setIsDisplayTimer] = useState<boolean>(true);
  const handleExpire = () => {
    showResendButton && showResendButton();
    setIsDisplayTimer(false);
  };

  const { seconds, restart } = useTimer({ expiryTimestamp, onExpire: () => handleExpire() });
  useEffect(() => {
    if (isRestarted) {
      restart(expiryTimestamp);
      onChangeReset && onChangeReset();
      // showResendButton && showResendButton();
      setIsDisplayTimer(true);
    }
  }, [isRestarted]);

  return (
    <>
      {isDisplayTimer && (
        <div className={cn('timer', { display: isDisplayTimer })}>{`0:${
          seconds ? (Number(seconds) < 10 ? '0' + seconds.toString() : seconds.toString()) : '60'
        }`}</div>
      )}
    </>
  );
};
