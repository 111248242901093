import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button } from '../../../../../components/Button';
import Text from '../../../../../components/common/Text';
import { ReactComponent as SuccessIcon } from '../../../../../icons/accepted.svg';
import { ReactComponent as FailIcon } from '../../../../../icons/denied.svg';
import { registerWarranty } from '../../../../../modules/product/actions';
import styles from './styles.module.scss';

const OwnerStep: FC<{ isOwner?: boolean; productId: string; warrantyId?: string; withoutWarranty?: boolean }> = ({
  isOwner,
  productId,
  warrantyId,
  withoutWarranty,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleActivate = useCallback(() => {
    warrantyId && dispatch(registerWarranty.req({ warrantyId, productId }));
  }, [productId]);
  return (
    <div className={styles.ownerStepContainer}>
      <div className={styles.ownerStepContainer__icon}>{isOwner ? <SuccessIcon /> : <FailIcon />}</div>
      <Text type="h5" fontWeight="bold" color="dark-primary">
        {t(isOwner ? '_YouAreTheOwner_' : '_YouAreNotOwner_')}
      </Text>
      <div className={styles.ownerStepContainer__infoText}>
        <Text
          type="h5"
          fontWeight="regular"
          color="ultra-dark-gray"
          className={styles.ownerStepContainer__infoText__textRow}
        >
          {t('_ThisProductBelongsToYou_')}{' '}
          <Link to="/products">
            <Text type="h5" fontWeight="regular" color="primary-blue">
              {' '}
              {t('_ProductListing_')}.
            </Text>
          </Link>
        </Text>
        {isOwner && !withoutWarranty && (
          <Text type="h5" fontWeight="regular" color="ultra-dark-gray">
            {t('_ToActivateTheWarranty_')}
          </Text>
        )}
      </div>
      {isOwner && warrantyId && (
        <Button
          text={t('_ActivateWarranty_')}
          appearence="primary"
          className={styles.ownerStepContainer__button}
          onClick={handleActivate}
        />
      )}
    </div>
  );
};

export default OwnerStep;
