import cn from 'classnames';
import styles from './styles.module.scss';

const ButtonLoader = (props) => {
  const { className, color = 'primary', style, size = 'xs' } = props;

  const loaderClass = cn(
    styles.loader,
    {
      [styles[`loader_color_${color}`]]: color,
      [styles[`loader_size_${size}`]]: size,
    },
    className,
  );

  return <div className={loaderClass} style={style} />;
};
export default ButtonLoader;
