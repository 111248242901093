import cn from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { UserApi } from '../../services/api/UserApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { Button } from '../Button/Button';
import { ReactComponent as InfoIcon } from './../../icons/infoIcon.svg';
import Text from './../common/Text';
import styles from './styles.module.scss';

export interface Notification {
  createdAt: Date;
  updatedAt: Date;
  info: any;
  message: string;
  notificationId: string;
  status: string;
  subject: string;
  ttl: number;
  type: string;
  userId: string;
}

const NotificationDialog = ({ notifications, setNotifications }) => {
  const { t } = useTranslation();
  const readAll = useCallback(() => {
    UserApi.updateUserNotificationStatuses('viewed')
      .catch((e) => {
        return Toast.error(CommonHelpers.erorHandlerMessage(e));
      })
      .finally(() => {
        UserApi.getUserNotifications().then((res) => {
          setNotifications({
            data: res.data.data,
            total: res.data.total,
          });
        });
      });
  }, []);
  const onReadNotification = useCallback((item: Notification) => {
    if (item.status === 'viewed') return null;
    UserApi.updateUserNotification(item.notificationId, 'viewed').finally(() => {
      UserApi.getUserNotifications().then((res) => {
        setNotifications({
          data: res.data.data,
          total: res.data.total,
        });
      });
    });
  }, []);

  return (
    <>
      <div className={styles.notification__body}>
        <div className={styles.notification__body__top_title}>
          <Text type="subtitle_m" color="secondary-dark-blue">
            {t('_Notifications_')}
          </Text>
          {!!notifications.data.length && (
            <Button isGhost text="Read all" className={styles.fitContentButton} onClick={readAll} />
          )}
        </div>
        {!notifications.data.length && (
          <div className={cn(styles.notification__body__item, styles.notification__body__viewed_item)}>
            <InfoIcon width={20} height={20} className={styles.notification__body__icon} />
            <div className={styles.notification__body__info}>
              <div className={styles.notification__body__description}>You don&lsquo;t have any notifications yet</div>
            </div>
          </div>
        )}
        {notifications.data.map((item: Notification) => (
          <div
            key={item.notificationId}
            className={cn(
              styles.notification__body__item,
              item.status === 'viewed' ? styles.notification__body__viewed_item : '',
            )}
            onClick={() => onReadNotification(item)}
          >
            {item.status !== 'viewed' && <div className={styles.notification__body__leftBorder} />}
            <InfoIcon width={20} height={20} className={styles.notification__body__icon} />
            <div className={styles.notification__body__info}>
              <div className={styles.notification__body__title}>{item.subject}</div>
              <div className={styles.notification__body__description}>{item.message}</div>
              <div className={styles.notification__body__date}>
                {moment(new Date(item.createdAt)).format('DD/MM/YYYY, HH:mm')}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default NotificationDialog;
