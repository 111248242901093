import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as NoCardIcon } from '../../icons/card.svg';
// import { ReactComponent as PlusIcon } from '../../icons/cardplus.svg';
import { ReactComponent as CardIcon } from '../../icons/masterCard.svg';
import { ReactComponent as StripeIcon } from '../../icons/stripe.svg';
import { ReactComponent as TrashIcon } from '../../icons/trash.svg';
import { Button } from '../Button/Button';
import AddModal from './AddModal';
import DeleteModal from './DeleteModal';
import styles from './HiddenBankCardInfo.module.scss';

interface HiddenBankCardInfo {
  cardNumber: string;
  className: string;
  needToClose?: boolean;
  style?: any;
  data?: any;
  onAddCard?: () => void;
  onDelete?: (id: string) => void;
  setNeedToClose?: () => void;
}

const HiddenBankCardInfo = ({
  cardNumber,
  data,
  onAddCard,
  onDelete,
  isBusyRequest,
  needToClose,
  setNeedToClose,
  ...restProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenAddModal, setIsOpenAddModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (isOpen) {
      setIsOpen(!needToClose);
    }
    if (isOpenAddModal) {
      setIsOpenAddModal(!needToClose);
    }
  }, [needToClose]);

  return (
    <div className={styles.container} {...restProps}>
      {!data ? (
        <div className="flexible vertical">
          <div className="flexible aCenter">
            <NoCardIcon />
            <span className={styles.noCardText}>{t('_NoSavedCards_')}</span>
          </div>
          <Button
            isPrimary
            className={styles.payment_button}
            type="submit"
            text={t('_AddPaymentMethod_')}
            onClick={() => {
              setIsOpenAddModal(true);
              setIsEditMode(false);
            }}
          />
        </div>
      ) : (
        <div className="flexible aCenter">
          <div className="flexible aCenter">
            <CardIcon />
            <span className={styles.cardNumber}>**** {cardNumber}</span>
          </div>
          <div className="flexible aCenter">
            <StripeIcon className={styles.stripe} />
            <TrashIcon
              className={styles.trashIcon}
              onClick={() => {
                setIsOpen(true);
                setNeedToClose(false);
              }}
            />
          </div>
        </div>
      )}
      {isOpen && (
        <DeleteModal
          isBusyRequest={isBusyRequest}
          cardNumber={cardNumber}
          onDelete={() => onDelete(data?._id)}
          onClose={() => setIsOpen(false)}
        />
      )}
      {isOpenAddModal && (
        <AddModal
          data={data}
          isEditMode={isEditMode}
          isBusyRequest={isBusyRequest}
          onAddCard={(...args) => {
            onAddCard(...args);
            setNeedToClose(false);
          }}
          onClose={() => setIsOpenAddModal(false)}
        />
      )}
    </div>
  );
};

export default HiddenBankCardInfo;
