import { CognitoUser } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { Button } from '../../../components/Button/Button';
import Text from '../../../components/common/Text';
import { Input } from '../../../components/Input/Input';
import Loader from '../../../components/Loader';
import { useAction } from '../../../hooks';
import { useRedirect } from '../../../hooks/useRedirect';
import { logoutActionRequest, userLogin } from '../../../modules/auth/actions';
import { SecurityQuestionsApi } from '../../../services/api';
import { BaseSecureStorage } from '../../../storage/persistence/BaseSecureStorage';
import { Toast } from '../../../utils/toastHelper';
import { SecAnswersValidation as validationSchema } from '../../../utils/validations';
import styles from './styles.module.scss';

const SetSecAnswers: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const redirect = useRedirect('/role-selection');
  const logOut = useAction(logoutActionRequest);
  const [loading, setLoading] = useState(true);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [questionsOptions, setQuestionsOptions] = useState<{ label: string; value: string }[]>([]);

  useEffect(() => {
    (async () => {
      localStorage.setItem('authFlow', 'registration');
      try {
        const questionsOptions = await SecurityQuestionsApi.fetchSecurityQuestions();
        const parsedQuestionsOptions = questionsOptions.map((value) => {
          return {
            label: value.question,
            value: value._id,
          };
        });
        setQuestionsOptions(parsedQuestionsOptions);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
      }
    })();
  }, []);

  const onSubmit = async () => {
    setDisableButton(true);
    const oneTimePass = localStorage.getItem('oneTimePass');
    const payload: string = JSON.stringify([
      { questionId: values.questionOne.value, answer: values.answerOne.trim() },
      { questionId: values.questionTwo.value, answer: values.answerTwo.trim() },
      { questionId: values.questionThree.value, answer: values.answerThree.trim() },
    ]);

    if (oneTimePass) {
      const { email: username, password } = JSON.parse(BaseSecureStorage.decrypKey(oneTimePass) as string);
      const cognitoUser: CognitoUser = await Auth.signIn({ username, password });

      await Auth.sendCustomChallengeAnswer(cognitoUser, payload)
        .then(() => {
          dispatch(userLogin.request(cognitoUser));
          redirect();
        })
        .catch(() => {
          setDisableButton(false);
          Toast.error(t('_SomethingWrong_'));
        });
    } else {
      logOut({ onSuccess: () => history.push('/') });
    }
  };

  const { setFieldValue, values, errors, handleSubmit }: any = useFormik({
    initialValues: {
      questionOne: '',
      questionTwo: '',
      questionThree: '',
      answerOne: '',
      answerTwo: '',
      answerThree: '',
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className={styles.questionsContainer}>
      {loading ? (
        <div className={styles.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <>
          <Text className={styles.mainLabel} type="h1">
            {t('_CreateSecurityQuestion_')}
          </Text>
          <section className={styles.questionsBlock}>
            <p className={styles.label}>
              {t('_Question_')}
              <span> *</span>
            </p>
            <Select
              value={values.questionOne}
              onChange={(selectedOption) => setFieldValue('questionOne', selectedOption)}
              options={questionsOptions}
              name="questionOne"
              classNamePrefix="input-select"
              maxMenuHeight={240}
            />
            {errors && <span className="errors">{errors.questionOne?.value || errors.questionOne}</span>}
            <Input
              type="text"
              value={values.answerOne}
              isTextArea
              isExpanding
              isRequired
              label={t('_Answer_')}
              hasError={!!errors.answerOne}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerOne', e.target.value)}
              errorMessage={errors?.answerOne}
            />
          </section>
          <section className={styles.questionsBlock}>
            <p className={styles.label}>
              {t('_Question_')}
              <span> *</span>
            </p>
            <Select
              value={values.questionTwo}
              onChange={(selectedOption) => setFieldValue('questionTwo', selectedOption)}
              options={questionsOptions}
              name="questionTwo"
              classNamePrefix="input-select"
              maxMenuHeight={240}
            />
            {errors && <span className="errors">{errors.questionTwo?.value || errors.questionTwo}</span>}
            <Input
              type="text"
              value={values.answerTwo}
              isTextArea
              isExpanding
              isRequired
              label={t('_Answer_')}
              hasError={!!errors.answerTwo}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerTwo', e.target.value)}
              errorMessage={errors?.answerTwo}
            />
          </section>
          <section className={styles.questionsBlock}>
            <p className={styles.label}>
              {t('_Question_')}
              <span> *</span>
            </p>
            <Select
              value={values.questionThree}
              onChange={(selectedOption) => setFieldValue('questionThree', selectedOption)}
              options={questionsOptions}
              name="questionThree"
              classNamePrefix="input-select"
              maxMenuHeight={240}
            />
            {errors && <span className="errors">{errors.questionThree?.value || errors.questionThree}</span>}
            <Input
              type="text"
              value={values.answerThree}
              isTextArea
              isExpanding
              isRequired
              label={t('_Answer_')}
              hasError={!!errors.answerThree}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('answerThree', e.target.value)}
              errorMessage={errors?.answerThree}
            />
          </section>
          <Button
            onClick={handleSubmit}
            isPrimary
            isLoading={disableButton}
            isDisabled={
              !values.questionOne.value ||
              !values.questionTwo.value ||
              !values.questionThree.value ||
              !values.answerThree ||
              !values.answerTwo ||
              !values.answerOne
            }
          >
            {t('_Next_')}
          </Button>
        </>
      )}
    </div>
  );
};

export default SetSecAnswers;
