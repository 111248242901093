import { createReducer } from 'deox';
import produce from 'immer';
import { setApiKeys, setChangeLogs, setDocumentation } from './actions';
import { ApiDocStateType } from './types';

const initialState: ApiDocStateType = {
  changeLogs: {},
  documentation: null,
  apiKeys: {},
};

export const apiDocReducer = createReducer(initialState, (handle) => [
  handle(setChangeLogs.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.changeLogs = payload;
    }),
  ),
  handle(setDocumentation.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.documentation = payload;
    }),
  ),
  handle(setApiKeys.success, (state, { payload }: any) =>
    produce(state, (draft) => {
      draft.apiKeys = payload;
    }),
  ),
]);
