import moment from 'moment';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Text from '../../../../components/common/Text';
import { ProductTemplate } from '../../../../modules/product/types';
import { TemplateActions } from '../TemplateActions';
import { ReactComponent as NoProductImage } from './../../../../icons/no-product-image.svg';

const ProductTemplateRow = memo(
  ({
    item,
    openRemoveTemplate,
    openEditTemplate,
  }: {
    item: ProductTemplate;
    openRemoveTemplate: (id: string) => void;
    openEditTemplate: (id: string) => void;
  }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const handleClick = () => {
      history.push(`/template/${item._id}`);
    };

    return (
      <div className="products__item">
        <div className="products__item__first_col" onClick={handleClick}>
          <div className="products__preview products__item__first_col-sm-col">
            {item.info.images?.length ? (
              <img
                src={
                  item.info.images
                    ? process.env.REACT_APP_ATTACHMENTS_DOMAIN +
                      (item.info.images[0]?.src || item.info.images[0]?.location)
                    : ''
                }
                alt={item.name}
              />
            ) : (
              <NoProductImage />
            )}
          </div>
          <Text
            id="product-id"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
            className="products__item__first_col-lg-col"
          >
            {item.name}
          </Text>
          <Text
            id="product-id"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
            className="products__item__first_col-lg-col"
          >
            {item.category ? item.category.name : t('_NoCategory_')}
          </Text>
          <Text
            id="product-id"
            className="products__item__first_col-md-col"
            type="subtitle_s"
            color="dark-primary"
            fontWeight="regular"
          >
            {moment(item.createdAt).format('DD/MM/YYYY')}
          </Text>
        </div>
        <div>
          <TemplateActions template={item} onDeleteClicked={openRemoveTemplate} onEditClicked={openEditTemplate} />
        </div>
      </div>
    );
  },
);

export default ProductTemplateRow;
