import cn from 'classnames';
import { format } from 'date-fns';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import Label from '../../../../components/common/Label';
import styles from './styles.module.scss';

interface ProductWarrantyModalProps {
  open: boolean;
  onClose: () => void;
  warranty: any;
}
const ProductWarrantyModal: FC<ProductWarrantyModalProps> = ({ open, onClose, warranty }) => {
  const { t } = useTranslation();
  const getLeft = (date) => {
    const date1 = new Date();
    const date2 = new Date(date);
    //@ts-ignore
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return diffDays - 1 < 0 ? 0 : diffDays - 1;
  };

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      className={styles['modal']}
      overlayClassName={styles['modal-overlay']}
      contentLabel={t('_CreateProduct_')}
    >
      <Label isWarranty status={warranty.status} classname={styles['label']} />
      <span className={styles['modal-close']} onClick={onClose}>
        &#x2715;
      </span>

      <div className={cn('flexible', styles['modal-container'])}>
        <div className="flexible vertical grow jBetween">
          <div className={styles['owner-info']}>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_StartDate_')}</span>
              <span className={styles['value']}>
                {warranty.startDate ? format(new Date(warranty.startDate), 'dd/MM/yy') : '-'}
              </span>
            </div>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_EndDate_')}</span>
              <span className={styles['value']}>
                {warranty.endDate ? format(new Date(warranty.endDate), 'dd/MM/yy') : '-'}
              </span>
            </div>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_Period_')}</span>
              <span className={styles['value']}>
                {warranty.period} {t('_days_')}
              </span>
            </div>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_Left_')}</span>
              <span className={styles['value']}>
                {getLeft(warranty.updatedAt)} {t('_days_')}
              </span>
            </div>
          </div>
          <div className={styles['owner-info']}>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_Description_')}</span>
              <span className={styles['value']}>{warranty.description}</span>
            </div>
          </div>
          <div className={styles['owner-info']}>
            <div className={styles['owner-info__field']}>
              <span className={styles['title']}>{t('_UserDetails_')}</span>
              <span className={styles['value']}>{warranty.title}</span>
              <span className={styles['value']}>{warranty?.info?.email}</span>
            </div>
          </div>
        </div>
        <div className={cn(styles['owner-info'], 'flexible vertical')}>
          <div className={styles['owner-info__field']}>
            <span className={styles['title']}>{t('_Email_')}</span>
            <span className={styles['value']}>{warranty?.info?.email || '-'}</span>
          </div>
          <div className={styles['owner-info__field']}>
            <span className={styles['title']}>{t('_Phone_')}</span>
            <span className={styles['value']}>{warranty?.info?.phone || '-'}</span>
          </div>
          <div className={styles['owner-info__field']}>
            <span className={styles['title']}>{t('_Address_')}</span>
            <span className={styles['value']}>{warranty?.info?.address || '-'}</span>
          </div>
          <div className={styles['owner-info__field']}>
            <span className={styles['title']}>{t('_RequestDate_')}</span>
            <span className={styles['value']}>
              {warranty.updatedAt ? format(new Date(warranty.updatedAt), 'dd/MM/yy') : '-'}
            </span>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ProductWarrantyModal;
