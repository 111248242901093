import cn from 'classnames';
import React, { FC, useCallback, useState } from 'react';
import Textarea from 'react-expanding-textarea';
import InfoTitle from '../InfoTitle';
import { ReactComponent as HideIcon } from './../../icons/hide.svg';
import { ReactComponent as ShowIcon } from './../../icons/show.svg';

export interface InputProps {
  ref?: any;
  type?: string;
  name?: string;
  label?: string;
  placeholder?: string;
  isExpanding?: boolean;
  secondary?: boolean;
  primary?: boolean;
  withIcon?: boolean;
  className?: string;
  onFocus?: any;
  onBlur?: any;
  onChange?: any;
  onChangeTextArea?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  value?: any;
  isRequired?: boolean;
  isTextArea?: boolean;
  isReadOnly?: boolean;
  isDisabled?: boolean;
  isPassword?: boolean;
  isRessizeable?: boolean;
  labelClassName?: string;
  errorMessage?: string;
  size?: 'lg' | 'md';
  info?: string;
  hasError?: boolean;
}
export const Input: FC<InputProps> = ({
  ref,
  type,
  primary,
  secondary,
  placeholder,
  withIcon,
  label,
  name,
  info,
  className,
  onChange,
  onBlur,
  onFocus,
  onChangeTextArea,
  value,
  isExpanding,
  isRequired,
  isTextArea = false,
  isReadOnly = false,
  isDisabled = false,
  isPassword,
  isRessizeable,
  labelClassName = '',
  errorMessage,
  size = 'md',
  hasError,
}) => {
  const [isVisible, setIsvisible] = useState(false);
  const toggle = useCallback(() => {
    setIsvisible((prevState) => !prevState);
  }, []);
  return (
    <div className={cn('input', { hasError: hasError })}>
      {label && (
        <p className={labelClassName}>
          {label} {isRequired && <span>*</span>}
        </p>
      )}
      {isTextArea ? (
        <div className={cn('textarea', { 'with-info': !!info })}>
          {!!info && <InfoTitle info={info} />}
          {isExpanding ? (
            <Textarea
              ref={ref}
              placeholder={placeholder}
              className={cn('expanding', className)}
              value={value}
              readOnly={isReadOnly}
              onChange={onChangeTextArea ? onChangeTextArea : onChange}
              disabled={isDisabled}
              style={{ resize: isRessizeable ? 'vertical' : 'none' }}
            />
          ) : (
            <textarea
              ref={ref}
              placeholder={placeholder}
              className={className || ''}
              value={value}
              readOnly={isReadOnly}
              onChange={onChangeTextArea ? onChangeTextArea : onChange}
              disabled={isDisabled}
              style={{ resize: isRessizeable ? 'vertical' : 'none' }}
            />
          )}
        </div>
      ) : isPassword ? (
        <div className="password_input-container">
          <input
            autoComplete="new-password"
            type={isVisible ? 'text' : 'password'}
            name={name}
            value={value}
            ref={ref}
            placeholder={placeholder}
            className={cn('input', className || '', {
              'input-primary': primary,
              'input-secondary': secondary,
              'input-with-icon': withIcon,
              error: className === 'error',
              'lg-input': size === 'lg',
            })}
            onChange={onChange}
            onBlur={onBlur}
            onFocus={onFocus}
            readOnly={isReadOnly}
            disabled={isDisabled}
          />
          {isVisible ? <ShowIcon onClick={toggle} /> : <HideIcon onClick={toggle} />}
        </div>
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          ref={ref}
          placeholder={placeholder}
          className={cn('input lg-input', className || '', {
            'input-primary': primary,
            'input-secondary': secondary,
            'input-with-icon': withIcon,
            'lg-input': size === 'lg',
            error: className === 'error' || errorMessage,
          })}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          readOnly={isReadOnly}
          disabled={isDisabled}
        />
      )}
      {errorMessage && <span className="errors">{errorMessage}</span>}
    </div>
  );
};
