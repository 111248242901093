/* eslint-disable react/no-unescaped-entities */
import { sha256 } from 'js-sha256';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { Modal } from '../../../../components/Modal/Modal';
import { getExplorerURL } from '../../../../config/environment';
import { ReactComponent as CopyIcon } from '../../../../icons/copy.svg';
import { Toast } from '../../../../utils/toastHelper';
import styles from './ProductSummary.module.scss';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  product: any;
}

const VerifyHashModal = ({ isOpen, onClose, product }: Props) => {
  const { t } = useTranslation();
  const { network } = useSelector((state: any) => state.network);

  const productData = useMemo(() => {
    if (!product) return { productData: '', hash: '' };

    const obj: any = {
      productName: product.name || '',
      description: product.description || '',
      _id: product._id,
      infoFields: [],
    };
    const fields = product.fields || product.info?.fields || [];
    if (Object.keys(fields).length) {
      Object.keys(fields).forEach((name) => {
        obj.infoFields.push({
          name,
          value: fields[name].value,
        });
      });
    }

    const string = JSON.stringify(obj);
    const hash = sha256(string);
    return { string, hash };
  }, [product]);

  const copyToClipboard = useCallback((text) => {
    text?.length &&
      navigator.clipboard.writeText(text as string).then(() => {
        Toast.info(t('_CopiedToClipboard_'));
      });
  }, []);

  return (
    <Modal open={isOpen} onClose={onClose}>
      <div className={styles.verifyHashModal}>
        <Text>
          To verify the transaction, you can visit{' '}
          <a
            href={`${getExplorerURL(network)}/transactions/${product.transactionId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            block explorer
          </a>{' '}
          and check the "Message" in the "Transaction Detail":
        </Text>
        <div className={styles.inputWrapper}>
          <Input value={productData.hash} />
          <CopyIcon className={styles.copy} onClick={() => copyToClipboard(productData.hash)} />
        </div>
        <Text>You can also copy the following text:</Text>
        <div className={styles.inputWrapper}>
          <Input value={productData.string} />
          <CopyIcon className={styles.copy} onClick={() => copyToClipboard(productData.string)} />
        </div>
        <Text>
          And paste it into any online SHA256 hash generator to verify that they are the same <br />
          (e.g.{' '}
          <a href="https://emn178.github.io/online-tools/sha256.html" target="_blank" rel="noopener noreferrer">
            https://emn178.github.io/online-tools/sha256.html
          </a>
          )
        </Text>
      </div>
    </Modal>
  );
};

export default VerifyHashModal;
