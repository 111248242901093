/*eslint-disable */

import ExcelJS from 'exceljs'; // https://github.com/sumankalia/export-xlsx-react/blob/main/src/App.js
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import _ from 'lodash';
import QRCode from 'qrcode';
import { ProductCombined } from '../interfaces/productCombined.interface';

export const generateQRCode = async (value: string, name: string = 'qrcode'): Promise<{ name: string; blob: Blob }> => {
  try {
    if (!value) throw new Error('Missing required argument: VALUE');
    const svgCodeString: string = await QRCode.toString(value, { type: 'svg', width: 200 });
    return {
      name: name,
      blob: new Blob([svgCodeString], { type: 'image/svg+xml' }),
    };
  } catch (error) {
    console.error('Error Generating QR Code');
    return Promise.reject(error);
  }
};

export const JSONToExcel = async (
  rows: any[],
  columns: { header: string; key: string; width?: number }[],
  name: string = 'Sheet',
  defaultRowHeight: number = 80,
): Promise<Blob> => {
  try {
    if (!rows) throw new Error('Missing required argument: Rows');
    if (!columns || columns.length === 0) throw new Error('Missing required argument: Columns');

    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet(name);
    sheet.properties.defaultRowHeight = defaultRowHeight;
    sheet.columns = columns;
    rows.map((row) => sheet.addRow(row));
    const buffer = await workbook.xlsx.writeBuffer();
    return new Blob([buffer], { type: 'application/octet-stream' });
  } catch (error) {
    console.error('Error Generating Excel');
    return Promise.reject(error);
  }
};

export const compressToZip = async (files: { name: string; blob: Blob }[]): Promise<Blob> => {
  try {
    if (!files) throw new Error('Missing required argument: Files');
    const zip = new JSZip();
    files.forEach((file) => zip.file(file.name, file.blob));
    return await zip.generateAsync({ type: 'blob' });
  } catch (error) {
    console.error('Error compress Blob files to Zip Archive');
    return Promise.reject(error);
  }
};

export const exportZipArchive = async (
  rows: ProductCombined.Combined[],
  columns?: {
    header: string;
    key: string;
    width?: number;
  }[],
) => {
  const _timeLog = 'Generate files and compress to zip';
  console.time(_timeLog);

  if (!columns) {
    columns = [
      { header: 'Product Link', key: 'productLink', width: 50 },
      { header: 'Name', key: 'name', width: 32 },
      { header: 'Category', key: 'category', width: 20 },
      { header: 'Public QR', key: 'publicQR', width: 32 },
      { header: 'Private QR', key: 'privateQR', width: 20 },
      { header: 'Public QR File', key: 'publicQRFile', width: 18 },
      { header: 'Private QR File', key: 'privateQRFile', width: 18 },
    ];
  }

  const qrCodesTuple: [{ name: string; blob: Blob }, { name: string; blob: Blob }][] = await Promise.all(
    rows.map(async (value) => {
      const _public = await generateQRCode(value.publicQR, value.publicQRFile);
      const _private = await generateQRCode(value.privateQR ?? 'null', value.privateQRFile); // privateQR must be required
      return [_public, _private];
    }),
  );

  const excelBlob = await JSONToExcel(rows, columns);

  const zipBlob = await compressToZip([..._.flatten(qrCodesTuple), { name: 'excel.xlsx', blob: excelBlob }]);

  saveAs(zipBlob, 'archive.zip');
  console.timeEnd(_timeLog);
};

export default exportZipArchive;
