import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BusyLoader from '../../components/BusyLoader';
import { Button } from '../../components/Button/Button';
import Text from '../../components/common/Text';
import EmployeeAssessment from '../../components/EmployeeAssesment';
import { Input } from '../../components/Input/Input';
import Table from '../../components/Table';
import { useDebounce } from '../../hooks/useDebounce';
import { ReactComponent as SearchIcon } from '../../icons/search.svg';
import { ReactComponent as RemoveIcon } from '../../icons/trash.svg';
import { ReactComponent as ViewIcon } from '../../icons/view.svg';
import { setUserManagmentList } from '../../modules/cv/actions';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import columns from './columns.json';
import './UserManagement.scss';

const UserManagement = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.user);
  const { userManagmentList } = useSelector((state: any) => state.cv);
  const isEmployee = useSelector((state: any) => state.user?.userData?.role === 'employee');
  const [searchValue, setSearchValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [hasChange, setHasChange] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isBusyRequest, setIsBusyRequest] = useState<boolean>(false);

  const debouncedValue = useDebounce(searchValue, 500);

  const onFinish = () => {
    setIsBusy(false);
  };

  const fetchUserManagementList = useCallback((filterParams?: any) => {
    setIsBusy(true);
    dispatch(
      setUserManagmentList.request({
        organizationId: userData.organizationId || userData.inviteCode,
        onFinish,
        ...(filterParams && { filterParams }),
      }),
    );
  }, []);

  useEffect(() => {
    fetchUserManagementList();
  }, []);

  useEffect(() => {
    hasChange &&
      (debouncedValue?.length > 2 || !debouncedValue?.length) &&
      fetchUserManagementList(debouncedValue ? { email: debouncedValue } : undefined);
  }, [debouncedValue, hasChange]);

  const onSendInvite = async (data) => {
    setIsBusyRequest(true);
    try {
      const res = await OrganizationApi[!inputValue ? 'inviteUser' : 'reInviteUser'](
        { email: data.positionName, ...(data?.comment && { comment: data.comment }) },
        userData.organizationId || userData.inviteCode,
      );
      if (res) {
        setIsOpen(false);
        setIsBusyRequest(false);
        fetchUserManagementList();
        Toast.success(t('_InvitationSuccessfulySent_'));
      }
    } catch (e) {
      setIsBusyRequest(false);
      const message = CommonHelpers.erorHandlerMessage(e);
      console.error(message);
      return Toast.error(message);
    }
  };

  const onDelete = async (id) => {
    setIsBusy(true);
    try {
      await OrganizationApi.deleteUserManagmentInfo(userData.organizationId || userData.inviteCode, id);
      fetchUserManagementList();
    } catch (e) {
      setIsBusy(false);
      const message = CommonHelpers.erorHandlerMessage(e);
      console.error(message);
      return Toast.error(message);
    }
  };

  const actions = (item) => (
    <div className="actions flexible aCenter">
      <ViewIcon
        onClick={() =>
          history.push({
            pathname: `/user-management-profile/${item._id}`,
            state: item,
          })
        }
      />
      {item.email !== userData.email && !isEmployee && <RemoveIcon onClick={() => onDelete(item._id)} />}
    </div>
  );

  const onItemKeyClick = (item) => {
    setInputValue(item.email);
    setIsOpen(true);
  };

  return (
    <div className="page-header UserManagement">
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_User_Management_')}
      </Text>
      <div className="search-filter-block flexible jBetween aCenter">
        <div className="search-input">
          <Input
            type="text"
            value={searchValue}
            placeholder={t('_SearchByEmail_')}
            onChange={({ target }) => {
              setSearchValue(target.value);
              setHasChange(true);
            }}
          />
          <SearchIcon className="search-icon" />
          {searchValue?.length > 0 && searchValue?.length < 3 && (
            <div className="search-tooltip">{t('_PleaseEnter3OrMore_')}</div>
          )}
        </div>
        <Button
          isPrimary
          className="flexible aCenter jCenter"
          onClick={() => {
            setIsOpen(true);
            setInputValue('');
          }}
        >
          {t('_Invite_User_')}
        </Button>
      </div>
      <BusyLoader className="table-content" isBusy={isBusy}>
        <Table
          data={userManagmentList?.data || []}
          actions={actions}
          columns={columns}
          onItemKeyClick={onItemKeyClick}
        />
      </BusyLoader>
      {isOpen && (
        <EmployeeAssessment
          isApprove
          withoutRate
          emailCheck
          description={t('_PleaseNoteThatOnceInvited_')}
          isCommentAdditional
          title={t('_Invite_User_via_email_')}
          inputLabel={t('_Email_')}
          inputValue={inputValue}
          inputPlaceHolder="email@email.com"
          buttonText={t('_SendInvitation_')}
          isBusyRequest={isBusyRequest}
          textareaPlaceHolder={t('_LeaveComment_')}
          onClose={() => {
            setIsOpen(false);
            setInputValue('');
          }}
          onRequestCheck={onSendInvite}
        />
      )}
    </div>
  );
};

export default UserManagement;
