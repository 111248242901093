import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Text from '../../../components/common/Text';
import { useRedirect } from '../../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg';
import ConfirmCodeStep from './ConfirmCodeStep';
import PasswordStep from './PasswordStep';
import './styles.scss';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { userData } = useSelector((state: any) => state.user);

  const handleBack = useRedirect('/settings');
  const [step, setStep] = useState(1);
  const [confirmationCode, setConfirmationCode] = useState('');
  const renderContent = useCallback(() => {
    switch (step) {
      case 1:
        return (
          <ConfirmCodeStep
            next={() => setStep(2)}
            email={userData.email}
            confirmationCode={confirmationCode}
            setConfirmationCode={setConfirmationCode}
          />
        );
      case 2:
        return (
          <PasswordStep
            isNewPasswordStep
            email={userData.email}
            confirmationCode={confirmationCode}
            next={() => {
              setStep(1);
              handleBack();
            }}
          />
        );
      default:
        return null;
    }
  }, [step, confirmationCode]);
  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title authenticated-page-title">
          {t('_ChangePassword_')}
        </Text>
        {renderContent()}
      </div>
    </>
  );
};

export default ChangePassword;
