import { all } from 'redux-saga/effects';
import { watchAccount } from '../../modules/account/saga';
import { watchApiDocs } from '../../modules/apiDoc/saga';
import { watchLogin } from '../../modules/auth/saga';
import { watchCommon } from '../../modules/common/sagas';
import { watchCvs } from '../../modules/cv/saga';
import { watchErrors } from '../../modules/errors/sagas';
import { watchHarvesting } from '../../modules/harvesting/saga';
import { watchNetwork } from '../../modules/network/saga';
import { watchOrganization } from '../../modules/organization/saga';
import { watchSubscriptions } from '../../modules/payment/saga';
import { watchProduct } from '../../modules/product/saga';
import { watchTransactions } from '../../modules/transaction/saga';
import { watchUser } from '../../modules/user/saga';
import { watchWallet } from '../../modules/wallet/saga';

export default function* rootSaga() {
  yield all([
    watchErrors(),
    watchCommon(),
    watchLogin(),
    watchProduct(),
    watchNetwork(),
    watchWallet(),
    watchAccount(),
    watchTransactions(),
    watchHarvesting(),
    watchUser(),
    watchOrganization(),
    watchSubscriptions(),
    watchCvs(),
    watchApiDocs(),
  ]);
}
