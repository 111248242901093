import { Auth } from 'aws-amplify';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactPinField from 'react-pin-field';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Toast } from '../../../../utils/toastHelper';
import { Timer } from '../../../ForgotPassword/components/Timer';

interface IProps {
  next: () => void;
  email: string;
  confirmationCode: string;
  setConfirmationCode: (code: string) => void;
}

const ConfirmCodeStep: FC<IProps> = ({ next, email, confirmationCode, setConfirmationCode }) => {
  const { t } = useTranslation();
  const ref = useRef();
  const time = new Date();
  time.setSeconds(time.getSeconds() + 60);
  const [isRestart, setIsRestart] = useState<boolean>(false);
  const [isDisabledResendButton, setIsDisabledResendButton] = useState<boolean>(true);
  useEffect(() => {
    Auth.forgotPassword(email);
  }, []);
  const resendCode = async () => {
    try {
      await Auth.resendSignUp(email);
      Toast.success(t('_ConfirmationCodeHasBeenResent_'));
      setIsRestart(true);
    } catch (e) {
      Toast.error(e.message);
    }
  };
  const handleSubmit = () => {
    next();
  };
  const handleFill = (value) => {
    setConfirmationCode(value);
  };
  const handleFocus = useCallback(() => {
    const elems: any = ref.current;
    elems.inputs.forEach((input) => {
      if (input.value) return;
      input.placeholder = '';
    });
  }, []);
  const handleBlur = useCallback(() => {
    const elems: any = ref.current;
    elems.inputs.forEach((input) => {
      if (input.value) return;
      input.placeholder = '0';
    });
  }, []);
  return (
    <div className="oldPasswordContainer">
      <ReactPinField
        length={6}
        validate="0123456789"
        name="confirmationCode"
        className="enter-code-input"
        onChange={handleFill}
        onComplete={handleFill}
        placeholder={'0'}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />
      <div className="forgot-password__timer">
        <Timer
          expiryTimestamp={time}
          isRestarted={isRestart}
          showResendButton={() => setIsDisabledResendButton(false)}
          onChangeReset={() => {
            setIsRestart(false);
          }}
        />
      </div>
      <div className="login__button-wrapper">
        <Button
          isPrimary
          className="login__button"
          type="submit"
          text={t('_ResetPassword_')}
          isDisabled={!confirmationCode || confirmationCode.length !== 6}
          onClick={handleSubmit}
        />
      </div>
      <div className="login__link-signup">
        <span>{t('_DidNotReceiveTheLetter_')}</span>
        <Text
          color={isDisabledResendButton ? 'ultra-dark-gray' : 'primary-blue'}
          className={isDisabledResendButton ? 'disabled' : 'enabled'}
          onClick={() => !isDisabledResendButton && resendCode()}
        >
          {t('_Resend_')}
        </Text>
      </div>
    </div>
  );
};

export default ConfirmCodeStep;
