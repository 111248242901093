import { Radio } from '@mui/material';
import cs from 'classnames';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button';
import Label from '../../components/common/Label';
import Text from '../../components/common/Text';
import TextGroup from '../../components/common/TextGroup';
import Loader from '../../components/Loader';
import { Modal } from '../../components/Modal/Modal';
import { loadAccountAction, loadAccountsBalancesAction } from '../../modules/wallet/actions';
import { CommonApi } from '../../services/api';
import { COUNTRY_LIST } from '../../utils/constants';
import { formatUserBirtdate } from '../../utils/dates';
// import { AccountSecureStorage } from '../../storage/persistence/AccountSecureStorage';
import GradientBorder from './../../components/common/GradientBorder';
import French_flag from './../../icons/flags/france.png';
import UK_flag from './../../icons/flags/united-kingdom.png';
import { ReactComponent as BinIcon } from './../../icons/trash.svg';
import RemoveAccountModal from './RemoveAccountModal/index';
import styles from './styles.module.scss';

const getCountryName = (code: any) => {
  if (!code) return '-';
  const selected = COUNTRY_LIST.filter((item) => Object.values(item)?.[0] === code);
  return selected?.[0] && Object.keys(selected?.[0])?.[0];
};

const Settings: FC = () => {
  const { t, i18n } = useTranslation();
  const { username } = useSelector((state: any) => state.auth);
  const { userData, loading } = useSelector((state: any) => state.user);
  // const { accountBalances, selectedAccount } = useSelector((state: any) => state.wallet);
  const { balance } = useSelector((state: any) => state.account);
  const dispatch = useDispatch();
  const history = useHistory();
  const defaultLang = localStorage.getItem('selectedLang') || 'en';
  const [selectedLang, setSelectedLang] = useState(defaultLang);
  const [visible, setVisible] = useState(false);
  const [KYC_isEnabled, setKYC_isEnabled] = useState(true);

  useEffect(() => {
    dispatch(loadAccountAction({}));
  }, []);

  useEffect(() => {
    CommonApi.getConfiguration()
      .then((res) => {
        setKYC_isEnabled(res.data.kycEnabled);
      })
      .catch((e) => {
        console.error(e);
        setKYC_isEnabled(true);
      });
  }, []);

  useEffect(() => {
    dispatch(loadAccountsBalancesAction());
    // AccountSecureStorage.removeAccount('CBEEBE6EFB2EBF9DF6F33F7E0FF6CBD2D1AF5C53F45238FCF5639F4327A8CFC5', 'testnet')
  }, [username]);

  const handleChange = useCallback((e) => {
    const lang = e.target.name;
    i18n.changeLanguage(lang);
    setSelectedLang(lang);
    localStorage.setItem('selectedLang', lang);
  }, []);

  const toggleModal = useCallback((status: boolean) => {
    setVisible(status);
  }, []);
  const redirectTo = useCallback((path: string) => {
    history.push(path);
  }, []);

  const userKYCVerified = userData?.kyc_status === 'accepted';

  return (
    <>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_Settings_')}
      </Text>
      {loading ? (
        <div className={cs('loader_wrapper', styles['loader_wrapper'])}>
          <Loader />
        </div>
      ) : userData ? (
        <div className={styles.settingsContainer}>
          <div className={styles.settingsWrapper}>
            <div className={styles.settingsWrapper__statusContainer}>
              <Label
                status={userKYCVerified ? 'verified' : 'expired'}
                text={userKYCVerified ? t('_Verified_') : t('_NotVerified_')}
              />
              {KYC_isEnabled && !userKYCVerified && (
                <div className={styles.settingsWrapper__statusContainer__messageContainer}>
                  <Text color="ultra-dark-gray" type="subtitle_m">
                    {t('_YourAccountHasNotBeenVerified_')}
                  </Text>
                  <Button appearence="blueBorder" text={t('_TryVerify_')} onClick={() => history.push('/kyc-intro')} />
                </div>
              )}
            </div>
            <div className={styles.settingsWrapper__walletContainer}>
              <div className={styles.settingsWrapper__walletContainer__balance}>
                <Text type="h3" color="dark-primary">
                  {t('_Balance_')}
                </Text>
                <Text color="primary-blue" type="h3" fontWeight="bolder">
                  {balance || 0} {process.env.REACT_APP_MARKET_CURRENCY_NAME}
                </Text>
              </div>
              <div className={styles.settingsWrapper__walletContainer__buttons}>
                <Button
                  appearence="primary"
                  className={styles.buttonStyle}
                  text={t('_WalletDetails_')}
                  onClick={() => redirectTo('/wallet-details')}
                />
                <Button
                  appearence="white"
                  className={styles.buttonStyle}
                  text={t('_ImportNewWallet_')}
                  onClick={() => redirectTo('/import-wallet')}
                />
              </div>
            </div>
            <GradientBorder />
            <div className={styles.settingsWrapper__personalInfoContainer}>
              <Text
                type="subtitle_m"
                color="ultra-dark-gray"
                className={styles.settingsWrapper__personalInfoContainer__title}
              >
                {t('_PersonalInfo_')}
              </Text>
              <div className={styles.settingsWrapper__personalInfoContainer__groupRow}>
                <TextGroup
                  label={t('_Name_')}
                  text={
                    `${userData?.info?.firstName} ${userData?.info?.middleName} ${userData?.info?.lastName}`
                      .replace('  ', ' ')
                      .trim() || '-'
                  }
                />
                <TextGroup label={t('_Address_')} text={userData.info.address || '-'} />
              </div>
              <div className={styles.settingsWrapper__personalInfoContainer__groupRow}>
                <TextGroup
                  label={t('_DateOfBirth_')}
                  text={formatUserBirtdate(userData.info.birthdate, '-', '/') || '-'}
                />
                <TextGroup label={t('_Phone_')} text={userData.info.phone} />
              </div>
              <div
                className={cs(
                  styles.settingsWrapper__personalInfoContainer__groupRow,
                  styles.settingsWrapper__fullWidth,
                )}
              >
                <TextGroup label={t('_Country_')} text={getCountryName(userData.info.country)} />
              </div>
              <Button appearence="primary" text={t('_Edit_')} onClick={() => redirectTo('/settings/profile')} />
              <GradientBorder />
            </div>

            <div className={styles.settingsWrapper__personalInfoContainer}>
              <Text
                type="subtitle_m"
                color="ultra-dark-gray"
                className={styles.settingsWrapper__personalInfoContainer__title}
              >
                {t('_ChangeEmail_')}
              </Text>
              <div className={styles.settingsWrapper__personalInfoContainer__groupRow}>
                <TextGroup className={styles.email_field} label={t('_Email_')} text={userData.email || '-'} />
              </div>
              <Button appearence="primary" text={t('_Edit_')} onClick={() => redirectTo('/settings/change-email')} />
              <GradientBorder />
            </div>

            <div className={styles.settingsWrapper__langContainer}>
              <Text type="subtitle_m" color="ultra-dark-gray" className={styles.settingsWrapper__langContainer__title}>
                {t('_ChangeLanguage_')}
              </Text>
              <div className={styles.settingsWrapper__langContainer__row}>
                <div className={styles.settingsWrapper__langContainer__row__box}>
                  <img src={UK_flag} alt="UK" />
                  <Radio checked={selectedLang === 'en'} value="en" onChange={handleChange} name="en" />
                  <Text color="dark-primary">English</Text>
                </div>
                <div className={styles.settingsWrapper__langContainer__row__box}>
                  <img src={French_flag} alt="Francias" className={styles.flagStyle} />
                  <Radio checked={selectedLang === 'fr'} value="fr" onChange={handleChange} name="fr" />
                  <Text color="dark-primary">Français</Text>
                </div>
              </div>
            </div>
            <GradientBorder />
            <div className={styles.settingsWrapper__changePassBtnContainer}>
              <Button
                appearence="primary"
                text={t('_ChangePassword_')}
                onClick={() => redirectTo('/settings/change-password')}
              />
            </div>
            <GradientBorder />
            <div
              className={cs(
                styles.settingsWrapper__changePassBtnContainer,
                styles.settingsWrapper__removeAccountBtnContainer,
              )}
            >
              <BinIcon />
              <Text fontWeight="bolder" onClick={() => toggleModal(true)} color="red">
                {t('_DeleteAccount_')}
              </Text>
            </div>
          </div>
        </div>
      ) : null}
      <Modal open={visible} onClose={() => toggleModal(false)}>
        <RemoveAccountModal onClose={() => toggleModal(false)} />
      </Modal>
    </>
  );
};

export default Settings;
