import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/common/Text';
import EmptyScan from '../../components/EmptyScan';
import Loader from '../../components/Loader';
import Pagination from '../../components/Pagination/Pagination';
import { getScanHistory } from '../../modules/product/actions';
import IndividualProductRow from '../Products/components/IndividualProductRow';
import styles from './styles.module.scss';

const ScanHistory: FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState<number>(1);
  useEffect(() => {
    dispatch(getScanHistory.req());
    return () => {
      dispatch(getScanHistory.reset());
    };
  }, []);
  const { scanHistory, dataLoading } = useSelector((state: any) => state.products);
  const { data } = scanHistory;

  const currentData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * 4;
    const lastPageIndex = firstPageIndex + 4;
    const currentArr = data?.slice(firstPageIndex, lastPageIndex) || [];
    return currentArr;
  }, [data, currentPage]);
  return (
    <div>
      <Text type="h1" className="page__title authenticated-page-title">
        {t('_ScanHistory_')}
      </Text>
      <div className={styles.products__wrapper}>
        {dataLoading ? (
          <div className="loader_wrapper">
            <Loader />
          </div>
        ) : currentData?.length ? (
          currentData.map((item: any) => {
            return item.product ? (
              <IndividualProductRow key={item._id} item={item.product} fromScanHistory />
            ) : (
              <IndividualProductRow key={item._id} item={item.cv} isCV_item />
            );
          })
        ) : (
          <EmptyScan fromHistory />
        )}
        {!dataLoading && (
          <div className={styles['pagination-container']}>
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={data?.length}
              pageSize={4}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ScanHistory;
