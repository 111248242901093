import { createReducer } from 'deox';
import produce from 'immer';
import {
  setBalanceAction,
  setCosignatoryOfAction,
  setIsMultisigAction,
  setLoadingAction,
  setOwnedMosaicsAction,
  setSelectedAccountAddressAction,
} from '../account/actions';
import { setError } from '../errors/action';
import { AccountState } from './types';

const initialState: AccountState = {
  refreshingObs: null,
  selectedAccount: {},
  selectedAccountAddress: '',
  loading: true,
  balance: 0,
  isMultisig: false,
  ownedMosaics: [],
  accounts: [],
  cosignatoryOf: [],
  pendingSignature: false,
  error: null,
};

export const accountReducer = createReducer(initialState, (handle) => [
  handle(setSelectedAccountAddressAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.selectedAccountAddress = payload;
    }),
  ),
  handle(setLoadingAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(setBalanceAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.balance = payload;
    }),
  ),
  handle(setIsMultisigAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.isMultisig = payload;
    }),
  ),
  handle(setOwnedMosaicsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.ownedMosaics = payload;
    }),
  ),
  handle(setCosignatoryOfAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.cosignatoryOf = payload;
    }),
  ),
  handle(setError, (state, { payload }) =>
    produce(state, (draft) => {
      draft.error = payload;
    }),
  ),
]);
