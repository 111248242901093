import sortby from 'lodash.sortby';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import BusyLoader from '../../components/BusyLoader';
import Text from '../../components/common/Text';
import SubscriptionCard from '../../components/SubscriptionCard';
import Switcher from '../../components/Switcher';
import currencies from '../../config/currencies';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { setCurrentSubscription, setSubscriptions } from '../../modules/payment/actions';
import { getUserData } from '../../modules/user/actions';
import { PaymentsApi } from '../../services/api/PaymentsApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import './Subscription.scss';

const color = ['#F6CF2F', '#55db75', '#56d0fd', '#766fe6'];

const Subscription = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { subscriptions, currentPlan } = useSelector((state: any) => state.payments);
  const { userData } = useSelector((state: any) => state.user);
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState(false);
  const [isBusySub, setIsBusySub] = useState<boolean>(false);
  const [selected, setSelected] = useState<string>('');

  const history = useHistory();

  const isFromRegistration = useMemo(() => history.location.pathname === '/subscription-plan', []);

  const onFinish = () => {
    setIsBusySub(false);
  };

  const fetchSubscriptions = useCallback(() => {
    setIsBusySub(true);
    dispatch(setSubscriptions.request({ onFinish }));
  }, [dispatch]);

  const fetchCurrentSubscription = useCallback(
    () => dispatch(setCurrentSubscription.request({ onFinish })),
    [dispatch],
  );

  const handleUpdate = () => {
    userData?._id && dispatch(getUserData.req(userData?._id));
  };

  const handleBack = () => {
    history.goBack();
  };

  useEffect(() => {
    currentPlan?.plan?.priceId && setSelected(currentPlan?.plan?.priceId);
  }, [currentPlan?.plan?.priceId]);

  useEffect(() => {
    if (currentPlan?._id && currentPlan?.status !== 'incomplete') {
      history.replace('/organisation-details');
    }
  }, [currentPlan]);
  useEffect(() => {
    handleUpdate();
    fetchSubscriptions();
  }, [currentPlan, selected]);

  useEffect(() => {
    fetchCurrentSubscription();
  }, []);

  const changedData = useMemo(
    () =>
      subscriptions?.data?.map((item) => ({
        ...item,
        metadata: Object.keys(item.metadata).map((el, index) => ({
          isActive: item.title !== 'Free' || index !== 1,
          name: el,
          limit: item.title === 'Free' ? 0 : item?.metadata?.[el],
        })),
        prices: item.prices.filter((item) => item.active),
      })) || [],
    [subscriptions],
  );

  const handleMessage = (e) => {
    const message = CommonHelpers.erorHandlerMessage(e);
    console.error(message);
    setIsBusy(false);
    return Toast.error(message);
  };

  const removeSubIdFromUser = () => {
    const user = { ...userData };
    delete user.subscriptionId;
    dispatch(getUserData.success(user));
    dispatch(setCurrentSubscription.success({}));
  };

  const handleClick = async (item) => {
    setSelected(item.prices?.[isActive ? 0 : 1]?._id);
    setIsBusy(true);
    if (currentPlan?.plan?.priceId === item.prices?.[isActive ? 0 : 1]?._id && currentPlan.status === 'active') {
      try {
        await PaymentsApi.deleteSubscription();
        removeSubIdFromUser();
        handleUpdate();
        Toast.success(t('_SuccessfullyUnSubscribed_'));
        setIsBusy(false);
      } catch (e) {
        handleMessage(e);
        e?.response?.data?.message === 'No such subscription' && removeSubIdFromUser();
      }
      fetchSubscriptions();
    } else {
      try {
        if (currentPlan?.plan?.productId) {
          await PaymentsApi.deleteSubscription();
        }
        if (item?.prices?.[0]?.amount) {
          const successUrl = `${process.env.REACT_APP_URL}organisation-details`;
          const cancelUrl = `${process.env.REACT_APP_URL}subscription-plan`;
          const res = await PaymentsApi.createCheckoutSession({
            priceId: item?.prices[isActive ? 0 : 1]?._id,
            successUrl,
            cancelUrl,
          });
          handleUpdate();
          if (res?.checkoutUrl) {
            dispatch(setCurrentSubscription.request({ onFinish }));
            window.location.href = res?.checkoutUrl;
          }
        } else {
          await PaymentsApi.createSubscriptions({ priceId: item.prices?.[isActive ? 0 : 1]?._id });
          dispatch(
            setCurrentSubscription.request({
              onFinish: () => {
                onFinish();
                setIsBusy(false);
              },
            }),
          );
        }
      } catch (e) {
        handleMessage(e);
      }
    }
  };

  const sortedData = sortby(changedData, (item) => item?.prices?.[0]?.amount);

  return (
    <div
      className={
        isFromRegistration ? 'page-header Subscription registration-subscription-step' : 'page-header Subscription'
      }
    >
      {!isFromRegistration && (
        <div className="signup__back-button" onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
      )}
      <Text type="h1" className="page__title">
        {t('_Subscription_')}
      </Text>
      {!isBusySub && (
        <div className="switcher-block">
          <span style={{ ...(!isActive && { color: '#0072FD' }) }} className="month-yearly-text">
            {t('_MONTHLY_')}
          </span>
          <Switcher isActive={isActive} onChange={setIsActive} />
          <div className="yearly-block">
            <span className="month-yearly-text" style={{ ...(isActive && { color: '#0072FD' }) }}>
              {t('_YEARLY_')}
            </span>{' '}
            <span className="yearly-info">{t('_SaveONBillsAnnually_')}</span>
          </div>
        </div>
      )}
      <BusyLoader className="subscription-items" isBusy={isBusySub}>
        {sortedData?.map((item, index) => (
          <SubscriptionCard
            key={item._id}
            isBusy={isBusy && selected === item.prices?.[isActive ? 0 : 1]?._id}
            disabled={isBusy && selected !== item.prices?.[isActive ? 0 : 1]?._id}
            title={item.title}
            color={color[index]}
            subtitle={item.description}
            active={
              (currentPlan?.plan?.priceId === item.prices?.[isActive ? 0 : 1]?._id &&
                currentPlan.status === 'active') ||
              (!isFromRegistration && currentPlan.status !== 'active' && item.title === 'Free')
            }
            unit={!isActive ? 'month' : 'year'}
            price={`${currencies[item?.prices?.[isActive ? 0 : 1]?.currency?.toLocaleUpperCase()]?.sign}${
              item?.prices?.[isActive ? 0 : 1]?.amount / 100
            }`}
            planes={item.metadata}
            onClick={() => handleClick(item)}
          />
        ))}
      </BusyLoader>
    </div>
  );
};

export default Subscription;
