import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import appstore from '../../icons/app-store2.png';
import googlePlay from '../../icons/google-play2.png';
import logo from '../../icons/veritise-design.svg';
import { ProductApi } from '../../services/api/ProductApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { ProductSummary } from './ProductSummary/ProductSummary';
import './style.scss';

const PublicProduct = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const alias = CommonHelpers.getParamFromURL(pathname, 2);
  const [product, setProduct] = useState(null);
  const [productCategory, setProductCategory] = useState();

  const fetchProduct = useCallback(async (id) => {
    try {
      const res = await ProductApi.getOne(id);
      setProduct(res.data);
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    if (alias) {
      fetchProduct(alias);
    }
  }, []);

  useEffect(() => {
    if (product?.categoryId) {
      ProductApi.getProductCategory(product.categoryId)
        .then(({ data: category }) => category.name)
        .then(setProductCategory);
    }
  }, [product]);

  const productOwner = useMemo(() => (product?.owner?.email && product?.owner?.info ? product.owner : null), [product]);

  return (
    <div className="PublicProduct flexible jBetween aCenter vertical">
      {product && <img src={logo} width="223" height="68" alt="Veritise" className="veritise-design" />}
      <div className="container flexible jCenter grow">
        {product ? (
          <ProductSummary product={product} category={productCategory} owner={productOwner} isBusinessUser />
        ) : (
          <img src={logo} width="639" height="195" alt="Veritise" className="veritise-design-single" />
        )}
      </div>
      <div className="PublicProduct_footer flexible vertical aCenter">
        <span className="footer-title">
          {t('_Download_the_')} <b>Veritise</b> {t('_app_for_full_access_')}
        </span>
        <div className="flexible aCenter jBetween grow">
          <a href="https://play.google.com/store/apps/details?id=com.veritise" target="_blank" rel="noreferrer">
            <img src={googlePlay} width="176" height="56" alt="googlePlay" />
          </a>
          <a href="https://testflight.apple.com/join/HDF7GcV3" target="_blank" rel="noreferrer">
            <img src={appstore} width="176" height="56" alt="appstore" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default PublicProduct;
