import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import Text from '../../../components/common/Text';
import Loader from '../../../components/Loader';
import { useAction } from '../../../hooks';
import { useRedirect } from '../../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../../icons/arrow.svg';
import { logoutActionRequest } from '../../../modules/auth/actions';
import { SecurityQuestionsApi } from '../../../services/api';
import { UserApi } from '../../../services/api/UserApi';
import { Toast } from '../../../utils/toastHelper';
import ConfirmCodeStep from './ConfirmCodeStep';
import ConfirmSecQStep from './ConfirmSecQStep';
import SetEmailStep from './SetEmailStep';
import styles from './styles.module.scss';

const ChangeEmail = () => {
  const handleBack = useRedirect('/settings');
  const history = useHistory();
  const { t } = useTranslation();
  const logOut = useAction(logoutActionRequest);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [questionsOptions, setQuestionsOptions] = useState<{ questionId: string; name: string }[]>([]);
  const securityAnswers = useRef<{ questionId: string; answer: string }[]>();
  const newEmail = useRef<string>();
  const tryCurrentCount = useRef(0);
  const tryCurrentCodeCount = useRef(0);

  const [stepIndex, setStepIndex] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        const questionsOptions = await SecurityQuestionsApi.accountSecurityQuestions();
        setQuestionsOptions(questionsOptions);
      } catch (error) {
        console.error(error);
      }
    })();
  }, []);

  const handleSetEmail = (email: string): void => {
    setDisableButton(true);
    newEmail.current = email;
    UserApi.changeEmailFlow({ securityAnswers: securityAnswers.current, newEmail: email })
      .then(() => {
        setDisableButton(false);
        setStepIndex(2);
      })
      .catch(() => setDisableButton(false));
  };

  const handleResendCode = (): void => {
    UserApi.changeEmailFlow({ securityAnswers: securityAnswers.current, newEmail: newEmail.current }).then(() => {
      Toast.success(t('_ConfirmationCodeHasBeenResent_'));
    });
  };

  const handleChangeEmailAndLogout = (code: string): void => {
    setDisableButton(true);
    UserApi.changeEmailFlow({ securityAnswers: securityAnswers.current, newEmail: newEmail.current, code })
      .then(() => {
        setDisableButton(false);
        logOut({ onSuccess: () => history.push('/') });
        Toast.success(t('_YourEmailSuccessfullyChanged_'));
      })
      .catch(() => {
        setDisableButton(false);
        if (tryCurrentCodeCount.current === 2) {
          Toast.error(t('_IncorrectAnswersAllAttempts_'));
          logOut({ onSuccess: () => history.push('/') });
        } else {
          Toast.error(t('_IncorrectCode_'));
        }
        tryCurrentCodeCount.current += 1;
      });
  };

  const handleCheckAnswers = (answers: { questionId: string; answer: string }[]): void => {
    securityAnswers.current = answers;
    setDisableButton(true);
    UserApi.changeEmailFlow({ securityAnswers: answers })
      .then(() => {
        setDisableButton(false);
        setStepIndex(1);
      })
      .catch(() => {
        setDisableButton(false);
        if (tryCurrentCount.current === 2) {
          Toast.error(t('_IncorrectAnswersAllAttempts_'));
          logOut({ onSuccess: () => history.push('/') });
        } else {
          Toast.error(t('_IncorrectAnswers_'));
        }
        tryCurrentCount.current += 1;
      });
  };

  let content = null;

  switch (stepIndex) {
    case 0:
      content = (
        <>
          {questionsOptions.length === 0 ? (
            <div className={styles.loaderWrapper}>
              <Loader />
            </div>
          ) : (
            <ConfirmSecQStep
              waitingState={disableButton}
              questions={questionsOptions}
              next={(answers) => handleCheckAnswers(answers)}
            />
          )}
        </>
      );
      break;
    case 1:
      content = <SetEmailStep waitingState={disableButton} next={(email) => handleSetEmail(email)} />;
      break;
    case 2:
      content = (
        <ConfirmCodeStep
          waitingState={disableButton}
          resend={handleResendCode}
          next={(code) => handleChangeEmailAndLogout(code)}
        />
      );
      break;
    default:
      content = null;
      break;
  }

  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title authenticated-page-title">
          {t('_ChangeEmail_')}
        </Text>
        <div>{content}</div>
      </div>
    </>
  );
};

export default ChangeEmail;
