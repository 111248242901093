import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import Loader from '../../components/Loader';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { CommonApi } from '../../services/api';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';

const KycVerificationButtons = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [processing, setProcessing] = useState(false);
  const user = useSelector((state: any) => state?.user?.userData);

  const redirect = async () => {
    if (sessionStorage.getItem('userData')) {
      sessionStorage.removeItem('userData');
    }

    if (localStorage.getItem('authFlow') === 'registration') {
      history.push('/wallet-creation');
      return;
    }

    if (!localStorage.getItem('MNEMONIC_MODEL')) {
      history.push('/wallet-connect');
      return;
    }

    const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
    const mnemonic = mnemonicModel.mnemonic;
    dispatch(
      saveWalletAction({
        mnemonic,
        onSuccess: () => {
          dispatch(networkInitStateAction());
          history.push('/products');
        },
        onError: () => {
          history.push('/wallet-connect');
        },
      }),
    );
  };
  const handleSimulateKYC = async (status?: boolean) => {
    const userData = sessionStorage.getItem('userData');
    const userId = userData ? JSON.parse(userData)._id : user?._id;
    setProcessing(true);
    try {
      const res = await CommonApi.setIdentity({ _id: userId, kycStatus: status ? 'accepted' : 'declined' });
      if (CommonHelpers.isOkStatus(res.status)) {
        return Toast.success('updated');
      }
    } catch (e) {
      //
    } finally {
      setProcessing(false);
    }
  };
  return (
    <div
      style={{
        position: 'absolute',
        left: 10,
        top: 300,
        height: 180,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        zIndex: 1,
      }}
    >
      <Button isPrimary text={'Simulate KYC Approved'} onClick={() => handleSimulateKYC(true)} />
      <Button isPrimary text={'Simulate KYC Declined'} onClick={() => handleSimulateKYC(false)} />
      <Button isPrimary text={'Next'} onClick={redirect} />
      {processing && <Loader />}
    </div>
  );
};

export default KycVerificationButtons;
