import { createReducer } from 'deox';
import produce from 'immer';
import {
  setAddressFilterAction,
  setAddTransactionsAction,
  setIsLastPageAction,
  setLoadingAction,
  setPageAction,
  setSubscriptionAction,
  setTransactionsAction,
} from '../../modules/transaction/actions';
import { TransactionState } from './types';

const initialState: TransactionState = {
  loading: false,
  isNextLoading: false,
  subscription: null,
  addressFilter: '',
  directionFilter: 'ALL',
  transactions: [],
  page: 0,
  isLastPage: false,
  pendingSignature: false,
};

export const transactionReducer = createReducer(initialState, (handle) => [
  handle(setPageAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.page = payload;
    }),
  ),
  handle(setLoadingAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(setIsLastPageAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.isLastPage = payload;
    }),
  ),
  handle(setTransactionsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.transactions = payload;
    }),
  ),
  handle(setAddTransactionsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.transactions.push(...payload);
    }),
  ),
  handle(setAddressFilterAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.addressFilter = payload;
    }),
  ),
  handle(setSubscriptionAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.subscription = payload;
    }),
  ),
]);
