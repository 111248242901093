//@ts-ignore
import { Api } from './Api';

export class PaymentsApi {
  static async getInvoices() {
    const { data } = await Api.get('/portal/invoice');
    return data;
  }

  static async getSubscriptions() {
    const { data } = await Api.get('/portal/product');
    return data;
  }

  static async getCurrentSubscription() {
    const { data } = await Api.get('/portal/subscribe');
    return data;
  }

  static async createSubscriptions(params: { priceId: string }) {
    const { data } = await Api.post('/portal/subscribe', params);
    return data;
  }

  static async createCheckoutSession(params: { priceId: string; successUrl: string; cancelUrl: string }) {
    const { data } = await Api.post('/portal/checkout', params);
    return data;
  }

  static async updateSubscriptions(params: { priceId: string }) {
    const { data } = await Api.put(`/portal/subscribe/${params.priceId}`, params);
    return data;
  }

  static async deleteSubscription() {
    const { data } = await Api.delete(`/portal/subscribe`);
    return data;
  }

  static async getPaymentInfo() {
    const { data } = await Api.get('/portal/payment/method');
    return data;
  }

  static async createPaymentInfo(params: any) {
    const { data } = await Api.post('/portal/payment/method', params);
    return data;
  }

  static async updatePaymentInfo(params: any) {
    const { data } = await Api.put('/portal/payment/method', params);
    return data;
  }

  static async deletePaymentInfo(id: string) {
    const { data } = await Api.delete(`/portal/payment/method/${id}`);
    return data;
  }
}
