import { Auth } from 'aws-amplify';
import { FC, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { CommonApi } from '../../services/api';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import './KYC.scss';

const KYC: FC = () => {
  const [verificationUrl, setVerificationUrl] = useState('');
  const [loading, setLoading] = useState(true);
  const iframeref = useRef<any>(null);
  const ORIGIN = window.location.origin;
  const history = useHistory();

  useEffect(() => {
    const country = localStorage.getItem('country') || 'FR';
    Auth.currentUserInfo().then((res) => {
      const { name } = res.attributes;
      const payload: any = {
        types: ['face', 'document'],
        params: {
          redirectUrl: `${ORIGIN}/kyc-finish`,
          country,
          language: 'EN',
          document: {
            fullName: name,
          },
        },
      };

      CommonApi.KYC_identity(payload)
        .then((response) => {
          if (CommonHelpers.isOkStatus(response.status)) {
            const { data } = response;
            if (data.event && data.event === 'request.pending') {
              setVerificationUrl(data.verification_url);
              setLoading(false);
              iframeref.current.click();
            }
          } else {
            setLoading(false);
            history.push('/kyc-finish');
          }
        })
        .catch((e) => {
          Toast.error(e);
          setLoading(false);
          history.push('/kyc-finish');
        });
    });
  }, []);

  const test = (ref: any) => {
    if (ref.current.contentDocument) {
      history.push('/kyc-finish');
    }
  };

  return (
    <div className="kyc">
      {loading ? (
        <Loader />
      ) : verificationUrl !== '' ? (
        <>
          <div className="overlay"></div>
          <iframe
            ref={(instance) => (iframeref.current = instance)}
            onLoad={() => test(iframeref)}
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
            title="shuftipro-iframe"
            name="shuftipro-iframe"
            allow="camera"
            id="shuftipro-iframe"
            src={verificationUrl}
            width="100%"
            height="100%"
          ></iframe>
        </>
      ) : null}
    </div>
  );
};

export default KYC;
