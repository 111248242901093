import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import Loader from '../Loader';
import './BusyLoader.scss';

const BusyLoader = ({ isBusy, progress, children, className = '', ...restProps }: any) => {
  const { t } = useTranslation();

  return (
    <div className={cn('BusyLoader', className, { isBusy: isBusy })} {...restProps}>
      {isBusy ? (
        <>
          <Loader />
          {progress ? (
            <>
              <p>{t('_Processing_')}</p>
              <div className="progressBar_container">
                <div className="progressBar_progress" style={{ width: `${progress}%` }} />
              </div>
              <p>{t('_DontLeavePage_')}</p>
            </>
          ) : null}
        </>
      ) : (
        children
      )}
    </div>
  );
};

export default BusyLoader;
