import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Loader from '../../components/Loader';
import { TabContent, TabMenu } from '../../components/TabMenu/TabMenu';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { useRedirect } from '../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { getProduct } from '../../modules/product/actions';
import { ProductApi } from '../../services/api/ProductApi';
import { ProductsSecureStorage } from '../../storage/persistence/ProductsSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { ProductHistory, ProductSummary, QrCodeLink } from './components';
import ProducrtOwnership from './components/ProductOwnership';
import styles from './ProductDetails.module.scss';

export const ProductDetails: FC = () => {
  const { t } = useTranslation();
  const { product } = useSelector((state: any) => state.products);
  const userType = useDetectUserType();
  const isBusinessUser = userType === 'company';
  const [loading, setLoading] = useState(false);
  const [privateKey, setPrivateKey] = useState<string>();
  const { pathname } = useLocation();
  const alias = CommonHelpers.getParamFromURL(pathname, 2);
  const [productCategory, setProductCategory] = useState<undefined | string>();

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getProduct.req(alias));
    return () => {
      dispatch(getProduct.reset());
    };
  }, [alias]);

  useEffect(() => {
    if (product) {
      const _getPrivateKey = async () => {
        const _product = await ProductsSecureStorage.getProduct(product?._id);
        if (_product && _product.privateKey) {
          setPrivateKey(_product.privateKey);
        }
      };
      _getPrivateKey();

      if (!loading) {
        setLoading(false);
      }
      if (product.categoryId) {
        ProductApi.getProductCategory(product.categoryId)
          .then(({ data: category }) => category.name)
          .then(setProductCategory);
      }
    }
  }, [product]);

  const handleBack = useRedirect('/products');

  const productOwner = useMemo(() => (product?.owner?.email && product?.owner?.info ? product.owner : null), [product]);

  return (
    <>
      <div className={styles['product-page-header']}>
        {!isBusinessUser && (
          <div className={cn(styles['back-button'], 'signup__back-button')} onClick={handleBack}>
            <ArrowIcon />
            <span>{t('_Back_')}</span>
          </div>
        )}
        <h1 className={styles['page-title']}>{product?.name}</h1>
      </div>
      {loading && !product ? (
        <div className="loader_wrapper">
          <Loader />
        </div>
      ) : (
        <TabMenu tabsContentClass={styles['product-description']}>
          <TabContent label={isBusinessUser ? t('_Summary_') : t('_Info_')}>
            {product && (
              <ProductSummary
                product={product}
                category={productCategory}
                owner={productOwner}
                isBusinessUser={isBusinessUser}
              />
            )}
          </TabContent>
          <TabContent label={t(isBusinessUser ? '_ProductHistory_' : '_Trace_')}>
            <ProductHistory productId={product?._id} />
          </TabContent>
          {isBusinessUser ? (
            <TabContent label="QR">
              {product ? (
                <div className={styles['product-qr-codes']}>
                  <QrCodeLink title={t('_PublicQRCode_')} link={CommonHelpers.generateQrLink(alias, 'p')} />

                  {privateKey ? (
                    <QrCodeLink title={t('_PrivateQRCode_')} link={privateKey} />
                  ) : product.privateQrCode ? (
                    <QrCodeLink title={t('_PrivateQRCode_')} link={product.privateQrCode.link} />
                  ) : null}
                </div>
              ) : (
                <Loader />
              )}
            </TabContent>
          ) : (
            <TabContent label={t('_Ownership_')}>
              <ProducrtOwnership product={product} />
            </TabContent>
          )}
        </TabMenu>
      )}
    </>
  );
};
