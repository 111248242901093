import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Loader from '../../components/Loader';
import { CommonApi } from '../../services/api';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import './KYB.scss';

const KYB = () => {
  const [loading, setLoading] = useState(true);

  const fromOrgPage = window.location.search.includes('fromOrgPage');

  const ORIGIN = window.location.origin;
  const history = useHistory();

  const redirectToKybResult = () => {
    if (fromOrgPage) {
      history.push('/kyb-finish?fromOrgPage');
    } else {
      history.push('/kyb-finish');
    }
  };

  useEffect(() => {
    const orgInfo = localStorage.getItem('orgInfo') ? JSON.parse(localStorage.getItem('orgInfo')) : null;

    OrganizationApi.getOrganizations()
      .then((res) => {
        const organization = res.data.data[0];
        const payload: any = {
          redirectUrl: `${ORIGIN}/kyb-finish`,
          country: localStorage.getItem('country') || CommonHelpers.getTextWithoutSpaces(orgInfo.country) || 'FR',
          organizationId: organization._id,
          // name: organization.name,
          registrationNumber: CommonHelpers.getTextWithoutSpaces(orgInfo.company_registration_number),
          jurisdictionCode: CommonHelpers.getTextWithoutSpaces(orgInfo.company_jurisdiction_code),
          language: 'EN',
        };
        CommonApi.KYB_identity(payload)
          .then(() => {
            setLoading(false);
            redirectToKybResult();
          })
          .catch((e) => {
            setLoading(false);
            Toast.error(CommonHelpers.erorHandlerMessage(e));
            redirectToKybResult();
          });
      })
      .catch((e) => {
        Toast.error(CommonHelpers.erorHandlerMessage(e));
        redirectToKybResult();
      });
  }, []);

  return <div className="kyb">{loading && <Loader />}</div>;
};
export default KYB;
