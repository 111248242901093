import { getType } from 'deox';
import { SagaIterator } from 'redux-saga';
import { call, put, takeLatest } from 'typed-redux-saga';
import { PaymentsApi } from '../../services/api/PaymentsApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import {
  createPaymentInfo,
  setBillingInvoices,
  setCurrentSubscription,
  setPaymentInfo,
  setSubscriptions,
} from './actions';

function* subscriptionsSaga(params): SagaIterator {
  const { onFinish } = params.payload;
  try {
    const data = yield* call(PaymentsApi.getSubscriptions);
    yield* put(setSubscriptions.success(data));
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    !!onFinish && onFinish();
  }
}

function* billingInvoicesSaga(params): SagaIterator {
  const { onFinish } = params.payload;
  try {
    const data = yield* call(PaymentsApi.getInvoices);
    yield* put(setBillingInvoices.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    yield* put(setBillingInvoices.success({}));
    !!onFinish && onFinish();
  }
}

function* currentSubscriptionSaga(params?): SagaIterator {
  const { onFinish } = params.payload;
  try {
    const data = yield* call(PaymentsApi.getCurrentSubscription);
    yield* put(setCurrentSubscription.success(data));
    !!onFinish && onFinish();
  } catch (e) {
    yield* put(setCurrentSubscription.success({}));
    !!onFinish && onFinish();
  }
}

function* paymentInfoSaga(params): SagaIterator {
  const args = params.payload;
  try {
    const data = yield* call(PaymentsApi.getPaymentInfo);
    yield* put(setPaymentInfo.success(data));
    !!args?.onSuccess && args.onSuccess();
    !!args?.onFinish && args.onFinish();
  } catch (e) {
    yield* put(setPaymentInfo.success({}));
    !!args?.onFinish && args.onFinish();
  }
}

function* paymentInfoCreationSaga(params): SagaIterator {
  const { data, onSuccess, onError } = params.payload;
  try {
    const res = yield* call(PaymentsApi.createPaymentInfo, data);
    yield* put(setPaymentInfo.success(res));
    !!onSuccess && onSuccess();
  } catch (e) {
    !!onError && onError();
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

export function* watchSubscriptions(): SagaIterator {
  yield* takeLatest(getType(setSubscriptions.request), subscriptionsSaga);
  yield* takeLatest(getType(setBillingInvoices.request), billingInvoicesSaga);
  yield* takeLatest(getType(setCurrentSubscription.request), currentSubscriptionSaga);
  yield* takeLatest(getType(setPaymentInfo.request), paymentInfoSaga);
  yield* takeLatest(getType(createPaymentInfo), paymentInfoCreationSaga);
}
