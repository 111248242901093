import { Api } from './Api';

export class SecurityQuestionsApi {
  static async fetchSecurityQuestions(): Promise<{ _id: string; question: string }[]> {
    const value = await (<Promise<{ data: { data: { _id: string; question: string }[] } }>>(
      Api.get(`/security-question`)
    ));
    return value.data.data;
  }
  static async accountSecurityQuestions(): Promise<{ questionId: string; name: string }[]> {
    const value = await (<Promise<{ data: { questionId: string; name: string }[] }>>(
      Api.get(`/security-question/personal`)
    ));
    return value.data;
  }
}
