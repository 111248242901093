/*eslint-disable*/
import cn from 'classnames';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Label from '../../../../components/common/Label';
import Text from '../../../../components/common/Text';
import MenuBtn from '../../../../components/MenuBtn/MenuBtn';
import { TextEditor } from '../../../../components/TextEditor/TextEditor';
import { getExplorerURL } from '../../../../config/environment';
import { ReactComponent as SuccessIcon } from '../../../../icons/kybAccepted.svg';
import { ReactComponent as FailIcon } from '../../../../icons/kybDeclined.svg';
import { OrganizationApi } from '../../../../services/api/OrganizationApi';
import { ProductApi } from '../../../../services/api/ProductApi';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { CommonHelpers } from '../../../../utils/commonHelpers';
import { APP_URL } from '../../../../utils/constants';
import { Toast } from '../../../../utils/toastHelper';
import exportZipArchive from '../../../Products/helpers/exportZipArchive';
import { ProductCombined } from '../../../Products/interfaces/productCombined.interface';
import { ProductImagesSlider } from '../ProductImagesSlider/ProductImagesSlider';
import { ProductOwnerModal } from '../ProductOwnerModal/ProductOwnerModal';
import ProductWarrantyModal from '../ProductWarrantyModal';
import { ReactComponent as DefaultImage } from './../../../../icons/no-org-logo.svg';
import { ReactComponent as NoProductImage } from './../../../../icons/no-product-image.svg';
import styles from './ProductSummary.module.scss';
import VerifyHashModal from './VerifyHashModal';

enum MenuActions {
  HOW_TO_VERIFY_PRODUCT = 'How to verify product',
  VIEW_IN_BLOCK_EXPLORER = 'View in block explorer',
  EXPORT_QR_CODES = 'Export QR Codes',
}

interface ProductSummaryProps {
  category?: string;
  owner: any;
  product: {
    _id: string;
    alias: string;
    description?: string;
    category_id?: string;
    category?: any;
    info: any;
    publicQrCode: any;
    warranty: any;
    customFields: { name: string; value: string }[];
    images?: { src: string }[];
    status: string;
    title?: string;
    name?: string;
    transactionId?: string;
    manufacturer: {
      info: {
        logo: string;
        fullName: string;
      };
      organizationId: string;
    };
  };
  isBusinessUser: boolean;
  createdViaBulk?: boolean;
  isTemplate?: boolean;
}

export const ProductSummary: FC<ProductSummaryProps> = ({
  product,
  owner,
  isBusinessUser,
  createdViaBulk = false,
  isTemplate,
}) => {
  const { t } = useTranslation();
  const [productOwnerModalOpened, setOpened] = useState(false);
  const [manufacturer, setManufacturer] = useState(null);
  const [category, setCategory] = useState(null);
  const [warrantyModalvisible, setWarrantyModalvisible] = useState(false);
  const [isVerifyHashModalOpen, setVerifyHashModalOpen] = useState(false);
  const { network } = useSelector((state: any) => state.network);

  useEffect(() => {
    setCategory(null);

    if (product.manufacturer?.organizationId) {
      OrganizationApi.getOrganizationInfo(product.manufacturer.organizationId).then((res) => {
        const { status, data } = res;
        if (CommonHelpers.isOkStatus(status)) {
          setManufacturer(data);
        }
      });
    }

    if (product.category_id) {
      ProductApi.getProductCategory(product.category_id).then((res) => {
        const { status, data } = res;
        if (CommonHelpers.isOkStatus(status)) {
          setCategory(data);
        }
      });
    }
  }, [product]);

  const getKybIcon = () => {
    const isAccepted = manufacturer.approvalStatus === 'accepted';

    return <div className={styles.kybStatus}>{isAccepted ? <SuccessIcon /> : <FailIcon />}</div>;
  };

  const { title, info, description, status, name, warranty, _id: walletId, transactionId } = product;

  const handleClose = () => {
    setWarrantyModalvisible(false);
  };
  const handleOpen = () => {
    setWarrantyModalvisible(true);
  };

  const images = useMemo(
    () => info?.images?.map((img) => ({ src: process.env.REACT_APP_ATTACHMENTS_DOMAIN + img.location })),
    [product],
  );

  const exportHandle = async () => {
    try {
      let combinedArray: ProductCombined.Combined[] = [];

      if (createdViaBulk) {
        const productsFromDB = await ProductsSecureStorage.getProductByBulkId(product._id);
        const productsKeys = {};
        productsFromDB.forEach((item) => (productsKeys[item.publicKey] = item));
        const _productsPublic = await ProductApi.getProductByBulkId(product._id);
        combinedArray = _productsPublic.data.map((value: ProductCombined.Public) => {
          return {
            productLink: `${process.env.REACT_APP_URL}/p/${value.alias}`,
            name: product.name,
            category: category ? category.name : '',
            publicQR: `${APP_URL}/p/${value.alias}`,
            privateQR: productsKeys[value._id]?.privateKey,
            publicQRFile: `${value.alias}_public.svg`,
            privateQRFile: `${value.alias}_private.svg`,
          };
        });
      } else {
        const _product = await ProductsSecureStorage.getProduct(product._id);
        const combined: ProductCombined.Combined = {
          productLink: `${process.env.REACT_APP_URL}${product.publicQrCode.link}`,
          name: product.name,
          category: category ? category.name : '',
          publicQR: `${APP_URL}/${product.publicQrCode.link}`,
          privateQR: _product.privateKey,
          publicQRFile: `${product.alias}_public.svg`,
          privateQRFile: `${product.alias}_private.svg`,
        };
        combinedArray = [combined];
      }

      exportZipArchive(combinedArray);
    } catch (error) {
      console.error(error);
      Toast.error(t('_ExportProductsError_'));
    }
  };

  const handleSelect = (action: MenuActions) => {
    switch (action) {
      case MenuActions.HOW_TO_VERIFY_PRODUCT:
        setVerifyHashModalOpen(true);
        break;
      case MenuActions.VIEW_IN_BLOCK_EXPLORER:
        window.open(`${getExplorerURL(network)}/accounts/${walletId}`, '_blank', 'noopener,noreferrer');
        break;
      case MenuActions.EXPORT_QR_CODES:
        exportHandle();
        break;
      default:
        break;
    }
  };

  const menuItems = [
    {
      key: MenuActions.EXPORT_QR_CODES,
      name: t('_Export_QR_codes_'),
    },
    {
      key: MenuActions.VIEW_IN_BLOCK_EXPLORER,
      name: t('_View_in_block_explorer_'),
    },
  ];

  if (transactionId) {
    menuItems.push({
      key: MenuActions.HOW_TO_VERIFY_PRODUCT,
      name: t('_How_to_verify_product_'),
    });
  }

  return (
    <div className={styles.product}>
      <div className={styles.product__images}>
        {images?.length > 0 ? (
          <ProductImagesSlider images={images} imgFit="contain" />
        ) : (
          <div style={{ overflow: 'hidden' }}>
            <NoProductImage />
          </div>
        )}
      </div>
      <div className={styles.product__info}>
        <section className={styles.product__info_name}>
          <Text type="h3">{name || title}</Text>
          {!isTemplate && <MenuBtn isIcon id="menu-btn" data={menuItems} onSelect={handleSelect} />}
        </section>

        <h2 className={styles['product__info_sub-title']}>
          {manufacturer?.info &&
            (manufacturer?.info?.images[0] ? (
              <img src={process.env.REACT_APP_ATTACHMENTS_DOMAIN + manufacturer?.info?.images[0].location} alt="" />
            ) : (
              <DefaultImage />
            ))}
          {manufacturer?.name}
          {manufacturer && getKybIcon()}
        </h2>

        <div className={styles['product__info_required-fields']}>
          {status && (
            <div className={styles['required-field']}>
              <div className={styles.row__title}>{t('_Status_')}</div>
              <Label status={status} />
            </div>
          )}

          <div className={styles.additionalInfo_row}>
            {isBusinessUser && (
              <div className={styles.additionalInfo_row__category}>
                <Text className={styles.row__title}>{t('_ProductCategory_')}</Text>
                <Text className={styles.additionalInfo_row__value}>{category ? category.name : '-'}</Text>
              </div>
            )}
            {isBusinessUser && (
              <div>
                <Text className={styles.row__title}>{t('_Warranty_')}</Text>
                <Text className={styles.additionalInfo_row__warranty_value} onClick={handleOpen}>
                  {warranty ? warranty.title : '-'}
                </Text>
                <ProductWarrantyModal open={warrantyModalvisible} onClose={handleClose} warranty={warranty} />
              </div>
            )}
          </div>

          {isBusinessUser && owner?.info?.fullName && (
            <div className={styles['required-field']}>
              <div className={styles['required-field__key']}>{t('_Owner_')}</div>
              <div className={cn(styles['required-field__value'])}>
                <span className={styles.owner} onClick={() => setOpened(true)}>
                  {owner?.info?.fullName}
                </span>
              </div>
              <ProductOwnerModal open={productOwnerModalOpened} onClose={() => setOpened(false)} owner={owner} />
            </div>
          )}

          <div className={styles['required-field']}>
            <div className={styles.row__title}>{t('_Description_')}</div>
            <TextEditor readOnly={true} initialState={description} />
          </div>
          {info?.fields?.length ? (
            <div className={styles['product__info_custom-fields']}>
              <div className={cn(styles.row__title, styles.costum_row__title)}>{t('_CustomFields_')}</div>
              {info?.fields.map((field, index) => {
                return (
                  <div
                    className={cn(styles['custom-field'], styles[`custom-field-${field.type}`])}
                    key={field.name + index}
                  >
                    <Text className={cn(styles['custom-field__key'])}>{field.name}</Text>
                    <Text className={cn(styles['custom-field__value'])}>{field.value}</Text>
                  </div>
                );
              })}
            </div>
          ) : null}
        </div>
      </div>
      <div className={styles.product__explorer}>
        {transactionId && (
          <>
            <VerifyHashModal
              isOpen={isVerifyHashModalOpen}
              onClose={() => setVerifyHashModalOpen(false)}
              product={product}
            />
          </>
        )}
      </div>
    </div>
  );
};
