import { createReducer } from 'deox';
import produce from 'immer';
import { setError } from '../../modules/errors/action';
import {
  setAccountBalancesAction,
  setAccountsAction,
  setInitialState,
  setLoader,
  setLoading,
  setMnemonicAction,
  setNameAction,
  setSelectedAccountAction,
  walletInitStateAction,
} from './actions';
import { WalletState } from './types';

const initialState: WalletState = {
  loading: false,
  error: null,
  name: '',
  mnemonic: null,
  password: null,
  selectedAccount: {},
  accounts: [],
  accountBalances: {},
  loadingPercent: 0,
};

export const walletReducer = createReducer(initialState, (handle) => [
  handle(walletInitStateAction, (state) =>
    produce(state, (draft) => {
      draft.error = null;
      draft.loadingPercent = 0;
    }),
  ),
  handle(setAccountsAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accounts = payload.accounts;
      // draft.loading = false;
      // draft.loadingPercent = 100;
    }),
  ),
  handle(setMnemonicAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.mnemonic = payload.mnemonic;
    }),
  ),
  handle(setLoading, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loadingPercent = payload;
    }),
  ),
  handle(setAccountBalancesAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.accountBalances = payload.accountBalances;
    }),
  ),
  handle(setSelectedAccountAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.selectedAccount = payload.accountModel;
    }),
  ),
  handle(setNameAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.name = payload;
      draft.loading = true;
    }),
  ),

  handle(setError, (state, { payload }) =>
    produce(state, (draft) => {
      draft.error = payload;
    }),
  ),
  handle(setLoader, (state, { payload }) =>
    produce(state, (draft) => {
      draft.loading = payload;
    }),
  ),
  handle(setInitialState, () => initialState),
]);
