import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useRedirect = (path: string) => {
  const history = useHistory();
  return useCallback(() => {
    history.push(path);
  }, [path]);
};

export { useRedirect };
