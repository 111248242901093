import {
  BusinessUsersIcon,
  CardIcon,
  CartIcon,
  CompanyIcon,
  IndividualUserScanIcon,
  InfoIcon,
  ScanHistoryIcon,
  ScanIcon,
  SettingsIcon,
  WarrantyIcon,
} from '../../icons/sidebar';

export const NavigationOptionsForCompany: {
  path: string;
  Icon: React.FC;
  text?: string;
  needStrokeStyle?: boolean;
  separatePath?: string[];
  isCv?: boolean;
  required?: boolean;
}[] = [
  {
    path: '/products',
    Icon: CartIcon,
    text: 'My Products',
    isCv: false,
    separatePath: ['/bulk', '/template'],
  },
  {
    path: '/company',
    Icon: CompanyIcon,
    text: 'My Products',
    required: true,
  },
  {
    path: '/warranties',
    Icon: WarrantyIcon,
    text: 'My Products',
    isCv: false,
  },
  {
    path: '/fields-management',
    Icon: ScanIcon,
    text: 'Scan something',
    isCv: false,
  },
  // {
  //   path: '/cv',
  //   Icon: CvIcon,
  //   text: 'My CV',
  //   needStrokeStyle: true,
  //   isCv: true,
  // },
  // {
  //   path: '/cv-requests',
  //   Icon: CVRequestsIcon,
  //   text: 'CV Check Requests',
  //   needStrokeStyle: true,
  //   separatePath: ['/cv-requests-item'],
  //   isCv: true,
  // },
  // {
  //   path: '/cv-list',
  //   Icon: CVListIcon,
  //   text: 'Cv list',
  //   needStrokeStyle: true,
  //   isCv: true,
  // },
  // {
  //   path: '/api',
  //   Icon: CodeIcon,
  //   text: 'Api Docs',
  //   isCv: false,
  // },
  {
    path: '/billing',
    Icon: CardIcon,
    isCv: false,
  },
  {
    path: '/user-management',
    Icon: BusinessUsersIcon,
    required: true,
  },
  {
    path: '/settings',
    Icon: SettingsIcon,
    text: 'User Settings',
    required: true,
  },
  {
    path: '/about-app',
    Icon: InfoIcon,
    text: 'About this app',
    required: true,
  },
];
export const NavigationOptionsForIndividualUser: {
  path: string;
  Icon: React.FC;
  text?: string;
  needStrokeStyle?: boolean;
  separatePath?: string[];
  isCv?: boolean;
  required?: boolean;
}[] = [
  {
    path: '/products',
    Icon: CartIcon,
    text: 'My Products',
  },
  {
    path: '/scan',
    Icon: IndividualUserScanIcon,
    text: 'Scan something',
  },
  {
    path: '/history-scan',
    Icon: ScanHistoryIcon,
    text: 'Scan history',
  },
  // {
  //   path: '/cv',
  //   Icon: CvIcon,
  //   text: 'My CV',
  //   needStrokeStyle: true,
  // },
  // {
  //   path: '/cv-requests',
  //   Icon: CVRequestsIcon,
  //   text: 'CV Check Requests',
  //   needStrokeStyle: true,
  //   separatePath: ['/cv-requests-item'],
  //   isCv: true,
  // },
  {
    path: '/settings',
    Icon: SettingsIcon,
    text: 'User Settings',
  },
  {
    path: '/about-app',
    Icon: InfoIcon,
    text: 'About this app',
  },
];
