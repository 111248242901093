import dayjs from 'dayjs';
import { FC, useMemo } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select from 'react-select';
import { Button } from '../../components/Button/Button';
import { DatePickerInput } from '../../components/DatePickerInput/DatePickerInput';
import { Input } from '../../components/Input/Input';
import { COUNTRY_LIST } from '../../utils/constants';

interface IProps {
  values: any;
  errors: any;
  handleSetFieldValue: any;
  buttonValue: any;
  handleSubmit: any;
  isButtonLoading?: boolean;
}

const ProfileInfo: FC<IProps> = ({
  values,
  errors,
  handleSetFieldValue,
  buttonValue,
  handleSubmit,
  isButtonLoading,
}) => {
  const { t } = useTranslation();

  const company_jurisdiction_codeOptions: { label: string; value: any }[] = useMemo(
    () =>
      COUNTRY_LIST.map((item) => {
        const key = Object.keys(item)[0];
        return {
          label: key,
          value: item[key],
        };
      }),
    [],
  );

  const sessionStorageCountry = localStorage.getItem('country_code')
    ? JSON.parse(localStorage.getItem('country_code')).toLowerCase()
    : 'fr';

  return (
    <>
      <Input
        type="text"
        placeholder={t('_EnterYourFirstName_')}
        label={t('_FirstName_')}
        isRequired
        name="firstName"
        value={values.firstName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetFieldValue('firstName', e.target.value)}
      />
      {errors && <span className="errors">{errors.firstName}</span>}

      <Input
        type="text"
        placeholder={t('_EnterYourMiddleName_')}
        label={t('_MiddleName_')}
        name="middleName"
        value={values.middleName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetFieldValue('middleName', e.target.value)}
      />
      {errors && <span className="errors">{errors.middleName}</span>}

      <Input
        type="text"
        placeholder={t('_EnterYourLastName_')}
        label={t('_LastName_')}
        isRequired
        name="lastName"
        value={values.lastName}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetFieldValue('lastName', e.target.value)}
      />
      {errors && <span className="errors">{errors.lastName}</span>}

      <div className="input">
        <p>
          {t('_Country_of_residence_')} <span>*</span>
        </p>
        <Select
          value={values.country}
          onChange={(selectedOption) => handleSetFieldValue('country', selectedOption)}
          options={company_jurisdiction_codeOptions}
          name="country"
          className="input-select"
          classNamePrefix="input-select"
          placeholder={t('_startTyping_')}
          maxMenuHeight={240}
        />
        {errors && <span className="errors">{errors.country}</span>}
      </div>

      <div className="input">
        <p>
          {t('_Country_of_cityzenship_')} <span>*</span>
        </p>
        <Select
          value={values.citizenship}
          onChange={(selectedOption) => handleSetFieldValue('citizenship', selectedOption)}
          options={company_jurisdiction_codeOptions}
          name="citizenship"
          className="input-select"
          classNamePrefix="input-select"
          placeholder={t('_startTyping_')}
          maxMenuHeight={240}
        />
        {errors && <span className="errors">{errors.citizenship}</span>}
      </div>
      <div className="input">
        <p className="google_autocomplete_label">
          {t('_Address_')} <span>*</span>
        </p>
        <ReactGoogleAutocomplete
          className="google_autocomplete_input"
          placeholder={t('_EnterYourAddress_')}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          defaultValue={values.address}
          onPlaceSelected={(selected) => {
            handleSetFieldValue('address', selected.formatted_address);
          }}
          options={{
            types: ['geocode', 'establishment'],
          }}
        />
        {errors && <span className="errors">{errors.address}</span>}
      </div>
      <Input
        type="text"
        placeholder={t('_EnterYourAddress_')}
        label={t('_Address2_')}
        name="address2"
        value={values.address2}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleSetFieldValue('address2', e.target.value)}
      />
      {errors && <span className="errors">{errors.address2}</span>}
      <div className="input">
        <p>
          {t('_DateOfBirth_')}
          <span> *</span>
        </p>
        <DatePickerInput
          withIcon
          isRange={false}
          placeholderText="dd/mm/yyyy"
          minDate={values.birthdate ? dayjs(values.birthdate).toDate() : null}
          needCustomEl={false}
          {...(errors.birthdate && { className: 'has-error' })}
          dataCallback={(birthdate) => {
            handleSetFieldValue('birthdate', birthdate);
          }}
        />
        {errors && <span className="errors">{errors.birthdate}</span>}
      </div>
      <div className={errors.phone ? 'input phone-row-item phone-row-item-with-error' : 'input phone-row-item'}>
        <p>
          {t('_PhoneNumber_')} <span className="isRequired"> *</span>
        </p>
        <PhoneInput
          country={sessionStorageCountry}
          onChange={(phone) => handleSetFieldValue('phone', phone)}
          value={values.phone}
        />
        {errors && <span className="errors">{errors.phone}</span>}
      </div>
      <Button
        isPrimary
        className="login__button withMargin"
        text={buttonValue}
        onClick={handleSubmit}
        isLoading={isButtonLoading}
      />
    </>
  );
};

export default ProfileInfo;
