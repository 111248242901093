import { put, select, takeLatest } from 'redux-saga/effects';
import { OrganizationApi } from '../../services/api/OrganizationApi';
import { ProductApi } from '../../services/api/ProductApi';
import { ProductsSecureStorage } from '../../storage/persistence/ProductsSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import {
  createProductForCompany,
  createWarranty,
  editProductForCompany,
  getCategories,
  getProduct,
  getProductBulksForCompany,
  getProductHistory,
  getProductsForCompany,
  getProductsForUser,
  getProductTemplatesForCompany,
  getScanHistory,
  getScanItem,
  getWarrianties,
  registerWarranty,
  removeProductForCompany,
  setDataLoading,
  setProductBulksLoadingAction,
  setProductsLoadingAction,
  setProductTemplatesLoadingAction,
  updateWarrianty,
} from './actions';

export function* fetchProductsForCompanySaga({
  payload: { type, accountID: _accountId, filters, inviteCode, range },
}: any) {
  yield put(setProductsLoadingAction(true));
  let accountID = _accountId;
  try {
    if (inviteCode) {
      const orginfoRes: any = yield OrganizationApi.getOrganizationInfo(inviteCode);
      const { status, data } = orginfoRes;
      if (CommonHelpers.isOkStatus(status)) {
        accountID = data.ownerId;
      }
    }
    const res: any = yield ProductApi.getOwnedProducts(type, accountID, filters, range);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProductsForCompany.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setProductsLoadingAction(false));
  }
}
export function* fetchProductsForUserSaga({ payload }: any) {
  yield put(setProductsLoadingAction(true));
  try {
    const res: any = yield ProductApi.getUserProducts(JSON.stringify(payload.range));
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProductsForCompany.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setProductsLoadingAction(false));
  }
}
export function* fetchProductSaga({ payload }: any) {
  try {
    const res: any = yield ProductApi.getOne(payload.params);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProduct.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}
export function* fetchProductHistorySaga({ payload }: any) {
  try {
    yield put(setDataLoading(true));
    const res: any = yield ProductApi.getProductHistory(payload);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProductHistory.success(data));
    }
    yield put(setDataLoading(false));
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}
export function* createProductForCompanySaga({ payload }: any) {
  try {
    const res: any = yield ProductApi.addProduct(payload.params);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      const state: any = yield select();
      const { selectedAccount } = state.wallet;
      const { _id } = state.user.userData;

      if (data.categoryId) {
        const categoryRes = yield OrganizationApi.getCategory(data.categoryId);
        const category = categoryRes.data;
        yield put(createProductForCompany.success({ ...data, category }));
      } else {
        yield put(createProductForCompany.success(data));
      }
      yield ProductsSecureStorage.createNewProduct({
        ...payload.wallet,
        userWallet: selectedAccount.id,
        userId: _id,
        privateKey: payload.wallet.privateKey,
        account: { ...payload.wallet.account, privateKey: payload.wallet.privateKey },
      });
      // const productSecureData = {
      //   ...payload.wallet,
      //   userWallet: selectedAccount.id,
      //   userId: _id,
      //   privateKey: BaseSecureStorage.encrytKey(payload.wallet.privateKey),
      //   account: { ...payload.wallet.account, privateKey: BaseSecureStorage.encrytKey(payload.wallet.privateKey) },
      // };
      // yield ProductsSecureStorage.createNewProduct({
      //   [data._id]: productSecureData,
      // });
      payload.onSuccess();
      return Toast.success('Product has been created');
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    payload.onFail();
    return Toast.error(message);
  }
}

export function* editProductForCompanySaga({ payload }: any) {
  try {
    const { id, payloadData } = payload.params;
    if (!payloadData.categoryId) delete payloadData.categoryId;
    const res: any = yield ProductApi.updateProduct(id, payloadData);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      const state: any = yield select();
      const { data: storedData, total } = state.products.products;
      const newProductsData = CommonHelpers.updateInArray(
        storedData,
        (p) => p._id === id,
        () => data,
      );
      yield put(getProductsForCompany.success({ data: newProductsData, total }));
      payload.onSuccess();
      return Toast.success(`Product successfully updated`);
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    payload.onFail();
    return Toast.error(message);
  }
}
export function* removeProductForCompanySaga({ payload }: any) {
  try {
    const res: any = yield ProductApi.removeProduct(payload.params);
    const { status } = res;

    if (CommonHelpers.isOkStatus(status)) {
      const state: any = yield select();
      const { data, total } = state.products.products;
      const newProductsData = CommonHelpers.removeFromArray(data, (p) => p._id === payload.params);
      yield put(getProductsForCompany.success({ data: newProductsData, total: total - 1 }));
      payload.onSuccess();
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}
export function* getUserWarranties({ payload = {} }: any) {
  try {
    yield put(setDataLoading(true));
    const res: any = yield ProductApi.getWarranties(payload.params, payload.isAPI_One, payload.range);
    const { status, data } = res;

    if (CommonHelpers.isOkStatus(status)) {
      yield put(getWarrianties.success({ warranties: data.data, total: data.total }));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setDataLoading(false));
  }
}
export function* getScanHistorySaga() {
  try {
    yield put(setDataLoading(true));
    const res: any = yield ProductApi.getScanHistory();
    const { status, data } = res;

    if (CommonHelpers.isOkStatus(status)) {
      yield put(getScanHistory.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setDataLoading(false));
  }
}

export function* registerProductWarrantySaga({ payload }: any) {
  const { productId, warrantyId } = payload;
  try {
    const state: any = yield select();
    const { product } = state.products;
    const res: any = yield ProductApi.registerWarranty(productId, warrantyId);
    const { status } = res;

    if (CommonHelpers.isOkStatus(status)) {
      yield put(
        getProduct.success({
          ...product,
          warranty: {
            ...product.warranty,
            status: 'Pending',
          },
        }),
      );
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

export function* updateWarrantySaga({ payload }: any) {
  try {
    const { warrantyId, updatedData } = payload;
    const res: any = yield ProductApi.updateWarranty(warrantyId, updatedData);
    const state: any = yield select();
    const { status } = res;

    if (CommonHelpers.isOkStatus(status)) {
      const { username } = state.auth;
      yield getWarrianties.req({ params: { manufacturerId: username }, isAPI_One: true });
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}
export function* createWarrantySaga({ payload }: any) {
  try {
    const res: any = yield ProductApi.addWarranty(undefined, payload.params);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(createWarranty.success(data));
      payload.onSuccess(data);
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    payload.onFail();
    return Toast.error(message);
  }
}
export function* getCategoriesSaga({ payload }: any) {
  try {
    const res: any = yield ProductApi.getCategories(payload.params);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getCategories.success(data.data));
      payload.onSuccess && payload.onSuccess();
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    // payload.onError();
    return Toast.error(message);
  }
}

export function* getScanItemSaga({ payload }: any) {
  try {
    yield put(setDataLoading(true));
    const res: any = yield ProductApi.getScanItem(payload.id);
    const { status, data } = res;

    if (CommonHelpers.isOkStatus(status)) {
      yield put(getScanItem.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setDataLoading(false));
  }
}

export function* fetchProductBulksForCompanySaga({
  payload: { accountID: _accountId, filters, inviteCode, range },
}: any) {
  yield put(setProductBulksLoadingAction(true));
  let accountID = _accountId;
  try {
    if (inviteCode) {
      const orginfoRes: any = yield OrganizationApi.getOrganizationInfo(inviteCode);
      const { status, data } = orginfoRes;
      if (CommonHelpers.isOkStatus(status)) {
        accountID = data.ownerId;
      }
    }
    const res: any = yield ProductApi.getProductBulks(accountID, filters, range);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProductBulksForCompany.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setProductBulksLoadingAction(false));
  }
}

export function* fetchProductTemplatesForCompanySaga({
  payload: { accountID: _accountId, filters, inviteCode, range },
}: any) {
  yield put(setProductTemplatesLoadingAction(true));
  let accountID = _accountId;
  try {
    if (inviteCode) {
      const orginfoRes: any = yield OrganizationApi.getOrganizationInfo(inviteCode);
      const { status, data } = orginfoRes;
      if (CommonHelpers.isOkStatus(status)) {
        accountID = data.ownerId;
      }
    }
    const res: any = yield ProductApi.getProductTemplates(accountID, filters, range);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getProductTemplatesForCompany.success(data));
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setProductTemplatesLoadingAction(false));
  }
}

export function* watchProduct(): Generator<any> {
  yield takeLatest(getProductsForCompany.req, fetchProductsForCompanySaga);
  yield takeLatest(getProductsForUser.req, fetchProductsForUserSaga);
  yield takeLatest(getProduct.req, fetchProductSaga);
  yield takeLatest(getProductHistory.req, fetchProductHistorySaga);
  yield takeLatest(createProductForCompany.req, createProductForCompanySaga);
  yield takeLatest(editProductForCompany.req, editProductForCompanySaga);
  yield takeLatest(removeProductForCompany.req, removeProductForCompanySaga);
  yield takeLatest(getWarrianties.req, getUserWarranties);
  yield takeLatest(registerWarranty.req, registerProductWarrantySaga);
  yield takeLatest(getScanHistory.req, getScanHistorySaga);
  yield takeLatest(updateWarrianty.req, updateWarrantySaga);
  yield takeLatest(createWarranty.req, createWarrantySaga);
  yield takeLatest(getCategories.req, getCategoriesSaga);
  yield takeLatest(getScanItem.req, getScanItemSaga);
  yield takeLatest(getProductBulksForCompany.req, fetchProductBulksForCompanySaga);
  yield takeLatest(getProductTemplatesForCompany.req, fetchProductTemplatesForCompanySaga);
}
