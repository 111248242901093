/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-console */
import { API } from 'aws-amplify';

export class Api {
  static instance: Api;
  static apiName = 'MyAPIGatewayAPI';
  static headers = {
    'X-Api-Key': process.env.REACT_APP_API_KEY,
  };

  static init() {
    if (Api.instance) {
      return Api.instance;
    }

    Api.instance = new Api();
  }

  static getAPI() {
    return API;
  }

  static get(url: string, params: object = {}, config: object = {}) {
    const init = {
      headers: Api.headers,
      response: true,
      queryStringParameters: params,
      ...config,
    };
    return Api.getAPI()
      .get(Api.apiName, url, init)
      .catch((e) => {
        console.trace({ scope: 'api', method: 'get', url, error: e });
        throw e;
      });
  }

  static post(url: string, data?: object, config: object = {}) {
    const init = {
      headers: Api.headers,
      response: true,
      body: data,
      ...config,
    };
    return Api.getAPI()
      .post(Api.apiName, url, init)
      .catch((e) => {
        console.trace({ scope: 'api', method: 'post', url, error: e });
        throw e;
      });
  }

  static put(url: string, data?: object, config: object = {}, additionalHeaders: object = {}) {
    const init = {
      headers: { ...Api.headers, ...additionalHeaders },
      response: true,
      body: data,
      ...config,
    };
    return Api.getAPI()
      .put(Api.apiName, url, init)
      .catch((e) => {
        console.trace({ scope: 'api', method: 'put', url, error: e });
        throw e;
      });
  }

  static patch(url: string, data?: object, config: object = {}) {
    const init = {
      headers: Api.headers,
      response: true,
      body: data,
      ...config,
    };
    return Api.getAPI()
      .patch(Api.apiName, url, init)
      .catch((e) => {
        console.trace({ scope: 'api', method: 'patch', url, error: e });
        throw e;
      });
  }
  static makeQuery(obj) {
    let query = '';
    Object.keys(obj).forEach((key) => {
      query += `&${key}=${obj[key]}`;
    });
    return query;
  }

  static delete(url: string, config: object = {}) {
    const init = {
      headers: Api.headers,
      response: true,
      ...config,
    };
    return Api.getAPI()
      .del(Api.apiName, url, init)
      .catch((e) => {
        console.trace({ scope: 'api', method: 'delete', url, error: e });
        throw e;
      });
  }
}
