/* eslint-disable */
import { useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks';
import { ReactComponent as IconMore } from '../../icons/moreOptions.svg';
import { Button } from '../Button/Button';
import styles from './MenuBtn.module.scss';

interface DropdownItem {
  key: string;
  name: string;
}

interface DropdownProps {
  id: string;
  title?: string;
  isIcon?: boolean;
  isLoading?: boolean;
  data: DropdownItem[];
  onSelect?: (id: string) => void;
}

const MenuBtn = ({ id, title = 'Menu', isLoading = false, isIcon = false, data, onSelect }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleChange = (item: DropdownItem) => {
    onSelect && onSelect(item.key);
    setIsOpen(false);
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => setIsOpen(false));

  return (
    <div ref={dropdownRef} className="relative">
      {isIcon ? (
        <div className={styles.menuIconContainer}>
          <IconMore id={id} onClick={() => setIsOpen(!isOpen)} />
        </div>
      ) : (
        <Button
          isLoading={isLoading}
          className={styles.menuBtn}
          id={id}
          isPrimary
          text={title}
          onClick={() => setIsOpen(!isOpen)}
        />
      )}

      {isOpen && (
        <div aria-label="Dropdown menu" className={styles.dropdownMenu}>
          <ul role="menu" aria-labelledby={id} aria-orientation="vertical" className={styles.list}>
            {data?.map((item) => (
              <li key={item.key} onClick={() => handleChange(item)} className={styles.listItem}>
                <span>{item.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuBtn;
