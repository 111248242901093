import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import 'swiper/swiper-bundle.min.css';
import styles from './styles.module.scss';

const ImageSlderModal = ({ images, open, onClose, initialSlide = 0 }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      className={styles['modal']}
      overlayClassName={styles['modal-overlay']}
      contentLabel={t('_CreateProduct_')}
    >
      <span className={styles['modal-close']} onClick={onClose}>
        &#x2715;
      </span>
      <div className={styles.wrapper}>
        <Swiper
          modules={[Pagination, Navigation]}
          pagination={{
            clickable: true,
            bulletActiveClass: styles['active-dot'],
          }}
          navigation={images.length > 1}
          initialSlide={initialSlide}
        >
          {images.map((image, i) => (
            <SwiperSlide className={styles.slide} key={image.src + i}>
              <div className={styles.image}>
                <img src={image.src} alt={image.alt} className={'contain'} />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </Modal>
  );
};

export default ImageSlderModal;
