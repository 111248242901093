/*eslint-disable*/
export namespace ProductDB {
  export interface Account {
    id: string;
    name: string;
    type: string;
    privateKey: string;
    path: string;
    network: string;
  }

  export interface Product {
    publicKey: string;
    privateKey: string;
    account: Account;
    transactionHash: string;
    userWallet: string;
    userId: string;
    bulkId?: string;
  }
}
