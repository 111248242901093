import Rating from 'react-rating';
import { ReactComponent as ActiveStarIcon } from '../../icons/active-star.svg';
import { ReactComponent as InActiveStarIcon } from '../../icons/Inactive-star.svg';

const StarRating = ({ size = 24, ...restProps }) => (
  <Rating
    emptySymbol={<InActiveStarIcon width={size} href="#icon-star-empty" className="icon" />}
    fullSymbol={<ActiveStarIcon width={size} href="#icon-star-empty" className="icon" />}
    className="Rating"
    {...restProps}
  />
);

export default StarRating;
