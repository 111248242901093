import { Api } from './Api';

export class UserApi {
  static registerUser(data: {
    _id: string;
    email: string;
    cognitoUserId: string;
    info: {
      fullName: string;
      address: string;
      birthdate: string;
      phone: string;
    };
  }) {
    return Api.post('/registerUser', data);
  }

  static removeAccount() {
    return Api.delete('/user/profile');
  }
  static getUsers() {
    return Api.get('/user');
  }
  static getUser(id: string) {
    return Api.get(`/user/${id}`);
  }
  static getUserNotifications() {
    return Api.get('/user/notification');
  }
  static updateUserNotification(id: string, status: 'sent' | 'viewed' | 'failed') {
    return Api.put(`/user/notification/${id}`, { status });
  }
  static updateUserNotificationStatuses(status: 'sent' | 'viewed' | 'failed') {
    return Api.put('/user/notification', { status });
  }
  static updateUser(payload: any) {
    return Api.put(`/user/profile`, payload);
  }
  static deleteUser() {
    return Api.delete('/user');
  }
  static async changeEmailFlow(payload: {
    securityAnswers: { questionId: string; answer: string }[];
    newEmail?: string;
    code?: string;
  }): Promise<void> {
    return Api.post('/user/change-email', payload);
  }
}
