import { createAction } from 'deox';
import { CvRequestsType, CvWorkExperienceType, MyCVType } from './types';

export const setMyCv = {
  request: createAction('cv/SAVE_MYCV_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_MYCV_SUCCESS', (resolve) => (payload: MyCVType) => resolve(payload)),
  fail: createAction('cv/SAVE_MYCV_FAIL', (resolve) => (payload: MyCVType) => resolve(payload)),
};
export const getMyCv = {
  request: createAction('cv/GET_MYCV_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/GET_MYCV_SUCCESS', (resolve) => (payload: MyCVType) => resolve(payload)),
  fail: createAction('cv/GET_MYCV_FAIL', (resolve) => (payload: MyCVType) => resolve(payload)),
};

export const createMyCv = {
  request: createAction('cv/CREATE_MYCV_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction('cv/CREATE_MYCV_SUCCESS', (resolve) => (payload: MyCVType) => resolve(payload)),
  fail: createAction('cv/CREATE_MYCV_FAIL', (resolve) => (payload: MyCVType) => resolve(payload)),
};

export const updateMyCv = {
  request: createAction('cv/UPDATE_MYCV_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction('cv/UPDATE_MYCV_SUCCESS', (resolve) => (payload: MyCVType) => resolve(payload)),
  fail: createAction('cv/UPDATE_MYCV_FAIL', (resolve) => (payload: MyCVType) => resolve(payload)),
};

export const setCvWorkExperience = {
  request: createAction('cv/SAVE_WOKR_EXPERIENCE_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction(
    'cv/SAVE_WOKR_EXPERIENCE_SUCCESS',
    (resolve) => (payload: CvWorkExperienceType) => resolve(payload),
  ),
  fail: createAction('cv/SAVE_WOKR_EXPERIENCE_FAIL', (resolve) => (payload: CvWorkExperienceType) => resolve(payload)),
};

export const createCvWorkExperience = {
  request: createAction('cv/CREATE_WOKR_EXPERIENCE_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction(
    'cv/CREATE_WOKR_EXPERIENCE_SUCCESS',
    (resolve) => (payload: CvWorkExperienceType) => resolve(payload),
  ),
  fail: createAction(
    'cv/CREATE_WOKR_EXPERIENCE_FAIL',
    (resolve) => (payload: CvWorkExperienceType) => resolve(payload),
  ),
};

export const updateCvWorkExperience = {
  request: createAction('cv/UPDATE_WOKR_EXPERIENCE_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction(
    'cv/UPDATE_WOKR_EXPERIENCE_SUCCESS',
    (resolve) => (payload: CvWorkExperienceType) => resolve(payload),
  ),
  fail: createAction(
    'cv/UPDATE_WOKR_EXPERIENCE_FAIL',
    (resolve) => (payload: CvWorkExperienceType) => resolve(payload),
  ),
};

export const setCvRequests = {
  request: createAction('cv/SAVE_CVREQUEST_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_CVREQUEST_SUCCESS', (resolve) => (payload: CvRequestsType) => resolve(payload)),
  fail: createAction('cv/SAVE_CVREQUEST_FAIL', (resolve) => (payload: CvRequestsType) => resolve(payload)),
};

export const createCvRequests = {
  request: createAction('cv/CREATE_CVREQUEST_REQUEST', (resolve) => (params) => resolve(params)),
  success: createAction('cv/CREATE_CVREQUEST_SUCCESS', (resolve) => (payload: CvRequestsType) => resolve(payload)),
  fail: createAction('cv/CREATE_CVREQUEST_FAIL', (resolve) => (payload: CvRequestsType) => resolve(payload)),
};

export const createEducation = createAction(
  'cv/CREATE_CVEDUCATION_REQUEST',
  (resolve) => (params?: any) => resolve(params),
);

export const updateEducation = createAction(
  'cv/UPDATE_CVEDUCATION_REQUEST',
  (resolve) => (params?: any) => resolve(params),
);

export const setUserManagmentList = {
  request: createAction('userManagment/SAVE_USERMANAGMENT_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('userManagment/SAVE_USERMANAGMENT_SUCCESS', (resolve) => (payload?: any) => resolve(payload)),
  fail: createAction('userManagment/SAVE_USERMANAGMENT_FAIL', (resolve) => (payload: any) => resolve(payload)),
};

export const setUserManagmentInfo = {
  request: createAction('userManagment/SAVE_USERMANAGMENTINFO_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction(
    'userManagment/SAVE_USERMANAGMENTINFO_SUCCESS',
    (resolve) => (payload?: any) => resolve(payload),
  ),
  fail: createAction('userManagment/SAVE_USERMANAGMENTINFO_FAIL', (resolve) => (payload: any) => resolve(payload)),
};

export const setOrganizationsCVList = {
  request: createAction('cv/SAVE_ORGANIZATIONS_CV_LIST_REQUEST', (resolve) => (params?: any) => resolve(params)),
  success: createAction('cv/SAVE_ORGANIZATIONS_CV_LIST_SUCCESS', (resolve) => (payload?: any) => resolve(payload)),
  fail: createAction('cv/SAVE_ORGANIZATIONS_CV_LIST_FAIL', (resolve) => (payload: any) => resolve(payload)),
};
