import { createAction } from 'deox';
import { UpdateNetwork } from '../../modules/network/types';

export const networkInitStateAction = createAction('network/NETWORK_INIT_STATE');
export const networkUpdateAction = createAction('network/NETWORK_UPDATE', (res) => (data: UpdateNetwork) => res(data));
export const setCheckNodeJobAction = createAction(
  'network/SET_CHECK_NODE_JOB',
  (res) => (data: { job: NodeJS.Timer }) => res(data),
);
export const registerNodeCheckJobAction = createAction('network/REGISTER_NODE_CHECK_JOB');
export const checkNetworkAction = createAction('network/CHECK_NETWORK');
export const setIsUpAction = createAction('network/SET_IS_UP', (res) => (data: { isUp: boolean }) => res(data));
export const setNodeFailedAttemptsAction = createAction(
  'network/SET_NODE_FAILED_ATTEMPTS',
  (res) => (data: number) => res(data),
);
