import { useEffect, useState } from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import BusyLoader from '../../../components/BusyLoader';
import './styles.scss';

const ApiDescription = () => {
  const [isBusy, setIsBusy] = useState(false);

  useEffect(() => {
    setIsBusy(true);
  }, []);

  return (
    <BusyLoader isBusy={isBusy} className="ApiDescription flexible aCenter jCenter s">
      <SwaggerUI url={`${process.env.REACT_APP_GATEWAY_API}/documentation`} onComplete={() => setIsBusy(false)} />
    </BusyLoader>
  );
};

export default ApiDescription;
