import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useAction } from '../../hooks';
import { logoutActionRequest } from '../../modules/auth/actions';
import { Header } from '../Header/Header';
import { Sidebar } from '../Sidebar/Sidebar';
import styles from './LayoutAuthenticated.module.scss';

export const LayoutAuthenticated: React.FC = (props) => {
  const location = useLocation();
  const logOut = useAction(logoutActionRequest);
  const history = useHistory();
  const timeoutRef = React.useRef<NodeJS.Timeout>();
  const { userData } = useSelector((state: any) => state.user);
  const { organizations } = useSelector((state: any) => state.organization);

  useEffect(() => {
    // clear session storage and logout user after 6 hours of inactivity
    timeoutRef.current = setTimeout(() => {
      logOut({ onSuccess: () => history.push('/') });
    }, 1000 * 60 * 60 * 6);

    // fallback just in case, to logout on page change
    localStorage.setItem('logout_timestamp', (Date.now() + 1000 * 60 * 60 * 6).toString());

    // closed session timestamp
    localStorage.setItem('closed_session_timestamp', (Date.now() + 1000 * 60 * 5).toString());

    return () => {
      clearTimeout(timeoutRef.current);

      if (localStorage.getItem('logout_timestamp') && +localStorage.getItem('logout_timestamp') < Date.now()) {
        logOut({ onSuccess: () => history.push('/') });
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    if (userData?.isVerificationNeeded && userData?.kyc_status === 'not verified') {
      logOut({ onSuccess: () => history.push('/') });
      return;
    }

    const approvalStatus = organizations?.data?.[0]?.approvalStatus || organizations?.data?.approvalStatus;
    if (userData.isVerificationNeeded && ['declined', 'not verified'].includes(approvalStatus)) {
      logOut({ onSuccess: () => history.push('/') });
      return;
    }
  }, [userData]);

  return (
    <div className={styles.layout}>
      <Header className={styles.header} pageTitle={location.pathname} />
      <Sidebar className={styles.sidebar} />
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};
