import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Input } from '../../components/Input/Input';
import { PaymentsApi } from '../../services/api/PaymentsApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export default function CheckoutForm() {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const { state, isActive }: { state: any; isActive?: boolean } = useLocation();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');

  const handleMessage = (e) => {
    const message = CommonHelpers.erorHandlerMessage(e);
    setIsLoading(false);
    return Toast.error(message);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const result = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
      billing_details: {
        email: email,
        name: `${firstName} ${lastName}`,
      },
    });

    if (result) {
      try {
        await PaymentsApi.createSubscriptions({ priceId: state?.data?.prices[isActive ? 0 : 1]?._id });
        setIsLoading(false);
        history.goBack();
      } catch (e) {
        handleMessage(e);
      }
    }
  };

  const handleChange = (val, func) => func(val);

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flexible">
      {!stripe ? (
        <div className="spinner" id="spinner"></div>
      ) : (
        <div className="flexible grow vertical form-content">
          <Input
            type="text"
            placeholder={t('_FirstName_')}
            onChange={(e) => handleChange(e.target.value, setFirstName)}
          />
          <Input
            type="text"
            placeholder={t('_LastName_')}
            onChange={(e) => handleChange(e.target.value, setLastName)}
          />
          <Input type="text" placeholder={t('_Email_')} onChange={(e) => handleChange(e.target.value, setEmail)} />
          <CardElement options={CARD_ELEMENT_OPTIONS} id="payment-element" className="flexible grow" />
        </div>
      )}
      <div className="summary-container flexible vertical">
        <h4>{t('_Summary_')}</h4>
        <div className="flexible aCenter jBetween total-info">
          <span>{t('_PlanName_')}</span>
          <span>{state?.data?.title}</span>
        </div>
        <div className="flexible aCenter jBetween total-info">
          <span>{t('_TotalPlanPrice_')}</span>
          <span>${state?.data?.prices[isActive ? 0 : 1]?.amount}</span>
        </div>
        <Button isPrimary disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">{isLoading ? <div className="spinner" id="spinner"></div> : t('_SubscribeNow_')}</span>
        </Button>
        <span className="info-text">{t('_YouWillBeChargedIndividuall_')}</span>
      </div>
    </form>
  );
}
