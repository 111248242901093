import encryptpwd from 'encrypt-with-password';
import { useFormik } from 'formik';
import { t } from 'i18next';
import { useState } from 'react';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { Modal } from '../../../../components/Modal/Modal';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { Toast } from '../../../../utils/toastHelper';
import { ExportImportProductKeysValidation } from '../../../../utils/validations';
import styles from '../ExportProductKeysModal/ExportProductKeysModal.module.scss';

interface ExportProductKeysModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ImportProductKeysModal = ({ isOpen, onClose }: ExportProductKeysModalProps) => {
  const [file, setFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileUpload = ({ target }) => {
    setFile(target.files?.[0]);
    setErrors({ password: '' });
  };

  const decodeFileToLocalStorage = async () => {
    if (!file) {
      setErrors({ password: t('_No_file_provided_') });
    }

    const reader = new FileReader();
    reader.onload = async (event) => {
      if (!event.target) {
        return;
      }
      setIsLoading(true);

      try {
        const encryptedData = JSON.parse(event.target.result as string).do_not_modify;
        const decryptedData = encryptpwd.decryptJSON(encryptedData, values.password);

        decryptedData.forEach((product) => {
          ProductsSecureStorage.createNewProduct(product);
          // here we can do transaction in case we need to transfer product to new wallet,
          // just need to be sure if transaction actually needed (authentic, belongs to user, wallet has changed, etc.)
        });

        handleClose();
        Toast.success(t('_Private_keys_imported_successfully_'));
      } catch (error) {
        setErrors({ password: t('_Invalid_password_or_file_') });
      } finally {
        setIsLoading(false);
      }
    };

    reader.readAsText(file);
  };

  const { setFieldValue, setErrors, values, errors, handleSubmit } = useFormik({
    initialValues: {
      password: '',
    },
    validateOnChange: false,
    validationSchema: ExportImportProductKeysValidation,
    onSubmit: decodeFileToLocalStorage,
  });

  const handleClose = () => {
    setFieldValue('password', '');
    setErrors({ password: '' });
    setFile(null);
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={styles.modalContent}>
        <Text type="h1">{t('_Import_private_keys_')}</Text>
        <input
          id="importProductKeysFileInout"
          type="file"
          accept=".json"
          onChange={handleFileUpload}
          className={styles.fileInput}
        />
        <label htmlFor="importProductKeysFileInout" className={styles.fileInputLabel}>
          <p>{file ? file.name : t('_Choose_file_')}</p>
        </label>
        <Input
          type="password"
          value={values.password}
          label={t('_SetPassword_')}
          isPassword
          hasError={!!errors.password}
          errorMessage={errors.password}
          size="lg"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
        />
        <Button onClick={handleSubmit} isPrimary isLoading={isLoading}>
          {t('_Import_private_keys_')}
        </Button>
      </div>
    </Modal>
  );
};

export default ImportProductKeysModal;
