import { createAction } from 'deox';

export const setDataLoading = createAction('user/SET_USER_DATA_LOADING', (res) => (params) => res(params));
export const setKYCVerified = {
  req: createAction('user/SET_KYC_VERIFIED', (res) => (params) => res(params)),
};

export const getUserData = {
  req: createAction('user/GET_USER_DATA', (res) => (params) => res(params)),
  success: createAction('user/GET_USER_DATA_SUCCESS', (res) => (params) => res(params)),
  reset: createAction('user/RESET_USER_DATA_SUCCESS', (res) => (params) => res(params)),
};
export const removeUserAccount = {
  req: createAction('user/REMOVE_ACCOUNT', (res) => (onSuccess) => {
    return res({ onSuccess });
  }),
};
export const editUserProfile = {
  req: createAction('user/EDIT_USER_PROFILE', (res) => (params) => res(params)),
  success: createAction('user/EDIT_USER_PROFILE_SUCCESS', (res) => (params) => res(params)),
};
export const setUserGroup = createAction('user/SET_USER_GROUP', (res) => (params) => res(params));
