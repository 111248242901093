/* eslint-disable @typescript-eslint/no-explicit-any */
import cn from 'classnames';
import { FC, forwardRef, useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useTranslation } from 'react-i18next';
import { useOnClickOutside } from '../../hooks';
import { ReactComponent as ArrowLeftIcon } from '../../icons/arrow-left.svg';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/calendar-arrow-down.svg';
import { ReactComponent as CalendarIcon } from '../../icons/calendar.svg';
import './DatePickerInput.scss';

interface DatePickerInputProps {
  disabled?: boolean;
  minDate?: any;
  maxDate?: any;
  startDate?: any;
  endDate?: any;
  dataCallback?: (dates: any) => void;
  isRange?: boolean;
  placeholderText?: string;
  needCustomEl?: boolean;
  dateFormat?: string;
  min?: any;
  max?: any;
  withIcon?: boolean;
  errorMessage?: string;
  className?: string;
  info?: string;
}

const getYearsOptions = () => {
  const endYear = new Date().getFullYear();

  return Array(122)
    .fill(0)
    .map((_, index) => endYear - index);
};

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
const years = getYearsOptions();

export const DatePickerInput: FC<DatePickerInputProps> = ({
  min,
  max,
  minDate,
  withIcon,
  disabled,
  maxDate = null,
  dataCallback,
  isRange = true,
  errorMessage,
  dateFormat = 'dd/MM/yyyy',
  needCustomEl = true,
  info = '',
  className = '',
  ...restProps
}) => {
  const [startDate, setStartDate] = useState(minDate);
  const [endDate, setEndDate] = useState(maxDate);
  const [isOpenYear, setIsOpenYear] = useState(false);
  const [isOpenMonth, setIsOpenMonth] = useState(false);
  const { t } = useTranslation();
  const monthRef = useRef(null);
  const yearRef = useRef(null);

  useOnClickOutside(monthRef, () => setIsOpenMonth(false));
  useOnClickOutside(yearRef, () => setIsOpenYear(false));

  useEffect(() => {
    setStartDate(minDate);
  }, [minDate]);

  const onChange = (date: any) => {
    if (isRange) {
      const [start, end] = date;
      setStartDate(start);
      setEndDate(end);
      if (dataCallback) {
        dataCallback({ start, end });
      }
    } else {
      setStartDate(date);
      dataCallback(date);
    }
  };
  // @ts-ignore
  const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
    <button
      className="input button-input"
      onClick={onClick}
      // @ts-ignore
      ref={ref}
    >
      {value || t('_SelectDates_')}
    </button>
  ));
  return (
    <div className={cn('DatePickerInput', className, { 'with-icon': withIcon, 'has-error': !!errorMessage })}>
      {withIcon && (
        <div className="calendar-icon">
          <div className="calendar-info-content">
            <CalendarIcon />
            {info && disabled && <div className="calendar-info">{info}</div>}
          </div>
        </div>
      )}
      <DatePicker
        selected={startDate}
        {...(min && { minDate: min })}
        {...(max && { maxDate: max })}
        onChange={onChange}
        disabled={disabled}
        dateFormat={dateFormat}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div className="custom-header flexible jBetween aCenter">
            <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
              <ArrowLeftIcon />
            </button>
            <div className="custom-select-block flexible aCenter" ref={yearRef}>
              <div className="custom-select" ref={monthRef}>
                <div className="custom-select-value flexible aCenter" onClick={() => setIsOpenMonth(true)}>
                  {months[date.getMonth()]} <ArrowDownIcon className="arrow-down" />
                </div>
                {isOpenMonth && (
                  <div className="custom-select-options forMonth flexible vertical aCenter">
                    {months.map((option) => (
                      <div
                        key={option}
                        className={cn('select-options', { active: option === months[date.getMonth()] })}
                        onClick={() => {
                          setIsOpenMonth(false);
                          changeMonth(months.indexOf(option.toString()));
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="custom-select">
                <div className="custom-select-value flexible aCenter" onClick={() => setIsOpenYear(true)}>
                  {date.getFullYear()} <ArrowDownIcon className="arrow-down" />
                </div>
                {isOpenYear && (
                  <div className="custom-select-options flexible vertical aCenter">
                    {years.map((option) => (
                      <div
                        key={option}
                        className={cn('select-options', { active: date.getFullYear() === Number(option) })}
                        onClick={() => {
                          setIsOpenYear(false);
                          changeYear(Number(option));
                        }}
                      >
                        {option}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
              <ArrowRightIcon />
            </button>
          </div>
        )}
        {...(isRange && {
          minDate,
          maxDate,
          endDate,
          startDate,
        })}
        {...(needCustomEl && {
          customInput: <ExampleCustomInput />,
        })}
        selectsRange={isRange}
        {...restProps}
      />
      {errorMessage && <span className="errors">{errorMessage}</span>}
    </div>
  );
};
