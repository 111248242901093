import cn from 'classnames';
import { convertToRaw, EditorState } from 'draft-js';
import { stateToMarkdown } from 'draft-js-export-markdown';
import { stateFromMarkdown } from 'draft-js-import-markdown';
import { FC, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import styles from './TextEditor.module.scss';

export const TextEditor: FC<{
  initialState?: string;
  placeholder?: string;
  onChange?: (editorState: string) => void;
  readOnly?: boolean;
}> = ({ placeholder, initialState, onChange, readOnly = false }) => {
  const [editorState, setEditorState] = useState(() => {
    return EditorState.createWithContent(stateFromMarkdown(initialState));
  });

  return (
    <div className={cn(styles.container)}>
      <Editor
        toolbar={{
          options: ['inline', 'list'],
          inline: {
            inDropdown: false,
            options: ['bold', 'italic', 'underline'],
          },
          list: { inDropdown: false },
        }}
        readOnly={readOnly}
        editorState={editorState}
        wrapperClassName={cn(styles.wrapper, {
          [styles.wrapper_hide]: readOnly,
        })}
        editorClassName={cn(styles.editor, {
          [styles.editor_hide]: readOnly,
        })}
        toolbarClassName={cn(styles.toolbar, {
          [styles.toolbar_hide]: readOnly,
        })}
        placeholder={placeholder}
        onEditorStateChange={(nextEditorState) => {
          const newState = convertToRaw(nextEditorState.getCurrentContent());
          const shouldUpdate = !(
            newState.blocks.length === 1 &&
            !newState.blocks[0].text &&
            !convertToRaw(editorState.getCurrentContent()).blocks[0].text
          );
          setEditorState(nextEditorState);
          if (shouldUpdate) {
            onChange?.(stateToMarkdown(nextEditorState.getCurrentContent()));
          }
        }}
      />
    </div>
  );
};
