import cn from 'classnames';
import { FC } from 'react';
import ButtonLoader from '../ButtonLoader';

export interface ButtonProps {
  text: string;
  isPrimary?: boolean;
  isPrimaryOutline?: boolean;
  isSecondary?: boolean;
  isGhost?: boolean;
  isDisabled?: boolean;
  isAlert?: boolean;
  isSuccess?: boolean;
  type: string;
  onClick: void;
  className?: string;
  style?: any;
  children?: any;
  isLoading?: boolean;
  isSmall?: boolean;
}
export const Button: FC<any> = ({
  className,
  text = 'submit',
  isPrimary = false,
  isSecondary = false,
  isPrimaryOutline = false,
  isDisabled = false,
  isSuccess = false,
  isGhost = false,
  isAlert = false,
  isSmall = false,
  type = 'button',
  style,
  onClick,
  children,
  isLoading,
}) => {
  return (
    <button
      className={cn('button', className, {
        'button-primary': isPrimary,
        'button-primaryOutline': isPrimaryOutline,
        'button-success': isSuccess,
        'button-secondary': isSecondary,
        'button-alert': isAlert,
        'button-ghost': isGhost,
        'button-small': isSmall,
      })}
      style={style}
      type={type}
      disabled={isDisabled}
      onClick={!isLoading ? onClick : null}
    >
      {isLoading ? <ButtonLoader /> : children || <span>{text}</span>}
    </button>
  );
};
