import { put, select, takeLatest } from 'redux-saga/effects';
import { UInt64 } from 'symbol-sdk';
import { setLoading } from '../../modules/wallet/actions';
import HarvestingService from '../../services/symbol/HarvestingService';
import { HarvestingSecureStorage } from '../../storage/persistence/HarvestingSecureStorage';
import {
  harvestingInitAction,
  loadHarvestedBlocksAction,
  loadHarvestedBlocksStatsAction,
  loadHarvestingModelAction,
  loadHarvestingNodesAction,
  loadStateAction,
  resetStateAction,
  setHarvestedBlocksAction,
  setHarvestedBlockStatsAction,
  setHarvestingModelAction,
  setNodesAction,
  setStatusAction,
} from './actions';

// { payload }: ReturnType<typeof harvestingAction.request>
function* harvestingInitSaga() {
  try {
    yield put(loadStateAction());
    yield put(loadHarvestedBlocksAction());
    yield put(loadHarvestedBlocksStatsAction());
    yield put(loadHarvestingModelAction());
    yield put(loadHarvestingNodesAction());
  } catch (error) {
    console.error(error);
    yield put(resetStateAction());
  }
}

function* loadStateSaga() {
  const { wallet, network } = yield select();
  try {
    // @ts-ignore
    const status = yield HarvestingService.getAccountStatus(wallet.selectedAccount, network.selectedNetwork);
    yield put(setStatusAction(status));
    yield put(setLoading(100));
  } catch (e) {
    console.error(e);
    yield put(setStatusAction('INACTIVE'));
  }
}
function* loadHarvestedBlocksSaga() {
  const { wallet, network } = yield select();

  try {
    // @ts-ignore
    const harvestedBlocks = yield HarvestingService.getHarvestedBlocks(wallet.selectedAccount, network.selectedNetwork);
    yield put(setHarvestedBlocksAction(harvestedBlocks));
  } catch (e) {
    console.error(e);
    yield put(setStatusAction('INACTIVE'));
  }
}
function* setBlock(blocks: { totalBlockCount: number; totalFeesEarned: UInt64 }) {
  yield put(setHarvestedBlockStatsAction(blocks));
}
function* loadHarvestedBlocksStatsSaga() {
  const { wallet, network } = yield select();

  try {
    // @ts-ignore
    yield HarvestingService.getHarvestedBlocksStats(wallet.selectedAccount, network.selectedNetwork, setBlock);
  } catch (e) {
    console.error(e);
  }
}
function* loadHarvestingModelSaga() {
  const { wallet } = yield select();
  try {
    // @ts-ignore
    const harvestingModel = yield HarvestingSecureStorage.getHarvestingModel(wallet.selectedAccount.id);
    yield put(setHarvestingModelAction(harvestingModel));
  } catch (e) {
    console.error(e);
  }
}
function* loadHarvestingNodesSaga() {
  const { network } = yield select();
  try {
    // @ts-ignore
    const nodes = yield HarvestingService.getPeerNodes(network.selectedNetwork);
    yield put(setNodesAction(nodes));
  } catch (e) {
    console.error(e);
    yield put(setNodesAction(HarvestingService.getHarvestingNodeList()));
  }
}
export function* watchHarvesting() {
  yield takeLatest(harvestingInitAction, harvestingInitSaga);
  yield takeLatest(loadStateAction, loadStateSaga);
  yield takeLatest(loadHarvestedBlocksAction, loadHarvestedBlocksSaga);
  yield takeLatest(loadHarvestedBlocksStatsAction, loadHarvestedBlocksStatsSaga);
  yield takeLatest(loadHarvestingModelAction, loadHarvestingModelSaga);
  yield takeLatest(loadHarvestingNodesAction, loadHarvestingNodesSaga);
}
