import cn from 'classnames';
import { FC, memo } from 'react';
import Text from '../Text';
import styles from './styles.module.scss';

const Label: FC<{ status: string; text?: string; classname?: string; isWarranty?: boolean; id?: string }> = memo(
  ({ status, text, classname = '', isWarranty = false, id = '' }) => {
    const className = cn(
      styles.labelStatus,
      styles[`${isWarranty ? 'warranty-' : ''}label-${status.toLocaleLowerCase().replace(' ', '-')}`],
      classname,
    );
    return (
      <Text id={id} className={className} type="subtitle_s" color="dark-primary" fontWeight="regular">
        {text || status}
      </Text>
    );
  },
);

export default Label;
