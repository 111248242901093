/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import cn from 'classnames';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDetectUserType } from '../../hooks/useDetectUserType';
import { networkInitStateAction } from '../../modules/network/actions';
import { saveWalletAction } from '../../modules/wallet/actions';
import { UserApi } from '../../services/api/UserApi';
import { MnemonicSecureStorage } from '../../storage/persistence/MnemonicSecureStorage';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { ProfileDetailsValidation as validationSchema } from '../../utils/validations';
import ProfileInfo from './ProfileInfo';
import './SignUp.scss';

const ProfileDetails: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const userType = useDetectUserType();
  const dispatch = useDispatch();
  const { userData } = useSelector((state: any) => state.user);

  const onSubmit = async (data: any, { setErrors }: any) => {
    try {
      setIsButtonLoading(true);
      if (!data.phone) {
        setErrors({ phone: t('_requiredPhone_') });
        setIsButtonLoading(false);
        return;
      }
      const info: any = CommonHelpers.removeObjectEmptyFillds({
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        country: data.country.value.toLocaleUpperCase(),
        citizenship: data.citizenship.value.toLocaleUpperCase(),
        address: data.address,
        address2: data.address2,
        birthdate: values.birthdate ? dayjs(values.birthdate).format() : '',
        phone: data.phone.startsWith('+') ? data.phone : '+' + data.phone,
        // "region": "",
      });
      try {
        await UserApi.updateUser({
          info,
          group: userType,
        });
        localStorage.setItem('country', data.country.value.toLocaleUpperCase());
        setIsButtonLoading(false);

        if (userData.group !== 'company' && userData.kyc_status === 'not verified') {
          sessionStorage.setItem('userData', JSON.stringify(userData));
          history.push('/intro-kyc');
          return;
        }

        if (userData.isVerificationNeeded && userData.kyc_status === 'not verified') {
          sessionStorage.setItem('userData', JSON.stringify(userData));
          history.push('/intro-kyc');
          return;
        }

        if (localStorage.getItem('authFlow') === 'registration') {
          history.push('/wallet-creation');
          return;
        }

        if (!localStorage.getItem('MNEMONIC_MODEL')) {
          history.push('/wallet-connect');
          return;
        }

        const mnemonicModel = await MnemonicSecureStorage.retrieveMnemonic();
        const mnemonic = mnemonicModel.mnemonic;
        dispatch(
          saveWalletAction({
            mnemonic,
            onSuccess: () => {
              dispatch(networkInitStateAction());
              history.push('/products');
            },
            onError: () => {
              history.push('/wallet-connect');
            },
          }),
        );
      } catch {
        setIsButtonLoading(false);
      }
    } catch (e) {
      if (CommonHelpers.erorHandlerMessage(e).includes('info.birthdate')) {
        setErrors({ birthdate: t('_Incorect_birtdate_') });
      }
      if (CommonHelpers.erorHandlerMessage(e).includes('info.phone')) {
        setErrors({ phone: CommonHelpers.erorHandlerMessage(e) });
      }

      // @ts-ignore
      if (e.message.match(/phone/gi)) {
        setErrors({ phone: t('_IncorrectPhoneNumber_') });
      }
      Toast.error(CommonHelpers.erorHandlerMessage(e));
    }
  };

  const { setFieldValue, values, errors, handleSubmit } = useFormik({
    initialValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      address: '',
      address2: '',
      birthdate: '',
      phone: '',
      country: { label: 'France', value: 'FR' },
      citizenship: { label: 'France', value: 'FR' },
    },
    validateOnChange: false,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="login signup">
      <div className="signup__container">
        <div className={cn('login__wrapper', 'signup-profile-details')}>
          <h2 className="auth__title">{t(userType === 'company' ? '_Business_profile_' : '_Individual_profile_')}</h2>
          <h4 className="auth__description">{''}</h4>

          <ProfileInfo
            values={values}
            errors={errors}
            handleSetFieldValue={setFieldValue}
            buttonValue={t('_Next_')}
            handleSubmit={handleSubmit}
            isButtonLoading={isButtonLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileDetails;
