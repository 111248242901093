import { createAction } from 'deox';
import { Subscription } from 'rxjs';
import { TransactionModel } from '../../storage/models/TransactionModel';

export const initTransactionAction = createAction('transaction/INIT_TRANSACTION');
export const resetTransactionAction = createAction('transaction/RESET_TRANSACTION');
export const loadNextPageAction = createAction('transaction/LOAD_NEXT_PAGE');
export const checkPendingSignaturesAction = createAction('transaction/CHECK_PENDING_SIGNATURES');
export const setLoadingAction = createAction('transaction/SET_LOADING', (res) => (loading: boolean) => res(loading));
export const setLoadingNextAction = createAction(
  'transaction/SET_LOADING_NEXT',
  (res) => (isNextLoading: boolean) => res(isNextLoading),
);
export const setSubscriptionAction = createAction(
  'transaction/SET_SUBSCRIPTION',
  (res) => (subscription: Subscription) => res(subscription),
);
export const setAddressFilterAction = createAction(
  'transaction/SET_ADDRESS_FILTER',
  (res) => (addressFilter: string) => res(addressFilter),
);
export const setDirectionFilterAction = createAction(
  'transaction/SET_DIRECTION_FILTER',
  (res) => (directionFilter: string) => res(directionFilter),
);
export const setTransactionsAction = createAction(
  'transaction/SET_TRANSACTIONS',
  (res) => (transactions: TransactionModel[]) => res(transactions),
);
export const setAddTransactionsAction = createAction(
  'transaction/SET_ADD_TRANSACTIONS',
  (res) => (transactions: TransactionModel[]) => res(transactions),
);
export const setPageAction = createAction('transaction/SET_PAGE', (res) => (page: number) => res(page));
export const setIsLastPageAction = createAction(
  'transaction/SET_IS_LAST_PAGE',
  (res) => (isLastPage: boolean) => res(isLastPage),
);
export const setPendingSignatureAction = createAction(
  'transaction/SET_PENDING_SIGNATURE',
  (res) => (pendingSignature: string) => res(pendingSignature),
);
