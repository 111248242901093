import cn from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Text from '../common/Text';
import { ReactComponent as EllipseIcon } from './../../icons/ellipse.svg';
import { ReactComponent as EmailIcon } from './../../icons/email_icon.svg';
import { ReactComponent as IdentifyIcon } from './../../icons/kyc_identify.svg';
import { ReactComponent as NoResult } from './../../icons/no_result.svg';
import styles from './styles.module.scss';

interface IProps {
  children?: any;
  type?: string;
  className?: string;
  hideText?: boolean;
}

const NoItem: FC<IProps> = ({ children, type = 'empty', className = '', hideText = false }) => {
  const { t } = useTranslation();
  const getIcon = () => {
    switch (type) {
      case 'empty':
        return (
          <div className={styles.container__img}>
            <NoResult />
          </div>
        );
      case 'email':
        return (
          <div className={styles.container__img}>
            <EmailIcon />
          </div>
        );
      case 'identify':
        return (
          <div className={styles.container__img}>
            <IdentifyIcon />
          </div>
        );
      default:
        return (
          <div className={styles.container__img}>
            <NoResult />
          </div>
        );
    }
  };
  return (
    <div className={cn(styles.container, className)}>
      {getIcon()}
      <EllipseIcon />
      {children ? (
        children
      ) : hideText ? null : (
        <Text className={styles.container__text} type="h3" color="secondary-dark-blue">
          {t('_No_results_')}
        </Text>
      )}
    </div>
  );
};

export default NoItem;
