import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const AppDownloader = () => {
  const { pathname } = useLocation();
  const currentRef = useRef();
  const isAppleDevice = pathname.includes('apple-app-site-association');

  useEffect(() => {
    const elem: any = currentRef.current;
    elem.click();
  }, []);

  // eslint-disable-next-line jsx-a11y/anchor-has-content
  return <a ref={currentRef} href={isAppleDevice ? `/apple-app-site-association` : '/assetlinks.json'} />;
};

export default AppDownloader;
