import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Text from '../../components/common/Text';
import { Input } from '../../components/Input/Input';
import { useRedirect } from '../../hooks/useRedirect';
import { ReactComponent as ArrowIcon } from '../../icons/arrow.svg';
import { loadAccountAction } from '../../modules/wallet/actions';
import { Toast } from '../../utils/toastHelper';
import './styles.scss';

const WalletDetails = () => {
  const { t } = useTranslation();
  const index = Number(localStorage.getItem('accountIndex'));
  const account = useSelector((state: any) => state.wallet.accounts[index]);
  const accountAddress = useSelector((state: any) => state.account?.selectedAccountAddress);
  const { mnemonic } = localStorage.MNEMONIC_MODEL ? JSON.parse(localStorage.MNEMONIC_MODEL) : '';
  const [values, setValues] = useState({
    publicKey: '',
    privateKey: '',
    seedPhrase: '',
  });
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(loadAccountAction({}));
  }, []);
  useEffect(() => {
    setValues({
      seedPhrase: mnemonic,
      publicKey: account?.id,
      privateKey: account?.privateKey,
    });
  }, [account, mnemonic]);
  const [blooredVallues, setBlooredValues] = useState({
    privateKeyIsBloored: true,
    seedPhraseIsBloored: true,
  });
  const handleBack = useRedirect('/settings');
  const copyToClipboard = useCallback((text) => {
    navigator.clipboard.writeText(text as string).then(() => {
      Toast.info(t('_CopiedToClipboard_'));
    });
  }, []);
  const handleBloor = (name: 'privateKeyIsBloored' | 'seedPhraseIsBloored', value: boolean) => {
    setBlooredValues({ ...blooredVallues, [name]: value });
  };
  return (
    <>
      <div className="page-header">
        <div className={'signup__back-button'} onClick={handleBack}>
          <ArrowIcon />
          <span>{t('_Back_')}</span>
        </div>
        <Text type="h1" className="page__title authenticated-page-title">
          {t('_WalletDetails_')}
        </Text>
        <div className="wallet-details-wrapper">
          <div className="wallet-details-wrapper__title">
            <Text color="tertiary-orange" type="caption" fontWeight="bold">
              {t('_NeverShareYourPrivateKey_')}
            </Text>
          </div>
          <div className="wallet-details-wrapper__inputBox">
            <Input type="text" value={accountAddress} label={t('_WalletAddress_')} name="accountAddress" isDisabled />
            <div className="box-bottom-text">
              <Text onClick={() => copyToClipboard(accountAddress)} color="primary-blue" fontWeight="bolder">
                {t('_Copy_')}
              </Text>
            </div>
          </div>
          <div className="wallet-details-wrapper__inputBox">
            <Input type="text" value={values.publicKey} label={t('_PublicKey_')} name="mnemonic" isDisabled />
            <div className="box-bottom-text">
              <Text onClick={() => copyToClipboard(values.publicKey)} color="primary-blue" fontWeight="bolder">
                {t('_Copy_')}
              </Text>
            </div>
          </div>

          <div className="wallet-details-wrapper__inputBox">
            <Input
              type="text"
              value={values.privateKey}
              label={t('_PrivateKey_')}
              name="mnemonic"
              isDisabled
              isTextArea
              className={blooredVallues.privateKeyIsBloored ? 'blooredText' : ''}
            />
            <div className="box-bottom-text box-bottom-text-withToggle">
              <Text
                color="primary-blue"
                fontWeight="bolder"
                onClick={() => handleBloor('privateKeyIsBloored', !blooredVallues.privateKeyIsBloored)}
              >
                {t(blooredVallues.privateKeyIsBloored ? '_Show_' : '_Hide_')} {t('_PrivateKey_')}
              </Text>
              <Text onClick={() => copyToClipboard(values.privateKey)} color="primary-blue" fontWeight="bolder">
                {t('_Copy_')}
              </Text>
            </div>
          </div>
          <div className="wallet-details-wrapper__inputBox">
            <Input
              type="text"
              value={values.seedPhrase}
              label={t('_SeedPhrase_')}
              name="mnemonic"
              isDisabled
              isTextArea
              className={blooredVallues.seedPhraseIsBloored ? 'blooredText' : ''}
            />
            <div className="box-bottom-text box-bottom-text-withToggle">
              <Text
                color="primary-blue"
                fontWeight="bolder"
                onClick={() => handleBloor('seedPhraseIsBloored', !blooredVallues.seedPhraseIsBloored)}
              >
                {t(blooredVallues.seedPhraseIsBloored ? '_Show_' : '_Hide_')} {t('_SeedPhrase_')}
              </Text>
              <Text onClick={() => copyToClipboard(values.seedPhrase)} color="primary-blue" fontWeight="bolder">
                {t('_Copy_')}
              </Text>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WalletDetails;
