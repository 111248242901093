/* eslint-disable @typescript-eslint/no-explicit-any */

import {
  AccountHttp,
  Address,
  AggregateTransaction,
  LockFundsTransaction,
  MosaicAliasTransaction,
  NamespaceRegistrationTransaction,
  Order,
  PublicAccount,
  RepositoryFactoryHttp,
  Transaction,
  TransactionGroup,
  TransactionHttp,
  TransferTransaction,
} from 'symbol-sdk';
import MosaicService from '../../services/symbol/MosaicService';
import NetworkService from '../../services/symbol/NetworkService';
import type { MosaicModel } from '../../storage/models/MosaicModel';
import type { NetworkModel } from '../../storage/models/NetworkModel';
// import FundsLockTransaction from 'components/organisms/transaction/FundsLockTransaction';
import type {
  AggregateTransactionModel,
  DirectionFilter,
  MosaicAliasTransactionModel,
  NamespaceRegistrationTransactionModel,
  TransactionModel,
  TransferTransactionModel,
} from '../../storage/models/TransactionModel';
import { formatTransactionLocalDateTime } from '../../utils/format';

export default class FetchTransactionService {
  /**
   * Check for pending signatures
   * @param address
   * @param network
   * @returns {Promise<void>}
   */
  static async hasAddressPendingSignatures(address: string, network: NetworkModel) {
    const transactionHttp = new TransactionHttp(network.node);
    // FIXME: Workaround with bad performance
    const accountHttp = new AccountHttp(network.node);
    let accountInfo;
    try {
      accountInfo = await accountHttp.getAccountInfo(Address.createFromRawAddress(address)).toPromise();
    } catch {
      return false;
    }
    if (!accountInfo.publicKey) {
      return false;
    }
    const publicAccount = PublicAccount.createFromPublicKey(
      accountInfo.publicKey,
      NetworkService.getNetworkTypeFromModel(network),
    );
    const transactionsData = await transactionHttp
      .search({
        pageNumber: 1,
        pageSize: 100,
        group: TransactionGroup.Partial,
        address: publicAccount.address,
      })
      .toPromise();
    // @ts-ignore
    for (const transaction: Transaction of transactionsData.data) {
      if (transaction instanceof AggregateTransaction) {
        // @ts-ignore
        const txModel = await this._populateAggregateTransactionModel({}, transaction, network);
        if (txModel.cosignaturePublicKeys.indexOf(publicAccount.publicKey) === -1 && txModel.status !== 'confirmed') {
          return true;
        }
      }
    }
    return false;
  }

  /**
   * Returns balance from a given Address and a node
   * @param rawAddress
   * @param page
   * @param directionFilter
   * @param network
   * @returns {Promise<number>}
   */
  static async getTransactionsFromAddress(
    rawAddress: string,
    page: number,
    directionFilter: DirectionFilter,
    network: NetworkModel,
  ): Promise<TransactionModel[]> {
    const transactionHttp = new TransactionHttp(network.node);
    const address = Address.createFromRawAddress(rawAddress);
    const baseSearchCriteria: {
      pageNumber: number;
      order: Order;
      signerPublicKey: string;
      recipientAddress?: any;
      address?: any;
    } = {
      pageNumber: page,
      order: Order.Desc,
      signerPublicKey: '',
    };
    if (directionFilter === 'SENT') {
      // FIXME: Workaround with bad performance
      const accountHttp = new AccountHttp(network.node);
      let accountInfo;
      try {
        accountInfo = await accountHttp.getAccountInfo(address).toPromise();
        baseSearchCriteria.signerPublicKey = accountInfo.publicKey;
      } catch {
        return [];
      }
    } else if (directionFilter === 'RECEIVED') {
      baseSearchCriteria.recipientAddress = address;
    } else {
      baseSearchCriteria.address = address;
    }

    const confirmedSearchCriteria = {
      ...baseSearchCriteria,
      group: TransactionGroup.Confirmed,
      pageSize: 25,
    };
    const partialSearchCriteria = {
      ...baseSearchCriteria,
      group: TransactionGroup.Partial,
      pageSize: 100,
    };
    const unconfirmedSearchCriteria = {
      ...baseSearchCriteria,
      group: TransactionGroup.Unconfirmed,
      pageSize: 100,
    };
    let allTransactions;
    if (page === 1) {
      const [confirmedTransactions, partialTransactions, unconfirmedTransactions] = await Promise.all([
        transactionHttp.search(confirmedSearchCriteria).toPromise(),
        transactionHttp.search(partialSearchCriteria).toPromise(),
        transactionHttp.search(unconfirmedSearchCriteria).toPromise(),
      ]);
      allTransactions = [...unconfirmedTransactions.data, ...partialTransactions.data, ...confirmedTransactions.data];
    } else {
      const confirmedTxs = await transactionHttp.search(confirmedSearchCriteria).toPromise();
      allTransactions = confirmedTxs.data;
    }
    const preLoadedMosaics = await this._preLoadMosaics(allTransactions, network);
    return Promise.all(
      allTransactions.map((tx) => this.symbolTransactionToTransactionModel(tx, network, preLoadedMosaics)),
    );
  }

  /**
   * Pre loads all mosaics from transaction list
   * @param transactions
   * @param network
   * @returns {Promise<$TupleMap<Promise<MosaicModel>[]>>}
   * @private
   */
  static async _preLoadMosaics(transactions: Transaction[], network: NetworkModel) {
    const mosaics = {};
    for (const transaction of transactions) {
      if (transaction instanceof TransferTransaction) {
        for (const mosaic of transaction.mosaics) {
          // @ts-ignore
          mosaics[mosaic.id.toHex()] = mosaic;
        }
      }
    }
    const mosaicModels = await Promise.all(
      Object.values(mosaics).map((mosaic) =>
        // @ts-ignore
        MosaicService.getMosaicModelFromMosaicId(mosaic, network),
      ),
    );
    return mosaicModels.reduce((acc, mosaicModel) => {
      // @ts-ignore
      acc[mosaicModel.mosaicId] = mosaicModel;
      return acc;
    }, {});
  }

  /**
   * Transform a symbol account to an account Model
   * @returns {{privateKey: string, name: string, id: string, type: AccountOriginType}}
   * @param transaction
   * @param network
   * @param preLoadedMosaics
   */
  static async symbolTransactionToTransactionModel(
    transaction: Transaction,
    network: NetworkModel,
    preLoadedMosaics?: any,
  ): Promise<TransactionModel> {
    let transactionModel: TransactionModel = {
      type: 'unknown',
      status: transaction.isConfirmed() ? 'confirmed' : 'unconfirmed',
      signerAddress: transaction?.signer?.address.pretty() || '',
      deadline: formatTransactionLocalDateTime(
        // @ts-ignore
        transaction?.deadline?.toLocalDateTime(network.epochAdjustment),
      ),
      hash: transaction?.transactionInfo?.hash || '',
      fee: 0,
    };
    if (transaction instanceof TransferTransaction) {
      transactionModel = await this._populateTransferTransactionModel(
        transactionModel,
        transaction,
        network,
        preLoadedMosaics,
      );
    } else if (transaction instanceof LockFundsTransaction) {
      transactionModel = await this._populateFundsLockTransactionModel(
        transactionModel,
        transaction,
        network,
        preLoadedMosaics,
      );
    } else if (transaction instanceof AggregateTransaction) {
      transactionModel = await this._populateAggregateTransactionModel(transactionModel, transaction);
    } else if (transaction instanceof NamespaceRegistrationTransaction) {
      transactionModel = await this._populateNamespaceRegistrationTransactionModel(transactionModel, transaction);
    } else if (transaction instanceof MosaicAliasTransaction) {
      transactionModel = await this._populateMosaicAliasTransactionModel(transactionModel, transaction, network);
    }
    return transactionModel;
  }

  /**
   * Populates transfer transaction Model
   * @param transactionModel
   * @param transaction
   * @param network
   * @param preLoadedMosaics
   * @returns {Promise<void>}
   * @private
   */
  static async _populateTransferTransactionModel(
    transactionModel: TransactionModel,
    transaction: TransferTransaction,
    network: NetworkModel,
    preLoadedMosaics?: any,
  ): Promise<TransferTransactionModel> {
    const mosaicModels: MosaicModel[] = [];
    for (const mosaic of transaction.mosaics) {
      let mosaicModel;
      if (preLoadedMosaics && preLoadedMosaics[mosaic.id.toHex()]) {
        mosaicModel = {
          ...preLoadedMosaics[mosaic.id.toHex()],
          amount: mosaic.amount.toString(),
        };
      } else {
        mosaicModel = await MosaicService.getMosaicModelFromMosaicId(mosaic, network);
      }
      mosaicModels.push(mosaicModel);
    }
    return {
      isConfirmed: false,
      ...transactionModel,
      type: 'transfer',
      recipientAddress:
        transaction.recipientAddress instanceof Address
          ? transaction.recipientAddress.pretty()
          : transaction.recipientAddress.id.toHex(),
      messageText: transaction.message.payload,
      messageEncrypted: transaction.message.type === 0x01,
      mosaics: mosaicModels,
    };
  }

  /**
   * Populates funds lock Model
   * @param transactionModel
   * @param transaction
   * @param network
   * @param preLoadedMosaics
   * @returns {Promise<void>}
   * @private
   */
  static async _populateFundsLockTransactionModel(
    transactionModel: TransactionModel,
    transaction: LockFundsTransaction,
    network: NetworkModel,
    preLoadedMosaics?: any,
  ): Promise<any> {
    let mosaicModel;
    if (preLoadedMosaics && preLoadedMosaics[transaction.mosaic.id.toHex()]) {
      mosaicModel = preLoadedMosaics[transaction.mosaic.id.toHex()];
      mosaicModel = {
        ...preLoadedMosaics[transaction.mosaic.id.toHex()],
        amount: transaction.mosaic.amount.toString(),
      };
    } else {
      mosaicModel = await MosaicService.getMosaicModelFromMosaicId(transaction.mosaic, network);
    }
    return {
      ...transactionModel,
      type: 'fundsLock',
      mosaic: mosaicModel,
      duration: transaction.duration.compact(),
      aggregateHash: transaction.hash,
    };
  }

  /**
   * Populates aggregate transaction Model
   * @param transactionModel
   * @param transaction
   * @param network
   * @returns {Promise<void>}
   * @private
   */
  static async _populateAggregateTransactionModel(
    transactionModel: TransactionModel,
    transaction: AggregateTransaction,
    network?: NetworkModel,
  ): Promise<AggregateTransactionModel> {
    let innerTransactionModels: any[] = [];
    try {
      const transactionHttp = new TransactionHttp(network?.node || '');

      const fullTransactionData = await transactionHttp
        .getTransaction(
          // @ts-ignore
          transaction.transactionInfo.id,
          transaction.isConfirmed()
            ? TransactionGroup.Confirmed
            : transaction.isUnconfirmed()
            ? TransactionGroup.Unconfirmed
            : TransactionGroup.Partial,
        )
        .toPromise();

      innerTransactionModels = await Promise.all(
        // @ts-ignore
        fullTransactionData.innerTransactions.map((innerTx) => {
          if (network) {
            this.symbolTransactionToTransactionModel(innerTx, network);
          }
        }),
      );
    } catch (e) {
      console.error(e);
    }
    const cosignaturePublicKeys = transaction.cosignatures.map((cosignature) => cosignature.signer.publicKey);
    if (transaction.signer) {
      cosignaturePublicKeys.push(transaction.signer.publicKey);
    }
    return {
      ...transactionModel,
      type: 'aggregate',
      innerTransactions: innerTransactionModels,
      cosignaturePublicKeys: cosignaturePublicKeys,
      signTransactionObject: transaction,
    };
  }

  /**
   * Populates namespace transaction Model
   * @param transactionModel
   * @param transaction
   * @param network
   * @returns {Promise<void>}
   * @private
   */
  static async _populateNamespaceRegistrationTransactionModel(
    transactionModel: TransactionModel,
    transaction: NamespaceRegistrationTransaction,
  ): Promise<NamespaceRegistrationTransactionModel> {
    const namespace = transaction.namespaceName;
    return {
      ...transactionModel,
      type: 'namespace',
      namespaceName: namespace,
    };
  }

  /**
   * Populates mosaicAlias transaction Model
   * @param transactionModel
   * @param transaction
   * @param network
   * @returns {Promise<void>}
   * @private
   */
  static async _populateMosaicAliasTransactionModel(
    transactionModel: TransactionModel,
    transaction: MosaicAliasTransaction,
    network: NetworkModel,
  ): Promise<MosaicAliasTransactionModel> {
    const namespaceId = transaction.namespaceId;
    const aliasAction = transaction.aliasAction;
    const mosaicId = transaction.mosaicId;
    const repositoryFactory = new RepositoryFactoryHttp(network.node);
    const namespaceHttp = repositoryFactory.createNamespaceRepository();
    const namespaceInfo = await namespaceHttp.getNamespacesNames([transaction.namespaceId]).toPromise();

    return {
      ...transactionModel,
      type: 'mosaicAlias',
      // @ts-ignore
      aliasAction: aliasAction,
      namespaceId: namespaceId.toHex(),
      namespaceName: namespaceInfo[0] ? namespaceInfo[0].name : '',
      mosaicId: mosaicId.toHex(),
    };
  }
}
