import { getType } from 'deox';
import { Action, combineReducers } from 'redux';
import { accountReducer } from '../../modules/account/reducer';
import { apiDocReducer } from '../../modules/apiDoc/reducer';
import { resetStore } from '../../modules/app/actions';
import { authReducer } from '../../modules/auth/reducer';
import { commonReducer } from '../../modules/common/reducer';
import { cvReducer } from '../../modules/cv/reducer';
import { harvestingReducer } from '../../modules/harvesting/reducer';
import { networkReducer } from '../../modules/network/reducer';
import { organizationReducer } from '../../modules/organization/reducer';
import { paymentsReducer } from '../../modules/payment/reducer';
import { productReducer } from '../../modules/product/reducer';
import { transactionReducer } from '../../modules/transaction/reducer';
import { tutorialReducer } from '../../modules/tutorial/reducer';
import { userReducer } from '../../modules/user/reducer';
import { walletReducer } from '../../modules/wallet/reducer';

const projectReducer = combineReducers({
  common: commonReducer,
  auth: authReducer,
  tutorial: tutorialReducer,
  network: networkReducer,
  account: accountReducer,
  wallet: walletReducer,
  transaction: transactionReducer,
  harvesting: harvestingReducer,
  products: productReducer,
  user: userReducer,
  organization: organizationReducer,
  payments: paymentsReducer,
  cv: cvReducer,
  apiDoc: apiDocReducer,
});

const rootReducer = (state: any | undefined, action: Action) => {
  if (action.type === getType(resetStore)) {
    state = undefined;
  }
  // @ts-ignore
  return projectReducer(state, action);
};

export default rootReducer;
