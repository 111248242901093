import { ReactComponent as InfoIcon } from './../../icons/info.svg';
import './InfoTitle.scss';

const InfoTitle = ({ info }) => (
  <div className="InfoTitle">
    <div className="icon-block">
      <InfoIcon className="info-icon" />
      <div className="info-desc">{info}</div>
    </div>
  </div>
);

export default InfoTitle;
