import { put, takeLatest } from 'redux-saga/effects';
import { UserApi } from '../../services/api/UserApi';
import { CommonHelpers } from '../../utils/commonHelpers';
import { Toast } from '../../utils/toastHelper';
import { editUserProfile, getUserData, removeUserAccount, setDataLoading } from './actions';

export function* getUserDataSaga({ payload }: { payload: string }) {
  try {
    yield put(setDataLoading(true));
    const res: any = yield UserApi.getUser(payload);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getUserData.success(data));

      if (data.info.country) {
        localStorage.setItem('country', data.info.country);
      }
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  } finally {
    yield put(setDataLoading(false));
  }
}
export function* editUserProfileSaga({ payload }: any) {
  try {
    const res: any = yield UserApi.updateUser(payload.params);
    const { status, data } = res;
    if (CommonHelpers.isOkStatus(status)) {
      yield put(getUserData.success(data));
      payload.onSuccess();
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    payload.onFail(message);
  }
}
export function* removeUserProfileSaga({ payload }: { payload: { onSuccess: () => void } }) {
  try {
    const res: any = yield UserApi.removeAccount();
    const { status } = res;
    if (CommonHelpers.isOkStatus(status)) {
      localStorage.clear();
      sessionStorage.clear();
      payload.onSuccess();
    }
  } catch (e) {
    const message = CommonHelpers.erorHandlerMessage(e);
    return Toast.error(message);
  }
}

export function* watchUser(): Generator<any> {
  //@ts-ignore
  yield takeLatest(getUserData.req, getUserDataSaga);
  yield takeLatest(removeUserAccount.req, removeUserProfileSaga);
  yield takeLatest(editUserProfile.req, editUserProfileSaga);
}
