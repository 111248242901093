/* eslint-disable @typescript-eslint/no-explicit-any */

import { Subscription } from 'rxjs';
import { map, reduce } from 'rxjs/operators';
import {
  Account,
  AccountHttp,
  AccountKeyLinkTransaction,
  Address,
  AggregateTransaction,
  Deadline,
  LinkAction,
  NodeHttp,
  NodeKeyLinkTransaction,
  Order,
  PersistentDelegationRequestTransaction,
  PublicAccount,
  ReceiptHttp,
  ReceiptPaginationStreamer,
  ReceiptType,
  RepositoryFactoryHttp,
  SignedTransaction,
  Transaction,
  TransactionHttp,
  TransactionService,
  UInt64,
  VrfKeyLinkTransaction,
} from 'symbol-sdk';
import AccountService from '../../services/symbol/AccountService';
import NetworkService from '../../services/symbol/NetworkService';
import type { AccountModel } from '../../storage/models/AccountModel';
import type { HarvestedBlock, HarvestingModel, HarvestingStatus } from '../../storage/models/HarvestingModel';
import type { NetworkModel } from '../../storage/models/NetworkModel';
import { HarvestingSecureStorage } from '../../storage/persistence/HarvestingSecureStorage';

export default class HarvestingService {
  /**
   * Get account linked keys
   * @param account
   * @param network
   * @returns {Promise<void>}
   */
  static async getAccountKeys(
    account: AccountModel,
    network: NetworkModel,
  ): Promise<{ vrf: any; linked: any; node: any }> {
    const accountHttp = new AccountHttp(network.node);
    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(account, network.type);
    const address = Address.createFromRawAddress(rawAddress);
    const accountInfo = await accountHttp.getAccountInfo(address).toPromise();
    return {
      vrf: accountInfo.supplementalPublicKeys.vrf,
      linked: accountInfo.supplementalPublicKeys.linked,
      node: accountInfo.supplementalPublicKeys.node,
    };
  }
  /**
   * Get account linked keys
   * @param account
   * @param network
   * @returns {Promise<void>}
   */
  static async getAccountStatus(account: AccountModel, network: NetworkModel): Promise<HarvestingStatus> {
    const keys = await this.getAccountKeys(account, network);
    const allKeysLinked = keys.node && keys.vrf && keys.linked;
    let unlockedAccounts: any[] = [];
    if (account.harvestingNode) {
      try {
        const nodeHttp = new NodeHttp(account.harvestingNode);
        unlockedAccounts = await nodeHttp.getUnlockedAccount().toPromise();
      } catch (e) {
        console.error(e, 86);
      }
    }
    const accountUnlocked = keys.linked && unlockedAccounts.some((publicKey) => publicKey === keys.linked.publicKey);

    if (allKeysLinked && accountUnlocked) {
      return 'ACTIVE';
    }
    const harvestedBlocks = await this.getHarvestedBlocks(account, network);
    if (harvestedBlocks.length > 0) {
      return 'ACTIVE';
    }
    let status: HarvestingStatus;
    if (allKeysLinked) {
      status = accountUnlocked ? 'ACTIVE' : account.isPersistentDelReqSent ? 'INPROGRESS_ACTIVATION' : 'KEYS_LINKED';
    } else {
      status = accountUnlocked ? 'INPROGRESS_DEACTIVATION' : 'INACTIVE';
    }
    return status;
  }

  /**
   * Get harvested blocks
   */
  static async getHarvestedBlocks(
    account: AccountModel,
    network: NetworkModel,
    pageNumber = 0,
    pageSize = 25,
  ): Promise<HarvestedBlock[]> {
    const receiptRepository = new ReceiptHttp(network.node);

    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(account, network.type);
    const targetAddress = Address.createFromRawAddress(rawAddress);

    const pageTxStatement = await receiptRepository
      .searchReceipts({
        targetAddress: targetAddress,
        receiptTypes: [ReceiptType.Harvest_Fee],
        pageNumber: pageNumber,
        pageSize: pageSize,
        order: Order.Desc,
      })
      .toPromise();

    const harvestedBlocks = pageTxStatement.data.map((t) => ({
      blockNo: t.height,
      // @ts-ignore
      fee: t.receipts.find((r) => r.targetAddress.plain() === targetAddress.plain())?.amount,
    }));
    const pageInfo = {
      isLastPage: pageTxStatement.isLastPage,
      pageNumber: pageTxStatement.pageNumber,
    };
    // @ts-ignore
    return { harvestedBlocks, pageInfo };
  }

  static async getHarvestedBlocksStats(
    account: AccountModel,
    network: NetworkModel,
    commit: any,
  ): Promise<Subscription> {
    const receiptRepository = new ReceiptHttp(network.node);
    const streamer = ReceiptPaginationStreamer.transactionStatements(receiptRepository);

    const rawAddress = AccountService.getAddressByAccountModelAndNetwork(account, network.type);
    const targetAddress = Address.createFromRawAddress(rawAddress);

    let counter = 0;
    return streamer
      .search({
        targetAddress: targetAddress,
        receiptTypes: [ReceiptType.Harvest_Fee],
        pageNumber: 1,
        pageSize: 50,
      })
      .pipe(
        map((t) => ({
          blockNo: t.height,
          // @ts-ignore
          fee: t.receipts.find((r) => r.targetAddress.plain() === targetAddress.plain()).amount,
        })),
        reduce(
          (acc, harvestedBlock) => ({
            totalBlockCount: ++counter,
            totalFeesEarned: acc.totalFeesEarned.add(harvestedBlock.fee),
          }),
          {
            totalBlockCount: 0,
            totalFeesEarned: UInt64.fromUint(0),
          },
        ),
      )
      .subscribe({
        next: (harvestedBlockStats) => {
          // @ts-ignore
          harvestedBlockStats.totalFeesEarned = harvestedBlockStats.totalFeesEarned.compact() / Math.pow(10, 6);
          commit(harvestedBlockStats);
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        error: () => {},
      });
  }

  static async getPeerNodes(network: NetworkModel): Promise<{ publicKey: string; url: string }[]> {
    const repositoryFactory = new RepositoryFactoryHttp(network.node);
    const nodeRepository = repositoryFactory.createNodeRepository();

    const peerNodes = await nodeRepository.getNodePeers().toPromise();
    return peerNodes
      .sort((a, b) => a.host.localeCompare(b.host))
      .map((node) => ({ publicKey: node.publicKey, url: node.host }));
  }

  static async getNodePublicKeyFromNode(node: string): Promise<string | undefined> {
    const repositoryFactory = new RepositoryFactoryHttp(node);
    const nodeRepository = repositoryFactory.createNodeRepository();
    const nodeInfo = await nodeRepository.getNodeInfo().toPromise();
    return nodeInfo.nodePublicKey;
  }

  /**
   * Static list for the time being - until the dynamic solution
   */
  static getHarvestingNodeList() {
    return [];
  }

  /**
   * Creates and links the keys
   *
   * @param accountModel
   * @param nodePublicKey
   * @param network
   */
  static async createAndLinkKeys(accountModel: AccountModel, nodePublicKey: string, network: NetworkModel) {
    const networkType = NetworkService.getNetworkTypeFromModel(network);
    const vrfAccount = Account.generateNewAccount(networkType);
    const remoteAccount = Account.generateNewAccount(networkType);

    const maxFee = UInt64.fromUint(1000000);
    const vrfTx = VrfKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      vrfAccount.publicKey,
      LinkAction.Link,
      networkType,
      maxFee,
    );
    const remoteTx = AccountKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      remoteAccount.publicKey,
      LinkAction.Link,
      networkType,
      maxFee,
    );
    const nodeTx = NodeKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      nodePublicKey,
      LinkAction.Link,
      networkType,
      maxFee,
    );

    const account = Account.createFromPrivateKey(accountModel.privateKey, networkType);
    const currentSigner = account.publicAccount;
    const aggregateTx = AggregateTransaction.createComplete(
      Deadline.create(network.epochAdjustment, 2),
      [vrfTx.toAggregate(currentSigner), remoteTx.toAggregate(currentSigner), nodeTx.toAggregate(currentSigner)],
      networkType,
      [],
      maxFee,
    );
    const signedTx = account.sign(aggregateTx, network.generationHash);
    const transactionHttp = new TransactionHttp(network.node);
    transactionHttp.announce(signedTx);

    await HarvestingSecureStorage.saveHarvestingModel(String(accountModel.id), {
      vrfPrivateKey: vrfAccount.privateKey,
      remotePrivateKey: remoteAccount.privateKey,
      nodePublicKey: nodePublicKey,
    });
  }

  /**
   * Unlinks all keys
   *
   * @param accountModel
   * @param network
   */
  static async unlinkAllKeys(accountModel: AccountModel, network: NetworkModel) {
    const networkType = NetworkService.getNetworkTypeFromModel(network);
    const keys = await this.getAccountKeys(accountModel, network);

    const maxFee = UInt64.fromUint(1000000);
    const vrfTx = VrfKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      keys.vrf.publicKey,
      LinkAction.Unlink,
      networkType,
      maxFee,
    );
    const remoteTx = AccountKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      keys.linked.publicKey,
      LinkAction.Unlink,
      networkType,
      maxFee,
    );
    const nodeTx = NodeKeyLinkTransaction.create(
      Deadline.create(network.epochAdjustment, 2),
      keys.node.publicKey,
      LinkAction.Unlink,
      networkType,
      maxFee,
    );

    const account = Account.createFromPrivateKey(accountModel.privateKey, networkType);
    const currentSigner = account.publicAccount;
    const aggregateTx = AggregateTransaction.createComplete(
      Deadline.create(network.epochAdjustment, 2),
      [vrfTx.toAggregate(currentSigner), remoteTx.toAggregate(currentSigner), nodeTx.toAggregate(currentSigner)],
      networkType,
      [],
      maxFee,
    );
    const signedTx = account.sign(aggregateTx, network.generationHash);
    const transactionHttp = new TransactionHttp(network.node);
    transactionHttp.announce(signedTx);

    await HarvestingSecureStorage.clear();
  }

  /**
   * Sends Persistent harvesting request
   * @param harvestingModel
   * @param accountModel
   * @param network
   */
  static async sendPersistentHarvestingRequest(
    harvestingModel: HarvestingModel,
    accountModel: AccountModel,
    network: NetworkModel,
  ) {
    const maxFee = UInt64.fromUint(1000000); // TODO: UInt64.fromUint(feesConfig.highest); // fixed to the Highest, txs must get confirmed
    const networkType = NetworkService.getNetworkTypeFromModel(network);

    const account = Account.createFromPrivateKey(accountModel.privateKey, networkType);

    const tx = PersistentDelegationRequestTransaction.createPersistentDelegationRequestTransaction(
      Deadline.create(network.epochAdjustment, 2),
      harvestingModel.remotePrivateKey,
      harvestingModel.vrfPrivateKey,
      harvestingModel.nodePublicKey,
      networkType,
      maxFee,
    );

    const signedTx = account.sign(tx, network.generationHash);
    const transactionHttp = new TransactionHttp(network.node);
    return transactionHttp.announce(signedTx);
  }

  /**
   * Start harvesting
   * @param action
   * @param accountModel
   * @param network
   * @param nodePublicKey
   * @returns {Promise<void>}
   */
  static async doHarvesting(
    action: 'START' | 'STOP' | 'SWAP',
    accountModel: AccountModel,
    network: NetworkModel,
    nodePublicKey?: string,
  ) {
    const networkType = NetworkService.getNetworkTypeFromModel(network);
    // @ts-ignore
    const txs = await this._getTransactions(action, accountModel, network, nodePublicKey);
    const account = Account.createFromPrivateKey(accountModel.privateKey, networkType);
    if (txs.length === 1) {
      const signedTx = account.sign(txs[0], network.generationHash);
      const transactionHttp = new TransactionHttp(network.node);
      return transactionHttp.announce(signedTx);
    } else if (txs.length === 2) {
      const firstSigned = account.sign(txs[0], network.generationHash);
      const secondSigned = account.sign(txs[1], network.generationHash);
      return this.announceChainedBinary(firstSigned, secondSigned, network);
    } else {
      throw new Error('Unexpected number of transactions: ' + txs.length);
    }
  }

  /**
   * Getter for PERSISTENT DELEGATION REQUEST transactions that will be staged
   * @return {TransferTransaction[]}
   */
  static async _getTransactions(
    action: any,
    account: AccountModel,
    network: NetworkModel,
    nodePublicKey: string,
  ): Promise<Transaction[]> {
    const maxFee = UInt64.fromUint(1000000); // TODO: UInt64.fromUint(feesConfig.highest); // fixed to the Highest, txs must get confirmed
    const txs: Transaction[] = [];
    const txsToBeAggregated: Transaction[] = [];

    const keys = await this.getAccountKeys(account, network);
    /*
         LINK
         START => link all (new keys)
         STOP =>  unlink all (linked keys)
         SWAP =>  unlink(linked) + link all (new keys)
         */
    const networkType = NetworkService.getNetworkTypeFromModel(network);

    if (keys.linked) {
      const accountKeyUnLinkTx = AccountKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        keys.linked.publicKey,
        LinkAction.Unlink,
        networkType,
        maxFee,
      );
      txsToBeAggregated.push(accountKeyUnLinkTx);
    }

    if (keys.vrf) {
      const vrfKeyUnLinkTx = VrfKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        keys.vrf.publicKey,
        LinkAction.Unlink,
        networkType,
        maxFee,
      );
      txsToBeAggregated.push(vrfKeyUnLinkTx);
    }

    if (keys.node) {
      const nodeUnLinkTx = NodeKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        keys.node.publicKey,
        LinkAction.Unlink,
        networkType,
        maxFee,
      );
      txsToBeAggregated.push(nodeUnLinkTx);
    }

    const newRemoteAccount = Account.generateNewAccount(networkType);
    const newVrfAccount = Account.generateNewAccount(networkType);

    if (action !== 'STOP') {
      const accountKeyLinkTx = AccountKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        newRemoteAccount.publicKey,
        LinkAction.Link,
        networkType,
        maxFee,
      );
      const vrfKeyLinkTx = VrfKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        newVrfAccount.publicKey,
        LinkAction.Link,
        networkType,
        maxFee,
      );
      const nodeLinkTx = NodeKeyLinkTransaction.create(
        Deadline.create(network.epochAdjustment, 2),
        nodePublicKey,
        LinkAction.Link,
        networkType,
        maxFee,
      );
      txsToBeAggregated.push(accountKeyLinkTx, vrfKeyLinkTx, nodeLinkTx);
    }

    if (txsToBeAggregated.length === 1) {
      txs.push(txsToBeAggregated[0]);
    }

    if (txsToBeAggregated.length > 1) {
      const currentSigner = PublicAccount.createFromPublicKey(String(account.id), networkType);
      txs.push(
        AggregateTransaction.createComplete(
          Deadline.create(network.epochAdjustment, 2),
          txsToBeAggregated.map((t) => t.toAggregate(currentSigner)),
          networkType,
          [],
          maxFee,
        ),
      );
    }

    if (action !== 'STOP') {
      const persistentDelegationReqTx =
        PersistentDelegationRequestTransaction.createPersistentDelegationRequestTransaction(
          Deadline.create(network.epochAdjustment, 2),
          newRemoteAccount.privateKey,
          newVrfAccount.privateKey,
          nodePublicKey,
          networkType,
          maxFee,
        );
      txs.push(persistentDelegationReqTx);
    }

    return txs;
  }

  /**
   * Announce chained transactions
   * @param first
   * @param second
   * @param network
   * @returns {Promise<void>}
   */
  static announceChainedBinary(
    first: SignedTransaction,
    second: SignedTransaction,
    network: NetworkModel,
  ): Promise<void> {
    const repositoryFactory = new RepositoryFactoryHttp(network.node, {
      websocketInjected: WebSocket,
    });
    const listener = repositoryFactory.createListener();
    const transactionService = new TransactionService(
      repositoryFactory.createTransactionRepository(),
      repositoryFactory.createReceiptRepository(),
    );
    return listener.open().then(() => {
      transactionService.announce(first, listener).subscribe(
        () => {
          transactionService.announce(second, listener).subscribe(() => {
            listener.close();
          });
        },
        (err) => console.error(err),
      );
    });
  }
}
