import { createReducer } from 'deox';
import produce from 'immer';
import { changeTutorialAction } from '../../modules/tutorial/actions';
import { TutorialState } from './types';

const initialState: TutorialState = {
  visible: true,
};

export const tutorialReducer = createReducer(initialState, (handle) => [
  handle(changeTutorialAction, (state, { payload }) =>
    produce(state, (draft) => {
      draft.visible = payload.status;
    }),
  ),
]);
