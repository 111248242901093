import moment from 'moment';
import * as Yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

const Validation = {
  // @ts-ignore
  email: () => Yup.string().trim().email('Please enter a valid e-mail').required('Email required'),
  password: (msg?: string) =>
    Yup.string()
      .min(6, 'Password should exist minimum 6 characters')
      .max(16, 'Password should exist maximum 16 characters')
      .matches(/[A-Z]/, 'Password should exist uppercase letter')
      .matches(/[a-z]/, 'Password should exist lowercase letter')
      .matches(/[0-9]/, 'Password should exist at least one numeric')
      .required(msg || 'Password required'),
  confirmPassword: () => Yup.string().oneOf([Yup.ref('password')], 'validations:passwordMatch'),
  object: (shape: ObjectShape) => Yup.object().shape(shape),
};

export const LoginValidation = Validation.object({
  email: Validation.email().required('Please enter your email address'),
  password: Yup.string().required('Password is required').max(256, 'Password is invalid.'),
});
export const SignupValidation = Validation.object({
  email: Validation.email(),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .max(256, 'Password must be less than 256 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter e.g. (A-Z)')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter e.g. (a-z)')
    .matches(/[0-9]/, 'Password must contain at least one numeric character e.g. (0-9)')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      'Password must contain at least one special symbol e.g. (~!@#$%^&*_-+=`|(){}[]:;")',
    )
    .required('Password required'),
});

export const OrganizationInfoValidation = Validation.object({
  organizationName: Yup.string().required('Company name is mandatory.').max(64, 'Length should be 64 symbols or less.'),
  company_registration_number: Yup.string()
    .required('Registration number is mandatory.')
    .min(6, 'Length should be 6 symbols or more.')
    .max(250, 'Length should be 250 symbols or less.'),
  company_jurisdiction_code: Yup.string().required('Registration country is mandatory.'),
});

export const ConfirmAnswersValidation = Validation.object({
  answerOne: Yup.string().required('Answer is mandatory.'),
  answerTwo: Yup.string().required('Answer is mandatory.'),
  answerThree: Yup.string().required('Answer is mandatory.'),
});

export const SecAnswersValidation = Validation.object({
  questionOne: Yup.object()
    .test(
      'is-unique',
      'Question must be unique',
      (field, ctx) =>
        !field.value || ![ctx.parent.questionTwo.value, ctx.parent.questionThree.value].includes(field.value),
    )
    .shape({
      value: Yup.string().required('Question is mandatory.'),
    }),
  answerOne: Yup.string().required('Answer is mandatory.'),
  questionTwo: Yup.object()
    .test(
      'is-unique',
      'Question must be unique',
      (field, ctx) =>
        !field.value || ![ctx.parent.questionOne.value, ctx.parent.questionThree.value].includes(field.value),
    )
    .shape({
      value: Yup.string().required('Question is mandatory.'),
    }),
  answerTwo: Yup.string().required('Answer is mandatory.'),
  questionThree: Yup.object()
    .test(
      'is-unique',
      'Question must be unique',
      (field, ctx) =>
        !field.value || ![ctx.parent.questionOne.value, ctx.parent.questionTwo.value].includes(field.value),
    )
    .shape({
      value: Yup.string().required('Question is mandatory.'),
    }),
  answerThree: Yup.string().required('Answer is mandatory.'),
});

export const ApproveExperienceValidation = Validation.object({
  email: Validation.email(),
});
export const ForgotValidation = Validation.object({
  email: Validation.email(),
});
export const ConfirmValidation = Validation.object({
  code: Yup.string().min(6, 'validations:codeMin6').max(6, 'validations:codeMax6'),
});
export const NewPasswordValidation = Validation.object({
  password: Validation.password().required('validations:password_required'),
  passwordConfirmation: Validation.confirmPassword().required('validations:passwordConfirmation_required'),
});

export const ProfileDetailsValidation = Validation.object({
  firstName: Yup.string().max(255).required('First name is mandatory.'),
  middleName: Yup.string().max(255),
  lastName: Yup.string().max(255).required('Last name is mandatory.'),
  phone: Yup.string()
    .required('Phone is mandatory.')
    .min(10, 'Please enter a valid Phone.')
    .max(15, 'Please enter a valid Phone.'),
  address: Yup.string().required('Address is mandatory.').max(255),
  address2: Yup.string().max(255),
  birthdate: Yup.string()
    .nullable()
    .required('Date of birth is mandatory.')
    .test('DOB', 'Please choose a valid date of birth', (value) => {
      if (!value) return true;
      return moment().diff(moment(value), 'years') >= 18;
    }),
});

export const EmployeeAssessmentValidation = Validation.object({
  rate: Yup.string().required(),
  position: Yup.string().required(),
  comment: Yup.string().max(1000, 'validations:comment1000'),
});

export const CvItemValidation = Validation.object({
  employerId: Yup.string().required('validations:employerId'),
  startDate: Yup.string().required('validations:startDate'),
  positionName: Yup.string().required('validations:positionName'),
  // positionDescription: Yup.string().required('validations:positionDescription'),
  // keyResults: Yup.string().required('validations:keyResults'),
});

export const CvValidation = Validation.object({
  firstName: Yup.string().required('validations:firstName'),
  lastName: Yup.string().required('validations:lastName'),
  // desiredPosition: Yup.string().required('validations:desiredPosition'),
  // address: Yup.string().required('validations:address'),
  email: Yup.string().email('validations:notValidEmail'),
  // phone: Yup.string().required('validations:phone'),
  // skype: Yup.string().required('validations:skype'),
  // linkedin: Yup.string().required('validations:linkedin'),
  summary: Yup.string().max(1000, 'validations:summary'),
  education: Yup.string().max(1000, 'validations:education'),
  additionalInformation: Yup.string().max(1000, 'validations:additionalInformation'),
  keyKnowledgeAndSkills: Yup.string().max(1000, 'validations:keyKnowledgeAndSkills'),
});

export const AddProductValidation = Validation.object({
  images: Yup.array(),
  productName: Yup.string().required('Please provide product name').max(255, 'Length should be 255 symbols or less.'),
  description: Yup.string().max(10000, 'Length should be 10000 symbols or less.'),
});

export const CreateWarrantyValidation = Validation.object({
  title: Yup.string().required('Please provide warranty name'),
  description: Yup.string().required('Please provide description'),
  period: Yup.object().required('Please provide a period for warranty').nullable(),
});

export const ExportImportProductKeysValidation = Validation.object({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .max(256, 'Password must be less than 256 characters long')
    .matches(/[A-Z]/, 'Password must contain at least one uppercase letter e.g. (A-Z)')
    .matches(/[a-z]/, 'Password must contain at least one lowercase letter e.g. (a-z)')
    .matches(/[0-9]/, 'Password must contain at least one numeric character e.g. (0-9)')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
      'Password must contain at least one special symbol e.g. (~!@#$%^&*_-+=`|(){}[]:;")',
    )
    .required('Password required'),
});
