import encryptpwd from 'encrypt-with-password';
import { saveAs } from 'file-saver';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Button } from '../../../../components/Button/Button';
import Text from '../../../../components/common/Text';
import { Input } from '../../../../components/Input/Input';
import { Modal } from '../../../../components/Modal/Modal';
import { ProductsSecureStorage } from '../../../../storage/persistence/ProductsSecureStorage';
import { ExportImportProductKeysValidation } from '../../../../utils/validations';
import styles from './ExportProductKeysModal.module.scss';

interface ExportProductKeysModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ExportProductKeysModal = ({ isOpen, onClose }: ExportProductKeysModalProps) => {
  const userEmail = useSelector((state: any) => state.user?.userData?.email);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const createFileWithPrivateKeys = async () => {
    setIsLoading(true);
    const products = await ProductsSecureStorage.getAllProducts();
    const encryptedData = encryptpwd.encryptJSON(products, values.password);
    const dataStr =
      'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify({ do_not_modify: encryptedData }));

    saveAs(
      dataStr,
      `Veritise private keys ${userEmail} ${new Date().getFullYear()} ${new Date()
        .getMonth()
        .toString()
        .padStart(2, '0')} ${new Date().getDate().toString().padStart(2, '0')}.json`,
    );

    setIsLoading(false);
  };

  const { setFieldValue, setErrors, values, errors, handleSubmit } = useFormik({
    initialValues: {
      password: '',
    },
    validateOnChange: false,
    validationSchema: ExportImportProductKeysValidation,
    onSubmit: createFileWithPrivateKeys,
  });

  const handleClose = () => {
    setFieldValue('password', '');
    setErrors({ password: '' });
    onClose();
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <div className={styles.modalContent}>
        <Text type="h1">{t('_Export_private_keys_')}</Text>

        <Input
          type="password"
          value={values.password}
          label={t('_SetPassword_')}
          isPassword
          hasError={!!errors.password}
          errorMessage={errors.password}
          size="lg"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('password', e.target.value)}
        />
        <Button onClick={handleSubmit} isPrimary isLoading={isLoading}>
          {t('_Export_private_keys_')}
        </Button>
      </div>
    </Modal>
  );
};

export default ExportProductKeysModal;
