import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import BusyLoader from '../../../components/BusyLoader';
import ChangeLogItem from '../../../components/ChangeLogItem';
import Text from '../../../components/common/Text';
import NoItem from '../../../components/NoItem';
import { setChangeLogs } from '../../../modules/apiDoc/actions';
import './styles.scss';

const ChangeLog = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { changeLogs } = useSelector((state: any) => state.apiDoc);
  const [isBusy, setIsBusy] = useState<boolean>(false);

  const onFinish = () => {
    setIsBusy(false);
  };

  const fetchChangeLogs = useCallback(() => {
    setIsBusy(true);
    dispatch(setChangeLogs.request({ onFinish }));
  }, [dispatch]);

  useEffect(() => {
    fetchChangeLogs();
  }, []);

  return (
    <div className="ChangeLog">
      <Text type="h4" className="logs-title" fontWeight="bolder">
        {t('_VeritiseAPIReleases_')}
      </Text>
      <BusyLoader isBusy={isBusy}>
        <div className="version-block flexible jCenter">
          {changeLogs?.data?.map((item) => (
            <div key={item._id} className="verios-item flexible aCenter jCenter">
              {item.version}
            </div>
          ))}
        </div>
        {changeLogs?.data?.map((item) => (
          <ChangeLogItem key={item._id} date={item.updatedAt} version={item.version} items={item.items} />
        ))}
        {!changeLogs?.data?.length && <NoItem />}
      </BusyLoader>
    </div>
  );
};

export default ChangeLog;
