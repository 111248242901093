import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from 'react-modal';
import styles from './ProductOwnerModal.module.scss';

interface ProductOwnerModalProps {
  open: boolean;
  onClose: () => void;
  owner: any;
}

export const ProductOwnerModal: FC<ProductOwnerModalProps> = ({ open, onClose, owner }) => {
  const { t } = useTranslation();

  return (
    <Modal
      isOpen={open}
      onRequestClose={onClose}
      ariaHideApp={false}
      className={styles['modal']}
      overlayClassName={styles['modal-overlay']}
      contentLabel={t('_CreateProduct_')}
    >
      <span className={styles['modal-close']} onClick={onClose}>
        &#x2715;
      </span>

      <div className={styles['owner-info']}>
        <div className={styles['owner-info__field']}>
          <span className={styles['title']}>{t('_Name_')}</span>
          <span className={styles['value']}>{owner?.info?.fullName}</span>
        </div>
        <div className={styles['owner-info__field']}>
          <span className={styles['title']}>{t('_DateOfBirth_')}</span>
          <span className={styles['value']}>{open ? owner?.info?.birthdate : '-'}</span>
        </div>
        <div className={styles['owner-info__field']}>
          <span className={styles['title']}>{t('_Email_')}</span>
          <span className={styles['value']}>{owner?.email}</span>
        </div>
        <div className={styles['owner-info__field']}>
          <span className={styles['title']}>{t('_Phone_')}</span>
          <span className={styles['value']}>{owner?.info?.phone || '-'}</span>
        </div>
        <div className={styles['owner-info__field']}>
          <span className={styles['title']}>{t('_Address_')}</span>
          <span className={styles['value']}>{owner?.info?.address || '-'}</span>
        </div>
      </div>
    </Modal>
  );
};
