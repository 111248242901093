import cn from 'classnames';
import { FC } from 'react';
import Text from '../Text';
import styles from './styles.module.scss';

interface IProps {
  label: string;
  text?: string | Date;
  children?: any;
  className?: any;
}

const TextGroup: FC<IProps> = ({ label, text, children, className }) => {
  return (
    <div className={cn([styles.textGroupContainer, className])}>
      <Text type="caption" color="gray-darkness" className={styles.textGroupContainer__label}>
        {label}
      </Text>
      {text ? (
        <Text type="subtitle_m" fontWeight="bold" color="dark-primary">
          {text}
        </Text>
      ) : (
        children
      )}
    </div>
  );
};

export default TextGroup;
