import { AxiosError } from 'axios';
import { Action, createAction } from 'deox';

export type ResponseError = { message: string } | null;
export type ResponseErrorWithCb = {
  onSuccessCb?: () => void;
  onErrorCb?: (err: AxiosError) => void;
};
type AbstractFailActionCreatorType = (payload: ResponseError) => Action<string, ResponseError>;

export const processRequestError = createAction(
  'errors/PROCESS_REQUEST_ERROR',
  // @ts-ignore
  (resolve) => (payload: { error: AxiosError; failAction: AbstractFailActionCreatorType }) => resolve(payload),
);
